import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Input, Button, Row } from "reactstrap"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { addParentProvidedFood as onAddParentProvidedFood } from "store/actions"
import { isEmpty } from "lodash"
import PageModal from "components/Common/PageModal"

const formdefault = {
  Foods: [
    {
      ParentProvidedFoodID: 0,
      Food: "",
    },
  ],
}

const FoodModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()

  //////////////////Child STATE/////////////////////
  const { child, providedfoods } = useSelector(state => state.child)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Foods: yup.array().of(
      yup.object().shape({
        ParentProvidedFoodID: yup.number(),
        Food: yup.string(),
      })
    ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, getValues, trigger } = methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "Foods",
    control,
  })

  useEffect(() => {
    if (isEmpty(providedfoods.Foods)) {
      reset(formdefault)
    } else {
      reset(providedfoods)
    }
  }, [providedfoods])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddParentProvidedFood(child.ChildID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Parent Provided Food"
    >
      <FormProvider {...methods}>
        <div className="float-none">
          {fields.map((item, index) => (
            <div key={`foods${index}`} className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                {index + 1 + `.`}
                <div>
                  <Controller
                    name={`Foods[${index}].Food`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          type="text"
                          className="form-control mb-3"
                          id={`Foods[${index}].Food`}
                        />
                      </>
                    )}
                  />
                </div>
                <Button
                  hidden={index > 0}
                  onClick={() =>
                    append({
                      ParentProvidedFoodID: 0,
                      Food: "",
                    })
                  }
                >
                  Add
                </Button>
                <Button hidden={index === 0} onClick={() => remove(index)}>
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      </FormProvider>
    </PageModal>
  )
}

FoodModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default FoodModal
