import {
  GET_ALBUMS_FAIL,
  GET_ALBUMS_SUCCESS,
  DELETE_ALBUM_SUCCESS,
  DELETE_ALBUM_FAIL,
  ADD_ALBUM_FAIL,
  ADD_ALBUM_SUCCESS,
  GET_ALBUM_DETAIL_SUCCESS,
  GET_ALBUM_DETAIL_FAIL,
  UPDATE_ALBUM_SUCCESS,
  UPDATE_ALBUM_FAIL,
  RESET_ALBUM_SUCCESS,
  DELETE_ALBUMMEDIA_SUCCESS,
  DELETE_ALBUMMEDIA_FAIL,
  ADD_NEW_ALBUMMEDIA_SUCCESS,
  ADD_NEW_ALBUMMEDIA_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  albums: [],
  album: {},
  albummedias: [],
  albummedia: {},
  error: {},
  loading: true,
}

const Album = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALBUMS_SUCCESS:
      return {
        ...state,
        albums: action.payload,
        loading: true,
      }
    case GET_ALBUMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_ALBUM_SUCCESS: {
      const data = {
        ...state,
        albums: state.albums.filter(
          album => album.AlbumID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        albums: state.albums.filter(album => album.AlbumID !== action.payload),
      }
    }
    case DELETE_ALBUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ALBUM_SUCCESS:
      return {
        ...state,
        albums: [...state.albums, action.payload],
      }
    case ADD_ALBUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALBUM_DETAIL_SUCCESS:
      return {
        ...state,
        album: action.payload,
        loading: true,
      }

    case GET_ALBUM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ALBUM_SUCCESS:
      return {
        ...state,
        albums: state.albums.map(album =>
          album.AlbumID.toString() === action.payload.AlbumID.toString()
            ? { album, ...action.payload }
            : album
        ),
      }

    case UPDATE_ALBUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_ALBUM_SUCCESS:
      return {
        ...state,
        album: action.payload,
      }

    case DELETE_ALBUMMEDIA_SUCCESS: {
      return {
        ...state,
        albums: state.albums.filter(album => album.AlbumID == action.payload),
      }
      
    }
    case DELETE_ALBUMMEDIA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NEW_ALBUMMEDIA_SUCCESS:
      return {
        ...state,
        forum: action.payload,
      }
    case ADD_NEW_ALBUMMEDIA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Album
