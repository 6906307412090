import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAIL,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_SUCCESS,
  GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_FAIL,
  GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_DETAIL_SUCCESS,
  GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_DETAIL_FAIL,
} from "./actionTypes"
import * as moment from "moment"

const INIT_STATE = {
  programenrollments: [],
  programenrollmentDetails: [],
  events: [
    {
      id: 1,
      title: "Brithday Party",
      start: new Date().setDate(new Date().getDate() + 1),
      end: new Date().setDate(new Date().getDate() + 1),
      className: "bg-success text-white",
    },
    {
      id: 2,
      title: "Meeting",
      start: new Date(),
      end: new Date(),
      className: "bg-success text-white",
    },
  ],
  categories: [
    {
      id: 1,
      title: "New Event Planning",
      type: "bg-success",
    },
    {
      id: 2,
      title: "Meeting",
      type: "bg-info",
    },
    {
      id: 3,
      title: "Generating Reports",
      type: "bg-warning",
    },
    {
      id: 4,
      title: "Create New theme",
      type: "bg-danger",
    },
  ],
  error: {},
}

const Calendar = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      }

    case GET_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
      }

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        programenrollments: action.payload,
        loading: true,
      }
    case GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_DETAIL_SUCCESS:
         return {
        ...state,
        programenrollmentDetails: action.payload,
        loading: true,
      }
    case GET_CALENDAR_PROGRAMS_ENROLLMENT_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Calendar
