import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Bus Redux States
import {
  GET_BUS_SCHEDULES,
  RESET_BUS_SCHEDULES,
  ADD_BUS_SCHEDULES,
  DELETE_BUS_SCHEDULE,
} from "./actionTypes"
import {
  getBusSchedulesSuccess,
  getBusSchedulesFail,
  resetBusScheduleSuccess,
  addBusScheduleSuccess,
  addBusScheduleFail,
  deleteBusScheduleSuccess,
  deleteBusScheduleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBusSchedules,
  addBusSchedule,
  deleteBusSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchBusSchedules({ filter }) {
  try {
    const response = yield call(getBusSchedules, filter)

    yield put(getBusSchedulesSuccess(response.Table))
  } catch (error) {
    yield put(getBusSchedulesFail(error))
  }
}

function* onAddBusSchedule({ payload: busSchedules }) {
  try {
    const response = yield call(addBusSchedule, busSchedules)

    yield put(addBusScheduleSuccess(response.Table))
    toast.success("Bus schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addBusScheduleFail(error))
    console.log(busSchedules)
    toast.error("Bus schedule addition failed", { autoClose: 2000 })
  }
}

function* onResetBusSchedules() {
  try {
    yield put(resetBusScheduleSuccess({}))
  } catch (error) {}
}

function* onDeleteBusSchedule({ payload: busSchedules }) {
  try {
    const response = yield call(deleteBusSchedule, busSchedules)
    yield put(deleteBusScheduleSuccess(response.Table))
    toast.success("Bus schedule deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteBusScheduleFail(error))
    toast.error("Bus schedule deletion failed", { autoClose: 2000 })
  }
}

function* busScheduleSaga() {
  yield takeEvery(GET_BUS_SCHEDULES, fetchBusSchedules)
  yield takeEvery(RESET_BUS_SCHEDULES, onResetBusSchedules)
  yield takeEvery(ADD_BUS_SCHEDULES, onAddBusSchedule)
  yield takeEvery(DELETE_BUS_SCHEDULE, onDeleteBusSchedule)
}

export default busScheduleSaga
