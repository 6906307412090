import {
  GET_FLEXIBLES,
  GET_FLEXIBLES_FAIL,
  GET_FLEXIBLES_SUCCESS,
  DELETE_FLEXIBLE,
  DELETE_FLEXIBLE_FAIL,
  DELETE_FLEXIBLE_SUCCESS,
  ADD_NEW_FLEXIBLE,
  ADD_FLEXIBLE_SUCCESS,
  ADD_FLEXIBLE_FAIL,
  GET_FLEXIBLE_DETAIL,
  GET_FLEXIBLE_DETAIL_SUCCESS,
  GET_FLEXIBLE_DETAIL_FAIL,
  UPDATE_FLEXIBLE,
  UPDATE_FLEXIBLE_SUCCESS,
  UPDATE_FLEXIBLE_FAIL,
  UPDATE_FLEXIBLE_SWITCH,
  UPDATE_FLEXIBLE_SWITCH_SUCCESS,
  UPDATE_FLEXIBLE_SWITCH_FAIL,
  UPDATE_FLEXIBLE_DEFAULT_RATE,
  UPDATE_FLEXIBLE_DEFAULT_RATE_SUCCESS,
  UPDATE_FLEXIBLE_DEFAULT_RATE_FAIL,
} from "./actionTypes"

export const getFlexibles = () => ({
  type: GET_FLEXIBLES,
})

export const getFlexiblesSuccess = flexibles => ({
  type: GET_FLEXIBLES_SUCCESS,
  payload: flexibles,
})

export const getFlexiblesFail = error => ({
  type: GET_FLEXIBLES_FAIL,
  payload: error,
})

export const deleteFlexible = flexibleId => ({
  type: DELETE_FLEXIBLE,
  payload: flexibleId,
})

export const deleteFlexibleSuccess = flexible => ({
  type: DELETE_FLEXIBLE_SUCCESS,
  payload: flexible,
})

export const deleteFlexibleFail = error => ({
  type: DELETE_FLEXIBLE_FAIL,
  payload: error,
})
export const addNewFlexible = flexible => ({
  type: ADD_NEW_FLEXIBLE,
  payload: flexible,
})

export const addFlexibleSuccess = flexible => ({
  type: ADD_FLEXIBLE_SUCCESS,
  payload: flexible,
})

export const addFlexibleFail = error => ({
  type: ADD_FLEXIBLE_FAIL,
  payload: error,
})

export const getFlexibleDetail = flexibleId => ({
  type: GET_FLEXIBLE_DETAIL,
  flexibleId,
})

export const getFlexibleDetailSuccess = flexible => ({
  type: GET_FLEXIBLE_DETAIL_SUCCESS,
  payload: flexible,
})

export const getFlexibleDetailFail = error => ({
  type: GET_FLEXIBLE_DETAIL_FAIL,
  payload: error,
})

export const updateFlexible = flexible => ({
  type: UPDATE_FLEXIBLE,
  payload: flexible,
})

export const updateFlexibleSuccess = flexible => ({
  type: UPDATE_FLEXIBLE_SUCCESS,
  payload: flexible,
})

export const updateFlexibleFail = error => ({
  type: UPDATE_FLEXIBLE_FAIL,
  payload: error,
})

export const updateFlexibleSwitch = (operation, payratetypeId) => ({
  type: UPDATE_FLEXIBLE_SWITCH,
  payload: { operation, payratetypeId },
})

export const updateFlexibleSwitchSuccess = flexible => ({
  type: UPDATE_FLEXIBLE_SWITCH_SUCCESS,
  payload: flexible,
})

export const updateFlexibleSwitchFail = error => ({
  type: UPDATE_FLEXIBLE_SWITCH_FAIL,
  payload: error,
})

export const updateFlexibleDefaultRate = data => ({
  type: UPDATE_FLEXIBLE_DEFAULT_RATE,
  payload: data,
})

export const updateFlexibleDefaultRateSuccess = flexibles => ({
  type: UPDATE_FLEXIBLE_DEFAULT_RATE_SUCCESS,
  payload: flexibles,
})

export const updateFlexibleDefaultRateFail = error => ({
  type: UPDATE_FLEXIBLE_DEFAULT_RATE_FAIL,
  payload: error,
})
