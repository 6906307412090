import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  NavItem,
  NavLink,
  Button,
  Input,
  Form,
  FormGroup,
  TabContent,
  TabPane,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import classnames from "classnames"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import {
  getFeeTransaction as onGetFeeTransaction,
  getPaymentMethods as onGetPaymentMethods,
  addFeeTransaction as onAddFeeTransaction,
} from "store/actions"

//Date filter
import Moment from "moment"
import { isEmpty } from "lodash"

const PaymentModal = ({ childID, show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [amount, setAmount] = useState(0)
  const [isCheck, setIsCheck] = useState([])
  const [activeTab, setActiveTab] = useState(1)

  //////////////////ADULT STATE/////////////////////
  const { adult } = useSelector(state => state.adult)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    AdultID: yup.number(),
    ChildID: yup.number(),
    FeeTransactionIDs: yup.array().required("Required"),
    Amount: yup
      .number()
      .required("Required")
      .when("PaymentMethods", {
        is: value => value?.PaymentMethodID === 4 && 0 > 0, //Adult's Wallet
        then: schema => schema.min(1, "Please load wallet before payment."),
        otherwise: schema => schema,
      }),
    PaymentMethods: yup.object().required("Required"),
    ReferenceNumber: yup.string().when("PaymentMethods", {
      is: value => value?.PaymentMethodID === 2 || value?.PaymentMethodID === 3, //Cash
      then: schema => schema.required("Required"),
      otherwise: schema => schema,
    }),
    Wallet: yup.number().when("PaymentMethods", {
      is: value => value?.PaymentMethodID === 4, //Adult's Wallet
      then: schema => schema.min(1, "Please load wallet before payment."),
      otherwise: schema => schema,
    }),
    Notes: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ChildID: childID,
      FeeTransactionIDs: [],
      Amount: 0,
      ReferenceNumber: "",
      Notes: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const CurrentPaymentMethod = watch("PaymentMethods")

  useDeepCompareEffect(() => {
    if (show) {
      dispatch(onGetFeeTransaction(childID))
      dispatch(onGetPaymentMethods())
    }
  }, [show, dispatch])

  //////////////////WALLET STATE/////////////////////
  const { paymentmethods, wallet } = useSelector(state => state.wallet)
  //////////////////////////////////////

  //////////////////FEE STATE/////////////////////
  const { feetransactions, loading } = useSelector(state => state.fee)
  //////////////////////////////////////

  const [passedSteps, setPassedSteps] = useState([1])

  useEffect(() => {
    setValue("Wallet", wallet.Balance)
  }, [wallet])

  const toggleTab = (tab, e) => {
    if (activeTab === 1 && amount === 0) {
      return
    }

    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps]
      modifiedSteps.push(tab)
      setActiveTab(tab)
      setPassedSteps(modifiedSteps)
    }
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(feetransactions.map(li => li.FeeTransactionID))
    if (isCheckAll) {
      setIsCheck([])
    }
  }

  const handleClick = (e, price) => {
    const { id, checked } = e.target
    setIsCheck([...isCheck, id])
    if (!checked) {
      setAmount(amount - price)
      setIsCheck(isCheck.filter(item => item !== id))
    } else {
      setAmount(amount + price)
    }
  }

  const onSaveClick = () => {
    setValue("FeeTransactionIDs", isCheck)
    setValue("Amount", amount)
    trigger()
    if (isValid) {
      dispatch(onAddFeeTransaction(adult.AdultID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader className="d-flex justify-content-center">
        Unpaid Services
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <div id="kyc-verify-wizard" className="wizard clearfix">
            <div className="content clearfix">
              <TabContent
                activeTab={activeTab}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1} id="pending-fees">
                  {/* <div className="d-flex mb-4">
                    <div className="me-3">
                      <input
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        className="form-check-input"
                        onChange={e => handleSelectAll(e)}
                        //checked={isCheckAll}
                      />
                    </div>
                    <div className="flex-grow-1">Select all</div>
                  </div> */}
                  {feetransactions &&
                    feetransactions.map((item, index) => {
                      return (
                        <div key={`pending${index}`} className="d-flex mb-4">
                          <div className="me-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={"checkbox" + item.FeeTransactionID}
                              id={item.FeeTransactionID}
                              onChange={e => handleClick(e, item.Price)}
                              //checked={isCheck.includes(item.FeeTransactionID)}
                            />
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-size-13 mb-1">
                              {item.ProgramName + ", " + item.DayID + " days"}
                            </h5>
                            <p className="text-muted mb-1">
                              {Moment(item.StartDate).format("DD MMM yyyy")}-{" "}
                              {Moment(item.EndDate).format("DD MMM yyyy")}
                            </p>
                          </div>
                          <div className="ml-3">
                            <Button className="btn btn-danger waves-effect btn-sm">
                              ${Number(item.Price).toFixed(2)}
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                </TabPane>
                <TabPane tabId={2} id="finalize-fees">
                  {errors?.Wallet?.message ? (
                    <div className="d-block alert alert-danger">
                      {errors?.Wallet?.message}
                    </div>
                  ) : null}
                  <div className="mb-3">
                    <Label>Amount</Label>
                    <Controller
                      name="Amount"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            id="Amount"
                            type="number"
                            readOnly
                            value={Number(amount).toFixed(2)}
                            invalid={!!errors.Amount}
                          />
                          {errors?.Amount?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.Amount?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Payment Type</Label>
                    <Controller
                      name="PaymentMethods"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id="PaymentMethods"
                            options={paymentmethods}
                            getOptionLabel={option => option.PaymentMethod}
                            getOptionValue={option => option.PaymentMethodID}
                            required
                            aria-invalid={!!errors.PaymentMethods}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.PaymentMethods?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.PaymentMethods?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div
                    className="mb-3"
                    hidden={
                      CurrentPaymentMethod === undefined ||
                      CurrentPaymentMethod?.PaymentMethodID === 1 ||
                      CurrentPaymentMethod?.PaymentMethodID === 4
                    }
                  >
                    <Label>Reference Number</Label>
                    <Controller
                      name="ReferenceNumber"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            id="ReferenceNumber"
                            type="text"
                            required
                            invalid={!!errors.ReferenceNumber}
                          />
                          {errors?.ReferenceNumber?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.ReferenceNumber?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div
                    className="mb-3"
                    hidden={CurrentPaymentMethod?.PaymentMethodID !== 4}
                  >
                    <b>Wallet Balance</b> :{" "}
                    {isEmpty(wallet)
                      ? "$0.00"
                      : "$" + Number(wallet.Balance).toFixed(2)}
                  </div>
                  <div className="mb-3">
                    <Label>Notes</Label>
                    <Controller
                      name="Notes"
                      control={control}
                      value=""
                      render={({ field }) => (
                        <>
                          <textarea
                            {...field}
                            rows={3}
                            className="form-control mb-3"
                            id="Notes"
                          />
                          {errors?.Notes?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.Notes?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div id="kyc-verify-wizard" className="wizard clearfix">
          <div className="actions clearfix">
            <ul role="menu" aria-label="Pagination">
              <li hidden={activeTab === 1}>
                <Link
                  to="#"
                  onClick={e => {
                    toggleTab(activeTab - 1, e)
                  }}
                >
                  Previous
                </Link>
              </li>
              <li
                hidden={activeTab === 2}
                className={amount === 0 ? "next disabled" : ""}
              >
                <Link
                  to="#"
                  onClick={e => {
                    toggleTab(activeTab + 1, e)
                  }}
                >
                  Next
                </Link>
              </li>
              <li hidden={activeTab === 1}>
                <Link to="#" onClick={() => onSaveClick()}>
                  Pay
                </Link>
              </li>
              <li>
                <Button
                  to="#"
                  onClick={onCloseClick}
                  className="btn btn btn-secondary"
                >
                  Cancel
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

PaymentModal.propTypes = {
  adultID: PropTypes.number,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default PaymentModal
