import { call, put, takeEvery } from "redux-saga/effects"

// Charge Redux States
import {
  GET_CHARGETYPES,
  GET_CHARGES,
  DELETE_CHARGE,
  ADD_CHARGE,
  GET_CHARGE_DETAIL,
  UPDATE_CHARGE,
} from "./actionTypes"
import {
  getChargeTypesSuccess,
  getChargeTypesFail,
  getChargesSuccess,
  getChargesFail,
  deleteChargeSuccess,
  deleteChargeFail,
  addChargeSuccess,
  addChargeFail,
  getChargeDetailSuccess,
  getChargeDetailFail,
  updateChargeSuccess,
  updateChargeFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getChargeTypes,
  getCharges,
  deleteCharge,
  addCharge,
  getChargeDetail,
  updateCharge,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchChargeTypes() {
  debugger
  try {
    const response = yield call(getChargeTypes)
    yield put(getChargeTypesSuccess(response))
  } catch (error) {
    yield put(getChargeTypesFail(error))
  }
}

function* fetchCharges() {
  try {
    const response = yield call(getCharges)
    yield put(getChargesSuccess(response))
  } catch (error) {
    yield put(getChargesFail(error))
  }
}

function* onDeleteCharge({ payload: chargeID }) {
  try {
    console.log(chargeID)
    const response = yield call(deleteCharge, chargeID)
    yield put(deleteChargeSuccess(response))
    toast.success("Charge deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteChargeFail(error))
    toast.error("Charge deletion failed", { autoClose: 2000 })
  }
}

function* onAddCharge({ payload: charge }) {
  try {
    const response = yield call(addCharge, charge)
    yield put(addChargeSuccess(response[0]))
    toast.success("Charge added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChargeFail(error))
    toast.error("Charge addition failed", { autoClose: 2000 })
  }
}

function* fetchChargeDetail({ RowID }) {
  try {
    const response = yield call(getChargeDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getChargeDetailSuccess(result))
  } catch (error) {
    yield put(getChargeDetailFail(error))
  }
}

function* onUpdateCharge({ payload: charge }) {
  try {
    const response = yield call(updateCharge, charge)
    let result = response[0]

    yield put(updateChargeSuccess(result))
    toast.success("Charge updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateChargeFail(error))
    toast.error("Charge updation failed", { autoClose: 2000 })
  }
}

function* chargeSaga() {
  yield takeEvery(GET_CHARGETYPES, fetchChargeTypes)
  yield takeEvery(GET_CHARGES, fetchCharges)
  yield takeEvery(DELETE_CHARGE, onDeleteCharge)
  yield takeEvery(ADD_CHARGE, onAddCharge)
  yield takeEvery(GET_CHARGE_DETAIL, fetchChargeDetail)
  yield takeEvery(UPDATE_CHARGE, onUpdateCharge)
}

export default chargeSaga
