/*Get BUS */
export const GET_BUSES = "GET_BUSES"
export const GET_BUSES_SUCCESS = "GET_BUSES_SUCCESS"
export const GET_BUSES_FAIL = "GET_BUSES_FAIL"

/**
 * Delete BUS
 */
export const DELETE_BUS = "DELETE_BUS"
export const DELETE_BUS_SUCCESS = "DELETE_BUS_SUCCESS"
export const DELETE_BUS_FAIL = "DELETE_BUS_FAIL"
/**
 * Add BUS
 */
export const ADD_NEW_BUS = "ADD_NEW_BUS"
export const ADD_BUS_SUCCESS = "ADD_BUS_SUCCESS"
export const ADD_BUS_FAIL = "ADD_BUS_FAIL"

/**
 * Get BUS DETAILS
 */
export const GET_BUS_DETAIL = "GET_BUS_DETAIL"
export const GET_BUS_DETAIL_SUCCESS = "GET_BUS_DETAIL_SUCCESS"
export const GET_BUS_DETAIL_FAIL = "GET_BUS_DETAIL_FAIL"

/**
 * Edit BUS
 */
export const UPDATE_BUS = "UPDATE_BUS"
export const UPDATE_BUS_SUCCESS = "UPDATE_BUS_SUCCESS"
export const UPDATE_BUS_FAIL = "UPDATE_BUS_FAIL"
/**
 * Reset BUS
 */
export const RESET_BUS = "RESET_BUS"
export const RESET_BUS_SUCCESS = "RESET_BUS_SUCCESS"
