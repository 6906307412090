import {
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLE_TYPES_SUCCESS,
  GET_ROLE_TYPES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  roles: [],
  roletypes: [],
  error: {},
  loading: true,
}

const Role = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: true,
      }
    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ROLE_TYPES_SUCCESS:
      return {
        ...state,
        roletypes: action.payload,
        loading: true,
      }
    case GET_ROLE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Role
