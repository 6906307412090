import React, { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import Spinners from "components/Common/Spinner"
import SimpleBar from "simplebar-react"
import { HandleValidDate } from "helpers/method_helper"
import moment from "moment"

const MessageSection = () => {
  const { mid } = useParams()

  const { messages, loading } = useSelector(state => state.child)
  const [isLoading, setLoading] = useState(loading)

  return (
    <React.Fragment>
      <div className="chat-leftsidebar me-lg-4">
        <h5 className="font-size-14 mb-3">Recent</h5>
        <ul className="list-unstyled chat-list" id="recent-list">
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <SimpleBar style={{ maxHeight: "410px" }}>
              {messages &&
                messages.map(item => (
                  <li
                    key={item.RowID}
                    className={mid === item.RowID ? "active" : ""}
                  >
                    <Link
                      to={`/child/messaging/${item.ChildRowID}/${item.RowID}`}
                    >
                      <div className="d-flex">
                        {/* <div className="align-self-center me-3">
                              <img
                                src={item.image}
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                            </div> */}
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="text-truncate font-size-14 mb-1">
                            {item.CreatedByName}
                          </h5>
                          <p className="text-truncate mb-0">{item.Topic}</p>
                        </div>
                        <div className="font-size-11">
                          {moment(new Date(item.CreatedDate)).format(
                            "DD MMM Y"
                          )}
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
            </SimpleBar>
          )}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default MessageSection
