import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Modal,
  ModalBody,
  InputGroup,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  getStaffAvailability as onGetStaffAvailability,
  addStaffAvailability as onAddStaffAvailability,
} from "store/actions"
import Spinners from "components/Common/Spinner"
import { isEmpty } from "lodash"
import TimesArray from "./TimesArray"

function AvailabilityModal({ staffID, show, onCloseClick }) {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Availability: yup.string(),
    Linked: yup.boolean(),
    Times: yup
      .array()
      .of(
        yup.object().shape({
          StaffAvailabilityDetailID: yup.number(),
          StartTime: yup.string(),
          EndTime: yup.string(),
          // startTime: yup
          //   .string()
          //   .test("startTest", "Invalid Time", function (value) {
          //     if (!value) return true;
          //     return moment(value, "HH:mm").isValid();
          //   }),
          // endTime: yup
          //   .string()
          //   .test("endTest", "Invalid Time", function (value) {
          //     if (!value) return true;
          //     if (!moment(value, "HH:mm").isValid()) {
          //       return this.createError({ message: "Invalid Time" });
          //     }
          //     if (
          //       moment(this.parent.start, "HH:mm").isSameOrAfter(
          //         moment(this.parent.end, "HH:mm")
          //       )
          //     ) {
          //       return this.createError({
          //         message: "End time must be after start time",
          //       });
          //     }
          //     return true;
          //   }),
        })
      )
      .when("Linked", {
        is: value => value === true,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      }),
  })

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const { fields, append, remove } = useFieldArray({
    name: "availability",
    control,
  })

  //////////////////ROLE STATE/////////////////////
  const { staffavailability, loading } = useSelector(state => state.staff)

  const [isLoading, setLoading] = useState(loading)
  ///////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetStaffAvailability(staffID))
  }, [dispatch])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddStaffAvailability(staffID, getValues()))
      onCloseClick()
    }
  }

  useEffect(() => {
    if (isEmpty(staffavailability)) {
      return
    }

    reset({ availability: staffavailability })
  }, [staffavailability])

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Editing General Availability
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <FormProvider {...methods}>
            {fields.map((item, index) => {
              return (
                <>
                  <Row key={`availability${index}`}>
                    <Col sm={1}>
                      <Controller
                        control={control}
                        name={`availability[${index}].Linked`}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              defaultChecked={field.value}
                              id={`availability[${index}].Linked`}
                              type="checkbox"
                              className="form-check-input"
                            />
                          </>
                        )}
                      />
                    </Col>
                    <Col sm={11}>{item.Availability}</Col>
                  </Row>
                  {watch(`availability[${index}].Linked`) && (
                    <TimesArray key={`timesarray${index}`} nestIndex={index} />
                  )}
                </>
              )
            })}
          </FormProvider>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
export default AvailabilityModal
