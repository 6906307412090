import React from "react"
import PropTypes from "prop-types"
import { Col, Modal, ModalBody, Row } from "reactstrap"

const ImpersonateModal = ({
  show,
  text = "You want to Impersonate this user?",
  onSubmitClick,
  onCloseClick,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <i
                className="mdi mdi-login"
                style={{ fontSize: "9em", color: "orange" }}
              />
              <h2>Are you sure?</h2>
              <h4>{text}</h4> {/* Display custom text here */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-success btn-lg ms-2"
                onClick={onSubmitClick}
              >
                Yes, Continue
              </button>
              <button
                type="button"
                className="btn btn-danger btn-lg ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

ImpersonateModal.propTypes = {
  show: PropTypes.bool.isRequired,
  text: PropTypes.string, // PropTypes for text prop added here
  onSubmitClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
}

export default ImpersonateModal
