/*Get IMMUNIZATIONS */
export const GET_IMMUNIZATIONS = "GET_IMMUNIZATIONS"
export const GET_IMMUNIZATIONS_SUCCESS = "GET_IMMUNIZATIONS_SUCCESS"
export const GET_IMMUNIZATIONS_FAIL = "GET_IMMUNIZATIONS_FAIL"

/**
 * Delete IMMUNIZATION
 */
export const DELETE_IMMUNIZATION = "DELETE_IMMUNIZATION"
export const DELETE_IMMUNIZATION_SUCCESS = "DELETE_IMMUNIZATION_SUCCESS"
export const DELETE_IMMUNIZATION_FAIL = "DELETE_IMMUNIZATION_FAIL"
/**
 * Add IMMUNIZATION
 */
export const ADD_NEW_IMMUNIZATION = "ADD_NEW_IMMUNIZATION"
export const ADD_IMMUNIZATION_SUCCESS = "ADD_IMMUNIZATION_SUCCESS"
export const ADD_IMMUNIZATION_FAIL = "ADD_IMMUNIZATION_FAIL"

/**
 * Get IMMUNIZATION DETAILS
 */
export const GET_IMMUNIZATION_DETAIL = "GET_IMMUNIZATION_DETAIL"
export const GET_IMMUNIZATION_DETAIL_SUCCESS = "GET_IMMUNIZATION_DETAIL_SUCCESS"
export const GET_IMMUNIZATION_DETAIL_FAIL = "GET_IMMUNIZATION_DETAIL_FAIL"

/**
 * Edit IMMUNIZATION
 */
export const UPDATE_IMMUNIZATION = "UPDATE_IMMUNIZATION"
export const UPDATE_IMMUNIZATION_SUCCESS = "UPDATE_IMMUNIZATION_SUCCESS"
export const UPDATE_IMMUNIZATION_FAIL = "UPDATE_IMMUNIZATION_FAIL"

/**
 * Get CHILD IMMUNIZATION
 */
export const GET_CHILD_IMMUNIZATION = "GET_CHILD_IMMUNIZATION"
export const GET_CHILD_IMMUNIZATION_SUCCESS = "GET_CHILD_IMMUNIZATION_SUCCESS"
export const GET_CHILD_IMMUNIZATION_FAIL = "GET_CHILD_IMMUNIZATION_FAIL"

/**
 * Update CHILD IMMUNIZATION
 */
export const UPDATE_CHILD_IMMUNIZATION = "UPDATE_CHILD_IMMUNIZATION"
export const UPDATE_CHILD_IMMUNIZATION_SUCCESS =
  "UPDATE_CHILD_IMMUNIZATION_SUCCESS"
export const UPDATE_CHILD_IMMUNIZATION_FAIL = "UPDATE_CHILD_IMMUNIZATION_FAIL"
