import {
  GET_CHATS,
  GET_CHATS_FAIL,
  GET_CHATS_SUCCESS,
  GET_LATEST_CHAT,
  GET_LATEST_CHAT_FAIL,
  GET_LATEST_CHAT_SUCCESS,
  DELETE_CHAT,
  DELETE_CHAT_FAIL,
  DELETE_CHAT_SUCCESS,
  ADD_NEW_CHAT,
  ADD_CHAT_SUCCESS,
  ADD_CHAT_FAIL,
  GET_CHAT_DETAIL,
  GET_CHAT_DETAIL_SUCCESS,
  GET_CHAT_DETAIL_FAIL,
  UPDATE_CHAT,
  UPDATE_CHAT_SUCCESS,
  UPDATE_CHAT_FAIL,
  RESET_CHAT_SUCCESS,
  RESET_CHAT,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_MESSAGE_FAIL,
  DELETE_CHAT_MESSAGE_SUCCESS,
  ADD_NEW_CHAT_MESSAGE,
  ADD_CHAT_MESSAGE_SUCCESS,
  ADD_CHAT_MESSAGE_FAIL,
  UPDATE_CHAT_MESSAGE_FILE,
  UPDATE_CHAT_MESSAGE_FILE_SUCCESS,
  UPDATE_CHAT_MESSAGE_FILE_FAIL,
} from "./actionTypes"

export const getChats = () => ({
  type: GET_CHATS,
})

export const getChatsSuccess = chats => ({
  type: GET_CHATS_SUCCESS,
  payload: chats,
})

export const getChatsFail = error => ({
  type: GET_CHATS_FAIL,
  payload: error,
})

export const getLatestChat = () => ({
  type: GET_LATEST_CHAT,
})

export const getLatestChatSuccess = chats => ({
  type: GET_LATEST_CHAT_SUCCESS,
  payload: chats,
})

export const getLatestChatFail = error => ({
  type: GET_LATEST_CHAT_FAIL,
  payload: error,
})

export const deleteChat = chat => ({
  type: DELETE_CHAT,
  payload: chat,
})

export const deleteChatSuccess = chat => ({
  type: DELETE_CHAT_SUCCESS,
  payload: chat,
})

export const deleteChatFail = error => ({
  type: DELETE_CHAT_FAIL,
  payload: error,
})
export const addNewChat = chat => ({
  type: ADD_NEW_CHAT,
  payload: chat,
})

export const addChatSuccess = chat => ({
  type: ADD_CHAT_SUCCESS,
  payload: chat,
})

export const addChatFail = error => ({
  type: ADD_CHAT_FAIL,
  payload: error,
})

export const getChatDetail = chatID => ({
  type: GET_CHAT_DETAIL,
  payload: chatID,
})

export const getChatDetailSuccess = chat => ({
  type: GET_CHAT_DETAIL_SUCCESS,
  payload: chat,
})

export const getChatDetailFail = error => ({
  type: GET_CHAT_DETAIL_FAIL,
  payload: error,
})

export const updateChat = chat => ({
  type: UPDATE_CHAT,
  payload: chat,
})

export const updateChatSuccess = chat => ({
  type: UPDATE_CHAT_SUCCESS,
  payload: chat,
})

export const updateChatFail = error => ({
  type: UPDATE_CHAT_FAIL,
  payload: error,
})
export const resetChat = () => ({
  type: RESET_CHAT,
})

export const resetChatSuccess = chat => ({
  type: RESET_CHAT_SUCCESS,
  payload: chat,
})

export const deleteChatMessage = chatMessage => ({
  type: DELETE_CHAT_MESSAGE,
  payload: chatMessage,
})

export const deleteChatMessageSuccess = chatMessageID => ({
  type: DELETE_CHAT_MESSAGE_SUCCESS,
  payload: chatMessageID,
})

export const deleteChatMessageFail = error => ({
  type: DELETE_CHAT_MESSAGE_FAIL,
  payload: error,
})
export const addNewChatMessage = chatMessage => ({
  type: ADD_NEW_CHAT_MESSAGE,
  payload: chatMessage,
})

export const addChatMessageSuccess = chatMessage => ({
  type: ADD_CHAT_MESSAGE_SUCCESS,
  payload: chatMessage,
})

export const addChatMessageFail = error => ({
  type: ADD_CHAT_MESSAGE_FAIL,
  payload: error,
})

export const updateChatMessageFile = chatMessage => ({
  type: UPDATE_CHAT_MESSAGE_FILE,
  payload: chatMessage,
})
export const updateChatMessageFileSuccess = chatMessage => ({
  type: UPDATE_CHAT_MESSAGE_FILE_SUCCESS,
  payload: chatMessage,
})

export const updateChatMessageFileFail = error => ({
  type: UPDATE_CHAT_MESSAGE_FILE_FAIL,
  payload: error,
})
