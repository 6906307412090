import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"
import FormUpload from "components/Common/Upload/FormUpload"
import {
  addNewAlbum as onAddNewAlbum,
  updateAlbum as onUpdateAlbum,
  // getClasses as onGetClasses,
  resetAlbum as onResetAlbum,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
function BasicInfoTab({ album, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedFile, setSelectedFile] = useState([])
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Title: yup
      .string()
      .required("Required")
      .min(3, "Title must be at least 3 characters"),
    //StartTime: yup.string().required("Required"),
    IsActive: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Title: "",
      StartTime: "",
      IsActive: true,
      Photo: selectedFile,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateAlbumState() {
      //reset form with server data
      //if (album != null) {
      reset(album)
      // }
    }
    const { id } = routeParams

    if (id === "new") {
    } else {
      updateAlbumState()
    }
  }, [routeParams, dispatch])

  useEffect(() => {
    return () => {
      dispatch(onResetAlbum())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/albums")
  }

 
  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      const newAlbum = {
        ...getValues(),
        id: id,
        Photo: selectedFile,
        AlbumID: album.AlbumID,
      }
      if (id === "new") {
        dispatch(onAddNewAlbum(newAlbum))
      } else {
        dispatch(onUpdateAlbum(newAlbum))
      }
      setSelectedImage(null)
      setSelectedFile(null)
      navigate("/albums")
    }
  }
  const onUpload = (files) => {
    console.log(files);
    setSelectedFile(files)
  };
  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          
           <FormUpload    onUpload={onUpload} /> 
          {/* <FormUpload ControlID="Photo" Accept={ValidExtensions} /> */}
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
