import {
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAIL,
  ADD_WALLET_TRANSACTION_SUCCESS,
  ADD_WALLET_TRANSACTION_FAIL,
  GET_WALLET_TRANSACTION_SUCCESS,
  GET_WALLET_TRANSACTION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  wallet: {},
  paymentmethods: [],
  wallettransactions: [],
  error: {},
  loading: true,
}

const Wallet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentmethods: action.payload,
        loading: true,
      }
    case GET_PAYMENT_METHODS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_WALLET_TRANSACTION_SUCCESS:
      return {
        ...state,
        wallet: action.payload.Wallet,
        wallettransactions: action.payload.WalletTransactions,
        loading: true,
      }
    case ADD_WALLET_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_WALLET_TRANSACTION_SUCCESS:
      return {
        ...state,
        wallet: action.payload.Wallet,
        wallettransactions: action.payload.WalletTransactions,
        loading: true,
      }
    case GET_WALLET_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Wallet
