import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { Label, FormFeedback} from "reactstrap"
import { useDeepCompareEffect } from "hooks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { getGenders as onGetGenders } from "./../../../store/staff/actions"


export default function GenderField(props) {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState
  const dispatch = useDispatch()
  const { genders } = useSelector(state => state.staff)
  useDeepCompareEffect(() => {
    function updateGenderState() {
      dispatch(onGetGenders())
    }

    updateGenderState()
  }, [genders])


  return (
    <>
          <div className="mb-3">
            <Label>Gender</Label>
            <Controller
              name="Genders"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Genders"
                    required
                    getOptionLabel={option => option.Gender}
                    getOptionValue={option => option.GenderID}
                    aria-invalid={!!errors.Genders}
                    options={genders}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Genders?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Genders?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>       
    </>
  )
}
