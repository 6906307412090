/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_THEME_IMAGE = "CHANGE_SIDEBAR_THEME_IMAGE"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

// Layout mode
export const CHANGE_LAYOUT_MODE = "CHANGE_LAYOUT_MODE"

export const GET_THEME = "GET_THEME"
export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS"
export const GET_THEME_FAIL = "GET_THEME_FAIL"

export const UPDATE_THEME = "UPDATE_THEME"
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS"
export const UPDATE_THEME_FAIL = "UPDATE_THEME_FAIL"
