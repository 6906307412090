import {
  GET_FEE_TRANSACTION,
  GET_FEE_TRANSACTION_SUCCESS,
  GET_FEE_TRANSACTION_FAIL,
  GET_FEE_TRANSACTION_DETAILS,
  GET_FEE_TRANSACTION_DETAILS_SUCCESS,
  GET_FEE_TRANSACTION_DETAILS_FAIL,
  ADD_FEE_TRANSACTION,
  ADD_FEE_TRANSACTION_SUCCESS,
  ADD_FEE_TRANSACTION_FAIL,
  GET_PAID_FEE_TRANSACTION,
  GET_PAID_FEE_TRANSACTION_SUCCESS,
  GET_PAID_FEE_TRANSACTION_FAIL,
} from "./actionTypes"

export const getFeeTransaction = childID => ({
  type: GET_FEE_TRANSACTION,
  payload: childID,
})

export const getFeeTransactionSuccess = transactions => ({
  type: GET_FEE_TRANSACTION_SUCCESS,
  payload: transactions,
})

export const getFeeTransactionFail = error => ({
  type: GET_FEE_TRANSACTION_FAIL,
  payload: error,
})

export const getFeeTransactionDetail = adultID => ({
  type: GET_FEE_TRANSACTION_DETAILS,
  payload: adultID,
})

export const getFeeTransactionDetailSuccess = transactions => ({
  type: GET_FEE_TRANSACTION_DETAILS_SUCCESS,
  payload: transactions,
})

export const getFeeTransactionDetailFail = error => ({
  type: GET_FEE_TRANSACTION_DETAILS_FAIL,
  payload: error,
})

export const addFeeTransaction = (adultID, values) => ({
  type: ADD_FEE_TRANSACTION,
  payload: { adultID, values },
})

export const addFeeTransactionSuccess = transactions => ({
  type: ADD_FEE_TRANSACTION_SUCCESS,
  payload: transactions,
})

export const addFeeTransactionFail = error => ({
  type: ADD_FEE_TRANSACTION_FAIL,
  payload: error,
})

export const getPaidFeeTransaction = transactionIds => ({
  type: GET_PAID_FEE_TRANSACTION,
  payload: transactionIds,
})

export const getPaidFeeTransactionSuccess = transactions => ({
  type: GET_PAID_FEE_TRANSACTION_SUCCESS,
  payload: transactions,
})

export const getPaidFeeTransactionFail = error => ({
  type: GET_PAID_FEE_TRANSACTION_FAIL,
  payload: error,
})
