/*Get CLASS ACTIVITIES */
export const GET_ACTIVITIES = "GET_ACTIVITIES"
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS"
export const GET_ACTIVITIES_FAIL = "GET_ACTIVITIES_FAIL"

/* Reset CLASS ACTIVITIES */
export const RESET_ACTIVITY = "RESET_ACTIVITY"

/*Add CLASS ACTIVITY */
export const ADD_ACTIVITY = "ADD_ACTIVITY"
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS"
export const ADD_ACTIVITY_FAIL = "ADD_ACTIVITY_FAIL"

/* Get CLASS ACTIVITY DETAILS */
export const GET_ACTIVITY_DETAIL = "GET_ACTIVITY_DETAIL"
export const GET_ACTIVITY_DETAIL_SUCCESS = "GET_ACTIVITY_DETAIL_SUCCESS"
export const GET_ACTIVITY_DETAIL_FAIL = "GET_ACTIVITY_DETAIL_FAIL"

/*Edit CLASS ACTIVITY */
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY"
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS"
export const UPDATE_ACTIVITY_FAIL = "UPDATE_ACTIVITY_FAIL"

/* Delete CLASS ACTIVITY */
export const DELETE_ACTIVITY = "DELETE_ACTIVITY"
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_FAIL = "DELETE_ACTIVITY_FAIL"

/*Get ACTIVITIES BY CLASS*/
export const GET_ACTIVITIES_BY_CLASS = "GET_ACTIVITIES_BY_CLASS"
export const GET_ACTIVITIES_BY_CLASS_SUCCESS = "GET_ACTIVITIES_BY_CLASS_SUCCESS"
export const GET_ACTIVITIES_BY_CLASS_FAIL = "GET_ACTIVITIES_BY_CLASS_FAIL"
