import {
  GET_CHARGETYPES,
  GET_CHARGETYPES_SUCCESS,
  GET_CHARGETYPES_FAIL,
  GET_CHARGES,
  GET_CHARGES_FAIL,
  GET_CHARGES_SUCCESS,
  DELETE_CHARGE,
  DELETE_CHARGE_FAIL,
  DELETE_CHARGE_SUCCESS,
  ADD_CHARGE,
  ADD_CHARGE_SUCCESS,
  ADD_CHARGE_FAIL,
  GET_CHARGE_DETAIL,
  GET_CHARGE_DETAIL_SUCCESS,
  GET_CHARGE_DETAIL_FAIL,
  UPDATE_CHARGE,
  UPDATE_CHARGE_SUCCESS,
  UPDATE_CHARGE_FAIL

} from "./actionTypes"


// charges


export const getChargeTypes = () => ({
  type: GET_CHARGETYPES,
})

export const getChargeTypesSuccess = chargetypes => ({
  type: GET_CHARGETYPES_SUCCESS,
  payload: chargetypes,
})

export const getChargeTypesFail = error => ({
  type: GET_CHARGETYPES_FAIL,
  payload: error,
})


export const getCharges = () => ({
  type: GET_CHARGES,
})

export const getChargesSuccess = charges => ({
  type: GET_CHARGES_SUCCESS,
  payload: charges,
})

export const getChargesFail = error => ({
  type: GET_CHARGES_FAIL,
  payload: error,
})

export const deleteCharge = chargeID => ({
  type: DELETE_CHARGE,
  payload: chargeID,
})

export const deleteChargeSuccess = charge => ({
  type: DELETE_CHARGE_SUCCESS,
  payload: charge,
})

export const deleteChargeFail = error => ({
  type: DELETE_CHARGE_FAIL,
  payload: error,
})


export const addCharge = charge => ({
  type: ADD_CHARGE,
  payload: charge,
})

export const addChargeSuccess = charge => ({
  type: ADD_CHARGE_SUCCESS,
  payload: charge,
})

export const addChargeFail = error => ({
  type: ADD_CHARGE_FAIL,
  payload: error,
})


export const getChargeDetail = RowID => ({
  type: GET_CHARGE_DETAIL,
  RowID,
})

export const getChargeDetailSuccess = charge => ({
  type: GET_CHARGE_DETAIL_SUCCESS,
  payload: charge,
})

export const getChargeDetailFail = error => ({
  type: GET_CHARGE_DETAIL_FAIL,
  payload: error,
})

export const updateCharge = charge => ({
  type: UPDATE_CHARGE,
  payload: charge,
})

export const updateChargeSuccess = charge => ({
  type: UPDATE_CHARGE_SUCCESS,
  payload: charge,
})

export const updateChargeFail = error => ({
  type: UPDATE_CHARGE_FAIL,
  payload: error,
})
