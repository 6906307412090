import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Modal,
  ModalBody,
  InputGroup,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import { addChildAvailability as onAddChildAvailability } from "store/actions"
import Spinners from "components/Common/Spinner"
import { isEmpty } from "lodash"
import TimesArray from "./TimesArray"
import PageModal from "components/Common/PageModal"

function AvailabilityModal({ show, onCloseClick }) {
  const dispatch = useDispatch()

  //////////////////Child STATE/////////////////////
  const { child, availabilities, childavailabilityloading } = useSelector(
    state => state.child
  )
  const [isLoading, setLoading] = useState(childavailabilityloading)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Availability: yup.string(),
    Linked: yup.boolean(),
    Times: yup
      .array()
      .of(
        yup.object().shape({
          ChildAvailabilityDetailID: yup.number(),
          StartTime: yup.string(),
          EndTime: yup.string(),
        })
      )
      .when("Linked", {
        is: value => value === true,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      }),
  })

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const { fields, append, remove } = useFieldArray({
    name: "availability",
    control,
  })

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      //console.log(getValues())
      dispatch(onAddChildAvailability(child.ChildID, getValues()))
      onCloseClick()
    }
  }

  useEffect(() => {
    if (isEmpty(availabilities)) {
      return
    }

    reset({ availability: availabilities })
  }, [availabilities])

  const onClosed = () => {
    reset()
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Select Desired Hours"
    >
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <FormProvider {...methods}>
          {fields.map((item, index) => {
            return (
              <>
                <Row key={`availability${index}`}>
                  <Col sm={1}>
                    <Controller
                      control={control}
                      name={`availability[${index}].Linked`}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            defaultChecked={field.value}
                            id={`availability[${index}].Linked`}
                            type="checkbox"
                            className="form-check-input"
                          />
                        </>
                      )}
                    />
                  </Col>
                  <Col sm={11}>{item.Availability}</Col>
                </Row>
                {watch(`availability[${index}].Linked`) && (
                  <TimesArray key={`timesarray${index}`} nestIndex={index} />
                )}
              </>
            )
          })}
        </FormProvider>
      )}
    </PageModal>
  )
}
export default AvailabilityModal
