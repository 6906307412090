export const getImagePath = url => {
  const path = process.env.REACT_APP_API_ROOT_URL + url
  return path
}

export const getUserImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/User/_${rowID}&Name=${fileName}`
  return path
}

export const getChildImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/Child/_${rowID}&Name=${fileName}`
  return path
}

export const getAdultImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/Adult/_${rowID}&Name=${fileName}`
  return path
}

export const getForumPostImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/ForumPost/_${rowID}&Name=${fileName}`
  return path
}

export const getChatMessageImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/ChatMessage/_${rowID}&Name=${fileName}`
  return path
}

export const getMediaAlbmImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/AlbumMedia/_${rowID}&Name=${fileName}`
  return path
}

export const getMessagePhotoDownloadPath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/DocHandler.ashx?ID=_files/Message/Photo/_${rowID}&Name=${fileName}`
  return path
}

export const getMessageFileDownloadPath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/DocHandler.ashx?ID=_files/Message/Attachment/_${rowID}&Name=${fileName}`
  return path
}
