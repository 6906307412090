import { call, put, takeEvery } from "redux-saga/effects"

// Program Redux States
import { GET_NAVIGATION_SIDEBAR } from "./actionTypes"
import {
  getNavigationSidebarSuccess,
  getNavigationSidebarFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getNavigation } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchNavigation({ payload: roleTypeId }) {
  try {
    const response = yield call(getNavigation, roleTypeId)
    yield put(getNavigationSidebarSuccess(response))
  } catch (error) {
    yield put(getNavigationSidebarFail(error))
  }
}

function* navigationSaga() {
  yield takeEvery(GET_NAVIGATION_SIDEBAR, fetchNavigation)
}

export default navigationSaga
