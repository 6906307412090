import { call, put, takeEvery } from "redux-saga/effects"

// Immunization Redux States
import {
  GET_IMMUNIZATIONS,
  DELETE_IMMUNIZATION,
  ADD_NEW_IMMUNIZATION,
  GET_IMMUNIZATION_DETAIL,
  UPDATE_IMMUNIZATION,
  GET_CHILD_IMMUNIZATION,
  UPDATE_CHILD_IMMUNIZATION,
} from "./actionTypes"
import {
  getImmunizationsFail,
  getImmunizationsSuccess,
  deleteImmunizationSuccess,
  deleteImmunizationFail,
  addImmunizationSuccess,
  addImmunizationFail,
  getImmunizationDetailSuccess,
  getImmunizationDetailFail,
  updateImmunizationSuccess,
  updateImmunizationFail,
  getChildImmunizationsSuccess,
  getChildImmunizationsFail,
  updateChildImmunizationSuccess,
  updateChildImmunizationFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getImmunizations,
  deleteImmunization,
  addNewImmunization,
  getImmunizationDetail,
  updateImmunization,
  getChildImmunizations,
  updateChildImmunization,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchImmunizations() {
  try {
    const response = yield call(getImmunizations)
    yield put(getImmunizationsSuccess(response))
  } catch (error) {
    yield put(getImmunizationsFail(error))
  }
}

function* onDeleteImmunization({ payload: immunizationId }) {
  try {
    const response = yield call(deleteImmunization, immunizationId)
    yield put(deleteImmunizationSuccess(response))
    toast.success("Immunization deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteImmunizationFail(error))
    toast.error("Immunization deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewImmunization({ payload: immunization }) {
  try {
    console.log(immunization)
    const response = yield call(addNewImmunization, immunization)
    yield put(addImmunizationSuccess(response[0]))
    toast.success("Immunization added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addImmunizationFail(error))
    toast.error("Immunization addition failed", { autoClose: 2000 })
  }
}

function* fetchImmunizationDetail({ rowId }) {
  try {
    const response = yield call(getImmunizationDetail, rowId)
    let result = response[0]

    result = {
      ...result,
      Doses: result.Doses.map(y => ({
        ...y,
        Years: {
          label: y.Year + "y",
          value: y.Year,
        },
        Months: {
          label: y.Month + "m",
          value: y.Month,
        },
      })),
    }

    yield put(getImmunizationDetailSuccess(result))
  } catch (error) {
    yield put(getImmunizationDetailFail(error))
  }
}

function* onUpdateImmunization({ payload: immunization }) {
  try {
    const response = yield call(updateImmunization, immunization)
    let result = response[0]

    yield put(updateImmunizationSuccess(result))
    toast.success("Immunization updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateImmunizationFail(error))
    toast.error("Immunization updation failed", { autoClose: 2000 })
  }
}

function* fetchChildImmunizations({ payload: childId }) {
  try {
    const response = yield call(getChildImmunizations, childId)
    yield put(getChildImmunizationsSuccess(response))
  } catch (error) {
    yield put(getChildImmunizationsFail(error))
  }
}

function* onUpdateChildImmunization({
  payload: { childId, childimmunization },
}) {
  try {
    const request = {
      childId: childId,
      childimmunization: childimmunization,
    }
    const response = yield call(updateChildImmunization, request)

    yield put(updateChildImmunizationSuccess(response))
    toast.success("Immunization Dose updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateChildImmunizationFail(error))
    toast.error("Immunization Dose updation failed", { autoClose: 2000 })
  }
}

function* immunizationSaga() {
  yield takeEvery(GET_IMMUNIZATIONS, fetchImmunizations)
  yield takeEvery(DELETE_IMMUNIZATION, onDeleteImmunization)
  yield takeEvery(ADD_NEW_IMMUNIZATION, onAddNewImmunization)
  yield takeEvery(GET_IMMUNIZATION_DETAIL, fetchImmunizationDetail)
  yield takeEvery(UPDATE_IMMUNIZATION, onUpdateImmunization)
  yield takeEvery(GET_CHILD_IMMUNIZATION, fetchChildImmunizations)
  yield takeEvery(UPDATE_CHILD_IMMUNIZATION, onUpdateChildImmunization)
}

export default immunizationSaga
