import {
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  ADD_NOTIFICATION_FAIL,
  ADD_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_DETAIL_FAIL,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  RESET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_EVENTS_SUCCESS,
  GET_NOTIFICATION_EVENTS_FAIL,
  GET_NOTIFICATION_RECIPIENTS_SUCCESS,
  GET_NOTIFICATION_RECIPIENTS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  notifications: [],
  notification: {},
  notificationEvents: [],
  notificationRecipients: [],
  error: {},
  loading: true,
}

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        loading: true,
      }
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTIFICATION_SUCCESS: {
      const data = {
        ...state,
        notifications: state.notifications.filter(
          notification =>
            notification.NotificationID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.NotificationID !== action.payload
        ),
      }
    }
    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      }
    case ADD_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        loading: true,
      }

    case GET_NOTIFICATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          notification.NotificationID.toString() ===
          action.payload.NotificationID.toString()
            ? { notification, ...action.payload }
            : notification
        ),
      }

    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
      }
    case GET_NOTIFICATION_EVENTS_SUCCESS:
      return {
        ...state,
        notificationEvents: action.payload,
        loading: true,
      }
    case GET_NOTIFICATION_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_NOTIFICATION_RECIPIENTS_SUCCESS:
      return {
        ...state,
        notificationRecipients: action.payload,
        loading: true,
      }
    case GET_NOTIFICATION_RECIPIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Notification
