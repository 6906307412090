import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLE_TYPES,
  GET_ROLE_TYPES_SUCCESS,
  GET_ROLE_TYPES_FAIL,
} from "./actionTypes"

export const getRoles = () => ({
  type: GET_ROLES,
})

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
})

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
})

export const getRoleTypes = () => ({
  type: GET_ROLE_TYPES,
})

export const getRoleTypesSuccess = roletypes => ({
  type: GET_ROLE_TYPES_SUCCESS,
  payload: roletypes,
})

export const getRoleTypesFail = error => ({
  type: GET_ROLE_TYPES_FAIL,
  payload: error,
})
