import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"

function EmergencyContact({ profile }) {
  if (isEmpty(profile?.FirstName)) {
    return <div>No emergency contact added</div>
  }

  return (
    <>
      <div>{profile.FirstName + " " + profile.LastName}</div>
      <div>{profile.PhoneNo}</div>
      <div>{profile.Address + ", " + profile?.ZipCode}</div>
      <div>{profile.Country + ", " + profile.State + ", " + profile.City}</div>
    </>
  )
}
export default EmergencyContact
