import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Input,
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import {
  updateProfileContact as onUpdateProfileContact,
  deleteProfileContact as onDeleteProfileContact,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const ContactModal = ({
  profileID,
  data,
  type,
  onEdit,
  show,
  onCloseClick,
}) => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [centerContactID, setCenterContactID] = useState(0)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CenterContactID: yup.number(),
    CenterProfileID: yup.number(),
    ContactTypeID: yup.number(),
    Description: yup.string().nullable(),
    Contact: yup.string().required("Required"),
  })

  const formdefault = {
    CenterContactID: 0,
    CenterProfileID: profileID,
    ContactTypeID: type,
    Contact: "",
    Description: "",
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      if (show) {
        if (onEdit) {
          reset(data)
        } else {
          reset(formdefault)
        }
      }
    }

    updateState()
  }, [show, dispatch])

  const onSaveClick = () => {
    debugger
    trigger()
    if (isValid) {
      dispatch(onUpdateProfileContact(getValues()))
      onCloseClick()
    }
  }

  const onDeleteClick = () => {
    setCenterContactID(data.CenterContactID)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (centerContactID) {
      dispatch(onDeleteProfileContact(centerContactID))
      setDeleteModal(false)
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <Modal
        isOpen={show}
        toggle={onCloseClick}
        onClosed={() => onClosed()}
        centered={true}
      >
        <ModalHeader className="d-flex justify-content-center">
          {onEdit ? "Edit " : "Add "}
          {type == 1
            ? "Email"
            : type == 2
            ? "Phone"
            : type == 3
            ? "Website"
            : ""}
        </ModalHeader>
        <ModalBody className="py-3 px-5">
          <FormProvider {...methods}>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label>
                    {type == 1
                      ? "Email"
                      : type == 2
                      ? "Phone"
                      : type == 3
                      ? "Website"
                      : ""}
                  </Label>
                  <Controller
                    name="Contact"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          id="Contact"
                          type="text"
                          required
                          invalid={!!errors.Contact}
                        />
                        {errors?.Contact?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.Contact?.message}
                          </FormFeedback>
                        ) : null}
                      </>
                    )}
                  />
                </div>
                <div className="mb-3">
                  <Label>Description</Label>
                  <Controller
                    name="Description"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input {...field} id="Description" type="text" />
                      </>
                    )}
                  />
                </div>
              </Col>
            </Row>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-wrap gap-2">
            <button
              hidden={!onEdit}
              type="button"
              className="btn btn btn-danger"
              onClick={() => onDeleteClick()}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn btn-primary"
              onClick={() => onSaveClick()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn btn-secondary"
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

ContactModal.propTypes = {
  data: PropTypes.any,
  onEdit: PropTypes.bool,
  type: PropTypes.number,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default ContactModal
