import {
  GET_CLASSES_FAIL,
  GET_CLASSES_SUCCESS,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_FAIL,
  ADD_CLASS_FAIL,
  ADD_CLASS_SUCCESS,
  GET_CLASS_DETAIL_SUCCESS,
  GET_CLASS_DETAIL_FAIL,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_FAIL,
  GET_CLASS_TYPES_SUCCESS,
  GET_CLASS_TYPES_FAIL,
  RESET_CLASS_SUCCESS,
  ADD_CLASS_NOTES_SUCCESS,
  ADD_CLASS_NOTES_FAIL,
  DELETE_CLASS_NOTES_SUCCESS,
  DELETE_CLASS_NOTES_FAIL,
  GET_CLASS_HAPPENING_TYPES_SUCCESS,
  GET_CLASS_HAPPENING_TYPES_FAIL,
  GET_CLASS_HAPPENING_SUCCESS,
  GET_CLASS_HAPPENING_FAIL,
  ADD_CLASS_HAPPENING_SUCCESS,
  ADD_CLASS_HAPPENING_FAIL,
  DELETE_CLASS_HAPPENING_SUCCESS,
  DELETE_CLASS_HAPPENING_FAIL,
  GET_CLASS_PROGRAM_DETAIL_SUCCESS,
  GET_CLASS_PROGRAM_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  classes: [],
  class1: {},
  classnotes: [],
  happeningtypes: [],
  classhappenings: [],
  programs: [],
  error: {},
  loading: true,
  classTypes: [],
}

const Class1 = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
        loading: true,
      }
    case GET_CLASSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CLASS_SUCCESS: {
      const data = {
        ...state,
        classes: state.classes.filter(
          c => c.ClassID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        classes: state.classes.filter(c => c.ClassID !== action.payload),
      }
    }
    case DELETE_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CLASS_SUCCESS:
      return {
        ...state,
        classes: [...state.classes, action.payload],
      }
    case ADD_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CLASS_DETAIL_SUCCESS:
      return {
        ...state,
        class1: action.payload,
        classnotes: action.payload.ClassNotes,
        loading: true,
      }

    case GET_CLASS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CLASS_SUCCESS:
      return {
        ...state,
        classes: state.classes.map(class1 =>
          class1.ClassID.toString() === action.payload.ClassID.toString()
            ? { class1, ...action.payload }
            : class1
        ),
      }
    case UPDATE_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CLASS_TYPES_SUCCESS:
      return {
        ...state,
        classTypes: action.payload,
        loading: true,
      }
    case GET_CLASS_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_CLASS_SUCCESS:
      return {
        ...state,
        class1: action.payload,
      }
    case ADD_CLASS_NOTES_SUCCESS:
      return {
        ...state,
        classnotes: action.payload.ClassNotes,
      }
    case ADD_CLASS_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CLASS_NOTES_SUCCESS: {
      return {
        ...state,
        classnotes: state.classnotes.filter(
          c => c.ClassNoteID !== action.payload
        ),
      }
    }
    case DELETE_CLASS_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CLASS_HAPPENING_TYPES_SUCCESS:
      return {
        ...state,
        happeningtypes: action.payload,
      }
    case GET_CLASS_HAPPENING_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CLASS_HAPPENING_SUCCESS:
      return {
        ...state,
        classhappenings: action.payload,
      }
    case GET_CLASS_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CLASS_HAPPENING_SUCCESS:
      return {
        ...state,
        classhappenings: action.payload,
      }

    case ADD_CLASS_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CLASS_HAPPENING_SUCCESS: {
      return {
        ...state,
        classhappenings: state.classhappenings.filter(
          c => c.ClassHappeningID !== action.payload
        ),
      }
    }
    case DELETE_CLASS_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CLASS_PROGRAM_DETAIL_SUCCESS:
      return {
        ...state,
        programs: action.payload,
        loading: true,
      }

    case GET_CLASS_PROGRAM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Class1
