import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import classnames from "classnames"

import { getScheduledData as onGetScheduledData } from "store/actions"
import { isEmpty } from "lodash"
import { getChildImagePath, getUserImagePath } from "helpers/imageService"

const LiveStatusTab = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetScheduledData(1))
  }, [dispatch])

  const { scheduledclass, scheduledteacher, scheduledchildren } = useSelector(
    state => state.Dashboard
  )
  let idCounter = 0
  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table table-bordered mb-0 table">
          <thead>
            <tr>
              <th>Class</th>
              <th>Teachers</th>
              <th>Children</th>
              <th>Events</th>
              <th>Room</th>
              <th>Teachers</th>
              <th>Children</th>
            </tr>
          </thead>
          <tbody>
            {scheduledclass.map((row, i) => (
              <tr key={"th" + i}>
                <th scope="row">{row.Title}</th>
                <td>
                  {
                    scheduledteacher.filter(x => x.ClassID === row.ClassID)
                      .length
                  }
                </td>
                <td>
                  {
                    scheduledchildren.filter(x => x.ClassID === row.ClassID)
                      .length
                  }
                </td>
                <td></td>
                <td>{row.RoomID == 0 ? "-" : row.Room}</td>
                <td>
                  <div className="avatar-group">
                    {scheduledteacher
                      .filter(x => x.ClassID === row.ClassID)
                      .map((tch, key1) => {
                        idCounter++ // Increment the counter for each iteration
                        const linkId = `teacher_${idCounter}` // Generate unique ID for Link
                        const tooltipId = `teacher_${idCounter}` // Generate unique ID for UncontrolledTooltip
                        return (
                          <div key={key1} className="avatar-group-item">
                            <Link
                              to="#"
                              id={linkId}
                              className="team-member d-inline-block"
                            >
                              <img
                                className="rounded-circle avatar-xs"
                                src={getUserImagePath(tch.RowID, "image.jpeg")}
                                alt="Avatar"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={tooltipId}
                              >
                                {tch.Name}
                              </UncontrolledTooltip>
                            </Link>
                          </div>
                        )
                      })}
                  </div>
                </td>
                <td>
                  <div className="avatar-group">
                    {scheduledchildren
                      .filter(x => x.ClassID === row.ClassID)
                      .map((ch, key2) => {
                        idCounter++ // Increment the counter for each iteration
                        const linkId = `schildren_${idCounter}` // Generate unique ID for Link
                        const tooltipId = `schildren_${idCounter}` // Generate unique ID for UncontrolledTooltip
                        return (
                          <div key={key2} className="avatar-group-item">
                            <Link
                              to="#"
                              id={linkId}
                              className="team-member d-inline-block"
                            >
                              <img
                                className="rounded-circle avatar-xs"
                                src={getChildImagePath(ch.RowID, "image.jpeg")}
                                alt="Avatar"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={tooltipId}
                              >
                                {ch.Name}
                              </UncontrolledTooltip>
                            </Link>
                          </div>
                        )
                      })}
                  </div>
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

LiveStatusTab.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LiveStatusTab)
