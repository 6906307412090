/*Get NOTIFICATION */
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL"

/**
 * Delete NOTIFICATION
 */
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL"
/**
 * Add NOTIFICATION
 */
export const ADD_NEW_NOTIFICATION = "ADD_NEW_NOTIFICATION"
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS"
export const ADD_NOTIFICATION_FAIL = "ADD_NOTIFICATION_FAIL"

/**
 * Get NOTIFICATION DETAILS
 */
export const GET_NOTIFICATION_DETAIL = "GET_NOTIFICATION_DETAIL"
export const GET_NOTIFICATION_DETAIL_SUCCESS = "GET_NOTIFICATION_DETAIL_SUCCESS"
export const GET_NOTIFICATION_DETAIL_FAIL = "GET_NOTIFICATION_DETAIL_FAIL"

/**
 * Edit NOTIFICATION
 */
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL"
/**
 * Reset NOTIFICATION
 */
export const RESET_NOTIFICATION = "RESET_NOTIFICATION"
export const RESET_NOTIFICATION_SUCCESS = "RESET_NOTIFICATION_SUCCESS"

/*Get NOTIFICATION EVENTS*/
export const GET_NOTIFICATION_EVENTS = "GET_NOTIFICATION_EVENTS"
export const GET_NOTIFICATION_EVENTS_SUCCESS = "GET_NOTIFICATION_EVENTS_SUCCESS"
export const GET_NOTIFICATION_EVENTS_FAIL = "GET_NOTIFICATION_EVENTS_FAIL"

/*Get NOTIFICATION RECIPIENTS*/
export const GET_NOTIFICATION_RECIPIENTS = "GET_NOTIFICATION_RECIPIENTS"
export const GET_NOTIFICATION_RECIPIENTS_SUCCESS =
  "GET_NOTIFICATION_RECIPIENTS_SUCCESS"
export const GET_NOTIFICATION_RECIPIENTS_FAIL = "GET_NOTIFICATION_RECIPIENTS"
