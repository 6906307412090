import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewClass as onAddNewClass,
  updateClass as onUpdateClass,
  getClassTypes as onGetClassTypes,
  getRooms as onGetRooms,
  resetClass as onResetClass,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"

function BasicInfoTab({ loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)

  //////////////////Class1 STATE/////////////////////
  const data = useSelector(state => state.class1)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Title: yup
      .string()
      .required("Required")
      .min(3, "Title must be at least 3 characters"),
    ClassTypes: yup.object().required("Required"),
    EnrollmentLimit: yup
      .string()
      .when("ClassTypes", {
        is: value => value?.ClassTypeID === 1,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    MinAge: yup
      .string()
      .when("ClassTypes", {
        is: value => value?.ClassTypeID === 1,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    MaxAge: yup
      .string()
      .when("ClassTypes", {
        is: value => value?.ClassTypeID === 1,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    Rooms: yup
      .object()
      .when("ClassTypes", {
        is: value => value?.ClassTypeID === 1,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    IsActive: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Title: "",
      EnrollmentLimit: "",
      MinAge: "",
      MaxAge: "",
      ClassType: "",
      Room: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, watch, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const CurrentClassType = watch("ClassTypes")

  useDeepCompareEffect(() => {
    function updateClassState() {
      dispatch(onGetClassTypes())
      dispatch(onGetRooms())
      //reset form with server data
      reset(data.class1)
    }

    updateClassState()
  }, [dispatch])

  //////////////////Class STATE/////////////////////
  const selectClassState = state => state.class1
  const ClassProperties = createSelector(selectClassState, e => ({
    classTypes: e.classTypes,
    loading: e.loading,
  }))
  const { classTypes } = useSelector(ClassProperties)

  ///////////////////////////////////////
  //////////////////Room STATE/////////////////////
  const selectRoomState = state => state.room
  const RoomProperties = createSelector(selectRoomState, e => ({
    rooms: e.rooms,
    loading: e.loading,
  }))
  const { rooms } = useSelector(RoomProperties)
  //////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetClass())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/classes")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewClass(getValues()))
      } else {
        dispatch(onUpdateClass(getValues()))
      }
      navigate("/classes")
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Class Type</Label>
            <Controller
              name="ClassTypes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="ClassTypes"
                    options={classTypes}
                    getOptionLabel={option => option.ClassType}
                    getOptionValue={option => option.ClassTypeID}
                    required
                    aria-invalid={!!errors.ClassTypes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.ClassTypes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.ClassTypes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          {CurrentClassType?.ClassTypeID === 1 ? (
            <>
              <div className="mb-3">
                <Label>Room</Label>
                <Controller
                  name="Rooms"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Rooms"
                        options={rooms}
                        getOptionLabel={option => option.Title}
                        getOptionValue={option => option.RoomID}
                        required
                        aria-invalid={!!errors.Rooms}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Rooms?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Rooms?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Enrollment Limit</Label>
                <Controller
                  name="EnrollmentLimit"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="EnrollmentLimit"
                        type="number"
                        required
                        invalid={!!errors.EnrollmentLimit}
                      />
                      {errors?.EnrollmentLimit?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.EnrollmentLimit?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Min Age (months)</Label>
                <Controller
                  name="MinAge"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="MinAge"
                        type="number"
                        required
                        invalid={!!errors.MinAge}
                      />
                      {errors?.MinAge?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.MinAge?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Max Age (months)</Label>
                <Controller
                  name="MaxAge"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="MaxAge"
                        type="number"
                        required
                        invalid={!!errors.MaxAge}
                      />
                      {errors?.MaxAge?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.MaxAge?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </>
          ) : (
            ""
          )}
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
