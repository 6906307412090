import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//Staff Pages
import Staffs from "../pages/Staff/index"
import StaffAddEdit from "../pages/Staff/StaffAddEdit"

//Child Pages
import Children from "../pages/Child/index"
import ChildAddEdit from "../pages/Child/ChildAddEdit"
import MessageInbox from "../pages/Child/tabs/MessageDetails/index"

//Adult Pages
import Adults from "../pages/Adult/index"
import AdultAddEdit from "../pages/Adult/AdultAddEdit"

//Class Pages
import Classes from "../pages/Class/index"
import ClassAddEdit from "../pages/Class/ClassAddEdit"

//Room Pages
import Rooms from "../pages/Room/index"
import RoomAddEdit from "../pages/Room/RoomAddEdit"

//Meal Pages
import Meals from "../pages/Meal/index"
import MealAddEdit from "../pages/Meal/MealAddEdit"

//Bus Pages
import Buses from "../pages/Bus/index"
import BusAddEdit from "../pages/Bus/BusAddEdit"

//Scheduling Pages
import Scheduling from "../pages/Scheduling/index"

//Certification Pages
import Certification from "../pages/Certification/index"

//Theme Pages
import Theme from "../pages/Theme/index"

//Immunization Pages
import Immunization from "../pages/Immunization/index"

//Report
import Report from "../pages/Report/index"
import ShowReport from "pages/Report/ShowReport"

//Notification Pages
import Notifications from "../pages/Notification/index"
import NotificationAddEdit from "../pages/Notification/NotificationAddEdit"

//Forum Pages
import Forums from "../pages/Forum/index"
import ForumAddEdit from "../pages/Forum/ForumAddEdit"
import Discussion from "../pages/Forum/pages/discussion"

//Chat Pages
import Chat from "../pages/Chat/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/dashboard/index"
import CenterStatus from "../pages/Dashboard/status/index"
import CenterDocument from "../pages/Dashboard/document/index"
import CenterBookingRequest from "../pages/Dashboard/request/index"

//Program Pages
import Program from "../pages/Pricing/Program/index"
import Flexibility from "../pages/Pricing/FlexibleCare/index"

//add-ons Pages
import AddOn from "../pages/Pricing/AddOns/index"

//Center Profile Pages
import Profile from "../pages/Profile/index"
import Charge from "../pages/Pricing/Charges/index"

import Albums from "../pages/Album/index"
import AlbumAddEdit from "pages/Album/AlbumAddEdit"

import LessonPlans from "pages/Learning/LearningEdit/LessonPlans"
import LessonPlanAddEdit from "pages/Learning/LearningEdit/LessonPlans/LessonPlanAddEdit"
import Curriculums from "pages/Learning/LearningEdit/Curriculums"
import CurriculumAddEdit from "pages/Learning/LearningEdit/Curriculums/CurriculumAddEdit"
import ActivityPlanner from "pages/Learning/LearningView/Activities"

import Notes from "pages/Learning/LearningEdit/Notes"
import Calender from "pages/Calendar/Calendar"

//Permission Pages
import RoleTypePermissions from "../pages/Permission/index"

import MyProfile from "pages/MyProfile/index"

import FamilyPage from "pages/Adult/profile/index"

//404 Page
import Pages404 from "../pages/Utility/404"
import ChangePasswordPage from "pages/Authentication/ChangePassword"
import PaymentPage from "pages/Adult/profile/Payments"
import DocumentPage from "pages/Adult/profile/Documents"
import NoteAddEdit from "pages/Learning/LearningEdit/Notes/NoteAddEdit"
import NotesView from "pages/Learning/LearningView/Notes"
import LessonView from "pages/Learning/LearningView/LessonPlans"
import CurriculumView from "pages/Learning/LearningView/Curriculums"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/dashboard/status", component: <CenterStatus /> },
  { path: "/dashboard/documents", component: <CenterDocument /> },
  { path: "/dashboard/requests", component: <CenterBookingRequest /> },

  //Staff
  { path: "/staffs", component: <Staffs /> },
  { path: "/staff/:id", component: <StaffAddEdit /> },

  //My Profile
  { path: "/profile", component: <MyProfile /> },

  //Family Profile
  { path: "/family/profile", component: <FamilyPage /> },

  //Child
  { path: "/children", component: <Children /> },
  { path: "/child/:id", component: <ChildAddEdit /> },
  { path: "/child/messaging/:id/:mid", component: <MessageInbox /> },
  //Adult
  { path: "/adults", component: <Adults /> },
  { path: "/adult/:id", component: <AdultAddEdit /> },
  //Class
  { path: "/classes", component: <Classes /> },
  { path: "/class/:id", component: <ClassAddEdit /> },
  //Bus
  { path: "/buses", component: <Buses /> },
  { path: "/bus/:id", component: <BusAddEdit /> },

  //albums
  { path: "/albums", component: <Albums /> },
  { path: "/album/:id", component: <AlbumAddEdit /> },

  //Room
  { path: "/rooms", component: <Rooms /> },
  { path: "/room/:id", component: <RoomAddEdit /> },
  //Meal
  { path: "/meals", component: <Meals /> },
  { path: "/meal/:id", component: <MealAddEdit /> },
  //Scheduling
  { path: "/scheduling", component: <Scheduling /> },
  { path: "/scheduling/:id", component: <Scheduling /> },
  //Notification
  { path: "/notifications", component: <Notifications /> },
  { path: "/notification/:id", component: <NotificationAddEdit /> },
  //Forum
  { path: "/forums", component: <Forums /> },
  { path: "/forum/:id", component: <ForumAddEdit /> },
  { path: "/discussion/:id", component: <Discussion /> },
  // //Chat
  { path: "/chat", component: <Chat /> },
  // report
  { path: "/reports", component: <Report /> },
  { path: "/report/:id", component: <ShowReport /> },

  // profile
  //{ path: "/profile", component: <UserProfile /> },

  //Certification
  { path: "/settings/certification", component: <Certification /> },

  //Immunization
  { path: "/settings/immunization", component: <Immunization /> },

  //Theme
  { path: "/settings/theme", component: <Theme /> },

  //Pricing
  { path: "/pricing/programs", component: <Program /> },
  { path: "/pricing/flexible", component: <Flexibility /> },

  //add-ons
  { path: "/pricing/addons", component: <AddOn /> },
  { path: "/pricing/flexible", component: <Flexibility /> },

  //money
  { path: "/money/payments", component: <PaymentPage /> },

  //document
  { path: "/documents", component: <DocumentPage /> },

  //Center Profile
  { path: "/settings/profile", component: <Profile /> },
  { path: "/pricing/charges", component: <Charge /> },
  //{ path: "/chat", component: <Chat /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  //aad-lesson plans
  { path: "/learning/lesson-plans", component: <LessonPlans /> },
  { path: "/learning/lesson-plans/:id", component: <LessonPlanAddEdit /> },

  //aad-curriculums
  // { path: "/learning/curriculums", component: <Curriculums /> },
  //albums
  { path: "/learning/curriculums", component: <Curriculums /> },
  { path: "/learning/curriculums/:id", component: <CurriculumAddEdit /> },

  //Planner
  { path: "/learning/planner", component: <ActivityPlanner /> },

  //aad-notes
  { path: "/learning/notes", component: <Notes /> },
  { path: "/learning/notes/:id", component: <NoteAddEdit /> },

  //Journal
  { path: "/journal/notes", component: <NotesView /> },
  { path: "/journal/lesson-plans", component: <LessonView /> },
  { path: "/journal/curriculums", component: <CurriculumView /> },

  //calendar
  { path: "/calendar", component: <Calender /> },

  //permission
  { path: "/settings/permissions", component: <RoleTypePermissions /> },

  //change password
  { path: "/settings/changepassword", component: <ChangePasswordPage /> },

  //404 Page
  { path: "*", component: <Pages404 /> },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
