import {
  GET_BUSES,
  GET_BUSES_FAIL,
  GET_BUSES_SUCCESS,
  DELETE_BUS,
  DELETE_BUS_FAIL,
  DELETE_BUS_SUCCESS,
  ADD_NEW_BUS,
  ADD_BUS_SUCCESS,
  ADD_BUS_FAIL,
  GET_BUS_DETAIL,
  GET_BUS_DETAIL_SUCCESS,
  GET_BUS_DETAIL_FAIL,
  UPDATE_BUS,
  UPDATE_BUS_SUCCESS,
  UPDATE_BUS_FAIL,
  RESET_BUS_SUCCESS,
  RESET_BUS,
} from "./actionTypes"

export const getBuses = () => ({
  type: GET_BUSES,
})

export const getBusesSuccess = buses => ({
  type: GET_BUSES_SUCCESS,
  payload: buses,
})

export const getBusesFail = error => ({
  type: GET_BUSES_FAIL,
  payload: error,
})

export const deleteBus = bus => ({
  type: DELETE_BUS,
  payload: bus,
})

export const deleteBusSuccess = bus => ({
  type: DELETE_BUS_SUCCESS,
  payload: bus,
})

export const deleteBusFail = error => ({
  type: DELETE_BUS_FAIL,
  payload: error,
})
export const addNewBus = bus => ({
  type: ADD_NEW_BUS,
  payload: bus,
})

export const addBusSuccess = bus => ({
  type: ADD_BUS_SUCCESS,
  payload: bus,
})

export const addBusFail = error => ({
  type: ADD_BUS_FAIL,
  payload: error,
})

export const getBusDetail = busId => ({
  type: GET_BUS_DETAIL,
  busId,
})

export const getBusDetailSuccess = bus => ({
  type: GET_BUS_DETAIL_SUCCESS,
  payload: bus,
})

export const getBusDetailFail = error => ({
  type: GET_BUS_DETAIL_FAIL,
  payload: error,
})

export const updateBus = bus => ({
  type: UPDATE_BUS,
  payload: bus,
})

export const updateBusSuccess = bus => ({
  type: UPDATE_BUS_SUCCESS,
  payload: bus,
})

export const updateBusFail = error => ({
  type: UPDATE_BUS_FAIL,
  payload: error,
})
export const resetBus = () => ({
  type: RESET_BUS,
})

export const resetBusSuccess = bus => ({
  type: RESET_BUS_SUCCESS,
  payload: bus,
})
