import {
  GET_CHATS_FAIL,
  GET_CHATS_SUCCESS,
  GET_LATEST_CHAT_FAIL,
  GET_LATEST_CHAT_SUCCESS,
  DELETE_CHAT_SUCCESS,
  DELETE_CHAT_FAIL,
  ADD_CHAT_FAIL,
  ADD_CHAT_SUCCESS,
  GET_CHAT_DETAIL_SUCCESS,
  GET_CHAT_DETAIL_FAIL,
  UPDATE_CHAT_SUCCESS,
  UPDATE_CHAT_FAIL,
  RESET_CHAT_SUCCESS,
  DELETE_CHAT_MESSAGE_SUCCESS,
  DELETE_CHAT_MESSAGE_FAIL,
  ADD_CHAT_MESSAGE_FAIL,
  ADD_CHAT_MESSAGE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  chats: [],
  chat: {},
  chatmessages: [],
  error: {},
  loading: true,
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        loading: true,
      }
    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LATEST_CHAT_SUCCESS:
      return {
        ...state,
        chat: action.payload,
        chatmessages: action.payload.ChatMessages,
        loading: true,
      }
    case GET_LATEST_CHAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CHAT_SUCCESS: {
      return {
        ...state,
        chats: state.chats.filter(chat => chat.ChatID !== action.payload),
      }
    }
    case DELETE_CHAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHAT_SUCCESS:
      return {
        ...state,
        chatmessages: action.payload.ChatMessages,
        chat: action.payload,
      }
    case ADD_CHAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHAT_DETAIL_SUCCESS:
      return {
        ...state,
        chat: action.payload,
        chatmessages: action.payload.ChatMessages,
        loading: true,
      }

    case GET_CHAT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CHAT_SUCCESS:
      return {
        ...state,
        chats: state.chats.map(chat =>
          chat.ChatID.toString() === action.payload.ChatID.toString()
            ? { chat, ...action.payload }
            : chat
        ),
      }

    case UPDATE_CHAT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_CHAT_SUCCESS:
      return {
        ...state,
        chat: action.payload,
      }
    case DELETE_CHAT_MESSAGE_SUCCESS: {
      return {
        ...state,
        chat: action.payload,
        chatmessages: action.payload.ChatMessages,
      }
    }
    case DELETE_CHAT_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHAT_MESSAGE_SUCCESS:
      return {
        ...state,
        chat: action.payload,
        chatmessages: action.payload.ChatMessages,
      }
    case ADD_CHAT_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Chat
