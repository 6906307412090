import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { UncontrolledTooltip } from "reactstrap"

//controls
const LinkControl = ({
  linkClassName,
  mdiClassName,
  linkText,
  linkTooltip,
  onClick,
}) => {
  return (
    <Link to="#" className={linkClassName} onClick={onClick}>
      <i className={mdiClassName} id={linkTooltip} />
      <UncontrolledTooltip placement="top" target={linkTooltip}>
        {linkText}
      </UncontrolledTooltip>
    </Link>
  )
}

LinkControl.propTypes = {
  linkClassName: PropTypes.string,
  mdiClassName: PropTypes.string,
  linkText: PropTypes.string,
  linkTooltip: PropTypes.string,
  onClick: PropTypes.func,
}

export default LinkControl
