import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"

function HomeClass({ profile }) {
  if (isEmpty(profile?.HomeClass)) {
    return <div>Home class has not been set for staff</div>
  }

  return <div>{profile.HomeClass}</div>
}
export default HomeClass
