import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
//redux
import { useSelector } from "react-redux"

function ProvidedFood() {
  //////////////////Child STATE/////////////////////
  const { providedfoods } = useSelector(state => state.child)
  //////////////////////////////////////

  if (isEmpty(providedfoods.Foods)) {
    return <div>Not set</div>
  }

  return (
    <>
      {providedfoods.Foods &&
        providedfoods.Foods.map((item, index) => {
          return <div key={"food" + index}>{item.Food}</div>
        })}
    </>
  )
}
export default ProvidedFood
