import {
  GET_ACTIVITIES_FAIL,
  GET_ACTIVITIES_SUCCESS,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAIL,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAIL,
  GET_ACTIVITY_DETAIL_SUCCESS,
  GET_ACTIVITY_DETAIL_FAIL,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAIL,
  RESET_ACTIVITY,
  GET_ACTIVITIES_BY_CLASS_SUCCESS,
  GET_ACTIVITIES_BY_CLASS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  activities: [],
  activity: {},
  column: [],
  error: {},
  loading: true,
}

const Schedules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    case GET_ACTIVITIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: action.payload,
      }
    case ADD_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_ACTIVITY:
      return {
        ...state,
        activity: {},
      }
    case DELETE_ACTIVITY_SUCCESS: {
      return {
        ...state,
        activities: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    }
    case DELETE_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ACTIVITY_DETAIL_SUCCESS:
      return {
        ...state,
        activity: action.payload,
        loading: true,
      }

    case GET_ACTIVITY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: action.payload,
      }
    case UPDATE_ACTIVITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ACTIVITIES_BY_CLASS_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        loading: true,
      }
    case GET_ACTIVITIES_BY_CLASS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Schedules
