import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getAddOns as onGetAddOns,
  getAddOnDetail as onGetAddOnDetail,
  deleteAddOn as onDeleteAddOn,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import AddOnModal from "./modal/AddOnModal"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
// import EnrollModal from "./modal/EnrollModal"

function AddOn() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [addOnModal, setAddOnModal] = useState(false)
  // const [enrollModal, setEnrollModal] = useState(false)
  const [addon, setAddOn] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetAddOns())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { addons, loading } = useSelector(state => state.addon)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    setAddOn(null)
    setEdit(false)
    setAddOnModal(true)
  }

  const onClickEdit = arg => {
    debugger
    //dispatch(onGetAddOnDetail(arg))
    setAddOn(arg)
    setEdit(true)
    setAddOnModal(true)
  }

  const onClickDelete = arg => {
    setAddOn(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
    setAddOnModal(false)
  }

  const handleDelete = () => {
    if (addon && addon.AddOnID) {
      dispatch(onDeleteAddOn(addon.AddOnID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return <>{cellProps.row.original.Name}</>
        },
      },
      // {
      //   Header: "Available for",
      //   accessor: "AvailableFor",
      //   Cell: cellProps => {
      //     return cellProps.value ? cellProps.value : ""
      //   },
      // },

      {
        Header: "Rate",
        accessor: "Rate",
        Cell: cellProps => {
          return cellProps.value
            ? "$" + cellProps.value.toFixed(2).toString()
            : ""
        },
      },
      {
        Header: "Private",
        accessor: "IsPrivate",
        Cell: cellProps => {
          return cellProps.value == true ? "Yes" : "No"
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                // to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {addOnModal && (
        <AddOnModal
          show={addOnModal}
          onEdit={edit}
          addon={addon}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Pricing" breadcrumbItem="Add-Ons" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="btn "
                      onClick={handleAddClick}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Add add-ons
                    </Button>
                  </div>
                  {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={addons}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
AddOn.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default AddOn
