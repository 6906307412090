/*Get MEAL */
export const GET_MEALS = "GET_MEALS"
export const GET_MEALS_SUCCESS = "GET_MEALS_SUCCESS"
export const GET_MEALS_FAIL = "GET_MEALS_FAIL"

/**
 * Delete MEAL
 */
export const DELETE_MEAL = "DELETE_MEAL"
export const DELETE_MEAL_SUCCESS = "DELETE_MEAL_SUCCESS"
export const DELETE_MEAL_FAIL = "DELETE_MEAL_FAIL"
/**
 * Add MEAL
 */
export const ADD_NEW_MEAL = "ADD_NEW_MEAL"
export const ADD_MEAL_SUCCESS = "ADD_MEAL_SUCCESS"
export const ADD_MEAL_FAIL = "ADD_MEAL_FAIL"

/**
 * Get MEAL DETAILS
 */
export const GET_MEAL_DETAIL = "GET_MEAL_DETAIL"
export const GET_MEAL_DETAIL_SUCCESS = "GET_MEAL_DETAIL_SUCCESS"
export const GET_MEAL_DETAIL_FAIL = "GET_MEAL_DETAIL_FAIL"

/**
 * Edit MEAL
 */
export const UPDATE_MEAL = "UPDATE_MEAL"
export const UPDATE_MEAL_SUCCESS = "UPDATE_MEAL_SUCCESS"
export const UPDATE_MEAL_FAIL = "UPDATE_MEAL_FAIL"
/**
 * Reset MEAL
 */
export const RESET_MEAL = "RESET_MEAL"
export const RESET_MEAL_SUCCESS = "RESET_MEAL_SUCCESS"
