import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { addStaffProfile as onAddStaffProfile } from "store/actions"

const NotesModal = ({ staffID, profile, show, onCloseClick }) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Notes: yup.string().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Notes: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      if (show) {
        reset(profile)
      }
    }

    updateState()
  }, [show, dispatch])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddStaffProfile(staffID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Manage Notes
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Notes</Label>
                <Controller
                  name="Notes"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <>
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control mb-3"
                        id="Notes"
                      />
                      {errors?.Notes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Notes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

NotesModal.propTypes = {
  staffID: PropTypes.number,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default NotesModal
