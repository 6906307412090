/*Get CHAT */
export const GET_CHATS = "GET_CHATS"
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS"
export const GET_CHATS_FAIL = "GET_CHATS_FAIL"

/*Get LATEST CHAT */
export const GET_LATEST_CHAT = "GET_LATEST_CHAT"
export const GET_LATEST_CHAT_SUCCESS = "GET_LATEST_CHAT_SUCCESS"
export const GET_LATEST_CHAT_FAIL = "GET_LATEST_CHAT_FAIL"

/**
 * Delete CHAT
 */
export const DELETE_CHAT = "DELETE_CHAT"
export const DELETE_CHAT_SUCCESS = "DELETE_CHAT_SUCCESS"
export const DELETE_CHAT_FAIL = "DELETE_CHAT_FAIL"
/**
 * Add CHAT
 */
export const ADD_NEW_CHAT = "ADD_NEW_CHAT"
export const ADD_CHAT_SUCCESS = "ADD_CHAT_SUCCESS"
export const ADD_CHAT_FAIL = "ADD_CHAT_FAIL"

/**
 * Get CHAT DETAILS
 */
export const GET_CHAT_DETAIL = "GET_CHAT_DETAIL"
export const GET_CHAT_DETAIL_SUCCESS = "GET_CHAT_DETAIL_SUCCESS"
export const GET_CHAT_DETAIL_FAIL = "GET_CHAT_DETAIL_FAIL"

/**
 * Edit CHAT
 */
export const UPDATE_CHAT = "UPDATE_CHAT"
export const UPDATE_CHAT_SUCCESS = "UPDATE_CHAT_SUCCESS"
export const UPDATE_CHAT_FAIL = "UPDATE_CHAT_FAIL"
/**
 * Reset CHAT
 */
export const RESET_CHAT = "RESET_CHAT"
export const RESET_CHAT_SUCCESS = "RESET_CHAT_SUCCESS"

/**
 * Delete CHAT POST
 */
export const DELETE_CHAT_MESSAGE = "DELETE_CHAT_MESSAGE"
export const DELETE_CHAT_MESSAGE_SUCCESS = "DELETE_CHAT_MESSAGE_SUCCESS"
export const DELETE_CHAT_MESSAGE_FAIL = "DELETE_CHAT_MESSAGE_FAIL"
/**
 * Add CHAT POST
 */
export const ADD_NEW_CHAT_MESSAGE = "ADD_NEW_CHAT_MESSAGE"
export const ADD_CHAT_MESSAGE_SUCCESS = "ADD_CHAT_MESSAGE_SUCCESS"
export const ADD_CHAT_MESSAGE_FAIL = "ADD_CHAT_MESSAGE_FAIL"

/**
 * Add/Edit FIle
 */

export const UPDATE_CHAT_MESSAGE_FILE = "UPDATE_CHAT_MESSAGE_FILE"
export const UPDATE_CHAT_MESSAGE_FILE_SUCCESS =
  "UPDATE_CHAT_MESSAGE_FILE_SUCCESS"
export const UPDATE_CHAT_MESSAGE_FILE_FAIL = "UPDATE_CHAT_MESSAGE_FILE_FAIL"
