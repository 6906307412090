import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import Moment from "moment"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Collapse,
  Table,
} from "reactstrap"

import { getClassProgramDetail as onGetClassProgramDetail } from "store/actions"
import classnames from "classnames"
import EnrollmentModal from "pages/Class/modal/EnrollmentModal"

function ClassPrograms() {
  const dispatch = useDispatch()
  const [enrollmentModal, setEnrollmentModal] = useState(false)
  const [dayID, setDayID] = useState(0)
  const [programID, setProgramID] = useState(0)
  const [objectsList, setObjectsList] = useState([])

  const { class1, programs, loading } = useSelector(state => state.class1)

  useEffect(() => {
    dispatch(onGetClassProgramDetail(class1.ClassID))
  }, [dispatch])

  useEffect(() => {
    setObjectsList(programs.map(e => ({ ...e, isOpen: false })))
  }, [programs])

  const handleClick = item => {
    setObjectsList(
      objectsList.map(e =>
        e.ProgramID === item.ProgramID ? { ...e, isOpen: !e.isOpen } : e
      )
    )
  }

  const showDetails = e => {
    setEnrollmentModal(true)
    setDayID(e.Days)
    setProgramID(e.ProgramID)
  }

  const onCloseModal = () => {
    setEnrollmentModal(false)
    setDayID(0)
    setProgramID(0)
  }

  return (
    <React.Fragment>
      {enrollmentModal && (
        <EnrollmentModal
          show={enrollmentModal}
          onCloseClick={() => onCloseModal()}
          dayID={dayID}
          programID={programID}
        />
      )}
      <Row>
        <Col xs="12">
          <div className="accordion" id="accordion">
            {objectsList &&
              objectsList.map((v, p) => {
                return (
                  <>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !v.isOpen,
                            }
                          )}
                          type="button"
                          onClick={() => handleClick(v)}
                          style={{ cursor: "pointer" }}
                        >
                          {v.Name}
                          <Badge className="bg-success ms-1">
                            {v.BillingCycle}
                          </Badge>
                          <div className="font-weight-normal ms-2">
                            {v.WeekDays.map((f, i) => {
                              return f.Name + ", "
                            })}
                            <span>
                              {` from ` +
                                Moment(v.StartTime).format("hh:mm A") +
                                " to " +
                                Moment(v.EndTime).format("hh:mm A")}
                            </span>
                          </div>
                        </button>
                      </h2>

                      <Collapse
                        isOpen={v.isOpen}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">
                          <Table className="table mb-0 table">
                            <thead>
                              <tr>
                                <th>Days</th>
                                <th>{v.BillingCycle + ` Price`}</th>
                                <th>Requested</th>
                                <th>Waitlist</th>
                                <th>Enrollments</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {v.ProgramSchedules.map((z, s) => {
                                return (
                                  <tr key={"schedule" + s}>
                                    <td>
                                      {z.Days +
                                        (z.Days === 1 ? " day" : " days")}
                                    </td>
                                    <td>{`$` + z.Price.toFixed(2)}</td>
                                    <td>{z.RequestedCount}</td>
                                    <td>{z.WaitlistCount}</td>
                                    <td>{z.EnrollmentCount}</td>
                                    <td>
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="btn btn-sm"
                                        onClick={() => {
                                          showDetails(z)
                                        }}
                                      >
                                        View
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Collapse>
                    </div>
                  </>
                )
              })}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ClassPrograms
