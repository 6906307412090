import PropTypes, { number, object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//Date filter
import Moment from "moment"

import { updateStaffCertification as onUpdateStaffCertification } from "store/actions"
import { isEmpty } from "lodash"

const formdefault = {
  IssueDate: "",
  Optout: false,
}

const CertificationModal = ({ show, data, onCloseClick }) => {
  const dispatch = useDispatch()
  const { staff } = useSelector(state => state.staff)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    StaffCertificationID: yup.number(),
    IssueDate: yup.string().when("Optout", {
      is: value => value === false,
      then: schema => schema.required("Required"),
      otherwise: schema => schema.nullable(true),
    }),
    Optout: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    const filtered = {
      ...data,
      IssueDate: data.IssueDate
        ? Moment(data.IssueDate).format("DD MMM, YYYY")
        : "",
    }

    reset(filtered)
  }, [data])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onUpdateStaffCertification(staff.StaffID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Issue Date
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Issue Date</Label>
                <Controller
                  name="IssueDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="IssueDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("IssueDate", dateStr)
                        }}
                      />
                      {errors?.IssueDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.IssueDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Opt out</Label>
                <div>
                  <Controller
                    name="Optout"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="Optout"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

CertificationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default CertificationModal
