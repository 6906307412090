import React, { useEffect, useMemo, useState } from "react"
import PropTypes, { func } from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"
import RightSidebar from "components/CommonForBoth/RightSidebar"

function Theme() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {}, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Theme" breadcrumbItem="Manage Theme" />
          <Col xs="12">
            <Card>
              <CardBody>
                <RightSidebar />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Theme.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Theme
