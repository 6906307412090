import {
  GET_CHILD_SCHEDULES,
  GET_CHILD_SCHEDULES_SUCCESS,
  GET_CHILD_SCHEDULES_FAIL,
  RESET_CHILD_SCHEDULES,
  RESET_CHILD_SCHEDULES_SUCCESS,
  ADD_CHILD_SCHEDULES,
  ADD_CHILD_SCHEDULES_SUCCESS,
  ADD_CHILD_SCHEDULES_FAIL,
  GET_CHILD_ABSENT_TYPES,
  GET_CHILD_ABSENT_TYPES_SUCCESS,
  DELETE_CHILD_SCHEDULE,
  DELETE_CHILD_SCHEDULE_SUCCESS,
  DELETE_CHILD_SCHEDULE_FAIL,
} from "./actionTypes"

export const getChildSchedules = filter => ({
  type: GET_CHILD_SCHEDULES,
  filter,
})

export const getChildSchedulesSuccess = childSchedules => ({
  type: GET_CHILD_SCHEDULES_SUCCESS,
  payload: childSchedules,
})

export const getChildSchedulesFail = error => ({
  type: GET_CHILD_SCHEDULES_FAIL,
  payload: error,
})

export const addChildSchedule = childSchedules => ({
  type: ADD_CHILD_SCHEDULES,
  payload: childSchedules,
})

export const addChildScheduleSuccess = childSchedules => ({
  type: ADD_CHILD_SCHEDULES_SUCCESS,
  payload: childSchedules,
})

export const addChildScheduleFail = error => ({
  type: ADD_CHILD_SCHEDULES_FAIL,
  payload: error,
})

export const getChildAbsentTypes = () => ({
  type: GET_CHILD_ABSENT_TYPES,
})

export const getChildAbsentTypesSuccess = absenttypes => ({
  type: GET_CHILD_ABSENT_TYPES_SUCCESS,
  payload: absenttypes,
})

export const getChildAbsentTypesFail = error => ({
  type: GET_CHILD_SCHEDULES_FAIL,
  payload: error,
})

export const resetChildSchedules = () => ({
  type: RESET_CHILD_SCHEDULES,
})

export const resetChildScheduleSuccess = childSchedules => ({
  type: RESET_CHILD_SCHEDULES_SUCCESS,
  payload: childSchedules,
})

export const deleteChildSchedule = childSchedules => ({
  type: DELETE_CHILD_SCHEDULE,
  payload: childSchedules,
})

export const deleteChildScheduleSuccess = childSchedules => ({
  type: DELETE_CHILD_SCHEDULE_SUCCESS,
  payload: childSchedules,
})

export const deleteChildScheduleFail = error => ({
  type: DELETE_CHILD_SCHEDULE_FAIL,
  payload: error,
})
