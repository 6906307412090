import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
//redux
import { useSelector } from "react-redux"

function HomeClass() {
  //////////////////Child STATE/////////////////////
  const { child } = useSelector(state => state.child)
  //////////////////////////////////////

  if (isEmpty(child.HomeClassTitle)) {
    return <div>Home class has not been set for child</div>
  }

  return <div>{child.HomeClassTitle}</div>
}
export default HomeClass
