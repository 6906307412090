import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback } from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { updateFlexibleDefaultRate as onUpdateFlexibleDefaultRate } from "store/actions"
import PageModal from "components/Common/PageModal"

import { isEmpty } from "lodash"

const formdefault = {
  ProgramPayRateID: 0,
  Price: 0,
}

const DefaultModal = ({ show, header, onCloseClick }) => {
  const dispatch = useDispatch()
  const { flexible } = useSelector(state => state.flexible)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramPayRateID: yup.number(),
    Price: yup.number(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    if (!isEmpty(flexible)) {
      const response = {
        ...flexible,
      }
      reset(response)
    }
  }, [flexible])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onUpdateFlexibleDefaultRate(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header={"Define " + header + " Rate"}
      size="md"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Default {header} Rate</Label>
              <Controller
                name="Price"
                control={control}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      id="Price"
                      type="number"
                      invalid={!!errors.Price}
                    />
                    {errors?.Price?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Price?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

DefaultModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  header: PropTypes.string,
  show: PropTypes.any,
}

export default DefaultModal
