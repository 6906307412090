import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Input, Button, Row } from "reactstrap"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { addNotes as onAddNotes } from "store/actions"
import { isEmpty } from "lodash"
import PageModal from "components/Common/PageModal"

const formdefault = {
  Notes: [
    {
      ChildNoteID: 0,
      IsPrivate: false,
      Note: "",
    },
  ],
}

const NotesModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()

  //////////////////Child STATE/////////////////////
  const { child, notes } = useSelector(state => state.child)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Notes: yup.array().of(
      yup.object().shape({
        ChildNoteID: yup.number(),
        IsPrivate: yup.bool(),
        Note: yup.string(),
      })
    ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, getValues, trigger } = methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "Notes",
    control,
  })

  useEffect(() => {
    if (isEmpty(notes.Notes)) {
      reset(formdefault)
    } else {
      reset(notes)
    }
  }, [notes])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddNotes(child.ChildID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Notes"
    >
      <FormProvider {...methods}>
        <div className="float-none">
          {fields.map((item, index) => (
            <div key={`notes${index}`} className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  <Label>Make Private</Label>
                  <Controller
                    name={`Notes[${index}].IsPrivate`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id={`Notes[${index}].IsPrivate`}
                          checked={field.value ?? false}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="ms-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
                <Button
                  hidden={index > 0}
                  onClick={() =>
                    append({
                      ChildNoteID: 0,
                      IsPrivate: false,
                      Note: "",
                    })
                  }
                >
                  Add
                </Button>
                <Button hidden={index === 0} onClick={() => remove(index)}>
                  Delete
                </Button>
              </div>

              <div className="mb-1">
                <Controller
                  name={`Notes[${index}].Note`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control mb-3"
                        id={`Notes[${index}].Note`}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </FormProvider>
    </PageModal>
  )
}

NotesModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default NotesModal
