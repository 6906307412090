import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Meal Redux States
import {
  GET_MEALS,
  DELETE_MEAL,
  ADD_NEW_MEAL,
  GET_MEAL_DETAIL,
  UPDATE_MEAL,
  RESET_MEAL,
} from "./actionTypes"
import {
  getMealsFail,
  getMealsSuccess,
  deleteMealSuccess,
  deleteMealFail,
  addMealSuccess,
  addMealFail,
  getMealDetailSuccess,
  getMealDetailFail,
  updateMealSuccess,
  updateMealFail,
  resetMealSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMeals,
  deleteMeal,
  addNewMeal,
  getMealDetail,
  updateMeal,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchMeals() {
  try {
    const response = yield call(getMeals)
    yield put(getMealsSuccess(response))
  } catch (error) {
    yield put(getMealsFail(error))
  }
}

function* onDeleteMeal({ payload: meal }) {
  try {
    const response = yield call(deleteMeal, meal)
    yield put(deleteMealSuccess(response))
    toast.success("Meal deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteMealFail(error))
    toast.error("Meal deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewMeal({ payload: meal }) {
  try {
    const response = yield call(addNewMeal, meal)
    yield put(addMealSuccess(response[0]))
    toast.success("Meal added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addMealFail(error))
    toast.error("Meal addition failed", { autoClose: 2000 })
  }
}

function* fetchMealDetail({ mealId }) {
  try {
    const response = yield call(getMealDetail, mealId)
    let result = response[0]
    result = {
      ...result,
      ClassIDs: JSON.parse(result.Classes),
    }
    yield put(getMealDetailSuccess(result))
  } catch (error) {
    yield put(getMealDetailFail(error))
  }
}

function* onUpdateMeal({ payload: meal }) {
  try {
    const response = yield call(updateMeal, meal)
    let result = response[0]

    yield put(updateMealSuccess(result))
    toast.success("Meal updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateMealFail(error))
    toast.error("Meal updation failed", { autoClose: 2000 })
  }
}

function* onResetMeal() {
  try {
    yield put(resetMealSuccess({}))
  } catch (error) {}
}
function* mealSaga() {
  yield takeEvery(GET_MEALS, fetchMeals)
  yield takeEvery(DELETE_MEAL, onDeleteMeal)
  yield takeEvery(ADD_NEW_MEAL, onAddNewMeal)
  yield takeEvery(GET_MEAL_DETAIL, fetchMealDetail)
  yield takeEvery(UPDATE_MEAL, onUpdateMeal)
  yield takeEvery(RESET_MEAL, onResetMeal)
}

export default mealSaga
