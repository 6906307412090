/*Get PAYMENT METHODS */
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS"
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS"
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL"

/*Get WALLET TRANSACTION*/
export const GET_WALLET_TRANSACTION = "GET_WALLET_TRANSACTION"
export const GET_WALLET_TRANSACTION_SUCCESS = "GET_WALLET_TRANSACTION_SUCCESS"
export const GET_WALLET_TRANSACTION_FAIL = "GET_WALLET_TRANSACTION_FAIL"

/*Add WALLET TRANSACTION*/
export const ADD_WALLET_TRANSACTION = "ADD_WALLET_TRANSACTION"
export const ADD_WALLET_TRANSACTION_SUCCESS = "ADD_WALLET_TRANSACTION_SUCCESS"
export const ADD_WALLET_TRANSACTION_FAIL = "ADD_WALLET_TRANSACTION_FAIL"
