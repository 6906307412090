import PropTypes from "prop-types"
import React from "react"
//controls
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap"
const PageModal = ({
  show,
  onViewMode = false,
  header,
  onClosed,
  onCloseClick,
  onSaveClick,
  children,
  size = "md",
}) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={onClosed}
      centered={true}
      size={size}
    >
      <ModalHeader className="d-flex justify-content-center">
        {header}
      </ModalHeader>
      <ModalBody className="py-3 px-5">{children}</ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            hidden={onViewMode}
            className="btn btn btn-primary"
            onClick={onSaveClick}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

PageModal.propTypes = {
  header: PropTypes.string,
  onClosed: PropTypes.func,
  onSaveClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  children: PropTypes.any,
}

export default PageModal
