import {
  GET_BUSES_FAIL,
  GET_BUSES_SUCCESS,
  DELETE_BUS_SUCCESS,
  DELETE_BUS_FAIL,
  ADD_BUS_FAIL,
  ADD_BUS_SUCCESS,
  GET_BUS_DETAIL_SUCCESS,
  GET_BUS_DETAIL_FAIL,
  UPDATE_BUS_SUCCESS,
  UPDATE_BUS_FAIL,
  RESET_BUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  buses: [],
  bus: {},
  error: {},
  loading: true,
}

const Bus = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUSES_SUCCESS:
      return {
        ...state,
        buses: action.payload,
        loading: true,
      }
    case GET_BUSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_BUS_SUCCESS: {
      const data = {
        ...state,
        buses: state.buses.filter(
          bus => bus.BusID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        buses: state.buses.filter(bus => bus.BusID !== action.payload),
      }
    }
    case DELETE_BUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BUS_SUCCESS:
      return {
        ...state,
        buses: [...state.buses, action.payload],
      }
    case ADD_BUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BUS_DETAIL_SUCCESS:
      return {
        ...state,
        bus: action.payload,
        loading: true,
      }

    case GET_BUS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_BUS_SUCCESS:
      return {
        ...state,
        buses: state.buses.map(bus =>
          bus.BusID.toString() === action.payload.BusID.toString()
            ? { bus, ...action.payload }
            : bus
        ),
      }

    case UPDATE_BUS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_BUS_SUCCESS:
      return {
        ...state,
        bus: action.payload,
      }
    default:
      return state
  }
}

export default Bus
