import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Spinners from "components/Common/Spinner"
import { getMediaAlbmImagePath } from "helpers/imageService"
import { Gallery } from "react-grid-gallery"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

//custom hooks
import { useDeepCompareEffect } from "hooks"
import { deleteAlbumMedia as onDeleteAlbumMedia } from "store/actions"

function MediaAlbumTab({ album, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const [isLoading, setLoading] = useState(loading)
  const [albummedias, setAlbumMedias] = useState(album?.MediaAlbums)
 // const [albummedia, setAlbumMedia] = useState(null)
  const dispatch = useDispatch()
  //  {getUserImagePath(staff?.RowID, staff?.Photo ?? "")}
  // album?.MediaAlbums

  const [images, setImages] = useState([])

  const [index, setIndex] = useState(-1)

  const currentImage = images[index]
  const nextIndex = (index + 1) % images.length
  const nextImage = images[nextIndex] || currentImage
  const prevIndex = (index + images.length - 1) % images.length
  const prevImage = images[prevIndex] || currentImage

  //const handleClick = (index: Number, item: CustomImage) => setIndex(index);
  const handleClick = (index, item) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  
  const handleDelete = (index) => alert('handleDelete'+index)

  useDeepCompareEffect(() => {
    function updateAlbumState() {
      setAlbumMedias(album?.MediaAlbums)
      const arrGallery = []
      album?.MediaAlbums.forEach(obj => {
        let newImage = {
          src: getMediaAlbmImagePath(obj?.RowID, obj?.FileName ?? ""),
          original: getMediaAlbmImagePath(obj?.RowID, obj?.FileName ?? ""),
          width: 320,
          height: 320,
          tags: [],
          caption: "",
        }
        arrGallery.push(newImage)
      })
      setImages(arrGallery)
    }

    const { id } = routeParams

    if (id === "new") {
    } else {
      updateAlbumState()
    }
  }, [routeParams, albummedias, images, dispatch])

  useEffect(() => {
    setAlbumMedias(album?.MediaAlbums)
  }, [albummedias, dispatch])

  return (
    <>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <>
          <h3>Gallery</h3>

          <div>
            <Gallery
              images={images}
              onClick={handleClick}
              enableImageSelection={false}
            />
            {!!currentImage && (
              /* @ts-ignore */
              <Lightbox
                mainSrc={currentImage.original}
                imageTitle={currentImage.caption}
                mainSrcThumbnail={currentImage.src}
                nextSrc={nextImage.original}
                nextSrcThumbnail={nextImage.src}
                prevSrc={prevImage.original}
                prevSrcThumbnail={prevImage.src}
                onCloseRequest={handleClose}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default MediaAlbumTab
