import {
  GET_ADULTS,
  GET_ADULTS_FAIL,
  GET_ADULTS_SUCCESS,
  DELETE_ADULT,
  DELETE_ADULT_FAIL,
  DELETE_ADULT_SUCCESS,
  ADD_NEW_ADULT,
  ADD_ADULT_SUCCESS,
  ADD_ADULT_FAIL,
  GET_ADULT_DETAIL,
  GET_ADULT_DETAIL_SUCCESS,
  GET_ADULT_DETAIL_FAIL,
  UPDATE_ADULT,
  UPDATE_ADULT_SUCCESS,
  UPDATE_ADULT_FAIL,
  RESET_ADULT_SUCCESS,
  RESET_ADULT,
  UPDATE_ADULT_PHOTO,
  UPDATE_ADULT_PHOTO_SUCCESS,
  UPDATE_ADULT_PHOTO_FAIL,
  GET_ADULT_RELATIONS,
  GET_ADULT_RELATIONS_SUCCESS,
  GET_ADULT_RELATIONS_FAIL,
  UPDATE_ADULT_NOTES,
  UPDATE_ADULT_NOTES_SUCCESS,
  UPDATE_ADULT_NOTES_FAIL,
  ADD_CHILD_BY_ADULT,
  ADD_CHILD_BY_ADULT_SUCCESS,
  ADD_CHILD_BY_ADULT_FAIL,
} from "./actionTypes"

export const getAdults = () => ({
  type: GET_ADULTS,
})

export const getAdultsSuccess = adults => ({
  type: GET_ADULTS_SUCCESS,
  payload: adults,
})

export const getAdultsFail = error => ({
  type: GET_ADULTS_FAIL,
  payload: error,
})

export const deleteAdult = adult => ({
  type: DELETE_ADULT,
  payload: adult,
})

export const deleteAdultSuccess = adult => ({
  type: DELETE_ADULT_SUCCESS,
  payload: adult,
})

export const deleteAdultFail = error => ({
  type: DELETE_ADULT_FAIL,
  payload: error,
})
export const addNewAdult = adult => ({
  type: ADD_NEW_ADULT,
  payload: adult,
})

export const addAdultSuccess = adult => ({
  type: ADD_ADULT_SUCCESS,
  payload: adult,
})

export const addAdultFail = error => ({
  type: ADD_ADULT_FAIL,
  payload: error,
})

export const getAdultDetail = adultId => ({
  type: GET_ADULT_DETAIL,
  adultId,
})

export const getAdultDetailSuccess = adult => ({
  type: GET_ADULT_DETAIL_SUCCESS,
  payload: adult,
})

export const getAdultDetailFail = error => ({
  type: GET_ADULT_DETAIL_FAIL,
  payload: error,
})

export const updateAdult = adult => ({
  type: UPDATE_ADULT,
  payload: adult,
})

export const updateAdultSuccess = adult => ({
  type: UPDATE_ADULT_SUCCESS,
  payload: adult,
})

export const updateAdultFail = error => ({
  type: UPDATE_ADULT_FAIL,
  payload: error,
})
export const resetAdult = () => ({
  type: RESET_ADULT,
})

export const resetAdultSuccess = adult => ({
  type: RESET_ADULT_SUCCESS,
  payload: adult,
})

export const updateAdultPhoto = adult => ({
  type: UPDATE_ADULT_PHOTO,
  payload: adult,
})
export const updateAdultPhotoSuccess = adult => ({
  type: UPDATE_ADULT_PHOTO_SUCCESS,
  payload: adult,
})

export const updateAdultPhotoFail = error => ({
  type: UPDATE_ADULT_PHOTO_FAIL,
  payload: error,
})

export const getAdultRelations = adultId => ({
  type: GET_ADULT_RELATIONS,
  payload: adultId,
})

export const getAdultRelationsSuccess = relations => ({
  type: GET_ADULT_RELATIONS_SUCCESS,
  payload: relations,
})

export const getAdultRelationsFail = error => ({
  type: GET_ADULT_RELATIONS_FAIL,
  payload: error,
})

export const updateAdultNotes = (adultID, obj) => ({
  type: UPDATE_ADULT_NOTES,
  payload: { adultID, obj },
})

export const updateAdultNotesSuccess = adult => ({
  type: UPDATE_ADULT_NOTES_SUCCESS,
  payload: adult,
})

export const updateAdultNotesFail = error => ({
  type: UPDATE_ADULT_NOTES_FAIL,
  payload: error,
})

export const addChildByAdult = child => ({
  type: ADD_CHILD_BY_ADULT,
  payload: child,
})

export const addChildByAdultSuccess = relations => ({
  type: ADD_CHILD_BY_ADULT_SUCCESS,
  payload: relations,
})

export const addChildByAdultFail = error => ({
  type: ADD_CHILD_BY_ADULT_FAIL,
  payload: error,
})
