import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Row, Input, FormFeedback, InputGroup } from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"
import PageModal from "components/Common/PageModal"
import moment from "moment"

import {
  updateCurriculumScheduleDetail as onUpdateCurriculumScheduleDetail,
  getNoteCategories as onGetNoteCategories,
} from "store/actions"
import Moment from "moment"

const formdefault = {
  Description: "",
}

const CurriculumScheduleModal = ({
  show,
  onEdit,
  onView,
  selectedDay,
  selectedTime,
  onCloseClick,
  data,
  header,
}) => {
  const dispatch = useDispatch()
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CurriculumScheduleDetailID: yup.number(),
    CurriculumScheduleID: yup.number(),
    NoteCategories: yup.object().required("Required"),
    Description: yup.string().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState
  const { curriculumscheduleDetails } = useSelector(state => state.curriculum)
  const { notecategories } = useSelector(state => state.note)

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetNoteCategories())
    }

    updateState()
  }, [dispatch, selectedDay, curriculumscheduleDetails])

  useEffect(() => {
    if (!isEmpty(data) && (onEdit || onView)) {
      let response = {
        CurriculumScheduleDetailID: Number(data?.CurriculumScheduleDetailID),
        CurriculumScheduleID: Number(data?.CurriculumScheduleID),
        Description: data?.Description ?? "",
      }

      if (Number(data?.NoteCategoryID) > 0) {
        response = {
          ...response,
          NoteCategories: {
            NoteCategoryID: Number(data?.NoteCategoryID),
            NoteCategory: data?.NoteCategory,
          },
        }
      }

      reset(response)
    } else {
      reset(formdefault)
    }
  }, [data, onEdit, onView])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(
        onUpdateCurriculumScheduleDetail({
          ...getValues(),
        })
      )
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
  }

  return (
    <PageModal
      show={show}
      onViewMode={onView}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header={onEdit ? "Update Curriculum" : "View Curriculum"}
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={6}>
            <div className="mb-6">
              <Label>Time: </Label> {Moment(selectedTime).format("h:mm a")}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-6">
              <Label>Day: </Label> {selectedDay}
            </div>
          </Col>
          <Col lg={12}></Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Category</Label>
              {onEdit ? (
                <Controller
                  name="NoteCategories"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="NoteCategories"
                        options={notecategories}
                        getOptionLabel={option => option.NoteCategory}
                        getOptionValue={option => option.NoteCategoryID}
                        required
                        aria-invalid={!!errors.NoteCategories}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.NoteCategories?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.NoteCategories?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              ) : (
                <div>{getValues("NoteCategories.NoteCategory")}</div>
              )}
            </div>
            <div className="mb-3">
              <Label>Description</Label>
              {onEdit ? (
                <Controller
                  name="Description"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="Description"
                        type="textarea"
                        required
                        invalid={!!errors.Description}
                      />
                      {errors?.Description?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Description?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              ) : (
                <div>{getValues("Description")}</div>
              )}
            </div>
          </Col>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

CurriculumScheduleModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default CurriculumScheduleModal
