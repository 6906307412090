import {
  GET_LESSON_PLANS_FAIL,
  GET_LESSON_PLANS_SUCCESS,
  DELETE_LESSON_PLAN_SUCCESS,
  DELETE_LESSON_PLAN_FAIL,
  ADD_LESSON_PLAN_FAIL,
  ADD_LESSON_PLAN_SUCCESS,
  GET_LESSON_PLAN_DETAIL_SUCCESS,
  GET_LESSON_PLAN_DETAIL_FAIL,
  UPDATE_LESSON_PLAN_SUCCESS,
  UPDATE_LESSON_PLAN_FAIL,
  RESET_LESSON_PLAN_SUCCESS,
  ADD_LESSON_PLAN_SCHEDULE_FAIL,
  ADD_LESSON_PLAN_SCHEDULE_SUCCESS,
  UPDATE_LESSON_PLAN_SCHEDULE_SUCCESS,
  UPDATE_LESSON_PLAN_SCHEDULE_FAIL,
  GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_SUCCESS,
  GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_FAIL,
  UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS,
  UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL,
  GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_SUCCESS,
  GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_FAIL,
  DELETE_LESSON_PLAN_SCHEDULE_SUCCESS,
  DELETE_LESSON_PLAN_SCHEDULE_FAIL,
  DELETE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS,
  DELETE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL,
  GET_LESSON_PLANS_VIEW_SUCCESS,
  GET_LESSON_PLANS_VIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  lessonPlans: [],
  lessonPlan: {},
  lessonPlancategories: [],
  lessonPlanschedules: [],
  lessonPlanscheduleDetails: [],
  lessonPlansView: [],
  error: {},
  loading: true,
}

const LessonPlan = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LESSON_PLANS_SUCCESS:
      return {
        ...state,
        lessonPlans: action.payload,
        loading: true,
      }
    case GET_LESSON_PLANS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_LESSON_PLAN_SUCCESS: {
      const data = {
        ...state,
        lessonPlans: state.lessonPlans.filter(
          lessonPlan => lessonPlan.LessonPlanID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        lessonPlans: state.lessonPlans.filter(
          lessonPlan => lessonPlan.LessonPlanID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_LESSON_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        lessonPlans: [...state.lessonPlans, action.payload],
      }
    case ADD_LESSON_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LESSON_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        lessonPlan: action.payload,
        loading: true,
      }

    case GET_LESSON_PLAN_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        lessonPlans: state.lessonPlans.map(lessonPlan =>
          lessonPlan.LessonPlanID.toString() ===
          action.payload.LessonPlanID.toString()
            ? { lessonPlan, ...action.payload }
            : lessonPlan
        ),
      }
    case UPDATE_LESSON_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        lessonPlan: action.payload,
      }
    case ADD_LESSON_PLAN_SCHEDULE_SUCCESS:
      return {
        ...state,
        lessonPlanschedules: action.payload,
      }
    case ADD_LESSON_PLAN_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LESSON_PLAN_SCHEDULE_SUCCESS:
      return {
        ...state,
        lessonPlanschedules: state.lessonPlanschedules.map(x =>
          x.LessonPlanScheduleID.toString() ===
          action.payload.LessonPlanScheduleID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_LESSON_PLAN_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        lessonPlanschedules: action.payload,
        loading: true,
      }
    case GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        lessonPlanschedules: state.lessonPlanschedules.map(x =>
          x.LessonPlanScheduleID.toString() ===
          action.payload.LessonPlanScheduleID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_SUCCESS:
      return {
        ...state,
        lessonPlanscheduleDetails: action.payload,
        loading: true,
      }
    case GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_LESSON_PLAN_SCHEDULE_SUCCESS: {
      return {
        ...state,
        lessonPlanschedules: state.lessonPlanschedules.filter(
          x => x.LessonPlanScheduleID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_LESSON_PLAN_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS: {
      return {
        ...state,
        lessonPlanschedules: state.lessonPlanschedules.map(x =>
          x.LessonPlanScheduleDetails.map((d, i) => {
            d.LessonPlanScheduleDetailID == parseInt(action.payload)
              ? { ...d, LessonPlanCategoryID: null, Description: null }
              : d
          })
        ),
      }
    }
    case DELETE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LESSON_PLANS_VIEW_SUCCESS:
      return {
        ...state,
        lessonPlansView: action.payload,
        loading: true,
      }
    case GET_LESSON_PLANS_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default LessonPlan
