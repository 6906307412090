import React from "react"
import { Navigate } from "react-router-dom"
import { isAuthTokenValid } from "helpers/token_helper"

const Authmiddleware = props => {
  if (!isAuthTokenValid()) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
