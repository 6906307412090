import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
  Label,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import List from "./List"

import "flatpickr/dist/themes/material_blue.css"
import FlatPickr from "react-flatpickr"

// selector
import Select from "react-select"
import Spinners from "components/Common/Spinner"
import { handleSearchData } from "components/Common/searchFile"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

import { getActivitiesByClass as onGetActivitiesByClass } from "store/actions"
import { useDeepCompareEffect, useUserDetail } from "hooks"
import Moment from "moment"

const ActivityPlanner = () => {
  document.title = "Activity Planner"

  const dispatch = useDispatch()
  const { userprofile, relations } = useUserDetail()

  const [filter, setFilter] = useState({
    classId: 0,
    startDate: Moment().format("DD MMM yyyy"),
  })

  const updateState = data => setFilter(state => ({ ...state, ...data }))

  // search
  const handleSearch = () => {
    dispatch(onGetActivitiesByClass(filter))
  }

  //////////////////ACTIVITY STATE/////////////////////
  const { activities, loading } = useSelector(state => state.activity)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Learning" breadcrumbItem="Activity Planner" />

          <Row>
            <Col lg={12}>
              <Card className="job-filter">
                <CardBody>
                  <form action="#">
                    <Row className="g-3">
                      <Col xxl={2} lg={6}>
                        <div className="position-relative">
                          <Select
                            className="select2"
                            onChange={e => {
                              updateState({ classId: e.ClassID })
                            }}
                            options={relations}
                            getOptionLabel={option => option.Name}
                            getOptionValue={option => option.ChildID}
                          />
                        </div>
                      </Col>

                      <Col xxl={2} lg={4}>
                        <div className="position-relative">
                          <div id="datepicker1">
                            <FlatPickr
                              className="form-control"
                              name="joiningDate"
                              placeholder="Select date"
                              options={{
                                dateFormat: "d M, Y",
                              }}
                              value={Moment(filter.startDate).format(
                                "DD MMM yyyy"
                              )}
                              onChange={(selectedDates, dateStr, instance) => {
                                updateState({ startDate: dateStr })
                              }}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xxl={2} lg={2}>
                        <div className="position-relative h-100 hstack gap-3">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleSearch()}
                          >
                            <i className="bx bx-search-alt align-middle"></i>{" "}
                            Proceed
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <List listData={activities} />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ActivityPlanner
