import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"

const MixedPaymentModal = ({ show, result, onCloseClick }) => {
  const { paidfeetransactions, loading } = useSelector(state => state.fee)
  return (
    <Modal isOpen={show} toggle={onCloseClick} size="lg" centered={true}>
      <div className="modal-content">
        <ModalHeader className="d-flex justify-content-center">
          Mixed Payment Details
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Program</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {paidfeetransactions &&
                  paidfeetransactions.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          {item.ProgramName}
                          <div className="text-muted mb-0">
                            {item.DayID + " days"}
                          </div>
                        </th>
                        <td>
                          {moment(item.StartDate).format("DD MMM Y, hh:mm A")}
                        </td>
                        <td>
                          {moment(item.EndDate).format("DD MMM Y, hh:mm A")}
                        </td>
                        <td>{"$" + Number(item.Price).toFixed(2)}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={onCloseClick}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

MixedPaymentModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default MixedPaymentModal
