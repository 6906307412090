import React, { useEffect, useState, useMemo, Fragment } from "react"
import { Table, Row, Col, Badge, UncontrolledTooltip } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { ToastContainer } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"
import { getEnrollments as onGetEnrollments } from "store/actions"
import EnrollmentApprovalModal from "./../../request/modal/EnrollmentApprovalModal"
import { EnumObjectTypes } from "helpers/enum_helper"
import ProfilePicture from "components/Common/ProfilePicture"
import { Calculate_Age } from "helpers/method_helper"
import EmptyContainer from "components/Common/EmptyContainer"
import TableContainer from "components/Common/TableContainer"

function WaitlistTab() {
  const dispatch = useDispatch()
  const [approvalModal, setApprovalModal] = useState(false)
  const [programEnrollmentID, setProgramEnrollmentID] = useState(0)

  //meta title
  document.title = "Booking Requests | " + process.env.REACT_APP_NAME

  useEffect(() => {
    dispatch(onGetEnrollments(0))
  }, [dispatch])

  //////////////////PROGRAM STATE/////////////////////
  const { enrollments, loading } = useSelector(state => state.program)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const onModalClick = e => {
    setProgramEnrollmentID(e)
    setApprovalModal(true)
  }

  const onCloseModal = () => {
    setApprovalModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Child",
        filterable: true,
        accessor: "Child",
        Cell: cellProps => {
          return (
            <ProfilePicture
              ObjectTypeID={EnumObjectTypes.Children}
              RowID={cellProps.row.original.RowID}
              Title={cellProps.row.original.Child}
              SubTitle={Calculate_Age(cellProps.row.original.DateOfBirth)}
            />
          )
        },
      },
      {
        Header: "Request Details",
        accessor: "Program",
        Cell: cellProps => {
          return (
            <>
              <p className="text-muted mb-0">
                {cellProps.row.original.Program}
              </p>
              <div className="font-size-12 mb-1">
                {"Starting date : " +
                  handleValidDate(cellProps.row.original.StartDate)}
              </div>
            </>
          )
        },
      },
      {
        Header: "Price",
        accessor: "Price",
        Cell: cellProps => {
          return "$" + Number(cellProps.value).toFixed(2)
        },
      },
      {
        Header: "Class",
        accessor: "Class",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Requested By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "EnrollmentType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onModalClick(cellProps.row.original.ProgramEnrollmentID)
                }}
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {approvalModal && (
        <EnrollmentApprovalModal
          show={approvalModal}
          programEnrollmentID={programEnrollmentID}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <Row>
        <Col xs="12">
          <TableContainer
            columns={columns}
            data={enrollments.filter(x => x.EnrollmentTypeID === 2)}
            isGlobalFilter={false}
            isAddOption={false}
            customPageSize={10}
            isPagination={true}
            filterable={false}
            iscustomPageSizeOptions={true}
            tableClass="align-middle table-nowrap table-check"
            theadClass="table-light"
            pagination="pagination pagination-rounded justify-content-end mb-2"
            isExportOption={true}
            headerTitle="Money"
          />
        </Col>
      </Row>
      <ToastContainer />
    </React.Fragment>
  )
}
export default WaitlistTab
