/*Get ADULT */
export const GET_ADULTS = "GET_ADULTS"
export const GET_ADULTS_SUCCESS = "GET_ADULTS_SUCCESS"
export const GET_ADULTS_FAIL = "GET_ADULTS_FAIL"

/**
 * Delete ADULT
 */
export const DELETE_ADULT = "DELETE_ADULT"
export const DELETE_ADULT_SUCCESS = "DELETE_ADULT_SUCCESS"
export const DELETE_ADULT_FAIL = "DELETE_ADULT_FAIL"
/**
 * Add ADULT
 */
export const ADD_NEW_ADULT = "ADD_NEW_ADULT"
export const ADD_ADULT_SUCCESS = "ADD_ADULT_SUCCESS"
export const ADD_ADULT_FAIL = "ADD_ADULT_FAIL"

/**
 * Get ADULT DETAILS
 */
export const GET_ADULT_DETAIL = "GET_ADULT_DETAIL"
export const GET_ADULT_DETAIL_SUCCESS = "GET_ADULT_DETAIL_SUCCESS"
export const GET_ADULT_DETAIL_FAIL = "GET_ADULT_DETAIL_FAIL"

/**
 * Edit ADULT
 */
export const UPDATE_ADULT = "UPDATE_ADULT"
export const UPDATE_ADULT_SUCCESS = "UPDATE_ADULT_SUCCESS"
export const UPDATE_ADULT_FAIL = "UPDATE_ADULT_FAIL"
/**
 * Reset ADULT
 */
export const RESET_ADULT = "RESET_ADULT"
export const RESET_ADULT_SUCCESS = "RESET_ADULT_SUCCESS"

/**
 * Add/Edit Photo
 */

export const UPDATE_ADULT_PHOTO = "UPDATE_ADULT_PHOTO"
export const UPDATE_ADULT_PHOTO_SUCCESS = "UPDATE_ADULT_PHOTO_SUCCESS"
export const UPDATE_ADULT_PHOTO_FAIL = "UPDATE_ADULT_PHOTO_FAIL"

/**
 * Get Adult Relations
 */
export const GET_ADULT_RELATIONS = "GET_ADULT_RELATIONS"
export const GET_ADULT_RELATIONS_SUCCESS = "GET_ADULT_RELATIONS_SUCCESS"
export const GET_ADULT_RELATIONS_FAIL = "GET_ADULT_RELATIONS_FAIL"

/**
 * Get Adult Notes
 */
export const UPDATE_ADULT_NOTES = "UPDATE_ADULT_NOTES"
export const UPDATE_ADULT_NOTES_SUCCESS = "UPDATE_ADULT_NOTES_SUCCESS"
export const UPDATE_ADULT_NOTES_FAIL = "UPDATE_ADULT_NOTES_FAIL"

/**
 * Add CHILD
 */
export const ADD_CHILD_BY_ADULT = "ADD_CHILD_BY_ADULT"
export const ADD_CHILD_BY_ADULT_SUCCESS = "ADD_CHILD_BY_ADULT_SUCCESS"
export const ADD_CHILD_BY_ADULT_FAIL = "ADD_CHILD_BY_ADULT_FAIL"
