import React, { useEffect, useMemo, useState } from "react"
import PropTypes, { func } from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import Moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getStaffs as onGetStaffs,
  deleteStaff as onDeleteStaff,
  addStaffHappening as onAddStaffHappening,
  getStaffDetail as onGetStaffDetail,
  updateImpersonateUser as onUpdateImpersonateUser,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import { getUserImagePath } from "helpers/imageService"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"
import JoinClassModal from "./modal/JoinClassModal"
import HappeningModal from "./modal/HappeningModal"
import ImpersonateModal from "./modal/ImpersonateModal"
import i18n from "./../../i18n"
import ProfilePicture from "components/Common/ProfilePicture"
import { EnumObjectTypes } from "helpers/enum_helper"

function Staff() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { staffs, loading } = useSelector(state => state.staff)
  const { userprofile } = useSelector(state => state.user)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [staff, setStaff] = useState(null)
  const [joinClassModal, setJoinClassModal] = useState(false)
  const [happeningTitle, setHappeningTitle] = useState("")
  const [changed, setChanged] = useState(true)
  const [staffHappening, setStaffHappening] = useState(null)
  const [happeningModal, setHappeningModal] = useState(false)
  const [impersonateModal, setImpersonateModal] = useState(false)

  useEffect(() => {
    if (changed) {
      dispatch(onGetStaffs())
      setChanged(false)
    }
  }, [dispatch, changed])

  const handleAddClick = () => {
    navigate("/staff/new")
  }

  const onClickDelete = arg => {
    setStaff(arg)
    setDeleteModal(true)
  }

  const onClickImpersonate = arg => {
    setStaff(arg)
    setImpersonateModal(true)
  }

  const onCheckInClick = arg => {
    var CurrentDate = Moment().format("DD MMM yyyy")
    var CurrentTime = Moment().format("HH:mm")
    dispatch(
      onAddStaffHappening({
        Date: CurrentDate,
        Time: CurrentTime,
        StaffID: arg.StaffID,
        HappeningTypeID: 1,
      })
    )
    setChanged(true)
    //dispatch(onGetStaffs())
  }

  const onCheckoutClick = arg => {
    setHappeningTitle("Check-Out")
    dispatch(onGetStaffDetail(arg.RowID))
    setHappeningModal(true)
  }

  const onJoinClassClick = arg => {
    setHappeningTitle("Join Class")
    dispatch(onGetStaffDetail(arg.RowID))
    setJoinClassModal(true)
  }

  const onChangeClassClick = arg => {
    setHappeningTitle("Change Class")
    dispatch(onGetStaffDetail(arg.RowID))

    let staffHappening = ClassJoined(arg)[0]
    const data = {
      ...staffHappening,
      Classes: {
        ClassID: staffHappening?.ClassID,
        Title: staffHappening?.Title,
      },
    }
    setStaffHappening(data)
    setJoinClassModal(true)
  }

  const onModalClose = () => {
    setChanged(true)
    //dispatch(onGetStaffs())
    setStaffHappening(null)
    setJoinClassModal(false)
    setHappeningModal(false)
  }

  const handleDelete = () => {
    if (staff && staff.StaffID) {
      dispatch(onDeleteStaff(staff.StaffID))
      setDeleteModal(false)
    }
  }

  const handleImpersonateUser = () => {
    if (staff && staff.UserID) {
      dispatch(onUpdateImpersonateUser(staff.UserID))
      setImpersonateModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = Moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const StaffStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  function CheckedIn(arg) {
    return (
      arg.StaffHappenings &&
      arg.StaffHappenings.filter(
        x => x.HappeningTypeID == 1 && x.StaffID == arg.StaffID
      )
    )
  }

  function CheckedOut(arg) {
    return (
      arg.StaffHappenings &&
      arg.StaffHappenings.filter(
        x => x.HappeningTypeID == 4 && x.StaffID == arg.StaffID
      )
    )
  }

  function ClassJoined(arg) {
    return (
      arg.StaffHappenings &&
      arg.StaffHappenings.filter(
        x => x.HappeningTypeID == 2 && x.StaffID == arg.StaffID
      )
    )
  }

  function LeaveClass(arg) {
    return (
      arg.StaffHappenings &&
      arg.StaffHappenings.filter(
        x => x.HappeningTypeID == 3 && x.StaffID == arg.StaffID
      )
    )
  }
  const ClassField = arg => {
    const latestHappening = getLatestHappening(arg)
    if (
      latestHappening != null &&
      latestHappening.ClassID >= 1 &&
      latestHappening.HappeningTypeID === 2
    ) {
      return (
        <>
          <span className="bg-primary badge me-2">{latestHappening.Title}</span>
          <Link
            className="text-success"
            onClick={() => onChangeClassClick(arg)}
          >
            Change Class
          </Link>
        </>
      )
    }

    if (
      latestHappening != null &&
      latestHappening.ClassID === 0 &&
      latestHappening.HappeningTypeID === 1
    ) {
      return (
        <Link className="text-success" onClick={() => onJoinClassClick(arg)}>
          Join Class
        </Link>
      )
    }
    return null
  }

  function getLatestHappening(arg) {
    if (arg.StaffHappenings) {
      // Sort by StaffHappeningID in descending order and get the most recent happening
      const sortedHappenings = arg.StaffHappenings.filter(
        x => x.StaffID == arg.StaffID
      ).sort((a, b) => b.StaffHappeningID - a.StaffHappeningID)

      return sortedHappenings.length > 0 ? sortedHappenings[0] : null
    }
    return null
  }

  const CheckInOutField = arg => {
    const latestHappening = getLatestHappening(arg)
    if (
      !latestHappening ||
      latestHappening.HappeningTypeID === 0 ||
      latestHappening.HappeningTypeID === 4
    ) {
      return (
        <button
          type="button"
          className="btn btn btn-sm btn-primary"
          onClick={() => onCheckInClick(arg)}
        >
          Check-in
        </button>
      )
    }

    if (
      !latestHappening ||
      latestHappening.HappeningTypeID === 1 ||
      latestHappening.HappeningTypeID === 2
    ) {
      return (
        <button
          type="button"
          className="btn btn btn-sm btn-primary"
          onClick={() => onCheckoutClick(arg)}
        >
          Check-out
        </button>
      )
    }
    return null
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return (
            <ProfilePicture
              ObjectTypeID={EnumObjectTypes.Staff}
              RowID={cellProps.row.original.RowID}
              Title={cellProps.row.original.Name}
              SubTitle={cellProps.row.original.RoleType}
            />
          )
        },
      },
      {
        Header: "Email",
        accessor: "Email",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <StaffStatus status={cellProps.value} />
        },
      },
      {
        Header: "Class",
        Cell: cellProps => {
          return ClassField(cellProps.row.original)
        },
      },
      {
        Header: "Check-in/out",
        Cell: cellProps => {
          return CheckInOutField(cellProps.row.original)
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onClickImpersonate(cellProps.row.original)
                }}
                style={{
                  display:
                    userprofile.Impersonate ||
                    cellProps.row.original.UserID == userprofile.UserID
                      ? "none"
                      : "inline-block",
                }}
              >
                <i
                  className="mdi mdi-login font-size-18"
                  id="impersonatetooltip"
                />
                <UncontrolledTooltip
                  placement="top"
                  target="impersonatetooltip"
                >
                  Impersonate
                </UncontrolledTooltip>
              </Link>
              <Link
                to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {happeningModal && (
        <HappeningModal
          happeningTitle={happeningTitle}
          show={happeningModal}
          onHappeningCloseClick={() => onModalClose()}
          happeningTypeID="4"
        />
      )}
      {joinClassModal && (
        <JoinClassModal
          happeningTitle={happeningTitle}
          show={joinClassModal}
          staffHappening={staffHappening}
          onHappeningCloseClick={() => onModalClose()}
          happeningTypeID="2"
        />
      )}
      {impersonateModal && (
        <ImpersonateModal
          show={impersonateModal}
          text="You want to impersonate this user?"
          onSubmitClick={handleImpersonateUser}
          onCloseClick={() => setImpersonateModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Staffs" breadcrumbItem="Staff List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={staffs}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add staff"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Staff.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Staff
