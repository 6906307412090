import {
  GET_CENTER_PROFILE_DETAIL_SUCCESS,
  GET_CENTER_PROFILE_DETAIL_FAIL,
  UPDATE_CENTER_PROFILE_SUCCESS,
  UPDATE_CENTER_PROFILE_FAIL,
  UPDATE_CENTER_ADDRESS_SUCCESS,
  UPDATE_CENTER_ADDRESS_FAIL,
  UPDATE_CENTER_CONTACT_SUCCESS,
  UPDATE_CENTER_CONTACT_FAIL,
  UPDATE_CENTER_OPERATING_HOUR_SUCCESS,
  UPDATE_CENTER_OPERATING_HOUR_FAIL,
  UPDATE_CENTER_CLOSING_SUCCESS,
  UPDATE_CENTER_CLOSING_FAIL,
  DELETE_CENTER_ADDRESS_SUCCESS,
  DELETE_CENTER_ADDRESS_FAIL,
  DELETE_CENTER_CONTACT_SUCCESS,
  DELETE_CENTER_CONTACT_FAIL,
  DELETE_CENTER_CLOSING_SUCCESS,
  DELETE_CENTER_CLOSING_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  profile: {},
  address: [],
  contacts: [],
  openings: [],
  closings: [],
  error: {},
  loading: false,
}

const CenterProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CENTER_PROFILE_DETAIL_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        address: action.payload.CenterAddress,
        contacts: action.payload.CenterContact,
        openings: action.payload.CenterOpening,
        closings: action.payload.CenterClosing,
        loading: true,
      }
    case GET_CENTER_PROFILE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CENTER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      }
    case UPDATE_CENTER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CENTER_ADDRESS_SUCCESS:
      return {
        ...state,
        address: action.payload.CenterAddress,
      }
    case UPDATE_CENTER_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CENTER_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload.CenterContact,
      }
    case UPDATE_CENTER_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CENTER_OPERATING_HOUR_SUCCESS:
      return {
        ...state,
        openings: action.payload.CenterOpening,
      }
    case UPDATE_CENTER_OPERATING_HOUR_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CENTER_CLOSING_SUCCESS:
      return {
        ...state,
        closings: action.payload.CenterClosing,
      }
    case UPDATE_CENTER_CLOSING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CENTER_ADDRESS_SUCCESS: {
      return {
        ...state,
        address: state.address.filter(
          x => x.CenterAddressID !== action.payload
        ),
      }
    }
    case DELETE_CENTER_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CENTER_CONTACT_SUCCESS: {
      return {
        ...state,
        contacts: state.contacts.filter(
          x => x.CenterContactID !== action.payload
        ),
      }
    }
    case DELETE_CENTER_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CENTER_CLOSING_SUCCESS: {
      return {
        ...state,
        closings: state.closings.filter(
          x => x.CenterClosingID !== action.payload
        ),
      }
    }
    case DELETE_CENTER_CLOSING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default CenterProfile
