import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap"
import SimpleBar from "simplebar-react"

//redux
import { useSelector, useDispatch } from "react-redux"

const LiveRoomStatus = () => {
  const { classstatusdata } = useSelector(state => state.Dashboard)

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-2">Live Class Status</CardTitle>
          <SimpleBar style={{ maxHeight: "300px" }}>
            <div className="table-responsive mt-4">
              <table className="table align-middle table-nowrap">
                <tbody>
                  {(classstatusdata || []).map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "80%" }}>
                        <p className="mb-0">{item.Title}</p>
                      </td>
                      <td>
                        <p className="mb-0">
                          Capacity:{item.Capacity}/Checked-In: {item.CheckedIn}
                        </p>
                        <Progress
                          value={item.CheckedIn}
                          color="primary"
                          animated
                        ></Progress>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LiveRoomStatus
