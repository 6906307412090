import {
  GET_FORUMS,
  GET_FORUMS_FAIL,
  GET_FORUMS_SUCCESS,
  DELETE_FORUM,
  DELETE_FORUM_FAIL,
  DELETE_FORUM_SUCCESS,
  ADD_NEW_FORUM,
  ADD_FORUM_SUCCESS,
  ADD_FORUM_FAIL,
  GET_FORUM_DETAIL,
  GET_FORUM_DETAIL_SUCCESS,
  GET_FORUM_DETAIL_FAIL,
  UPDATE_FORUM,
  UPDATE_FORUM_SUCCESS,
  UPDATE_FORUM_FAIL,
  RESET_FORUM_SUCCESS,
  RESET_FORUM,
  GET_FORUM_VISIBILITY_TYPES,
  GET_FORUM_VISIBILITY_TYPES_SUCCESS,
  GET_FORUM_VISIBILITY_TYPES_FAIL,
  DELETE_FORUM_POST,
  DELETE_FORUM_POST_FAIL,
  DELETE_FORUM_POST_SUCCESS,
  ADD_NEW_FORUM_POST,
  ADD_FORUM_POST_SUCCESS,
  ADD_FORUM_POST_FAIL,
  UPDATE_FORUM_POST_FILE,
  UPDATE_FORUM_POST_FILE_SUCCESS,
  UPDATE_FORUM_POST_FILE_FAIL,
} from "./actionTypes"

export const getForums = () => ({
  type: GET_FORUMS,
})

export const getForumsSuccess = forums => ({
  type: GET_FORUMS_SUCCESS,
  payload: forums,
})

export const getForumsFail = error => ({
  type: GET_FORUMS_FAIL,
  payload: error,
})

export const deleteForum = forum => ({
  type: DELETE_FORUM,
  payload: forum,
})

export const deleteForumSuccess = forum => ({
  type: DELETE_FORUM_SUCCESS,
  payload: forum,
})

export const deleteForumFail = error => ({
  type: DELETE_FORUM_FAIL,
  payload: error,
})
export const addNewForum = forum => ({
  type: ADD_NEW_FORUM,
  payload: forum,
})

export const addForumSuccess = forum => ({
  type: ADD_FORUM_SUCCESS,
  payload: forum,
})

export const addForumFail = error => ({
  type: ADD_FORUM_FAIL,
  payload: error,
})

export const getForumDetail = forumId => ({
  type: GET_FORUM_DETAIL,
  forumId,
})

export const getForumDetailSuccess = forum => ({
  type: GET_FORUM_DETAIL_SUCCESS,
  payload: forum,
})

export const getForumDetailFail = error => ({
  type: GET_FORUM_DETAIL_FAIL,
  payload: error,
})

export const updateForum = forum => ({
  type: UPDATE_FORUM,
  payload: forum,
})

export const updateForumSuccess = forum => ({
  type: UPDATE_FORUM_SUCCESS,
  payload: forum,
})

export const updateForumFail = error => ({
  type: UPDATE_FORUM_FAIL,
  payload: error,
})
export const resetForum = () => ({
  type: RESET_FORUM,
})

export const resetForumSuccess = forum => ({
  type: RESET_FORUM_SUCCESS,
  payload: forum,
})

export const getForumVisibilityTypes = () => ({
  type: GET_FORUM_VISIBILITY_TYPES,
})

export const getForumVisibilityTypesSuccess = forumVisibilityTypes => ({
  type: GET_FORUM_VISIBILITY_TYPES_SUCCESS,
  payload: forumVisibilityTypes,
})

export const getForumVisibilityTypesFail = error => ({
  type: GET_FORUM_VISIBILITY_TYPES_FAIL,
  payload: error,
})

export const deleteForumPost = forumPost => ({
  type: DELETE_FORUM_POST,
  payload: forumPost,
})

export const deleteForumPostSuccess = forumPostID => ({
  type: DELETE_FORUM_POST_SUCCESS,
  payload: forumPostID,
})

export const deleteForumPostFail = error => ({
  type: DELETE_FORUM_POST_FAIL,
  payload: error,
})
export const addNewForumPost = forumPost => ({
  type: ADD_NEW_FORUM_POST,
  payload: forumPost,
})

export const addForumPostSuccess = forumPost => ({
  type: ADD_FORUM_POST_SUCCESS,
  payload: forumPost,
})

export const addForumPostFail = error => ({
  type: ADD_FORUM_POST_FAIL,
  payload: error,
})

export const updateForumPostFile = forumPost => ({
  type: UPDATE_FORUM_POST_FILE,
  payload: forumPost,
})
export const updateForumPostFileSuccess = forumPost => ({
  type: UPDATE_FORUM_POST_FILE_SUCCESS,
  payload: forumPost,
})

export const updateForumPostFileFail = error => ({
  type: UPDATE_FORUM_POST_FILE_FAIL,
  payload: error,
})
