/**
 * Get CENTER_PROFILE DETAILS
 */
export const GET_CENTER_PROFILE_DETAIL = "GET_CENTER_PROFILE_DETAIL"
export const GET_CENTER_PROFILE_DETAIL_SUCCESS =
  "GET_CENTER_PROFILE_DETAIL_SUCCESS"
export const GET_CENTER_PROFILE_DETAIL_FAIL = "GET_CENTER_PROFILE_DETAIL_FAIL"

/**
 * Edit CENTER_PROFILE
 */
export const UPDATE_CENTER_PROFILE = "UPDATE_CENTER_PROFILE"
export const UPDATE_CENTER_PROFILE_SUCCESS = "UPDATE_CENTER_PROFILE_SUCCESS"
export const UPDATE_CENTER_PROFILE_FAIL = "UPDATE_CENTER_PROFILE_FAIL"

/**
 * Edit CENTER_PROFILE_ADDRESS
 */
export const UPDATE_CENTER_ADDRESS = "UPDATE_CENTER_ADDRESS"
export const UPDATE_CENTER_ADDRESS_SUCCESS = "UPDATE_CENTER_ADDRESS_SUCCESS"
export const UPDATE_CENTER_ADDRESS_FAIL = "UPDATE_CENTER_ADDRESS_FAIL"

/**
 * Delete CENTER_PROFILE_ADDRESS
 */
export const DELETE_CENTER_ADDRESS = "DELETE_CENTER_ADDRESS"
export const DELETE_CENTER_ADDRESS_SUCCESS = "DELETE_CENTER_ADDRESS_SUCCESS"
export const DELETE_CENTER_ADDRESS_FAIL = "DELETE_CENTER_ADDRESS_FAIL"

/**
 * Edit CENTER_PROFILE_CONTACT
 */
export const UPDATE_CENTER_CONTACT = "UPDATE_CENTER_CONTACT"
export const UPDATE_CENTER_CONTACT_SUCCESS = "UPDATE_CENTER_CONTACT_SUCCESS"
export const UPDATE_CENTER_CONTACT_FAIL = "UPDATE_CENTER_CONTACT_FAIL"

/**
 * Delete CENTER_PROFILE_CONTACT
 */
export const DELETE_CENTER_CONTACT = "DELETE_CENTER_CONTACT"
export const DELETE_CENTER_CONTACT_SUCCESS = "DELETE_CENTER_CONTACT_SUCCESS"
export const DELETE_CENTER_CONTACT_FAIL = "DELETE_CENTER_CONTACT_FAIL"

/*Edit CENTER_PROFILE_OPERATING_HOUR */
export const UPDATE_CENTER_OPERATING_HOUR = "UPDATE_CENTER_OPERATING_HOUR"
export const UPDATE_CENTER_OPERATING_HOUR_SUCCESS =
  "UPDATE_CENTER_OPERATING_HOUR_SUCCESS"
export const UPDATE_CENTER_OPERATING_HOUR_FAIL =
  "UPDATE_CENTER_OPERATING_HOUR_FAIL"

/**
 * Edit CENTER_PROFILE_CLOSING
 */
export const UPDATE_CENTER_CLOSING = "UPDATE_CENTER_CLOSING"
export const UPDATE_CENTER_CLOSING_SUCCESS = "UPDATE_CENTER_CLOSING_SUCCESS"
export const UPDATE_CENTER_CLOSING_FAIL = "UPDATE_CENTER_CLOSING_FAIL"

/**
 * Delete CENTER_PROFILE_CLOSING
 */
export const DELETE_CENTER_CLOSING = "DELETE_CENTER_CLOSING"
export const DELETE_CENTER_CLOSING_SUCCESS = "DELETE_CENTER_CLOSING_SUCCESS"
export const DELETE_CENTER_CLOSING_FAIL = "DELETE_CENTER_CLOSING_FAIL"
