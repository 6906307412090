import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

const Summary = () => {
  const { cards } = useSelector(state => state.Dashboard)

  return (
    <React.Fragment>
      <Row>
        {cards &&
          cards.map((report, key) => (
            <Col md="4" key={"_col_" + key}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">{report.Title}</p>
                      <h4 className="mb-0">{report.Value}</h4>
                    </div>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i
                          className={"bx " + report.IconClass + " font-size-24"}
                        ></i>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
    </React.Fragment>
  )
}

export default Summary
