import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAIL,
  ADD_WALLET_TRANSACTION,
  ADD_WALLET_TRANSACTION_SUCCESS,
  ADD_WALLET_TRANSACTION_FAIL,
  GET_WALLET_TRANSACTION,
  GET_WALLET_TRANSACTION_SUCCESS,
  GET_WALLET_TRANSACTION_FAIL,
} from "./actionTypes"

export const getPaymentMethods = () => ({
  type: GET_PAYMENT_METHODS,
})

export const getPaymentMethodsSuccess = paymentmethods => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  payload: paymentmethods,
})

export const getPaymentMethodsFail = error => ({
  type: GET_PAYMENT_METHODS_FAIL,
  payload: error,
})

export const addWalletTransaction = (adultID, values) => ({
  type: ADD_WALLET_TRANSACTION,
  payload: { adultID, values },
})

export const addWalletTransactionSuccess = transactions => ({
  type: ADD_WALLET_TRANSACTION_SUCCESS,
  payload: transactions,
})

export const addWalletTransactionFail = error => ({
  type: ADD_WALLET_TRANSACTION_FAIL,
  payload: error,
})

export const getWalletTransaction = adultID => ({
  type: GET_WALLET_TRANSACTION,
  payload: adultID,
})

export const getWalletTransactionSuccess = transactions => ({
  type: GET_WALLET_TRANSACTION_SUCCESS,
  payload: transactions,
})

export const getWalletTransactionFail = error => ({
  type: GET_WALLET_TRANSACTION_FAIL,
  payload: error,
})
