import React, { useEffect, useState, useMemo, Fragment } from "react"
import { Table, Row, Col, Badge, UncontrolledTooltip } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { ToastContainer } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"

import {
  getAwaitedMessages as onGetAwaitedMessages,
  getMessagePosts as onGetMessagePosts,
  deleteMessage as onDeleteMessage,
} from "store/actions"
import DeleteModal from "../../../../components/Common/DeleteModal"
import EmptyContainer from "components/Common/EmptyContainer"
import TableContainer from "components/Common/TableContainer"
import { useDeepCompareEffect } from "hooks"
import { getChildImagePath } from "helpers/imageService"
import AwaitingModal from "../modal/AwaitingModal"

function AwaitingReleaseTab() {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [awaitModal, setAwaitModal] = useState(false)
  const [data, setData] = useState({})

  const { awaited } = useSelector(state => state.child)

  useEffect(() => {
    dispatch(onGetAwaitedMessages())
  }, [dispatch])

  const onClickDelete = arg => {
    setData(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (data && data.MessageID) {
      dispatch(onDeleteMessage(data.MessageID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM yyyy")
  }

  const onAwaitModalClick = e => {
    setData(e)
    dispatch(onGetMessagePosts(e.MessageID))
    setAwaitModal(true)
  }

  const onCloseModal = () => {
    setAwaitModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        disableFilters: true,
        filterable: true,
        accessor: "ChildRowID",
        width: "20px",
        Cell: cellProps => {
          return (
            <img
              className="rounded-circle avatar-xs"
              src={getChildImagePath(cellProps.value, "image.jpeg")}
              alt="Avatar"
            />
          )
        },
      },
      {
        Header: "Name",
        accessor: "ChildName",
        Cell: cellProps => {
          return (
            <>
              <div>{cellProps.value}</div>
              <div hidden={!cellProps.row.original.ApprovalRequired}>
                <Badge className="bg-success"> Awaiting Approval </Badge>
              </div>
            </>
          )
        },
      },
      {
        Header: "Last Message",
        accessor: "Topic",
        Cell: cellProps => {
          return (
            <>
              <div>
                <b>Topic : </b>
                {cellProps.row.original.Topic}
              </div>
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onAwaitModalClick(cellProps.row.original)
                }}
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  id="viewtooltip"
                />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <Fragment>
      {awaitModal && (
        <AwaitingModal
          show={awaitModal}
          message={data}
          onCloseClick={onCloseModal}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <Row>
        <Col xs="12">
          {data?.length == 0 ? (
            <EmptyContainer
              backURL=""
              message="No messages found for release"
              linkText=""
            />
          ) : (
            <TableContainer
              columns={columns}
              data={awaited}
              isGlobalFilter={true}
              customPageSize={10}
              isPagination={true}
              filterable={false}
              iscustomPageSizeOptions={true}
              tableClass="align-middle table-nowrap table-check"
              theadClass="table-light"
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          )}
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  )
}
export default AwaitingReleaseTab
