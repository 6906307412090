import EmptyContainer from "components/Common/EmptyContainer"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import Moment from "moment"
import { getActivityDetail as onGetActivityDetail } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import ActivityModal from "pages/Scheduling/pages/activity/modal/ActivityModal"
import { RESET_ACTIVITY } from "store/scheduling/activity/actionTypes"

const List = ({ listData }) => {
  const dispatch = useDispatch()
  const [viewMode, setViewMode] = useState(false)
  const [assignModal, setAssignModal] = useState(false)

  const onClickView = arg => {
    if (arg > 0) {
      dispatch(onGetActivityDetail(arg))
      setViewMode(true)
      setAssignModal(true)
    }
  }

  const onCloseModal = () => {
    setAssignModal(false)
    setViewMode(false)
    dispatch({ type: RESET_ACTIVITY })
  }

  return (
    <React.Fragment>
      {assignModal && (
        <ActivityModal
          show={assignModal}
          isViewMode={viewMode}
          isParent={true}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {listData.length === 0 ? (
        <EmptyContainer />
      ) : (
        <Row>
          {(listData || []).map((item, key) => (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div className="flex-grow-1">
                      <span>
                        {Moment(item.ActivityStartDate).format("DD/MMM/yyyy")}
                      </span>
                    </div>
                  </div>
                  <div className="text-center mb-2">
                    <h6 className="font-size-15 mt-2 mb-1">{item.Name}</h6>
                    <p className="mb-0 text-muted">{item.Description}</p>
                  </div>
                  {item.ActivityStartTime ? (
                    <div className="d-flex justify-content-center gap-2 text-muted">
                      <p>
                        <i className="bx bx-time align-middle text-primary"></i>{" "}
                        {item.ActivityStartTime &&
                          Moment(item.ActivityStartTime).format("hh:mm A") +
                            " - " +
                            Moment(item.ActivityEndTime).format("hh:mm A")}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {item.Attachments ? (
                    <div className="text-center gap-2 mb-2 text-muted">
                      <span className="border rounded p-2 w-25">
                        <i className="bx bx-file text-primary"></i>
                        {item.Attachments.length}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="pt-1">
                    <Link
                      onClick={() => onClickView(item.ActivityID)}
                      className="btn btn-soft-primary d-block"
                    >
                      View
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </React.Fragment>
  )
}

export default List
