import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Bus Redux States
import {
  GET_BUSES,
  DELETE_BUS,
  ADD_NEW_BUS,
  GET_BUS_DETAIL,
  UPDATE_BUS,
  RESET_BUS,
} from "./actionTypes"
import {
  getBusesFail,
  getBusesSuccess,
  deleteBusSuccess,
  deleteBusFail,
  addBusSuccess,
  addBusFail,
  getBusDetailSuccess,
  getBusDetailFail,
  updateBusSuccess,
  updateBusFail,
  resetBusSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBuses,
  deleteBus,
  addNewBus,
  getBusDetail,
  updateBus,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchBuses() {
  try {
    const response = yield call(getBuses)
    yield put(getBusesSuccess(response))
  } catch (error) {
    yield put(getBusesFail(error))
  }
}

function* onDeleteBus({ payload: bus }) {
  try {
    const response = yield call(deleteBus, bus)
    yield put(deleteBusSuccess(response))
    toast.success("Bus deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteBusFail(error))
    toast.error("Bus deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewBus({ payload: bus }) {
  try {
    const response = yield call(addNewBus, bus)
    yield put(addBusSuccess(response[0]))
    toast.success("Bus added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addBusFail(error))
    toast.error("Bus addition failed", { autoClose: 2000 })
  }
}

function* fetchBusDetail({ busId }) {
  try {
    const response = yield call(getBusDetail, busId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getBusDetailSuccess(result))
  } catch (error) {
    yield put(getBusDetailFail(error))
  }
}

function* onUpdateBus({ payload: bus }) {
  try {
    const response = yield call(updateBus, bus)
    let result = response[0]

    yield put(updateBusSuccess(result))
    toast.success("Bus updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateBusFail(error))
    toast.error("Bus updation failed", { autoClose: 2000 })
  }
}

function* onResetBus() {
  try {
    yield put(resetBusSuccess({}))
  } catch (error) {}
}
function* busSaga() {
  yield takeEvery(GET_BUSES, fetchBuses)
  yield takeEvery(DELETE_BUS, onDeleteBus)
  yield takeEvery(ADD_NEW_BUS, onAddNewBus)
  yield takeEvery(GET_BUS_DETAIL, fetchBusDetail)
  yield takeEvery(UPDATE_BUS, onUpdateBus)
  yield takeEvery(RESET_BUS, onResetBus)
}

export default busSaga
