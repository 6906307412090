import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CHARTS_DATA,
  GET_SCHEDULED_DATA,
  GET_LIVE_DATA,
} from "./actionTypes"
import {
  getChartsDataSuccess,
  getChartsDataFail,
  getScheduledDataSuccess,
  getScheduledDataFail,
  getLiveDataSuccess,
  getLiveDataFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getChartData,
  getScheduledData,
  getLiveData,
} from "../../helpers/backend_helper"

function* getChartsData({ payload: centerId }) {
  try {
    const response = yield call(getChartData, centerId)
    yield put(getChartsDataSuccess(response))
  } catch (error) {
    yield put(getChartsDataFail(error))
  }
}

function* getCenterScheduledData({ payload: centerId }) {
  try {
    const response = yield call(getScheduledData, centerId)
    yield put(getScheduledDataSuccess(response))
  } catch (error) {
    yield put(getScheduledDataFail(error))
  }
}

function* getCenterLiveData({ payload: centerId }) {
  try {
    const response = yield call(getLiveData, centerId)
    yield put(getLiveDataSuccess(response))
  } catch (error) {
    yield put(getLiveDataFail(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
  yield takeEvery(GET_SCHEDULED_DATA, getCenterScheduledData)
  yield takeEvery(GET_LIVE_DATA, getCenterLiveData)
}

export default dashboardSaga
