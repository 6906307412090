import React, { useEffect, useState } from "react"
import { getAuthUser } from "helpers/token_helper"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

import {
  getProfile as onGetProfile,
  getAdultRelations as onGetAdultRelations,
} from "store/actions"

const useUserDetail = () => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})

  useEffect(() => {
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  useEffect(() => {
    if (!isEmpty(userprofile)) {
      dispatch(onGetAdultRelations(userprofile.UserID))
    }
  }, [userprofile])

  //////////////////ADULT STATE/////////////////////
  const { relations } = useSelector(state => state.adult)
  ///////////////////////////////////////

  return { userprofile, relations }
}

export default useUserDetail
