import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import { createSelector } from "reselect"
import Select from "react-select"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

//import { addNewDocument as onAddDocument } from "store/actions"
import {
  getClasses as onGetClasses,
  // getStaffHappenings as onGetStaffHappenings,
  addStaffHappening as onAddStaffHappening,
  updateStaffHappening as onUpdateStaffHappening,
  //getStaffHappeningDetail as onGetStaffHappeningDetail,
} from "../../../store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { json } from "react-router-dom"

const JoinClassModal = ({
  happeningTitle,
  show,
  staffHappening,
  onHappeningCloseClick,
  happeningTypeID,
}) => {
  const dispatch = useDispatch()

  const { staff, loading } = useSelector(state => state.staff)
  const { classes } = useSelector(state => state.class1)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Date: yup.string().required("Required"),
    Time: yup.string().required("Required"),
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Date: "",
      Time: "",
      Classes: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const onHappeningSaveClick = () => {
    trigger()
    if (isValid) {
      if (staffHappening == null) {
        dispatch(
          onAddStaffHappening({
            ...getValues(),
            StaffID: staff.StaffID,
            HappeningTypeID: happeningTypeID,
          })
        )
        onHappeningCloseClick()
      } else {
        dispatch(
          onUpdateStaffHappening({
            ...getValues(),
            StaffID: staff.StaffID,
            StaffHappeningID: staffHappening.StaffHappeningID,
          })
        )

        onHappeningCloseClick()
      }
    }
  }

  const onHappeningModalClosed = () => {
    reset()
  }

  useDeepCompareEffect(() => {
    function updateStaffHappeningState() {
      dispatch(onGetClasses())
      // dispatch(onGetEmploymentTypes())
      // dispatch(onGetClassificationTypes())
      //reset form with server data
    }
    updateStaffHappeningState()
    debugger
    if (staffHappening == null) {
      reset({
        Date: Moment().format("DD MMM yyyy"),
        Time: Moment().format("h:mm a"),
      })
    } else {
      const result = {
        ...staffHappening,
        Date: Moment(staffHappening.Date).format("DD MMM yyyy"),
        Time: Moment(staffHappening.Time).format("h:mm a"),
      }
      reset(result)
    }
  }, [dispatch, staffHappening])

  //useEffect(() => {}, [dispatch, staffHappening])

  return (
    <Modal
      isOpen={show}
      toggle={onHappeningCloseClick}
      onClosed={() => onHappeningModalClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        {happeningTitle}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Date</Label>
                <Controller
                  name="Date"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="Date"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("Date", dateStr)
                        }}
                        required
                      />
                      {errors?.Date?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Date?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>

              <div className="mb-3">
                <Label>Time</Label>
                <Controller
                  name="Time"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputGroup>
                        <Flatpickr
                          {...field}
                          className="form-control d-block"
                          id="Time"
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: "h:i K",
                          }}
                          onChange={(selectedTimes, timeStr, instance) => {
                            setValue(
                              "Time",
                              Moment(selectedTimes[0]).format("h:mm a")
                            )
                          }}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-clock-outline" />
                          </span>
                        </div>
                        {errors?.Time?.message ? (
                          <FormFeedback type="invalid" className="d-block">
                            {errors?.Time?.message}
                          </FormFeedback>
                        ) : null}
                      </InputGroup>
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Class</Label>
                <Controller
                  name="Classes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Classes"
                        options={classes.filter(x => x.IsSystem == 0)}
                        getOptionLabel={option => option.Title}
                        getOptionValue={option => option.ClassID}
                        required
                        aria-invalid={!!errors.Classes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Classes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Classes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onHappeningSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onHappeningCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

JoinClassModal.propTypes = {
  onHappeningCloseClick: PropTypes.func,
  onHappeningSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default JoinClassModal
