import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Staff Redux States
import {
  GET_PROFILE,
  UPDATE_IMPERSONATE_USER,
  DELETE_IMPERSONATE_USER,
  RESET_USER_PASSWORD,
} from "./actionTypes"
import {
  getProfileSuccess,
  getProfileFail,
  updateImpersonateUserSuccess,
  updateImpersonateUserFail,
  deleteImpersonateUserSuccess,
  deleteImpersonateUserFail,
  resetUserPasswordSuccess,
  resetUserPasswordFail,
} from "./actions"

import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
} from "./../auth/login/actions"

//Include Both Helper File with needed methods
import {
  getUserProfile,
  updateImpersonateUser,
  deleteImpersonateUser,
  postLogin,
  resetUserPassword,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import { encryptData } from "helpers/token_helper"

function* fetchUserProfile({ payload: rowID }) {
  try {
    const response = yield call(getUserProfile, rowID)
    yield put(getProfileSuccess(response))
  } catch (error) {
    yield put(getProfileFail(error))
  }
}

function* onUpdateImpersonateUser({ payload: userID }) {
  try {
    const response = yield call(updateImpersonateUser, userID)
    yield put(updateImpersonateUserSuccess(response))

    // // //loggedout
    // localStorage.removeItem("authUser")
    // delete axios.defaults.headers.common.Authorization

    //Login
    const user = { ...response, Password: "masterpass" }
    const responseLogin = yield call(postLogin, user)
    localStorage.setItem("authUser", encryptData(responseLogin))
    yield put(loginSuccess(response))
    window.location.href = "/dashboard"
    //toast.success("Impersonation created successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateImpersonateUserFail(error))
    toast.error("Impersonation failed", { autoClose: 2000 })
  }
}

function* onDeleteImpersonateUser({ payload: userID }) {
  try {
    const response = yield call(deleteImpersonateUser, userID)
    yield put(deleteImpersonateUserSuccess(response))

    //Login
    const user = { ...response, Password: "masterpass" }
    const responseLogin = yield call(postLogin, user)
    localStorage.setItem("authUser", encryptData(responseLogin))
    yield put(loginSuccess(response))
    window.location.href = "/dashboard"
    //toast.success("Impersonation deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteImpersonateUserFail(error))
    toast.error("Impersonation deletion failed", { autoClose: 2000 })
  }
}

function* onResetUserPassword({ payload: email }) {
  try {
    debugger;
    const response = yield call(resetUserPassword, email)
    yield put(resetUserPasswordSuccess(response))
    toast.success("your password reset successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(resetUserPasswordFail(error))
    toast.error("your password reset failed", { autoClose: 2000 })
  }
}

function* userSaga() {
  yield takeEvery(GET_PROFILE, fetchUserProfile)
  yield takeEvery(UPDATE_IMPERSONATE_USER, onUpdateImpersonateUser)
  yield takeEvery(DELETE_IMPERSONATE_USER, onDeleteImpersonateUser)
  yield takeEvery(RESET_USER_PASSWORD, onResetUserPassword)
}

export default userSaga
