import React, { useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { Input, Label, FormFeedback, Row, Col } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  getCountries as onGetCountries,
  getStates as onGetStates,
  getCities as onGetCities,
} from "store/actions"

export default function LocationField(props) {
  const methods = useFormContext()
  const dispatch = useDispatch()
  const { control, formState, setValue, getValues, reset } = methods
  const { errors } = formState

  useEffect(() => {
    dispatch(onGetCountries())
    if (getValues("Countries") !== undefined) {
      dispatch(onGetStates(getValues("Countries").CountryID))
      dispatch(onGetCities(getValues("States").StateID))
    }
  }, [dispatch])

  const GetStates = x => {
    setValue("Countries", x)
    dispatch(onGetStates(x.CountryID))
  }

  const GetCities = x => {
    setValue("States", x)
    dispatch(onGetCities(x.StateID))
  }

  //////////////////HELPER STATE/////////////////////
  const { countries, states, cities } = useSelector(state => state.helper)
  ///////////////////////////////////////

  return (
    <Row>
      <Col sm="12">
        <div className="mb-3">
          <Label>Address</Label>
          <Controller
            name="Address"
            control={control}
            value=""
            render={({ field }) => (
              <textarea
                {...field}
                rows={3}
                className="form-control mb-3"
                id="Address"
              />
            )}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <Label>Country</Label>
          <Controller
            name="Countries"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="Countries"
                getOptionLabel={option => option.Name}
                getOptionValue={option => option.CountryID}
                options={countries}
                onChange={x => GetStates(x)}
                classNamePrefix="select2-selection"
              />
            )}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <Label>State</Label>
          <Controller
            name="States"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="States"
                getOptionLabel={option => option.Name}
                getOptionValue={option => option.StateID}
                options={states}
                onChange={x => GetCities(x)}
                classNamePrefix="select2-selection"
              />
            )}
          />
        </div>
      </Col>
      <Col sm="4">
        <div className="mb-3">
          <Label>City</Label>
          <Controller
            name="Cities"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  id="Cities"
                  required
                  getOptionLabel={option => option.Name}
                  getOptionValue={option => option.CityID}
                  aria-invalid={!!errors.Cities}
                  options={cities}
                  classNamePrefix="select2-selection"
                />
                {errors?.Cities?.message ? (
                  <FormFeedback type="invalid" className="d-block">
                    {errors?.Cities?.message}
                  </FormFeedback>
                ) : null}
              </>
            )}
          />
        </div>
      </Col>
      <Col sm="12">
        <div className="mb-3">
          <Label>Zip Code</Label>
          <Controller
            name="ZipCode"
            control={control}
            render={({ field }) => (
              <Input {...field} id="ZipCode" type="number" />
            )}
          />
        </div>
      </Col>
    </Row>
  )
}
