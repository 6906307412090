import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Forum Redux States
import {
  GET_FORUMS,
  DELETE_FORUM,
  ADD_NEW_FORUM,
  GET_FORUM_DETAIL,
  UPDATE_FORUM,
  RESET_FORUM,
  GET_FORUM_VISIBILITY_TYPES,
  DELETE_FORUM_POST,
  ADD_NEW_FORUM_POST,
  UPDATE_FORUM_POST_FILE,
} from "./actionTypes"
import {
  getForumsFail,
  getForumsSuccess,
  deleteForumSuccess,
  deleteForumFail,
  addForumSuccess,
  addForumFail,
  getForumDetailSuccess,
  getForumDetailFail,
  updateForumSuccess,
  updateForumFail,
  resetForumSuccess,
  getForumVisibilityTypesSuccess,
  getForumVisibilityTypesFail,
  deleteForumPostSuccess,
  deleteForumPostFail,
  addForumPostSuccess,
  addForumPostFail,
  updateForumPostFileSuccess,
  updateForumPostFileFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getForums,
  deleteForum,
  addNewForum,
  getForumDetail,
  updateForum,
  getForumVisibilityTypes,
  deleteForumPost,
  addNewForumPost,
  updateForumPostFile,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchForums() {
  try {
    const response = yield call(getForums)
    yield put(getForumsSuccess(response))
  } catch (error) {
    yield put(getForumsFail(error))
  }
}

function* onDeleteForum({ payload: forum }) {
  try {
    const response = yield call(deleteForum, forum)
    yield put(deleteForumSuccess(response))
    toast.success("Forum deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteForumFail(error))
    toast.error("Forum deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewForum({ payload: forum }) {
  try {
    const response = yield call(addNewForum, forum)
    yield put(addForumSuccess(response[0]))
    toast.success("Forum added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addForumFail(error))
    toast.error("Forum addition failed", { autoClose: 2000 })
  }
}

function* fetchForumDetail({ forumId }) {
  try {
    const response = yield call(getForumDetail, forumId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getForumDetailSuccess(result))
  } catch (error) {
    yield put(getForumDetailFail(error))
  }
}

function* onUpdateForum({ payload: forum }) {
  try {
    const response = yield call(updateForum, forum)
    let result = response[0]
    yield put(updateForumSuccess(result))
    toast.success("Forum updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateForumFail(error))
    toast.error("Forum updation failed", { autoClose: 2000 })
  }
}

function* onResetForum() {
  try {
    yield put(resetForumSuccess({}))
  } catch (error) {}
}

function* fetchForumVisibilityTypes() {
  try {
    const response = yield call(getForumVisibilityTypes)
    yield put(getForumVisibilityTypesSuccess(response))
  } catch (error) {
    yield put(getForumVisibilityTypesFail(error))
  }
}

function* onDeleteForumPost({ payload: forumPostID }) {
  try {
    const response = yield call(deleteForumPost, forumPostID)
    yield put(deleteForumPostSuccess(response[0]))
    toast.success("Forum Post deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteForumPostFail(error))
    toast.error("Forum Post deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewForumPost({ payload: forumPost }) {
  try {
    debugger
    var photoData = new FormData()
    photoData.append("files", forumPost.Photo)
    photoData.append("field", "ForumPost")
    delete forumPost.Photo

    const response = yield call(addNewForumPost, forumPost)

    yield put(addForumPostSuccess(response[0]))
    photoData.append("refId", response[0].CurrentPostID)
    //photoData.append("PostID", 33)
    try {
      const response1 = yield call(updateForumPostFile, photoData)
      //let result = response[0]

      yield put(updateForumPostFileSuccess(response1))
      //toast.success("File Update Successfully", { autoClose: 2000 })
    } catch (error) {
      yield put(updateForumPostFileFail(error))
      toast.error("File Update Failed", { autoClose: 2000 })
    }

    toast.success("Forum Post added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addForumPostFail(error))
    toast.error("Forum Post addition failed", { autoClose: 2000 })
  }
}

function* onUpdateForumPostFile({ payload: forumPost }) {
  try {
    var fileData = new FormData()
    fileData.append("files", forumPost.FileName)
    fileData.append("refId", forumPost.PostID)
    fileData.append("field", "ForumPost")
    const response = yield call(updateForumPostFile, fileData)
    yield put(updateForumPostFileSuccess(response))
  } catch (error) {
    yield put(updateForumPostFileFail(error))
    toast.error("File Update Failed", { autoClose: 2000 })
  }
}

function* forumSaga() {
  yield takeEvery(GET_FORUMS, fetchForums)
  yield takeEvery(DELETE_FORUM, onDeleteForum)
  yield takeEvery(ADD_NEW_FORUM, onAddNewForum)
  yield takeEvery(GET_FORUM_DETAIL, fetchForumDetail)
  yield takeEvery(UPDATE_FORUM, onUpdateForum)
  yield takeEvery(RESET_FORUM, onResetForum)
  yield takeEvery(GET_FORUM_VISIBILITY_TYPES, fetchForumVisibilityTypes)
  yield takeEvery(DELETE_FORUM_POST, onDeleteForumPost)
  yield takeEvery(ADD_NEW_FORUM_POST, onAddNewForumPost)
  yield takeEvery(UPDATE_FORUM_POST_FILE, onUpdateForumPostFile)
}

export default forumSaga
