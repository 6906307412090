import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Staff Redux States
import {
  GET_CLASS_SCHEDULES,
  RESET_CLASS_SCHEDULES,
  ADD_CLASS_SCHEDULES,
  //GET_STAFF_ABSENT_TYPES,
  DELETE_CLASS_SCHEDULE,
} from "./actionTypes"
import {
  getClassSchedulesSuccess,
  getClassSchedulesFail,
  resetClassScheduleSuccess,
  addClassScheduleSuccess,
  addClassScheduleFail,
  // getStaffAbsentTypesSuccess,
  // getStaffAbsentTypesFail,
  deleteClassScheduleSuccess,
  deleteClassScheduleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClassSchedules,
  addClassSchedule,
  //getAbsentTypes as getStaffAbsentTypes,
  deleteClassSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchClassSchedules({ filter }) {
  try {
    const response = yield call(getClassSchedules, filter)
    yield put(getClassSchedulesSuccess(response.Table))
  } catch (error) {
    yield put(getClassSchedulesFail(error))
  }
}

function* onAddClassSchedule({ payload: classSchedules }) {
  try {
    const request = {
      ...classSchedules,
      StaffID: classSchedules.Staffs.StaffID,
      ClassID: classSchedules.Classes?.ClassID ?? null,
      AbsentTypeID: classSchedules.StaffAbsentTypes?.AbsentTypeID ?? null,
    }

    const response = yield call(addClassSchedule, request)
    yield put(addClassScheduleSuccess(response.Table))
    toast.success("Class schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addClassScheduleFail(error))
    toast.error("Class schedule addition failed", { autoClose: 2000 })
  }
}

// function* fetchStaffAbsentTypes() {
//   try {
//     const response = yield call(getStaffAbsentTypes)
//     yield put(getStaffAbsentTypesSuccess(response))
//   } catch (error) {
//     yield put(getStaffAbsentTypesFail(error))
//   }
// }

function* onResetClassSchedules() {
  try {
    yield put(resetClassScheduleSuccess({}))
  } catch (error) {}
}

function* onDeleteClassSchedule({ payload: classSchedules }) {
  try {
    const response = yield call(deleteClassSchedule, classSchedules)
    yield put(deleteClassScheduleSuccess(response.Table))
    toast.success("Class schedule deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteClassScheduleFail(error))
    toast.error("Class schedule deletion failed", { autoClose: 2000 })
  }
}

function* staffScheduleSaga() {
  yield takeEvery(GET_CLASS_SCHEDULES, fetchClassSchedules)
  yield takeEvery(RESET_CLASS_SCHEDULES, onResetClassSchedules)
  yield takeEvery(ADD_CLASS_SCHEDULES, onAddClassSchedule)
  //yield takeEvery(GET_STAFF_ABSENT_TYPES, fetchStaffAbsentTypes)
  yield takeEvery(DELETE_CLASS_SCHEDULE, onDeleteClassSchedule)
}

export default staffScheduleSaga
