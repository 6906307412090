import React, { useState, useEffect } from "react"

//controls
import { Button, Col, Form, Input, Label, Row, FormFeedback } from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewRoom as onAddNewRoom,
  updateRoom as onUpdateRoom,
  resetRoom as onResetRoom,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import Spinners from "components/Common/Spinner"

function BasicInfoTab({ room, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Title: yup
      .string()
      .required("Required")
      .min(3, "Title must be at least 3 characters"),
    Capacity: yup.string().required("Required"),
    MinAge: yup.string().required("Required"),
    MaxAge: yup.string().required("Required"),
    IsActive: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Title: "",
      Capacity: "",
      MinAge: "",
      MaxAge: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

   useDeepCompareEffect(() => {
    function updateRoomState() {

      //reset form with server data
      reset(room)
    }

    updateRoomState()
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(onResetRoom())
    }
  }, [dispatch])
  const onCancelClick = () => {
    navigate("/rooms")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewRoom(getValues()))
      } else {
        dispatch(onUpdateRoom(getValues()))
      }
      navigate("/rooms")
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="6">
          <div className="mb-3">
            <Label>Title</Label>
            <Controller
              name="Title"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Title"
                    type="text"
                    required
                    invalid={!!errors.Title}
                  />
                  {errors?.Title?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Title?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Capacity</Label>
            <Controller
              name="Capacity"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Capacity"
                    type="number"
                    required
                    invalid={!!errors.Capacity}
                  />
                  {errors?.Capacity?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Capacity?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Min Age (months)</Label>
            <Controller
              name="MinAge"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="MinAge"
                    type="number"
                    required
                    invalid={!!errors.MinAge}
                  />
                  {errors?.MinAge?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.MinAge?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Max Age (months)</Label>
            <Controller
              name="MaxAge"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="MaxAge"
                    type="number"
                    required
                    invalid={!!errors.MaxAge}
                  />
                  {errors?.MaxAge?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.MaxAge?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
