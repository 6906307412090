/*Get CHILD */
export const GET_CHILDREN = "GET_CHILDREN"
export const GET_CHILDREN_SUCCESS = "GET_CHILDREN_SUCCESS"
export const GET_CHILDREN_FAIL = "GET_CHILDREN_FAIL"

/**
 * Delete CHILD
 */
export const DELETE_CHILD = "DELETE_CHILD"
export const DELETE_CHILD_SUCCESS = "DELETE_CHILD_SUCCESS"
export const DELETE_CHILD_FAIL = "DELETE_CHILD_FAIL"
/**
 * Add CHILD
 */
export const ADD_NEW_CHILD = "ADD_NEW_CHILD"
export const ADD_CHILD_SUCCESS = "ADD_CHILD_SUCCESS"
export const ADD_CHILD_FAIL = "ADD_CHILD_FAIL"

/**
 * Get CHILD DETAILS
 */
export const GET_CHILD_DETAIL = "GET_CHILD_DETAIL"
export const GET_CHILD_DETAIL_SUCCESS = "GET_CHILD_DETAIL_SUCCESS"
export const GET_CHILD_DETAIL_FAIL = "GET_CHILD_DETAIL_FAIL"

/**
 * Edit CHILD
 */
export const UPDATE_CHILD = "UPDATE_CHILD"
export const UPDATE_CHILD_SUCCESS = "UPDATE_CHILD_SUCCESS"
export const UPDATE_CHILD_FAIL = "UPDATE_CHILD_FAIL"

/**
 * Reset CHILD
 */
export const RESET_CHILD = "RESET_CHILD"
export const RESET_CHILD_SUCCESS = "RESET_CHILD_SUCCESS"

/**
 * Add/Edit Photo
 */
export const UPDATE_CHILD_PHOTO = "UPDATE_CHILD_PHOTO"
export const UPDATE_CHILD_PHOTO_SUCCESS = "UPDATE_CHILD_PHOTO_SUCCESS"
export const UPDATE_CHILD_PHOTO_FAIL = "UPDATE_CHILD_PHOTO_FAIL"

/**
 * Get Provided Food
 */
export const GET_CHILD_PROVIDED_FOOD = "GET_CHILD_PROVIDED_FOOD"
export const GET_CHILD_PROVIDED_FOOD_SUCCESS = "GET_CHILD_PROVIDED_FOOD_SUCCESS"
export const GET_CHILD_PROVIDED_FOOD_FAIL = "GET_CHILD_PROVIDED_FOOD_FAIL"

/**
 * Add Provided Food
 */
export const ADD_CHILD_PROVIDED_FOOD = "ADD_CHILD_PROVIDED_FOOD"
export const ADD_CHILD_PROVIDED_FOOD_SUCCESS = "ADD_CHILD_PROVIDED_FOOD_SUCCESS"
export const ADD_CHILD_PROVIDED_FOOD_FAIL = "ADD_CHILD_PROVIDED_FOOD_FAIL"

/**
 * Get Notes
 */
export const GET_CHILD_NOTES = "GET_CHILD_NOTES"
export const GET_CHILD_NOTES_SUCCESS = "GET_CHILD_NOTES_SUCCESS"
export const GET_CHILD_NOTES_FAIL = "GET_CHILD_NOTES_FAIL"

/**
 * Add Notes
 */
export const ADD_CHILD_NOTES = "ADD_CHILD_NOTES"
export const ADD_CHILD_NOTES_SUCCESS = "ADD_CHILD_NOTES_SUCCESS"
export const ADD_CHILD_NOTES_FAIL = "ADD_CHILD_NOTES_FAIL"

/*Get Availability */
export const GET_CHILD_AVAILABILITY = "GET_CHILD_AVAILABILITY"
export const GET_CHILD_AVAILABILITY_SUCCESS = "GET_CHILD_AVAILABILITY_SUCCESS"
export const GET_CHILD_AVAILABILITY_FAIL = "GET_CHILD_AVAILABILITY_FAIL"

/*Add Availability */
export const ADD_CHILD_AVAILABILITY = "ADD_CHILD_AVAILABILITY"
export const ADD_CHILD_AVAILABILITY_SUCCESS = "ADD_CHILD_AVAILABILITY_SUCCESS"
export const ADD_CHILD_AVAILABILITY_FAIL = "ADD_CHILD_AVAILABILITY_FAIL"

/**
 * Get Relations
 */
export const GET_CHILD_RELATIONS = "GET_CHILD_RELATIONS"
export const GET_CHILD_RELATIONS_SUCCESS = "GET_CHILD_RELATIONS_SUCCESS"
export const GET_CHILD_RELATIONS_FAIL = "GET_CHILD_RELATIONS_FAIL"

/**
 * Add Relation
 */
export const ADD_CHILD_RELATION = "ADD_CHILD_RELATION"
export const ADD_CHILD_RELATION_SUCCESS = "ADD_CHILD_RELATION_SUCCESS"
export const ADD_CHILD_RELATION_FAIL = "ADD_CHILD_RELATION_FAIL"

/**
 * Get Relation Types
 */
export const GET_RELATION_TYPES = "GET_RELATION_TYPES"
export const GET_RELATION_TYPES_SUCCESS = "GET_RELATION_TYPES_SUCCESS"
export const GET_RELATION_TYPES_FAIL = "GET_RELATION_TYPES_FAIL"

/**
 * Get Permission Types
 */
export const GET_PERMISSION_TYPES = "GET_PERMISSION_TYPES"
export const GET_PERMISSION_TYPES_SUCCESS = "GET_PERMISSION_TYPES_SUCCESS"
export const GET_PERMISSION_TYPES_FAIL = "GET_PERMISSION_TYPES_FAIL"

/**
 * Delete Relation
 */
export const DELETE_CHILD_RELATION = "DELETE_CHILD_RELATION"
export const DELETE_CHILD_RELATION_SUCCESS = "DELETE_CHILD_RELATION_SUCCESS"
export const DELETE_CHILD_RELATION_FAIL = "DELETE_CHILD_RELATION_FAIL"

/*Get Child Happening */

export const GET_HAPPENING_TYPES = "GET_HAPPENING_TYPES"
export const GET_HAPPENING_TYPES_SUCCESS = "GET_HAPPENING_TYPES_SUCCESS"
export const GET_HAPPENING_TYPES_FAIL = "GET_HAPPENING_TYPES_FAIL"

export const GET_CHILD_HAPPENING = "GET_CHILD_HAPPENING"
export const GET_CHILD_HAPPENING_SUCCESS = "GET_CHILD_HAPPENING_SUCCESS"
export const GET_CHILD_HAPPENING_FAIL = "GET_CHILD_HAPPENING_FAIL"

export const ADD_CHILD_HAPPENING = "ADD_CHILD_HAPPENING"
export const ADD_CHILD_HAPPENING_SUCCESS = "ADD_CHILD_HAPPENING_SUCCESS"
export const ADD_CHILD_HAPPENING_FAIL = "ADD_CHILD_HAPPENING_FAIL"

/**
 * Get CHILD_HAPPENING DETAILS
 */
export const GET_CHILD_HAPPENING_DETAIL = "GET_CHILD_HAPPENING_DETAIL"
export const GET_CHILD_HAPPENING_DETAIL_SUCCESS =
  "GET_CHILD_HAPPENING_DETAIL_SUCCESS"
export const GET_CHILD_HAPPENING_DETAIL_FAIL = "GET_CHILD_HAPPENING_DETAIL_FAIL"

/**
 * Edit CHILD_HAPPENING
 */
export const UPDATE_CHILD_HAPPENING = "UPDATE_CHILD_HAPPENING"
export const UPDATE_CHILD_HAPPENING_SUCCESS = "UPDATE_CHILD_HAPPENING_SUCCESS"
export const UPDATE_CHILD_HAPPENING_FAIL = "UPDATE_CHILD_HAPPENING_FAIL"

/**
 * Delete CHILD_HAPPENING
 */
export const DELETE_CHILD_HAPPENING = "DELETE_CHILD_HAPPENING"
export const DELETE_CHILD_HAPPENING_SUCCESS = "DELETE_CHILD_HAPPENING_SUCCESS"
export const DELETE_CHILD_HAPPENING_FAIL = "DELETE_CHILD_HAPPENING_FAIL"

/**
 * Reset CHILD_HAPPENING
 */
export const RESET_CHILD_HAPPENING = "RESET_CHILD_HAPPENING"
export const RESET_CHILD_HAPPENING_SUCCESS = "RESET_CHILD_HAPPENING_SUCCESS"

export const GET_HOUR_TYPES = "GET_HOUR_TYPES"
export const GET_HOUR_TYPES_SUCCESS = "GET_HOUR_TYPES_SUCCESS"
export const GET_HOUR_TYPES_FAIL = "GET_HOUR_TYPES_FAIL"

/*Get TAG CATEGORIES */
export const GET_TAG_CATEGORY = "GET_TAG_CATEGORY"
export const GET_TAG_CATEGORY_SUCCESS = "GET_TAG_CATEGORY_SUCCESS"
export const GET_TAG_CATEGORY_FAIL = "GET_TAG_CATEGORY_FAIL"

/*Get TAGS */
export const GET_TAGS = "GET_TAGS"
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS"
export const GET_TAGS_FAIL = "GET_TAGS_FAIL"

/* Delete TAG */
export const DELETE_TAG = "DELETE_TAG"
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS"
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL"

/* Add TAG */
export const ADD_NEW_TAG = "ADD_NEW_TAG"
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS"
export const ADD_TAG_FAIL = "ADD_TAG_FAIL"

/*Get MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"

/*Get Awaited MESSAGES */
export const GET_AWAITED_MESSAGES = "GET_AWAITED_MESSAGES"
export const GET_AWAITED_MESSAGES_SUCCESS = "GET_AWAITED_MESSAGES_SUCCESS"
export const GET_AWAITED_MESSAGES_FAIL = "GET_AWAITED_MESSAGES_FAIL"

/*Get MESSAGE POSTS */
export const GET_MESSAGE_POSTS = "GET_MESSAGE_POSTS"
export const GET_MESSAGE_POSTS_SUCCESS = "GET_MESSAGE_POSTS_SUCCESS"
export const GET_MESSAGE_POSTS_FAIL = "GET_MESSAGE_POSTS_FAIL"

/*Add MESSAGE */
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE"
export const ADD_NEW_MESSAGE_SUCCESS = "ADD_NEW_MESSAGE_SUCCESS"
export const ADD_NEW_MESSAGE_FAIL = "ADD_NEW_MESSAGE_FAIL"

/*Add MESSAGE POST */
export const ADD_MESSAGE_POST = "ADD_MESSAGE_POST"
export const ADD_MESSAGE_POST_SUCCESS = "ADD_MESSAGE_POST_SUCCESS"
export const ADD_MESSAGE_POST_FAIL = "ADD_MESSAGE_POST_FAIL"

/*Update MESSAGE APPROVAL */
export const UPDATE_MESSAGE_APPROVAL = "UPDATE_MESSAGE_APPROVAL"
export const UPDATE_MESSAGE_APPROVAL_SUCCESS = "UPDATE_MESSAGE_APPROVAL_SUCCESS"
export const UPDATE_MESSAGE_APPROVAL_FAIL = "UPDATE_MESSAGE_APPROVAL_FAIL"

/*Update MESSAGE */
export const DELETE_MESSAGE = "DELETE_MESSAGE"
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS"
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL"

/*Update MESSAGE POST */
export const DELETE_MESSAGE_POST = "DELETE_MESSAGE_POST"
export const DELETE_MESSAGE_POST_SUCCESS = "DELETE_MESSAGE_POST_SUCCESS"
export const DELETE_MESSAGE_POST_FAIL = "DELETE_MESSAGE_POST_FAIL"
