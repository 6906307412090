import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Input,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import {
  getPaymentMethods as onGetPaymentMethods,
  addWalletTransaction as onAddWalletTransaction,
} from "store/actions"

const WalletModal = ({ adultID, show, onCloseClick }) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Amount: yup.number().min(1).required("Required"),
    PaymentMethods: yup.object().required("Required"),
    ReferenceNumber: yup.string().when("PaymentMethods", {
      is: value => value?.PaymentMethodID === 2 || value?.PaymentMethodID === 3, //Cash
      then: schema => schema.required("Required"),
      otherwise: schema => schema,
    }),
    Notes: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Amount: 0,
      ReferenceNumber: "",
      Notes: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, watch, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const CurrentPaymentMethod = watch("PaymentMethods")

  useDeepCompareEffect(() => {
    dispatch(onGetPaymentMethods())
  }, [dispatch])

  //////////////////Adult STATE/////////////////////
  const { paymentmethods } = useSelector(state => state.wallet)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddWalletTransaction(adultID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Load Wallet
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Amount</Label>
                <Controller
                  name="Amount"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="Amount"
                        type="number"
                        invalid={!!errors.Amount}
                      />
                      {errors?.Amount?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Amount?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Payment Type</Label>
                <Controller
                  name="PaymentMethods"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="PaymentMethods"
                        options={paymentmethods.filter(
                          x => x.PaymentMethodID !== 4
                        )}
                        getOptionLabel={option => option.PaymentMethod}
                        getOptionValue={option => option.PaymentMethodID}
                        required
                        aria-invalid={!!errors.PaymentMethods}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.PaymentMethods?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.PaymentMethods?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div
                className="mb-3"
                hidden={
                  CurrentPaymentMethod === undefined ||
                  CurrentPaymentMethod?.PaymentMethodID === 1
                }
              >
                <Label>Reference Number</Label>
                <Controller
                  name="ReferenceNumber"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="ReferenceNumber"
                        type="text"
                        required
                        invalid={!!errors.ReferenceNumber}
                      />
                      {errors?.ReferenceNumber?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.ReferenceNumber?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Notes</Label>
                <Controller
                  name="Notes"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <>
                      <textarea
                        {...field}
                        rows={3}
                        className="form-control mb-3"
                        id="Notes"
                      />
                      {errors?.Notes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Notes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

WalletModal.propTypes = {
  adultID: PropTypes.number,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default WalletModal
