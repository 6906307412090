import React, { useEffect, useState } from "react"
import { Container, Row } from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"

import { Col } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  getChildDetail as onGetChildDetail,
  getMessages as onGetMessages,
} from "store/actions"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DetailSection from "./DetailSection"
import MessageSection from "./MessageSection"
import { isEmpty } from "lodash"

const MessageInbox = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const { child } = useSelector(state => state.child)

  useEffect(() => {
    dispatch(onGetChildDetail(id))
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(child)) {
      dispatch(onGetMessages(child.ChildID))
    }
  }, [dispatch, child])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Children" breadcrumbItem="Message Details" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <MessageSection />
                <DetailSection />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MessageInbox
