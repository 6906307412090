import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import {
  getClasses as onGetClasses,
  addStaffProfile as onAddStaffProfile,
} from "store/actions"

const HomeClassModal = ({ staffID, profile, show, onCloseClick }) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Classes: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      dispatch(onGetClasses())

      if (show) {
        reset(profile)
      }
    }

    updateState()
  }, [show, dispatch])

  //////////////////CLASS SCHEDULE STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddStaffProfile(staffID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Select Home Class
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Class</Label>
                <Controller
                  name="Classes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Classes"
                        options={classes}
                        getOptionLabel={option => option.Title}
                        getOptionValue={option => option.ClassID}
                        required
                        aria-invalid={!!errors.Classes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.Classes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Classes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

HomeClassModal.propTypes = {
  staffID: PropTypes.number,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default HomeClassModal
