import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Notification Redux States
import {
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  ADD_NEW_NOTIFICATION,
  GET_NOTIFICATION_DETAIL,
  UPDATE_NOTIFICATION,
  RESET_NOTIFICATION,
  GET_NOTIFICATION_EVENTS,
  GET_NOTIFICATION_RECIPIENTS,
} from "./actionTypes"
import {
  getNotificationsFail,
  getNotificationsSuccess,
  deleteNotificationSuccess,
  deleteNotificationFail,
  addNotificationSuccess,
  addNotificationFail,
  getNotificationDetailSuccess,
  getNotificationDetailFail,
  updateNotificationSuccess,
  updateNotificationFail,
  resetNotificationSuccess,
  getNotificationEventsSuccess,
  getNotificationEventsFail,
  getNotificationRecipientsSuccess,
  getNotificationRecipientsFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getNotifications,
  deleteNotification,
  addNewNotification,
  getNotificationDetail,
  updateNotification,
  getNotificationEvents,
  getNotificationRecipients,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchNotifications() {
  try {
    const response = yield call(getNotifications)
    yield put(getNotificationsSuccess(response))
  } catch (error) {
    yield put(getNotificationsFail(error))
  }
}

function* onDeleteNotification({ payload: notification }) {
  try {
    const response = yield call(deleteNotification, notification)
    yield put(deleteNotificationSuccess(response))
    toast.success("Notification deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteNotificationFail(error))
    toast.error("Notification deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewNotification({ payload: notification }) {
  try {
    const response = yield call(addNewNotification, notification)
    yield put(addNotificationSuccess(response[0]))
    toast.success("Notification added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNotificationFail(error))
    toast.error("Notification addition failed", { autoClose: 2000 })
  }
}

function* fetchNotificationDetail({ notificationId }) {
  try {
    const response = yield call(getNotificationDetail, notificationId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getNotificationDetailSuccess(result))
  } catch (error) {
    yield put(getNotificationDetailFail(error))
  }
}

function* onUpdateNotification({ payload: notification }) {
  try {
    const response = yield call(updateNotification, notification)
    let result = response[0]

    yield put(updateNotificationSuccess(result))
    toast.success("Notification updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateNotificationFail(error))
    toast.error("Notification updation failed", { autoClose: 2000 })
  }
}

function* onResetNotification() {
  try {
    yield put(resetNotificationSuccess({}))
  } catch (error) {}
}

function* fetchNotificationEvents() {
  try {
    const response = yield call(getNotificationEvents)
    yield put(getNotificationEventsSuccess(response))
  } catch (error) {
    yield put(getNotificationEventsFail(error))
  }
}

function* fetchNotificationRecipients() {
  try {
    const response = yield call(getNotificationRecipients)
    yield put(getNotificationRecipientsSuccess(response))
  } catch (error) {
    yield put(getNotificationRecipientsFail(error))
  }
}

function* notificationSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications)
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification)
  yield takeEvery(ADD_NEW_NOTIFICATION, onAddNewNotification)
  yield takeEvery(GET_NOTIFICATION_DETAIL, fetchNotificationDetail)
  yield takeEvery(UPDATE_NOTIFICATION, onUpdateNotification)
  yield takeEvery(RESET_NOTIFICATION, onResetNotification)
  yield takeEvery(GET_NOTIFICATION_EVENTS, fetchNotificationEvents)
  yield takeEvery(GET_NOTIFICATION_RECIPIENTS, fetchNotificationRecipients)
}

export default notificationSaga
