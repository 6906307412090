import {
  GET_MEALS_FAIL,
  GET_MEALS_SUCCESS,
  DELETE_MEAL_SUCCESS,
  DELETE_MEAL_FAIL,
  ADD_MEAL_FAIL,
  ADD_MEAL_SUCCESS,
  GET_MEAL_DETAIL_SUCCESS,
  GET_MEAL_DETAIL_FAIL,
  UPDATE_MEAL_SUCCESS,
  UPDATE_MEAL_FAIL,
  RESET_MEAL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  meals: [],
  meal: {},
  error: {},
  loading: true,
}

const Meal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEALS_SUCCESS:
      return {
        ...state,
        meals: action.payload,
        loading: true,
      }
    case GET_MEALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_MEAL_SUCCESS: {
      const data = {
        ...state,
        meals: state.meals.filter(
          meal => meal.MealID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        meals: state.meals.filter(meal => meal.MealID !== action.payload),
      }
    }
    case DELETE_MEAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MEAL_SUCCESS:
      return {
        ...state,
        meals: [...state.meals, action.payload],
      }
    case ADD_MEAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MEAL_DETAIL_SUCCESS:
      return {
        ...state,
        meal: action.payload,
        loading: true,
      }

    case GET_MEAL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_MEAL_SUCCESS:
      return {
        ...state,
        meals: state.meals.map(meal =>
          meal.MealID.toString() === action.payload.MealID.toString()
            ? { meal, ...action.payload }
            : meal
        ),
      }

    case UPDATE_MEAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_MEAL_SUCCESS:
      return {
        ...state,
        meal: action.payload,
      }
    default:
      return state
  }
}

export default Meal
