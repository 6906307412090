import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Wallet Redux States
import {
  GET_PAYMENT_METHODS,
  ADD_WALLET_TRANSACTION,
  GET_WALLET_TRANSACTION,
} from "./actionTypes"
import {
  getPaymentMethodsSuccess,
  getPaymentMethodsFail,
  addWalletTransactionSuccess,
  addWalletTransactionFail,
  getWalletTransactionSuccess,
  getWalletTransactionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPaymentMethods,
  addWalletTransaction,
  getWalletTransaction,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchPaymentMethods() {
  try {
    const response = yield call(getPaymentMethods)
    yield put(getPaymentMethodsSuccess(response))
  } catch (error) {
    yield put(getPaymentMethodsFail(error))
  }
}

function* onAddWalletTransaction({ payload: { adultID, values } }) {
  try {
    const request = {
      AdultID: adultID,
      Values: values,
    }
    const response = yield call(addWalletTransaction, request)
    yield put(addWalletTransactionSuccess(response))
  } catch (error) {
    yield put(addWalletTransactionFail(error))
  }
}

function* fetchWalletTransaction({ payload: adultID }) {
  try {
    const response = yield call(getWalletTransaction, adultID)
    yield put(getWalletTransactionSuccess(response))
  } catch (error) {
    yield put(getWalletTransactionFail(error))
  }
}

function* walletSaga() {
  yield takeEvery(GET_PAYMENT_METHODS, fetchPaymentMethods)
  yield takeEvery(ADD_WALLET_TRANSACTION, onAddWalletTransaction)
  yield takeEvery(GET_WALLET_TRANSACTION, fetchWalletTransaction)
}

export default walletSaga
