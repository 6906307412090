import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  ADD_NEW_DOCUMENT,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  GET_PRIVACY_TYPES,
  GET_PRIVACY_TYPES_SUCCESS,
  GET_PRIVACY_TYPES_FAIL,
} from "./actionTypes"

export const getDocuments = filter => ({
  type: GET_DOCUMENTS,
  filter,
})

export const getDocumentsSuccess = documents => ({
  type: GET_DOCUMENTS_SUCCESS,
  payload: documents,
})

export const getDocumentsFail = error => ({
  type: GET_DOCUMENTS_FAIL,
  payload: error,
})

export const deleteDocument = document => ({
  type: DELETE_DOCUMENT,
  payload: document,
})

export const deleteDocumentSuccess = document => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: document,
})

export const deleteDocumentFail = error => ({
  type: DELETE_DOCUMENT_FAIL,
  payload: error,
})

export const addNewDocument = document => ({
  type: ADD_NEW_DOCUMENT,
  payload: document,
})

export const addDocumentSuccess = document => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload: document,
})

export const addDocumentFail = error => ({
  type: ADD_DOCUMENT_FAIL,
  payload: error,
})

export const getPrivacyTypes = objectTypeID => ({
  type: GET_PRIVACY_TYPES,
  payload: objectTypeID,
})

export const getPrivacyTypesSuccess = data => ({
  type: GET_PRIVACY_TYPES_SUCCESS,
  payload: data,
})

export const getPrivacyTypesFail = error => ({
  type: GET_PRIVACY_TYPES_FAIL,
  payload: error,
})
