import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Album Redux States
import {
  GET_ALBUMS,
  DELETE_ALBUM,
  ADD_NEW_ALBUM,
  GET_ALBUM_DETAIL,
  UPDATE_ALBUM,
  RESET_ALBUM,
  ADD_NEW_ALBUMMEDIA,
  DELETE_ALBUMMEDIA,
} from "./actionTypes"
import {
  getAlbumsFail,
  getAlbumsSuccess,
  deleteAlbumSuccess,
  deleteAlbumFail,
  addAlbumSuccess,
  addAlbumFail,
  getAlbumDetailSuccess,
  getAlbumDetailFail,
  updateAlbumSuccess,
  updateAlbumFail,
  resetAlbumSuccess,
  deleteAlbumMediaSuccess,
  deleteAlbumMediaFail,
  //addNewAlbumMedia
  addAlbumMediaSuccess,
  addAlbumMediaFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAlbums,
  deleteAlbum,
  addNewAlbum,
  getAlbumDetail,
  updateAlbum,
  addNewAlbumMedia,
  deleteAlbumMedia,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchAlbums() {
  try {
    const response = yield call(getAlbums)
    yield put(getAlbumsSuccess(response))
  } catch (error) {
    yield put(getAlbumsFail(error))
  }
}

function* onDeleteAlbum({ payload: album }) {
  try {
    const response = yield call(deleteAlbum, album)
    yield put(deleteAlbumSuccess(response))
    toast.success("Album deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteAlbumFail(error))
    toast.error("Album deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewAlbum({ payload: album }) {
  try {
    
    var photoData = new FormData()
  
    for (let i = 0; i < album.Photo?.length; i++) {
      photoData.append("files"+i, album.Photo[i])
    }
    photoData.append("field", "albummedia")
    delete album.Photo

    const response = yield call(addNewAlbum, album)
    yield put(addAlbumSuccess(response[0]))

    photoData.append("refId", response[0].CurrentAlbumID)
    try {
      const responseAlbumMedia = yield call(addNewAlbumMedia, photoData)
      yield put(addAlbumMediaSuccess(responseAlbumMedia[0]))
    } catch (error) {
      yield put(addAlbumMediaFail(error))
      toast.error("File upload Failed", { autoClose: 2000 })
    }

    toast.success("Album added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addAlbumFail(error))
    toast.error("Album addition failed", { autoClose: 2000 })
  }
}

function* fetchAlbumDetail({ albumId }) {
  try {
    const response = yield call(getAlbumDetail, albumId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getAlbumDetailSuccess(result))
  } catch (error) {
    yield put(getAlbumDetailFail(error))
  }
}

function* onUpdateAlbum({ payload: album }) {
  try {
   
    var photoData = new FormData()
    
    for (let i = 0; i < album.Photo?.length; i++) {
      photoData.append("files"+i, album.Photo[i])
    }
    photoData.append("field", "albummedia")
    delete album.Photo

    const response = yield call(updateAlbum, album)
    let result = response[0]

    yield put(updateAlbumSuccess(result))

    photoData.append("refId", album.AlbumID)
    try {
      const responseAlbumMedia = yield call(addNewAlbumMedia, photoData)
      yield put(addAlbumMediaSuccess(responseAlbumMedia[0]))
    } catch (error) {
      yield put(addAlbumMediaFail(error))
      toast.error("File upload Failed", { autoClose: 2000 })
    }

    toast.success("Album updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateAlbumFail(error))
    toast.error("Album updation failed", { autoClose: 2000 })
  }
}
function* onAddNewAlbumMedia({ payload: albummedia }) {
  try {
    try {
   
      const response = yield call(updateAlbum, album)
      let result = response[0]

      yield put(updateAlbumSuccess(result))
      toast.success("Album updated successfully", { autoClose: 2000 })
    } catch (error) {
      yield put(updateAlbumFail(error))
      toast.error("Album updation failed", { autoClose: 2000 })
    }
  } catch (error) {}
}

function* onDeleteAlbumMedia({ payload: albumMedia }) {
  try {
  
    const response = yield call(deleteAlbumMedia, albumMedia)
    yield put(deleteAlbumMediaSuccess(response))
    toast.success("Album deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteAlbumMediaFail(error))
    toast.error("Album  deletion failed", { autoClose: 2000 })
  }
}

function* onResetAlbum() {
  try {
    yield put(resetAlbumSuccess({}))
  } catch (error) {}
}
function* albumSaga() {
  yield takeEvery(GET_ALBUMS, fetchAlbums)
  yield takeEvery(DELETE_ALBUM, onDeleteAlbum)
  yield takeEvery(ADD_NEW_ALBUM, onAddNewAlbum)
  yield takeEvery(GET_ALBUM_DETAIL, fetchAlbumDetail)
  yield takeEvery(UPDATE_ALBUM, onUpdateAlbum)
  yield takeEvery(RESET_ALBUM, onResetAlbum)

  yield takeEvery(ADD_NEW_ALBUMMEDIA, onAddNewAlbumMedia)
  yield takeEvery(DELETE_ALBUMMEDIA, onDeleteAlbumMedia)
}

export default albumSaga
