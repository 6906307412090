import React, { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { Input, Label, FormFeedback, Row, Col } from "reactstrap"
import Switch from "react-switch"
import Flatpickr from "react-flatpickr"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

export default function AssignMultiple(props) {
  const methods = useFormContext()
  const { control, formState, watch, setValue, getValues } = methods
  const { errors } = formState
  const IsMultiple = watch("MultipleDays")

  const daysofweek = [
    {
      DayID: 1,
      Name: "Sun",
    },
    {
      DayID: 2,
      Name: "Mon",
    },
    {
      DayID: 3,
      Name: "Tue",
    },
    {
      DayID: 4,
      Name: "Wed",
    },
    {
      DayID: 5,
      Name: "Thu",
    },
    {
      DayID: 6,
      Name: "Fri",
    },
    {
      DayID: 7,
      Name: "Sat",
    },
  ]

  const [dates, setDates] = useState([])

  const onDaySelect = e => {
    const { value, checked } = e.target

    const existing = [...dates]
    if (checked) {
      existing.push(value)
      setDates(existing)
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      setDates(updatedValue)
    }
  }

  useEffect(() => {
    setValue("DaysOfWeek", dates)
  }, [dates])

  return (
    <Row>
      <Col sm="12">
        <div className="mb-3">
          <Label>Assign Multiple Days</Label>
          <div>
            <Controller
              name="MultipleDays"
              control={control}
              render={({ field }) => (
                <>
                  <Switch
                    {...field}
                    id="MultipleDays"
                    checked={field.value}
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                  />
                </>
              )}
            />
          </div>
        </div>
      </Col>
      {IsMultiple ? (
        <>
          <Col sm="12">
            <div className="mb-3">
              <Label>Days Of The Week</Label>
              <div className="btn-group" role="group">
                {(daysofweek || []).map((day, i) => (
                  <div key={"d" + i}>
                    <input
                      type="checkbox"
                      className="btn-check"
                      value={day.DayID}
                      id={"dvCheck" + i}
                      onChange={onDaySelect}
                      autoComplete="off"
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor={"dvCheck" + i}
                    >
                      {day.Name}
                    </label>
                  </div>
                ))}
              </div>
              {errors?.DaysOfWeek?.message ? (
                <FormFeedback type="invalid" className="d-block">
                  {errors?.DaysOfWeek?.message}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label>Start Date</Label>
              <Controller
                name="StartDate"
                control={control}
                render={({ field }) => (
                  <>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="StartDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("StartDate", dateStr)
                      }}
                      required
                    />
                    {errors?.StartDate?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.StartDate?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label>End Date (Optional)</Label>
              <Controller
                name="EndDate"
                control={control}
                render={({ field }) => (
                  <>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="EndDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("EndDate", dateStr)
                      }}
                      required
                    />
                    {errors?.EndDate?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.EndDate?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
        </>
      ) : (
        ""
      )}
    </Row>
  )
}
