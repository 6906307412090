import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import classnames from "classnames"
import { EnumObjectTypes } from "helpers/enum_helper"
import { getAuthUser } from "helpers/token_helper"
import Document from "components/Common/Document"
import { ToastContainer } from "react-toastify"

const CenterDocument = props => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})

  //meta title
  document.title = "Documents | " + process.env.REACT_APP_NAME

  useEffect(() => {
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t("Documents")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Document
                    objectID={0}
                    objectTypeID={EnumObjectTypes.CenterAdmin}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

CenterDocument.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CenterDocument)
