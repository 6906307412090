import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Room Redux States
import {
  GET_ROOMS,
  DELETE_ROOM,
  ADD_NEW_ROOM,
  GET_ROOM_DETAIL,
  UPDATE_ROOM,
  RESET_ROOM,
} from "./actionTypes"
import {
  getRoomsFail,
  getRoomsSuccess,
  deleteRoomSuccess,
  deleteRoomFail,
  addRoomSuccess,
  addRoomFail,
  getRoomDetailSuccess,
  getRoomDetailFail,
  updateRoomSuccess,
  updateRoomFail,
  resetRoomSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRooms,
  deleteRoom,
  addNewRoom,
  getRoomDetail,
  updateRoom,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchRooms() {
  try {
    const response = yield call(getRooms)
    yield put(getRoomsSuccess(response))
  } catch (error) {
    yield put(getRoomsFail(error))
  }
}

function* onDeleteRoom({ payload: room }) {
  try {
    const response = yield call(deleteRoom, room)
    yield put(deleteRoomSuccess(response))
    toast.success("Room deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteRoomFail(error))
    toast.error("Room deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewRoom({ payload: room }) {
  try {
    const response = yield call(addNewRoom, room)
    yield put(addRoomSuccess(response[0]))
    toast.success("Room added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addRoomFail(error))
    toast.error("Room addition failed", { autoClose: 2000 })
  }
}

function* fetchRoomDetail({ roomId }) {
  try {
    const response = yield call(getRoomDetail, roomId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getRoomDetailSuccess(result))
  } catch (error) {
    yield put(getRoomDetailFail(error))
  }
}

function* onUpdateRoom({ payload: room }) {
  try {
    const response = yield call(updateRoom, room)
    let result = response[0]

    yield put(updateRoomSuccess(result))
    toast.success("Room updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateRoomFail(error))
    toast.error("Room updation failed", { autoClose: 2000 })
  }
}

function* onResetRoom() {
  try {
    yield put(resetRoomSuccess({}))
  } catch (error) {}
}
function* roomSaga() {
  yield takeEvery(GET_ROOMS, fetchRooms)
  yield takeEvery(DELETE_ROOM, onDeleteRoom)
  yield takeEvery(ADD_NEW_ROOM, onAddNewRoom)
  yield takeEvery(GET_ROOM_DETAIL, fetchRoomDetail)
  yield takeEvery(UPDATE_ROOM, onUpdateRoom)
  yield takeEvery(RESET_ROOM, onResetRoom)
}

export default roomSaga
