import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getFlexibles as onGetFlexibles,
  updateFlexibleSwitch as onUpdateFlexibleSwitch,
  getFlexibleDetail as onGetFlexibleDetail,
  deleteFlexible as onDeleteFlexible,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
import HourModal from "./modal/HourModal"
import LinkControl from "components/Common/LinkControl"
import DailyModal from "./modal/DailyModal"
import DefaultModal from "./modal/DefaultModal"
// import EnrollModal from "./modal/EnrollModal"

function Flexibility() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [deleteModal, setDeleteModal] = useState(false)
  const [hourModal, setHourModal] = useState(false)
  const [dailyModal, setDailyModal] = useState(false)
  const [defaultModal, setDefaultModal] = useState(false)
  const [data, setData] = useState(false)
  const [edit, setEdit] = useState(false)
  const [dailySwitch, setDailySwitch] = useState(false)
  const [lateSwitch, setLateSwitch] = useState(false)
  const [defaultHeader, setDefaultHeader] = useState("Hourly")

  useDeepCompareEffect(() => {
    dispatch(onGetFlexibles())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { flexibles, loading } = useSelector(state => state.flexible)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  useEffect(() => {
    if (
      flexibles.filter(c => {
        return c.ProgramPayRateTypeID === 2 && c.DailyRateApplicable
      }).length > 0
    ) {
      setDailySwitch(true)
    }

    if (
      flexibles.filter(c => {
        return c.ProgramPayRateTypeID === 3 && c.LateFeesApplicable
      }).length > 0
    ) {
      setLateSwitch(true)
    }
  }, [flexibles])

  const handleAddHourlyRateClick = () => {
    setEdit(false)
    setHourModal(true)
  }

  const handleAddDailyRateClick = () => {
    setEdit(false)
    setDailyModal(true)
  }

  const onClickDefaultRate = (arg, header) => {
    dispatch(onGetFlexibleDetail(arg))
    setDefaultHeader(header)
    setDefaultModal(true)
  }

  const onClickDailyEdit = arg => {
    dispatch(onGetFlexibleDetail(arg))
    setEdit(true)
    setDailyModal(true)
  }

  const onClickHourlyEdit = arg => {
    dispatch(onGetFlexibleDetail(arg))
    setEdit(true)
    setHourModal(true)
  }

  const onClickDelete = arg => {
    setData(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
    setHourModal(false)
    setDailyModal(false)
    setDefaultModal(false)
  }

  const handleDelete = () => {
    if (data && data.ProgramPayRateID) {
      dispatch(onDeleteFlexible(data.ProgramPayRateID))
      setDeleteModal(false)
    }
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {hourModal && (
        <HourModal
          show={hourModal}
          onEdit={edit}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {dailyModal && (
        <DailyModal
          show={dailyModal}
          onEdit={edit}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {defaultModal && (
        <DefaultModal
          show={defaultModal}
          header={defaultHeader}
          onCloseClick={() => onCloseModal()}
        />
      )}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Pricing" breadcrumbItem="Flexible Care" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div>
                      <div className="float-start">
                        <div className="mb-0 text-break white-space-normal h4 text-left">
                          Hourly Visits
                        </div>
                        <div className="text-muted font-weight-light">
                          Hourly visits are charged based on hourly rates
                          provided in this table. If you don't want to charge
                          hourly, set the default rate to $0.00, and don't set
                          special rates.
                        </div>
                        <div className="text-muted font-weight-light">
                          Special rates let you charge different hourly rates
                          based on days of the week, hours of the day. Hourly
                          rates should not overlap. Hourly rates will be accrued
                          by the minute.
                        </div>
                      </div>
                      <div className="float-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2"
                          onClick={handleAddHourlyRateClick}
                        >
                          Add Hourly Rate
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table mb-0 table">
                        {flexibles
                          .filter(c => c.ProgramPayRateTypeID === 1)
                          .map((v, p) => {
                            return (
                              <tbody key={"hourly" + p}>
                                <tr>
                                  <td>
                                    <h5>{v.RateName}</h5>
                                    <div
                                      hidden={v.IsSystem}
                                      className="font-weight-normal"
                                    >
                                      {v.WeekDays &&
                                        v.WeekDays.map((f, i) => {
                                          return f.Name + ", "
                                        })}
                                      <span>
                                        {` from ` +
                                          Moment(v.StartTime).format(
                                            "hh:mm A"
                                          ) +
                                          " to " +
                                          Moment(v.EndTime).format("hh:mm A")}
                                      </span>
                                    </div>
                                    <span>{v.Description}</span>
                                  </td>
                                  <td className="text-center" width="10%">
                                    <h5>{`$` + v.Price.toFixed(2)}</h5>
                                    <span>All</span>
                                  </td>
                                  <td hidden={!v.IsSystem} width="5%">
                                    <Button
                                      onClick={() => {
                                        onClickDefaultRate(v.RowID, "Hourly")
                                      }}
                                    >
                                      Change
                                    </Button>
                                  </td>
                                  <td hidden={v.IsSystem} width="5%">
                                    <div className="d-flex gap-3">
                                      <LinkControl
                                        linkClassName="text-success"
                                        mdiClassName="mdi mdi-pencil font-size-18"
                                        linkText="Edit"
                                        linkTooltip="edittooltip"
                                        onClick={() => {
                                          onClickHourlyEdit(v.RowID)
                                        }}
                                      />
                                      <LinkControl
                                        linkClassName="text-danger"
                                        mdiClassName="mdi mdi-delete font-size-18"
                                        linkText="Delete"
                                        linkTooltip="deletetooltip"
                                        onClick={() => {
                                          onClickDelete(v)
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            )
                          })}
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <div className="float-start">
                        <div className="mb-0 text-break white-space-normal h4 text-left">
                          Daily Visits{" "}
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={() => {
                              setDailySwitch(!dailySwitch)
                              dispatch(onUpdateFlexibleSwitch(!dailySwitch, 2))
                            }}
                            checked={dailySwitch}
                          />
                        </div>
                        <div className="text-muted font-weight-light">
                          Special rates let you charge different daily rates
                          based on days of the week or child ages. Special daily
                          rates should not overlap.
                        </div>
                      </div>
                      <div className="float-end">
                        <Button
                          hidden={!dailySwitch}
                          type="button"
                          color="primary"
                          className="btn me-2"
                          onClick={handleAddDailyRateClick}
                        >
                          Add Daily Rate
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {dailySwitch ? (
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0 table">
                          {flexibles
                            .filter(c => c.ProgramPayRateTypeID === 2)
                            .map((v, p) => {
                              return (
                                <tbody key={"daily" + p}>
                                  <tr>
                                    <td>
                                      <h5>{v.RateName}</h5>
                                      <div
                                        hidden={v.IsSystem}
                                        className="font-weight-normal"
                                      >
                                        {v.WeekDays &&
                                          v.WeekDays.map((f, i) => {
                                            return f.Name + ", "
                                          })}
                                      </div>
                                      <span>{v.Description}</span>
                                    </td>
                                    <td className="text-center" width="10%">
                                      <h5>{`$` + v.Price.toFixed(2)}</h5>
                                      <span>All</span>
                                    </td>
                                    <td hidden={!v.IsSystem} width="5%">
                                      <Button
                                        onClick={() => {
                                          onClickDefaultRate(v.RowID, "Daily")
                                        }}
                                      >
                                        Change
                                      </Button>
                                    </td>
                                    <td hidden={v.IsSystem} width="5%">
                                      <div className="d-flex gap-3">
                                        <Link
                                          to="#"
                                          className="text-success"
                                          onClick={() => {
                                            onClickDailyEdit(v.RowID)
                                          }}
                                        >
                                          <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="edittooltip"
                                          >
                                            Edit
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() => {
                                            onClickDelete(v)
                                          }}
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="deletetooltip"
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
                <Card>
                  <CardBody>
                    <div>
                      <div className="float-start">
                        <div className="mb-0 text-break white-space-normal h4 text-left">
                          Late Fees{" "}
                          <Switch
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            className="me-1 mb-sm-8 mb-2"
                            onColor="#626ed4"
                            onChange={() => {
                              setLateSwitch(!lateSwitch)
                              dispatch(onUpdateFlexibleSwitch(!lateSwitch, 3))
                            }}
                            checked={lateSwitch}
                          />
                        </div>
                        <div className="text-muted font-weight-light">
                          Attendance rate to charge when the child is
                          checked-out after center's operating hours. Hourly
                          rates will be accrued by the minute.
                        </div>
                      </div>
                      <div className="float-end"></div>
                    </div>
                  </CardBody>
                </Card>
                {lateSwitch ? (
                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <Table className="table mb-0 table">
                          {flexibles
                            .filter(c => c.ProgramPayRateTypeID === 3)
                            .map((v, p) => {
                              return (
                                <tbody key={"late" + p}>
                                  <tr>
                                    <td>
                                      <h5>{v.RateName}</h5>
                                      <span>{v.Description}</span>
                                    </td>
                                    <td className="text-center" width="10%">
                                      <h5>{`$` + v.Price.toFixed(2)}</h5>
                                      <span>All</span>
                                    </td>
                                    <td hidden={!v.IsSystem} width="5%">
                                      <Button
                                        onClick={() => {
                                          onClickDefaultRate(v.RowID, "Late")
                                        }}
                                      >
                                        Change
                                      </Button>
                                    </td>
                                    <td hidden={v.IsSystem} width="5%">
                                      <div className="d-flex gap-3">
                                        <Link
                                          to="#"
                                          className="text-success"
                                          onClick={() => {}}
                                        >
                                          <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="edittooltip"
                                          >
                                            Edit
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() => {}}
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="deletetooltip"
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })}
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Flexibility.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Flexibility
