import {
  GET_CHARGETYPES_SUCCESS,
  GET_CHARGETYPES_FAIL,
  GET_CHARGES_FAIL,
  GET_CHARGES_SUCCESS,
  DELETE_CHARGE_SUCCESS,
  DELETE_CHARGE_FAIL,
  ADD_CHARGE_FAIL,
  ADD_CHARGE_SUCCESS,
  GET_CHARGE_DETAIL_SUCCESS,
  GET_CHARGE_DETAIL_FAIL,
  UPDATE_CHARGE_SUCCESS,
  UPDATE_CHARGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  chargeTypes:[],
  charges: [],
  charge: {},
  error: {},
  loading: true,
}

const Charge = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHARGETYPES_SUCCESS:
      return {
        ...state,
        chargeTypes: action.payload,
        loading: true,
      }
    case GET_CHARGETYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.payload,
        loading: true,
      }
    case GET_CHARGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CHARGE_SUCCESS: {
      const data = {
        ...state,
        charges: state.charges.filter(
          charge => charge.ChargeID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        charges: state.charges.filter(
          charge => charge.ChargeID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_CHARGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHARGE_SUCCESS:
      return {
        ...state,
        charges: [...state.charges, action.payload],
      }
    case ADD_CHARGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHARGE_DETAIL_SUCCESS:
      return {
        ...state,
        charge: action.payload,
        loading: true,
      }

    case GET_CHARGE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CHARGE_SUCCESS:
      return {
        ...state,
        charges: state.charges.map(charge =>
          charge.ChargeID.toString() === action.payload.ChargeID.toString()
            ? { charge, ...action.payload }
            : charge
        ),
      }
    case UPDATE_CHARGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Charge
