import { call, put, takeEvery } from "redux-saga/effects"

// Program Redux States
import {
  GET_PROGRAMS,
  DELETE_PROGRAM,
  ADD_NEW_PROGRAM,
  GET_PROGRAM_DETAIL,
  UPDATE_PROGRAM,
  GET_PROGRAM_SCHEDULE_DETAIL,
  ADD_ENROLLMENT,
  GET_ENROLLMENTS,
  UPDATE_ENROLLMENT,
  GET_ENROLLMENT_DETAIL,
} from "./actionTypes"
import {
  getProgramsFail,
  getProgramsSuccess,
  deleteProgramSuccess,
  deleteProgramFail,
  addProgramSuccess,
  addProgramFail,
  getProgramDetailSuccess,
  getProgramDetailFail,
  updateProgramSuccess,
  updateProgramFail,
  getProgramScheduleDetailSuccess,
  getProgramScheduleDetailFail,
  addEnrollmentSuccess,
  addEnrollmentFail,
  getEnrollmentsSuccess,
  getEnrollmentsFail,
  updateEnrollmentSuccess,
  updateEnrollmentFail,
  getEnrollmentDetailSuccess,
  getEnrollmentDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPrograms,
  deleteProgram,
  addNewProgram,
  getProgramDetail,
  updateProgram,
  addEnrollment,
  getEnrollments,
  updateEnrollment,
  getEnrollmentDetail,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchPrograms() {
  try {
    const response = yield call(getPrograms)
    yield put(getProgramsSuccess(response))
  } catch (error) {
    yield put(getProgramsFail(error))
  }
}

function* onDeleteProgram({ payload: programId }) {
  try {
    console.log(programId)
    const response = yield call(deleteProgram, programId)
    yield put(deleteProgramSuccess(response))
    toast.success("Program deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProgramFail(error))
    toast.error("Program deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewProgram({ payload: program }) {
  try {
    const response = yield call(addNewProgram, program)
    yield put(addProgramSuccess(response[0]))
    toast.success("Program added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addProgramFail(error))
    toast.error("Program addition failed", { autoClose: 2000 })
  }
}

function* fetchProgramDetail({ programId }) {
  try {
    const response = yield call(getProgramDetail, programId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getProgramDetailSuccess(result))
  } catch (error) {
    yield put(getProgramDetailFail(error))
  }
}

function* onUpdateProgram({ payload: program }) {
  try {
    const response = yield call(updateProgram, program)
    let result = response[0]

    yield put(updateProgramSuccess(result))
    toast.success("Program updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateProgramFail(error))
    toast.error("Program updation failed", { autoClose: 2000 })
  }
}

function* fetchProgramScheduleDetail({
  payload: { programId, programScheduleId },
}) {
  try {
    const response = yield call(getProgramDetail, programId)
    let result = response[0]
    result = {
      ...result,
      ProgramSchedules: result.ProgramSchedules.filter(
        e => e.ProgramScheduleID == programScheduleId
      ),
    }
    yield put(getProgramScheduleDetailSuccess(result))
  } catch (error) {
    yield put(getProgramScheduleDetailFail(error))
  }
}

function* onAddEnrollment({ payload: enrollment }) {
  try {
    const response = yield call(addEnrollment, enrollment)
    yield put(addEnrollmentSuccess(response))
    toast.success("Enrollment added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addEnrollmentFail(error))
    toast.error("Enrollment addition failed", { autoClose: 2000 })
  }
}

function* fetchEnrollments({ payload: childId }) {
  try {
    const response = yield call(getEnrollments, childId)
    yield put(getEnrollmentsSuccess(response))
  } catch (error) {
    yield put(getEnrollmentsFail(error))
  }
}

function* fetchEnrollmentDetail({ payload: programEnrollmentID }) {
  try {
    const response = yield call(getEnrollmentDetail, programEnrollmentID)
    yield put(getEnrollmentDetailSuccess(response))
  } catch (error) {
    yield put(getEnrollmentDetailFail(error))
  }
}

function* onUpdateEnrollment({ payload: enrollment }) {
  try {
    const response = yield call(updateEnrollment, enrollment)
    yield put(updateEnrollmentSuccess(response))
  } catch (error) {
    yield put(updateEnrollmentFail(error))
  }
}

function* programSaga() {
  yield takeEvery(GET_PROGRAMS, fetchPrograms)
  yield takeEvery(DELETE_PROGRAM, onDeleteProgram)
  yield takeEvery(ADD_NEW_PROGRAM, onAddNewProgram)
  yield takeEvery(GET_PROGRAM_DETAIL, fetchProgramDetail)
  yield takeEvery(UPDATE_PROGRAM, onUpdateProgram)
  yield takeEvery(GET_PROGRAM_SCHEDULE_DETAIL, fetchProgramScheduleDetail)
  yield takeEvery(ADD_ENROLLMENT, onAddEnrollment)
  yield takeEvery(GET_ENROLLMENTS, fetchEnrollments)
  yield takeEvery(GET_ENROLLMENT_DETAIL, fetchEnrollmentDetail)
  yield takeEvery(UPDATE_ENROLLMENT, onUpdateEnrollment)
}

export default programSaga
