import hours from "../components/Common/Duration/hours.json"
import minutes from "../components/Common/Duration/minutes.json"

const getHours = () => {
  return hours
}

const getMinutes = () => {
  return minutes
}
const getHour = hh => {
  return getHours().filter(hour => hour.hh == hh)[0]
}
const getMinute = mm => {

  return getMinutes().filter(minute => minute.mm == mm)[0]
}
export { getHours, getMinutes, getHour, getMinute}
