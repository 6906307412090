import {
  GET_ADDONS,
  GET_ADDONS_FAIL,
  GET_ADDONS_SUCCESS,
  DELETE_ADDON,
  DELETE_ADDON_FAIL,
  DELETE_ADDON_SUCCESS,
  ADD_ADDON,
  ADD_ADDON_SUCCESS,
  ADD_ADDON_FAIL,
  GET_ADDON_DETAIL,
  GET_ADDON_DETAIL_SUCCESS,
  GET_ADDON_DETAIL_FAIL,
  UPDATE_ADDON,
  UPDATE_ADDON_SUCCESS,
  UPDATE_ADDON_FAIL

} from "./actionTypes"


// Add-ons


export const getAddOns = () => ({
  type: GET_ADDONS,
})

export const getAddOnsSuccess = addons => ({
  type: GET_ADDONS_SUCCESS,
  payload: addons,
})

export const getAddOnsFail = error => ({
  type: GET_ADDONS_FAIL,
  payload: error,
})

export const deleteAddOn = addOnID => ({
  type: DELETE_ADDON,
  payload: addOnID,
})

export const deleteAddOnSuccess = addon => ({
  type: DELETE_ADDON_SUCCESS,
  payload: addon,
})

export const deleteAddOnFail = error => ({
  type: DELETE_ADDON_FAIL,
  payload: error,
})


export const addAddOn = addon => ({
  type: ADD_ADDON,
  payload: addon,
})

export const addAddOnSuccess = addon => ({
  type: ADD_ADDON_SUCCESS,
  payload: addon,
})

export const addAddOnFail = error => ({
  type: ADD_ADDON_FAIL,
  payload: error,
})


export const getAddOnDetail = RowID => ({
  type: GET_ADDON_DETAIL,
  RowID,
})

export const getAddOnDetailSuccess = addon => ({
  type: GET_DETAIL_SUCCESS,
  payload: addon,
})

export const getAddOnDetailFail = error => ({
  type: GET_ADDON_DETAIL_FAIL,
  payload: error,
})

export const updateAddOn = addon => ({
  type: UPDATE_ADDON,
  payload: addon,
})

export const updateAddOnSuccess = addon => ({
  type: UPDATE_ADDON_SUCCESS,
  payload: addon,
})

export const updateAddOnFail = error => ({
  type: UPDATE_ADDON_FAIL,
  payload: error,
})
