import {
  GET_FORUMS_FAIL,
  GET_FORUMS_SUCCESS,
  DELETE_FORUM_SUCCESS,
  DELETE_FORUM_FAIL,
  ADD_FORUM_FAIL,
  ADD_FORUM_SUCCESS,
  GET_FORUM_DETAIL_SUCCESS,
  GET_FORUM_DETAIL_FAIL,
  UPDATE_FORUM_SUCCESS,
  UPDATE_FORUM_FAIL,
  RESET_FORUM_SUCCESS,
  GET_FORUM_VISIBILITY_TYPES_SUCCESS,
  GET_FORUM_VISIBILITY_TYPES_FAIL,
  DELETE_FORUM_POST_SUCCESS,
  DELETE_FORUM_POST_FAIL,
  ADD_FORUM_POST_FAIL,
  ADD_FORUM_POST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  forums: [],
  forum: {},
  forumVisibilityTypes: [],
  error: {},
  loading: true,
}

const Forum = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FORUMS_SUCCESS:
      return {
        ...state,
        forums: action.payload,
        loading: true,
      }
    case GET_FORUMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_FORUM_SUCCESS: {
      return {
        ...state,
        forums: state.forums.filter(forum => forum.ForumID !== action.payload),
      }
    }
    case DELETE_FORUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_FORUM_SUCCESS:
      return {
        ...state,
        forums: [...state.forums, action.payload],
      }
    case ADD_FORUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FORUM_DETAIL_SUCCESS:
      return {
        ...state,
        forum: action.payload,
        loading: true,
      }

    case GET_FORUM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_FORUM_SUCCESS:
      return {
        ...state,
        forums: state.forums.map(forum =>
          forum.ForumID.toString() === action.payload.ForumID.toString()
            ? { forum, ...action.payload }
            : forum
        ),
      }

    case UPDATE_FORUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_FORUM_SUCCESS:
      return {
        ...state,
        forum: action.payload,
      }
    case GET_FORUM_VISIBILITY_TYPES_SUCCESS:
      return {
        ...state,
        forumVisibilityTypes: action.payload,
        loading: true,
      }
    case GET_FORUM_VISIBILITY_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_FORUM_POST_SUCCESS: {
      return {
        ...state,
        forum: action.payload,
      }
    }
    case DELETE_FORUM_POST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_FORUM_POST_SUCCESS:
      return {
        ...state,
        forum: action.payload,
      }
    case ADD_FORUM_POST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Forum
