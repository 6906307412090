import {
  GET_ADDONS_FAIL,
  GET_ADDONS_SUCCESS,
  DELETE_ADDON_SUCCESS,
  DELETE_ADDON_FAIL,
  ADD_ADDON_FAIL,
  ADD_ADDON_SUCCESS,
  GET_ADDON_DETAIL_SUCCESS,
  GET_ADDON_DETAIL_FAIL,
  UPDATE_ADDON_SUCCESS,
  UPDATE_ADDON_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  addons: [],
  addon: {},
  error: {},
  loading: true,
}

const AddOn = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADDONS_SUCCESS:
      return {
        ...state,
        addons: action.payload,
        loading: true,
      }
    case GET_ADDONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_ADDON_SUCCESS: {
      const data = {
        ...state,
        addons: state.addons.filter(
          addon => addon.AddOnID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        addons: state.addons.filter(
          addon => addon.AddOnID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_ADDON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ADDON_SUCCESS:
      return {
        ...state,
        addons: [...state.addons, action.payload],
      }
    case ADD_ADDON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ADDON_DETAIL_SUCCESS:
      return {
        ...state,
        addon: action.payload,
        loading: true,
      }

    case GET_ADDON_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ADDON_SUCCESS:
      return {
        ...state,
        addons: state.addons.map(addon =>
          addon.AddOnID.toString() === action.payload.AddOnID.toString()
            ? { addon, ...action.payload }
            : addon
        ),
      }
    case UPDATE_ADDON_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default AddOn
