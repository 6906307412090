/*Get DOCUMENT */
export const GET_DOCUMENTS = "GET_DOCUMENTS"
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS"
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL"

/**
 * Delete DOCUMENT
 */
export const DELETE_DOCUMENT = "DELETE_DOCUMENT"
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS"
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL"

/**
 * Add DOCUMENT
 */
export const ADD_NEW_DOCUMENT = "ADD_NEW_DOCUMENT"
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS"
export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL"

/*Get DOCUMENT PRIVACY TYPES */
export const GET_PRIVACY_TYPES = "GET_PRIVACY_TYPES"
export const GET_PRIVACY_TYPES_SUCCESS = "GET_PRIVACY_TYPES_SUCCESS"
export const GET_PRIVACY_TYPES_FAIL = "GET_PRIVACY_TYPES_FAIL"
