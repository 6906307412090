import {
  GET_BUS_SCHEDULES_FAIL,
  GET_BUS_SCHEDULES_SUCCESS,
  RESET_BUS_SCHEDULES_SUCCESS,
  ADD_BUS_SCHEDULES_SUCCESS,
  ADD_BUS_SCHEDULES_FAIL,
  DELETE_BUS_SCHEDULE_SUCCESS,
  DELETE_BUS_SCHEDULE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  busSchedules: [],
  column: [],
  error: {},
  loading: true,
}

const Schedules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BUS_SCHEDULES_SUCCESS:
      return {
        ...state,
        busSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    case GET_BUS_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BUS_SCHEDULES_SUCCESS:
      return {
        ...state,
        busSchedules: action.payload,
      }
    case ADD_BUS_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_BUS_SCHEDULES_SUCCESS:
      return {
        ...state,
        busSchedules: action.payload,
        column: [],
        loading: true,
      }
    case DELETE_BUS_SCHEDULE_SUCCESS: {
      return {
        ...state,
        busSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    }
    case DELETE_BUS_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Schedules
