import { HandleValidDate, FormatDate } from "helpers/method_helper"
import React from "react"
import { Link } from "react-router-dom"
import { Row, Col, UncontrolledTooltip } from "reactstrap"
import ProfilePicture from "components/Common/ProfilePicture"
import { Calculate_Age } from "helpers/method_helper"
import { EnumObjectTypes } from "helpers/enum_helper"
import Moment from "moment"

const ChildrenDailyColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original.DateOfBirth)}
        />
      )
    },
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenWeeklyColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original.DateOfBirth)}
        />
      )
    },
  },
  {
    Header: "Date",
    accessor: "HappeningDate",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const StaffDailyColumns = [
  {
    Header: "Staff",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Staff}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={cellProps.row.original.RoleType}
        />
      )
    },
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenAttendanceColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original.DateOfBirth)}
        />
      )
    },
  },
  {
    Header: "Date",
    accessor: "HappeningDate",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const ChildrenAllergiesColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original.DateOfBirth)}
        />
      )
    },
  },
  {
    Header: "Home Class",
    accessor: "HomeClass",
  },
  {
    Header: "Allergy Tags",
    accessor: "Tags",
    Cell: cellProps => {
      return (
        cellProps.value &&
        JSON.parse(cellProps.value).map((item, i) => (
          <div key={"tag" + item.TagID}>{item.Title}</div>
        ))
      )
    },
  },
  {
    Header: "Primary Contacts",
    accessor: "PrimaryContacts",
    Cell: cellProps => {
      return (
        cellProps.value &&
        JSON.parse(cellProps.value).map((item, i) => (
          <div key={"primary" + item.ChildRelationID}>
            <ProfilePicture
              ObjectTypeID={EnumObjectTypes.Adult}
              RowID={item.RowID}
              Title={item.Name}
              SubTitle={item.Relation}
            />
          </div>
        ))
      )
    },
  },
  {
    Header: "Emergency Contacts",
    accessor: "EmergencyContacts",
    Cell: cellProps => {
      return (
        cellProps.value &&
        JSON.parse(cellProps.value).map((item, i) => (
          <div key={"emergency" + item.ChildRelationID}>
            <ProfilePicture
              ObjectTypeID={EnumObjectTypes.Adult}
              RowID={item.RowID}
              Title={item.Name}
              SubTitle={item.Relation}
            />
          </div>
        ))
      )
    },
  },
]

const ChildrenEmergencyContactsColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return cellProps.row.original.RowID ? (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
        />
      ) : (
        ""
      )
    },
  },
  {
    Header: "Home Class",
    accessor: "HomeClass",
  },
  {
    Header: "Emergency Contact",
    accessor: "AdultName",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Adult}
          RowID={cellProps.row.original.AdultRowID}
          Title={cellProps.row.original.AdultName}
          SubTitle={cellProps.row.original.Relation}
        />
      )
    },
  },
  {
    Header: "Phone",
    accessor: "PhoneNumber",
  },
  {
    Header: "Email",
    accessor: "EmailAddress",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
]

const StaffEmergencyContactsColumns = [
  {
    Header: "Staff",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Staff}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={cellProps.row.original.RoleType}
        />
      )
    },
  },
  {
    Header: "Emergency Contact",
    accessor: "ContactName",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={0}
          RowID=""
          Title={cellProps.row.original.ContactName}
        />
      )
    },
  },
  {
    Header: "Phone",
    accessor: "PhoneNumber",
  },
  {
    Header: "Email",
    accessor: "EmailAddress",
  },
  {
    Header: "Address",
    accessor: "Address",
  },
]

const StaffWeeklyColumns = [
  {
    Header: "Staff",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Staff}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={cellProps.row.original.RoleType}
        />
      )
    },
  },
  {
    Header: "Date",
    accessor: "HappeningDate",
  },
  {
    Header: "Class",
    accessor: "ClassTitle",
  },
  {
    Header: "Check In",
    accessor: "CheckIn",
  },
  {
    Header: "Check Out",
    accessor: "CheckOut",
  },
]

const AbsentChildrenColumns = [
  {
    Header: "Children",
    accessor: "Name",
    Cell: cellProps => {
      return cellProps.row.original.RowID ? (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
        />
      ) : (
        ""
      )
    },
  },
  {
    Header: "Absent Date",
    accessor: "HappeningDate",
  },
]

const AbsentStaffColumns = [
  {
    Header: "Staff",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Staff}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={cellProps.row.original.RoleType}
        />
      )
    },
  },
  {
    Header: "Absent Date",
    accessor: "HappeningDate",
  },
]

const UnpaidServicesColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return cellProps.row.original.RowID ? (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
        />
      ) : (
        ""
      )
    },
  },
  {
    Header: "Adults",
    accessor: "PrimaryContacts",
    Cell: cellProps => {
      return (
        cellProps.value && (
          <Row>
            {JSON.parse(cellProps.value).map((tch, key) => {
              return (
                <Col xl={4} key={key}>
                  <ProfilePicture
                    ObjectTypeID={EnumObjectTypes.Adult}
                    RowID={tch.RowID}
                    Title={tch.Name}
                    SubTitle={tch.Relation}
                  />
                </Col>
              )
            })}
          </Row>
        )
      )
    },
  },
  {
    Header: "Adult Wallet",
    accessor: "Wallet",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
  {
    Header: "Past Due",
    accessor: "DueAmount",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const FinanceColumns = [
  {
    Header: "Created By",
    accessor: row => `${row.ModifiedByName} - ${row.ModifiedDate}`,
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {HandleValidDate(cellProps.row.original.ModifiedDate)}
          </p>
        </>
      )
    },
  },
  {
    Header: "Transaction",
    accessor: row => `${row.ProgramName} - ${row.ChildName}`,
    Cell: cellProps => {
      return (
        <>
          <p
            className="font-size-12 mb-1"
            hidden={cellProps.row.original.TransactionTypeID === 0}
          >
            Wallet Load
          </p>
          <p
            className="font-size-12 mb-1"
            hidden={cellProps.row.original.ProgramID >= 0}
          >
            {cellProps.row.original.ProgramName}{" "}
            {/* <Link
              to="#"
              className="text-success"
               onClick={() => {
                 onMixedModalClick(cellProps.row.original.FeeTransactionID)
               }}
            >
              <i
                className="mdi mdi-eye-outline font-size-18"
                id="deletetooltip"
              />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                View Detail
              </UncontrolledTooltip>
            </Link> */}
          </p>
          <p
            className="font-size-12 mb-1"
            hidden={cellProps.row.original.ProgramID <= 0}
          >
            {cellProps.row.original.ProgramName +
              ", " +
              cellProps.row.original.DayID +
              " days"}
            <div className="font-size-10 mb-1">
              {Moment(cellProps.row.original.StartDate).format(
                "DD MMM Y, hh:mm A"
              )}
              {" - "}
              {Moment(cellProps.row.original.EndDate).format(
                "DD MMM Y, hh:mm A"
              )}
            </div>
          </p>
        </>
      )
    },
  },
  {
    Header: "Child",
    accessor: "ChildName",
    Cell: cellProps => {
      return cellProps.row.original.ChildRowID ? (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.ChildRowID}
          Title={cellProps.row.original.ChildName}
          SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
        />
      ) : (
        ""
      )
    },
  },
  {
    Header: "Payer",
    accessor: "Payer",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Adult}
          RowID={cellProps.row.original.PayerRowID}
          Title={cellProps.row.original.Payer}
          SubTitle={cellProps.row.original.Relation}
        />
      )
    },
  },
  {
    Header: "Transaction Type",
    accessor: "TransactionType",
    Cell: cellProps => {
      return cellProps.value ? cellProps.value : ""
    },
  },
  {
    Header: "Payment method",
    accessor: "PaymentMethod",
    Cell: cellProps => {
      return (
        <>
          <span>{cellProps.row.original.PaymentMethod}</span>
          <p
            hidden={
              cellProps.row.original.PaymentMethodID === 1 ||
              cellProps.row.original.PaymentMethodID === 4
            }
            className="font-size-10 mb-1"
          >
            {"Ref No. " + cellProps.row.original.ReferenceNumber}
          </p>
          <div className="font-size-12 mb-1">
            {"by " + cellProps.row.original.ModifiedByName}
          </div>
        </>
      )
    },
  },
  {
    Header: "Amount",
    accessor: "Amount",
    Cell: cellProps => {
      return "$" + Number(cellProps.value).toFixed(2)
    },
  },
  {
    Header: "Status",
    accessor: "TransactionStatus",
    Cell: cellProps => {
      return cellProps.value ? cellProps.value : ""
    },
  },
]

const WalletBalanceColumns = [
  {
    Header: "Adult",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Adult}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
        />
      )
    },
  },
  {
    Header: "Wallet Balance",
    accessor: "Wallet",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const WalletColumns = [
  {
    Header: "Transaction Type",
    accessor: "TransactionType",
  },
  {
    Header: "Transactions",
    accessor: "Transactions",
    Cell: cellProps => {
      return Number(cellProps.value) > 0 ? `${Number(cellProps.value)}` : ""
    },
  },
  {
    Header: "Amount",
    accessor: "Balance",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const PaymentColumns = [
  {
    Header: "Payment",
    accessor: "TransactionType",
  },
  {
    Header: "Transactions",
    accessor: "Transactions",
    Cell: cellProps => {
      return Number(cellProps.value) > 0 ? `${Number(cellProps.value)}` : ""
    },
  },
  {
    Header: "Amount",
    accessor: "Balance",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const ProgramChargesColumns = [
  {
    Header: "Program",
    accessor: "ProgramName",
  },
  {
    Header: "Service Period",
    accessor: "Period",
  },
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return (
        <ProfilePicture
          ObjectTypeID={EnumObjectTypes.Children}
          RowID={cellProps.row.original.RowID}
          Title={cellProps.row.original.Name}
          SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
        />
      )
    },
  },
  {
    Header: "Amount",
    accessor: "Amount",
    Cell: cellProps => {
      return `$${Number(cellProps.value).toFixed(2)}`
    },
  },
]

const DocExpColumns = [
  {
    Header: "User",
    accessor: "Name",
    Cell: cellProps => {
      return (
        cellProps.value &&
        (cellProps.row.original.UserRoleID === 2 ? (
          <ProfilePicture
            ObjectTypeID={EnumObjectTypes.Adult}
            RowID={cellProps.row.original.RowID}
            Title={cellProps.row.original.Name}
          />
        ) : cellProps.row.original.UserRoleID === 3 ? (
          <ProfilePicture
            ObjectTypeID={EnumObjectTypes.Children}
            RowID={cellProps.row.original.RowID}
            Title={cellProps.row.original.Name}
            SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
          />
        ) : (
          <ProfilePicture
            ObjectTypeID={EnumObjectTypes.Staff}
            RowID={cellProps.row.original.RowID}
            Title={cellProps.row.original.Name}
            SubTitle={cellProps.row.original.RoleType}
          />
        ))
      )
    },
  },
  {
    Header: "User Role",
    accessor: "UserRoleID",
    Cell: cellProps => {
      return Number(cellProps.value) === 2
        ? "Adult"
        : Number(cellProps.value) === 3
        ? "Child"
        : "Staff"
    },
  },
  {
    Header: "Document",
    accessor: "Document",
  },
  {
    Header: "Uploaded",
    accessor: row => `${row.ModifiedByName} - ${row.ModifiedDate}`,
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {HandleValidDate(cellProps.row.original.ModifiedDate)}
          </p>
          <div className="font-size-12 mb-1">
            {"by " + cellProps.row.original.ModifiedByName}
          </div>
        </>
      )
    },
  },
  {
    Header: "Expiration Day",
    accessor: "ExpiryDate",
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {cellProps.value ? FormatDate(cellProps.value) : ""}
          </p>
        </>
      )
    },
  },
  {
    Header: "Expiration Day Ago",
    accessor: "ExpireDaysAgo",
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {cellProps.row.original.ExpireDaysAgo > 0
              ? cellProps.row.original.ExpireDaysAgo + " days"
              : ""}
          </p>
        </>
      )
    },
  },
]

const TagExpChildrenColumns = [
  {
    Header: "Child",
    accessor: "Name",
    Cell: cellProps => {
      return (
        cellProps.value && (
          <ProfilePicture
            ObjectTypeID={EnumObjectTypes.Children}
            RowID={cellProps.row.original.RowID}
            Title={cellProps.row.original.Name}
            SubTitle={Calculate_Age(cellProps.row.original?.DateOfBirth)}
          />
        )
      )
    },
  },
  {
    Header: "Tag",
    accessor: "Tags",
  },
  {
    Header: "Category",
    accessor: "Category",
  },
  {
    Header: "Added By",
    accessor: row => `${row.ModifiedByName} - ${row.ModifiedDate}`,
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {HandleValidDate(cellProps.row.original.ModifiedDate)}
          </p>
          <div className="font-size-12 mb-1">
            {"by " + cellProps.row.original.ModifiedByName}
          </div>
        </>
      )
    },
  },
  {
    Header: "Expiration Day",
    accessor: "ExpiryDate",
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {cellProps.value ? FormatDate(cellProps.value) : ""}
          </p>
        </>
      )
    },
  },
  {
    Header: "Expiration Day Ago",
    accessor: "ExpireDaysAgo",
    Cell: cellProps => {
      return (
        <>
          <p className="text-muted mb-0">
            {cellProps.row.original.ExpireDaysAgo > 0
              ? cellProps.row.original.ExpireDaysAgo + " days"
              : ""}
          </p>
        </>
      )
    },
  },
]

export {
  ChildrenDailyColumns,
  ChildrenWeeklyColumns,
  StaffDailyColumns,
  ChildrenAttendanceColumns,
  ChildrenAllergiesColumns,
  ChildrenEmergencyContactsColumns,
  StaffEmergencyContactsColumns,
  StaffWeeklyColumns,
  AbsentChildrenColumns,
  AbsentStaffColumns,
  UnpaidServicesColumns,
  FinanceColumns,
  WalletBalanceColumns,
  WalletColumns,
  PaymentColumns,
  ProgramChargesColumns,
  DocExpColumns,
  TagExpChildrenColumns,
}
