import React, { useState, useEffect } from "react"

//redux
import { useSelector } from "react-redux"

//Date filter
import Moment from "moment"

import { isEmpty } from "lodash"

function Availability({ staffID }) {
  const [data, setData] = useState([])

  //////////////////ROLE STATE/////////////////////
  const { availabilities } = useSelector(state => state.child)
  ///////////////////////////////////////

  useEffect(() => {
    if (isEmpty(availabilities)) {
      return
    }

    //only linked availabilities
    const filtered = availabilities.filter(e => e.Linked == true)

    setData(filtered)
  }, [availabilities])

  if (isEmpty(data)) {
    return <div>No desired hours set</div>
  }

  return (
    <>
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={`outer${index}`}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div>{item.Availability}</div>
              <div>
                {item.Times &&
                  item.Times.map((inner, i) => {
                    return (
                      <>
                        {Moment(inner.StartTime).format("h:mm a") +
                          "-" +
                          Moment(inner.EndTime).format("h:mm a")}
                        <br />
                      </>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </>
  )
}
export default Availability
