import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
} from "./actionTypes"

export const getCountries = () => ({
  type: GET_COUNTRIES,
})

export const getCountriesSuccess = countries => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
})

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})

export const getStates = countryID => ({
  type: GET_STATES,
  payload: countryID,
})

export const getStatesSuccess = states => ({
  type: GET_STATES_SUCCESS,
  payload: states,
})

export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const getCities = stateID => ({
  type: GET_CITIES,
  payload: stateID,
})

export const getCitiesSuccess = cities => ({
  type: GET_CITIES_SUCCESS,
  payload: cities,
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error,
})
