import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Input,
  FormFeedback,
  InputGroup,
  Badge,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import { isEmpty } from "lodash"

import {
  getChildren as onGetChildren,
  getClasses as onGetClasses,
  addEnrollment as onAddEnrollment,
} from "store/actions"
import PageModal from "components/Common/PageModal"
import Moment from "moment"
import TimesArray from "./TimesArray"

const enrollmenttypes = [
  {
    EnrollmentTypeID: 1,
    EnrollmentType: "Requested",
  },
  {
    EnrollmentTypeID: 2,
    EnrollmentType: "Waitlist",
  },
  {
    EnrollmentTypeID: 3,
    EnrollmentType: "Enrollments",
  },
]

const formdefault = {
  ProgramID: 0,
  DayID: 0,
  ProgramSchedules: [],
}

const EnrollModal = ({ show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [scheduleCount, setScheduleCount] = useState(0)
  const [actualArray, setActualArray] = useState([])
  const [didLoad, setDidLoad] = useState(false)
  const { program } = useSelector(state => state.program)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ProgramID: yup.number(),
    DayID: yup.number(),
    Children: yup
      .array()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    ProgramSchedules: yup.array().of(
      yup.object().shape({
        DayID: yup.number(),
        Name: yup.string(),
        IsLinked: yup.bool(),
        Times: yup.array().of(
          yup.object().shape({
            StartTime: yup.string(),
            EndTime: yup.string(),
          })
        ),
      })
    ),
    EnrollmentTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    IsSchedulePass: yup.string().required("Required"),
    StartDate: yup.string().required("Required"),
    EndDate: yup.string(),
    Classes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState

  const { fields, append, remove, update } = useFieldArray({
    name: "ProgramSchedules",
    control,
  })

  useDeepCompareEffect(() => {
    dispatch(onGetChildren())
    dispatch(onGetClasses())
  }, [dispatch])

  useEffect(() => {
    if (!didLoad && !isEmpty(program)) {
      setScheduleCount(program.ProgramSchedules[0].Days)
      for (let i = 0; i < program.WeekDays.length; i++) {
        append({
          DayID: program.WeekDays[i].DayID,
          Name: program.WeekDays[i].Name,
          IsLinked: false,
          Times: [
            {
              StartTime: "",
              EndTime: "",
            },
          ],
        })
      }
      setDidLoad(true)
    }
  }, [program])

  //////////////////CHILDREN STATE/////////////////////
  const { children } = useSelector(state => state.child)
  //////////////////////////////////////

  //////////////////CLASS STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  //////////////////////////////////////

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      setValue("ProgramID", program.ProgramID)
      setValue("DayID", program.ProgramSchedules[0].Days)
      //console.log(getValues())
      dispatch(onAddEnrollment(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset(formdefault)
    setDidLoad(false)
    setScheduleCount(0)
    setActualArray([])
  }

  function onCheckBoxClick(e) {
    const isChecked = e.target.checked
    if (isChecked) {
      setActualArray(arr => [...arr, e.target.value])
    } else {
      let index = actualArray.indexOf(e.target.value)
      actualArray.splice(index, 1)
      setActualArray(actualArray)
    }
  }

  useEffect(() => {
    if (actualArray.length > 0) {
      if (actualArray.length > scheduleCount) {
        setValue("IsSchedulePass", "")
      } else {
        setValue("IsSchedulePass", "pass")
      }
    } else {
      setValue("IsSchedulePass", "")
    }
  }, [actualArray.length, scheduleCount])

  if (isEmpty(program)) {
    return
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header="Enroll into Program"
    >
      <FormProvider {...methods}>
        <Row>
          <Col lg={12} className="mb-3">
            <h4>
              {program.Name +
                " " +
                program.ProgramSchedules[0].Days +
                (program.ProgramSchedules[0].Days > 1 ? " days" : " day")}
              <Badge className="bg-success ms-1">{program.BillingCycle}</Badge>
              <div>
                {`Price - $` +
                  Math.round(program.ProgramSchedules[0].Price).toFixed(2)}
              </div>
            </h4>
            <div>
              You can pick{" "}
              {program.ProgramSchedules[0].Days +
                (program.ProgramSchedules[0].Days > 1 ? +" days" : " day")}{" "}
              a week within{" "}
              {program.WeekDays.map((f, i) => {
                return f.Name + ", "
              })}
              between
              <span>
                {` from ` +
                  Moment(program.StartTime).format("hh:mm A") +
                  " to " +
                  Moment(program.EndTime).format("hh:mm A")}
              </span>
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label>Children</Label>
              <Controller
                name="Children"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      id="Children"
                      options={children}
                      getOptionLabel={option => option.Name}
                      getOptionValue={option => option.ChildID}
                      required
                      isMulti
                      onChange={(e, { value }) => {
                        const values = e.map(v =>
                          children.find(item => item.ChildID == v.ChildID)
                        )
                        setValue("Children", values)
                      }}
                      aria-invalid={!!errors.Children}
                      classNamePrefix="select2-selection"
                    />
                    {errors?.Children?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.Children?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              {(fields || []).map((s, i) => (
                <>
                  <div key={"d" + i} className="row p-1 my-3">
                    <div className="col-9 d-flex flex-column justify-content-center">
                      <label
                        className="cursor-pointer checkbox mb-0 align-items-start h5 font-weight-normal"
                        htmlFor={"dvCheck" + i}
                      >
                        <Controller
                          control={control}
                          name={`ProgramSchedules[${i}].IsLinked`}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                value={s.DayID}
                                id={`ProgramSchedules[${i}].IsLinked`}
                                type="checkbox"
                                className="form-check-input checkbox-medium me-2"
                                onClick={onCheckBoxClick}
                              />
                            </>
                          )}
                        />
                        <span className="font-weight-normal">{s.Name}</span>
                      </label>
                    </div>
                  </div>
                  {watch(`ProgramSchedules[${i}].IsLinked`) && (
                    <TimesArray key={`timesarray${i}`} nestIndex={i} />
                  )}
                </>
              ))}
              {errors?.IsSchedulePass?.message ? (
                <FormFeedback
                  type="invalid"
                  className="d-block alert alert-danger"
                >
                  <h6>
                    You've selected {actualArray.length} days for an{" "}
                    {scheduleCount} day program
                  </h6>
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label>Start Date</Label>
              <Controller
                name="StartDate"
                control={control}
                render={({ field }) => (
                  <>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="StartDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("StartDate", dateStr)
                      }}
                      required
                    />
                    {errors?.StartDate?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.StartDate?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="mb-3">
              <Label>End Date</Label>
              <Controller
                name="EndDate"
                control={control}
                render={({ field }) => (
                  <>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="EndDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("EndDate", dateStr)
                      }}
                      required
                    />
                    {errors?.EndDate?.message ? (
                      <FormFeedback type="invalid" className="d-block">
                        {errors?.EndDate?.message}
                      </FormFeedback>
                    ) : null}
                  </>
                )}
              />
            </div>
          </Col>
          <div className="mb-3">
            <Label>Assign to Class</Label>
            <Controller
              name="Classes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Classes"
                    options={classes}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.ClassID}
                    required
                    aria-invalid={!!errors.Classes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Classes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Classes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Enrollment Type</Label>
            <Controller
              name="EnrollmentTypes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="EnrollmentTypes"
                    options={enrollmenttypes}
                    getOptionLabel={option => option.EnrollmentType}
                    getOptionValue={option => option.EnrollmentTypeID}
                    required
                    aria-invalid={!!errors.EnrollmentTypes}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.EnrollmentTypes?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.EnrollmentTypes?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
        </Row>
      </FormProvider>
    </PageModal>
  )
}

EnrollModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default EnrollModal
