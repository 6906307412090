import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

//redux
import { useSelector, useDispatch } from "react-redux"

import { getAuthUser } from "helpers/token_helper"
import { getAdultImagePath, getUserImagePath } from "helpers/imageService"

import { getProfile as onGetProfile } from "store/actions"
import { isEmpty } from "lodash"

const ProfileMenu = props => {
  const dispatch = useDispatch()

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [profile, setProfile] = useState({})

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  useEffect(() => {
    if (getAuthUser()) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setProfile(getAuthUser())
      }
    }
  }, [props.success])

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {!isEmpty(userprofile) && (
            <img
              className="rounded-circle header-profile-user"
              src={
                userprofile.RoleID === 1 || userprofile.RoleID === 2
                  ? getUserImagePath(userprofile.RowID, "image.jpeg")
                  : getAdultImagePath(userprofile.RowID, "image.jpeg")
              }
              alt={userprofile.Name}
            />
          )}

          <span className="d-none d-xl-inline-block ms-2 me-1">
            {profile.username}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          <Link to="/settings/changepassword" className="dropdown-item">
            <i className="bx bx-lock-alt font-size-16 align-middle me-1" />
            {props.t("Change password")}
          </Link>
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
