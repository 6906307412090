import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Chat Redux States
import {
  GET_CHATS,
  GET_LATEST_CHAT,
  DELETE_CHAT,
  ADD_NEW_CHAT,
  GET_CHAT_DETAIL,
  UPDATE_CHAT,
  RESET_CHAT,
  DELETE_CHAT_MESSAGE,
  ADD_NEW_CHAT_MESSAGE,
  UPDATE_CHAT_MESSAGE_FILE,
} from "./actionTypes"
import {
  getChatsFail,
  getChatsSuccess,
  getLatestChatFail,
  getLatestChatSuccess,
  deleteChatSuccess,
  deleteChatFail,
  addChatSuccess,
  addChatFail,
  getChatDetailSuccess,
  getChatDetailFail,
  updateChatSuccess,
  updateChatFail,
  resetChatSuccess,
  deleteChatMessageSuccess,
  deleteChatMessageFail,
  addChatMessageSuccess,
  addChatMessageFail,
  updateChatMessageFileSuccess,
  updateChatMessageFileFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getChats,
  getLatestChat,
  deleteChat,
  addNewChat,
  getChatDetail,
  updateChat,
  deleteChatMessage,
  addNewChatMessage,
  updateChatMessageFile,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchChats() {
  try {
    const response = yield call(getChats)
    yield put(getChatsSuccess(response))
  } catch (error) {
    yield put(getChatsFail(error))
  }
}

function* fetchLatestChat() {
  try {
    const response = yield call(getLatestChat)
    yield put(getLatestChatSuccess(response[0]))
  } catch (error) {
    yield put(getLatestChatFail(error))
  }
}

function* onDeleteChat({ payload: chat }) {
  try {
    const response = yield call(deleteChat, chat)
    yield put(deleteChatSuccess(response))
    //toast.success("Chat deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteChatFail(error))
    //toast.error("Chat deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewChat({ payload: chat }) {
  try {
    const response = yield call(addNewChat, chat)
    yield put(addChatSuccess(response[0]))
    //toast.success("Chat added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChatFail(error))
    //toast.error("Chat addition failed", { autoClose: 2000 })
  }
}

function* fetchChatDetail({ payload: chatID }) {
  try {
    const response = yield call(getChatDetail, chatID)
    yield put(getChatDetailSuccess(response[0]))
  } catch (error) {
    yield put(getChatDetailFail(error))
  }
}

function* onUpdateChat({ payload: chat }) {
  try {
    const response = yield call(updateChat, chat)
    let result = response[0]
    yield put(updateChatSuccess(result))
    //toast.success("Chat updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateChatFail(error))
    //toast.error("Chat updation failed", { autoClose: 2000 })
  }
}

function* onResetChat() {
  try {
    yield put(resetChatSuccess({}))
  } catch (error) {}
}

function* onDeleteChatMessage({ payload: chatMessageID }) {
  try {
    const response = yield call(deleteChatMessage, chatMessageID)
    yield put(deleteChatMessageSuccess(response[0]))
    toast.success("Chat Message deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteChatMessageFail(error))
    toast.error("Chat Message deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewChatMessage({ payload: chatMessage }) {
  try {
    var photoData = new FormData()
    photoData.append("files", chatMessage.Photo)
    photoData.append("field", "ChatMessage")
    delete chatMessage.Photo

    const response = yield call(addNewChatMessage, chatMessage)

    yield put(addChatMessageSuccess(response[0]))
    photoData.append("refId", response[0].CurrentChatMessageID)
    try {
      const response1 = yield call(updateChatMessageFile, photoData)
      //let result = response[0]

      yield put(updateChatMessageFileSuccess(response1))
      //toast.success("File Update Successfully", { autoClose: 2000 })
    } catch (error) {
      yield put(updateChatMessageFileFail(error))
      toast.error("File Update Failed", { autoClose: 2000 })
    }

    toast.success("Chat Message added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addChatMessageFail(error))
    toast.error("Chat Message addition failed", { autoClose: 2000 })
  }
}

function* onUpdateChatMessageFile({ payload: chatMessage }) {
  try {
    var fileData = new FormData()
    fileData.append("files", chatMessage.FileName)
    fileData.append("refId", chatMessage.ChatMessageID)
    fileData.append("field", "ChatMessage")
    const response = yield call(updateChatMessageFile, fileData)
    yield put(updateChatMessageFileSuccess(response))
  } catch (error) {
    yield put(updateChatMessageFileFail(error))
    toast.error("File Update Failed", { autoClose: 2000 })
  }
}

function* chatSaga() {
  yield takeEvery(GET_CHATS, fetchChats)
  yield takeEvery(GET_LATEST_CHAT, fetchLatestChat)
  yield takeEvery(DELETE_CHAT, onDeleteChat)
  yield takeEvery(ADD_NEW_CHAT, onAddNewChat)
  yield takeEvery(GET_CHAT_DETAIL, fetchChatDetail)
  yield takeEvery(UPDATE_CHAT, onUpdateChat)
  yield takeEvery(RESET_CHAT, onResetChat)
  yield takeEvery(DELETE_CHAT_MESSAGE, onDeleteChatMessage)
  yield takeEvery(ADD_NEW_CHAT_MESSAGE, onAddNewChatMessage)
  yield takeEvery(UPDATE_CHAT_MESSAGE_FILE, onUpdateChatMessageFile)
}

export default chatSaga
