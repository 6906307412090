import {
  GET_REPORTS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORT_TYPES,
  GET_REPORT_TYPES_SUCCESS,
  GET_REPORT_TYPES_FAIL,
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DETAIL,
  GET_REPORT_DETAIL_SUCCESS,
  GET_REPORT_DETAIL_FAIL,
} from "./actionTypes"

export const getReports = () => ({
  type: GET_REPORTS,
})

export const getReportsSuccess = reports => ({
  type: GET_REPORTS_SUCCESS,
  payload: reports,
})

export const getReportsFail = error => ({
  type: GET_REPORTS_FAIL,
  payload: error,
})

export const getReportDetail = reportID => ({
  type: GET_REPORT_DETAIL,
  payload: reportID,
})

export const getReportDetailSuccess = report => ({
  type: GET_REPORT_DETAIL_SUCCESS,
  payload: report,
})

export const getReportDetailFail = error => ({
  type: GET_REPORT_DETAIL_FAIL,
  payload: error,
})

export const getReportTypes = () => ({
  type: GET_REPORT_TYPES,
})

export const getReportTypesSuccess = reportTypes => ({
  type: GET_REPORT_TYPES_SUCCESS,
  payload: reportTypes,
})

export const getReportTypesFail = error => ({
  type: GET_REPORT_TYPES_FAIL,
  payload: error,
})

export const getReportData = (reportID, filterDate, filterEndDate) => ({
  type: GET_REPORT_DATA,
  payload: { reportID, filterDate, filterEndDate },
})

export const getReportDataSuccess = data => ({
  type: GET_REPORT_DATA_SUCCESS,
  payload: data,
})

export const getReportDataFail = error => ({
  type: GET_REPORT_DATA_FAIL,
  payload: error,
})
