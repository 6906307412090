import {
  GET_ROLETYPE_MODULES_SUCCESS,
  GET_ROLETYPE_MODULES_FAIL,
  ADD_ROLETYPE_MODULES_SUCCESS,
  ADD_ROLETYPE_MODULES_FAIL,
  RESET_MODULE,
} from "./actionTypes"

const INIT_STATE = {
  roletypemodules: [],
  error: {},
  loading: true,
}

const Module = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLETYPE_MODULES_SUCCESS:
      return {
        ...state,
        roletypemodules: action.payload,
        loading: true,
      }
    case GET_ROLETYPE_MODULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ROLETYPE_MODULES_SUCCESS:
      return {
        ...state,
        roletypemodules: action.payload,
      }
    case ADD_ROLETYPE_MODULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_MODULE:
      return {
        ...state,
        roletypemodules: [],
      }
    default:
      return state
  }
}

export default Module
