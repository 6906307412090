import {
  GET_CLASS_SCHEDULES_FAIL,
  GET_CLASS_SCHEDULES_SUCCESS,
  RESET_CLASS_SCHEDULES_SUCCESS,
  ADD_CLASS_SCHEDULES_SUCCESS,
  ADD_CLASS_SCHEDULES_FAIL,
  //GET_STAFF_ABSENT_TYPES_SUCCESS,
  //GET_STAFF_ABSENT_TYPES_FAIL,
  DELETE_CLASS_SCHEDULE_SUCCESS,
  DELETE_CLASS_SCHEDULE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  classSchedules: [],
  absenttypes: [],
  column: [],
  error: {},
  loading: true,
}

const Schedules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASS_SCHEDULES_SUCCESS:
      return {
        ...state,
        classSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    case GET_CLASS_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CLASS_SCHEDULES_SUCCESS:
      return {
        ...state,
        classSchedules: action.payload,
      }
    case ADD_CLASS_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    // case GET_STAFF_ABSENT_TYPES_SUCCESS:
    //   return {
    //     ...state,
    //     absenttypes: action.payload,
    //     loading: true,
    //   }
    // case GET_STAFF_ABSENT_TYPES_FAIL:
    //   return {
    //     ...state,
    //     error: action.payload,
    //   }
    case RESET_CLASS_SCHEDULES_SUCCESS:
      return {
        ...state,
        classSchedules: action.payload,
        column: [],
        loading: true,
      }
    case DELETE_CLASS_SCHEDULE_SUCCESS: {
      return {
        ...state,
        classSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    }
    case DELETE_CLASS_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Schedules
