import {
  GET_ROOMS_FAIL,
  GET_ROOMS_SUCCESS,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_FAIL,
  ADD_ROOM_FAIL,
  ADD_ROOM_SUCCESS,
  GET_ROOM_DETAIL_SUCCESS,
  GET_ROOM_DETAIL_FAIL,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_FAIL,
  RESET_ROOM_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  rooms: [],
  room: {},
  error: {},
  loading: true,
}

const Room = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROOMS_SUCCESS:
      return {
        ...state,
        rooms: action.payload,
        loading: true,
      }
    case GET_ROOMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_ROOM_SUCCESS: {
      const data = {
        ...state,
        rooms: state.rooms.filter(
          room => room.RoomID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        rooms: state.rooms.filter(room => room.RoomID !== action.payload),
      }
    }
    case DELETE_ROOM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ROOM_SUCCESS:
      return {
        ...state,
        rooms: [...state.rooms, action.payload],
      }
    case ADD_ROOM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ROOM_DETAIL_SUCCESS:
      return {
        ...state,
        room: action.payload,
        loading: true,
      }

    case GET_ROOM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        rooms: state.rooms.map(room =>
          room.RoomID.toString() === action.payload.RoomID.toString()
            ? { room, ...action.payload }
            : room
        ),
      }

    case UPDATE_ROOM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_ROOM_SUCCESS:
      return {
        ...state,
        room: action.payload,
      }
    default:
      return state
  }
}

export default Room
