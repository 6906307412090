import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import {
  getPrograms as onGetPrograms,
  getProgramDetail as onGetProgramDetail,
  deleteProgram as onDeleteProgram,
  getProgramScheduleDetail as onGetProgramScheduleDetail,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import ProgramModal from "./modal/ProgramModal"
import { useDeepCompareEffect } from "hooks"
import Moment from "moment"
import EnrollModal from "./modal/EnrollModal"

function Program() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [deleteModal, setDeleteModal] = useState(false)
  const [programModal, setProgramModal] = useState(false)
  const [enrollModal, setEnrollModal] = useState(false)
  const [program, setProgram] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetPrograms())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { programs, loading } = useSelector(state => state.program)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    setEdit(false)
    setProgramModal(true)
  }

  const EnrollClick = (rowID, programScheduleID) => {
    dispatch(onGetProgramScheduleDetail(rowID, programScheduleID))
    setEnrollModal(true)
  }

  const onClickEdit = arg => {
    dispatch(onGetProgramDetail(arg))
    setEdit(true)
    setProgramModal(true)
  }

  const onClickDelete = arg => {
    setProgram(arg)
    setDeleteModal(true)
  }

  const onCloseModal = () => {
    setProgramModal(false)
    setEnrollModal(false)
  }

  const handleDelete = () => {
    if (program && program.ProgramID) {
      dispatch(onDeleteProgram(program.ProgramID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {programModal && (
        <ProgramModal
          show={programModal}
          onEdit={edit}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <EnrollModal show={enrollModal} onCloseClick={() => onCloseModal()} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Pricing" breadcrumbItem="Programs" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn mb-2 me-2"
                        onClick={handleAddClick}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Create Program
                      </Button>
                    </div>
                    <div className="table-responsive">
                      <Table className="table mb-0 table">
                        {programs &&
                          programs.map((v, p) => {
                            return (
                              <>
                                <thead>
                                  <tr>
                                    <th>Days</th>
                                    <th>{v.BillingCycle + ` Price`}</th>
                                    <th>Requested</th>
                                    <th>Waitlist</th>
                                    <th>Enrollments</th>
                                    <th>Upcoming</th>
                                    <th>Created By</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    className="table-light"
                                    key={"program" + p}
                                  >
                                    <td>
                                      <h4>{v.Name}</h4>
                                      <Badge className="bg-success ms-1">
                                        {v.BillingCycle}
                                      </Badge>
                                      <div className="font-weight-normal">
                                        {v.WeekDays.map((f, i) => {
                                          return f.Name + ", "
                                        })}
                                        <span>
                                          {` from ` +
                                            Moment(v.StartTime).format(
                                              "hh:mm A"
                                            ) +
                                            " to " +
                                            Moment(v.EndTime).format("hh:mm A")}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{v.PriceRatio}</td>
                                    <td>{v.RequestedCount}</td>
                                    <td>{v.WaitlistCount}</td>
                                    <td>{v.EnrollmentCount}</td>
                                    <td>{v.UpcomingCount}</td>
                                    <td>
                                      <>
                                        <span className="font-size-14 mb-1">
                                          {v.CreatedByName}
                                        </span>
                                        <p className="text-muted mb-0">
                                          {handleValidDate(v.CreatedDate)}
                                        </p>
                                      </>
                                    </td>
                                    <td>
                                      <div className="d-flex gap-3">
                                        <Link
                                          to="#"
                                          className="text-success"
                                          onClick={() => {
                                            onClickEdit(v.RowID)
                                          }}
                                        >
                                          <i
                                            className="mdi mdi-pencil font-size-18"
                                            id="edittooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="edittooltip"
                                          >
                                            Edit
                                          </UncontrolledTooltip>
                                        </Link>
                                        <Link
                                          to="#"
                                          className="text-danger"
                                          onClick={() => {
                                            onClickDelete(v)
                                          }}
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-18"
                                            id="deletetooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="deletetooltip"
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>

                                  {v.ProgramSchedules.filter(
                                    e => e.IsLinked == true
                                  ).map((z, s) => {
                                    return (
                                      <tr key={"schedule" + s}>
                                        <td>
                                          {z.Days +
                                            (z.Days === 1 ? " day" : " days")}
                                        </td>
                                        <td>{`$` + z.Price.toFixed(2)}</td>
                                        <td>{z.RequestedCount}</td>
                                        <td>{z.WaitlistCount}</td>
                                        <td>{z.EnrollmentCount}</td>
                                        <td>{z.UpcomingCount}</td>
                                        <td></td>
                                        <td>
                                          <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-sm"
                                            onClick={() => {
                                              EnrollClick(
                                                v.RowID,
                                                z.ProgramScheduleID
                                              )
                                            }}
                                          >
                                            Enroll
                                          </Button>
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </>
                            )
                          })}
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Program.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Program
