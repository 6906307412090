import {
  GET_CENTER_PROFILE_DETAIL,
  GET_CENTER_PROFILE_DETAIL_SUCCESS,
  GET_CENTER_PROFILE_DETAIL_FAIL,
  UPDATE_CENTER_PROFILE,
  UPDATE_CENTER_PROFILE_SUCCESS,
  UPDATE_CENTER_PROFILE_FAIL,
  UPDATE_CENTER_ADDRESS,
  UPDATE_CENTER_ADDRESS_SUCCESS,
  UPDATE_CENTER_ADDRESS_FAIL,
  UPDATE_CENTER_CONTACT,
  UPDATE_CENTER_CONTACT_SUCCESS,
  UPDATE_CENTER_CONTACT_FAIL,
  UPDATE_CENTER_OPERATING_HOUR,
  UPDATE_CENTER_OPERATING_HOUR_SUCCESS,
  UPDATE_CENTER_OPERATING_HOUR_FAIL,
  UPDATE_CENTER_CLOSING,
  UPDATE_CENTER_CLOSING_SUCCESS,
  UPDATE_CENTER_CLOSING_FAIL,
  DELETE_CENTER_ADDRESS,
  DELETE_CENTER_ADDRESS_SUCCESS,
  DELETE_CENTER_ADDRESS_FAIL,
  DELETE_CENTER_CLOSING,
  DELETE_CENTER_CLOSING_SUCCESS,
  DELETE_CENTER_CLOSING_FAIL,
  DELETE_CENTER_CONTACT,
  DELETE_CENTER_CONTACT_SUCCESS,
  DELETE_CENTER_CONTACT_FAIL,
} from "./actionTypes"

export const getProfileDetail = profileId => ({
  type: GET_CENTER_PROFILE_DETAIL,
  profileId,
})

export const getProfileDetailSuccess = profile => ({
  type: GET_CENTER_PROFILE_DETAIL_SUCCESS,
  payload: profile,
})

export const getProfileDetailFail = error => ({
  type: GET_CENTER_PROFILE_DETAIL_FAIL,
  payload: error,
})

export const updateProfile = profile => ({
  type: UPDATE_CENTER_PROFILE,
  payload: profile,
})

export const updateProfileSuccess = profile => ({
  type: UPDATE_CENTER_PROFILE_SUCCESS,
  payload: profile,
})

export const updateProfileFail = error => ({
  type: UPDATE_CENTER_PROFILE_FAIL,
  payload: error,
})

export const updateProfileAddress = address => ({
  type: UPDATE_CENTER_ADDRESS,
  payload: address,
})

export const updateProfileAddressSuccess = address => ({
  type: UPDATE_CENTER_ADDRESS_SUCCESS,
  payload: address,
})

export const updateProfileAddressFail = error => ({
  type: UPDATE_CENTER_ADDRESS_FAIL,
  payload: error,
})

export const updateProfileContact = contact => ({
  type: UPDATE_CENTER_CONTACT,
  payload: contact,
})

export const updateProfileContactSuccess = contact => ({
  type: UPDATE_CENTER_CONTACT_SUCCESS,
  payload: contact,
})

export const updateProfileContactFail = error => ({
  type: UPDATE_CENTER_CONTACT_FAIL,
  payload: error,
})

export const updateProfileOperatingHour = (profileID, operatinghour) => ({
  type: UPDATE_CENTER_OPERATING_HOUR,
  payload: { profileID, operatinghour },
})

export const updateProfileOperatingHourSuccess = operatinghour => ({
  type: UPDATE_CENTER_OPERATING_HOUR_SUCCESS,
  payload: operatinghour,
})

export const updateProfileOperatingHourFail = error => ({
  type: UPDATE_CENTER_OPERATING_HOUR_FAIL,
  payload: error,
})

export const updateProfileClosing = closing => ({
  type: UPDATE_CENTER_CLOSING,
  payload: closing,
})

export const updateProfileClosingSuccess = closing => ({
  type: UPDATE_CENTER_CLOSING_SUCCESS,
  payload: closing,
})

export const updateProfileClosingFail = error => ({
  type: UPDATE_CENTER_CLOSING_FAIL,
  payload: error,
})

export const deleteProfileAddress = addressID => ({
  type: DELETE_CENTER_ADDRESS,
  payload: addressID,
})

export const deleteProfileAddressSuccess = addressID => ({
  type: DELETE_CENTER_ADDRESS_SUCCESS,
  payload: addressID,
})

export const deleteProfileAddressFail = error => ({
  type: DELETE_CENTER_ADDRESS_FAIL,
  payload: error,
})

export const deleteProfileContact = contactID => ({
  type: DELETE_CENTER_CONTACT,
  payload: contactID,
})

export const deleteProfileContactSuccess = contactID => ({
  type: DELETE_CENTER_CONTACT_SUCCESS,
  payload: contactID,
})

export const deleteProfileContactFail = error => ({
  type: DELETE_CENTER_CONTACT_FAIL,
  payload: error,
})

export const deleteProfileClosing = closingID => ({
  type: DELETE_CENTER_CLOSING,
  payload: closingID,
})

export const deleteProfileClosingSuccess = closingID => ({
  type: DELETE_CENTER_CLOSING_SUCCESS,
  payload: closingID,
})

export const deleteProfileClosingFail = error => ({
  type: DELETE_CENTER_CLOSING_FAIL,
  payload: error,
})
