import {
  GET_ROLETYPE_MODULES,
  GET_ROLETYPE_MODULES_SUCCESS,
  GET_ROLETYPE_MODULES_FAIL,
  ADD_ROLETYPE_MODULES,
  ADD_ROLETYPE_MODULES_SUCCESS,
  ADD_ROLETYPE_MODULES_FAIL,
} from "./actionTypes"

export const getRoleTypeModules = roleTypeID => ({
  type: GET_ROLETYPE_MODULES,
  payload: roleTypeID,
})

export const getRoleTypeModulesSuccess = roletypemodules => ({
  type: GET_ROLETYPE_MODULES_SUCCESS,
  payload: roletypemodules,
})

export const getRoleTypeModulesFail = error => ({
  type: GET_ROLETYPE_MODULES_FAIL,
  payload: error,
})

export const addRoleTypeModules = (roleTypeID, roleTypeModules) => ({
  type: ADD_ROLETYPE_MODULES,
  payload: { roleTypeID, roleTypeModules },
})

export const addRoleTypeModulesSuccess = roletypemodules => ({
  type: ADD_ROLETYPE_MODULES_SUCCESS,
  payload: roletypemodules,
})

export const addRoleTypeModulesFail = error => ({
  type: ADD_ROLETYPE_MODULES_FAIL,
  payload: error,
})
