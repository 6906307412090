import { call, put, takeEvery } from "redux-saga/effects"

// lessonPlan Redux States
import {
  GET_LESSON_PLANS,
  DELETE_LESSON_PLAN,
  ADD_LESSON_PLAN,
  GET_LESSON_PLAN_DETAIL,
  UPDATE_LESSON_PLAN,
  RESET_LESSON_PLAN,
  ADD_LESSON_PLAN_SCHEDULE,
  UPDATE_LESSON_PLAN_SCHEDULE,
  GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN,
  UPDATE_LESSON_PLAN_SCHEDULE_DETAIL,
  DELETE_LESSON_PLAN_SCHEDULE,
  DELETE_LESSON_PLAN_SCHEDULE_DETAIL,
  GET_LESSON_PLANS_VIEW,
} from "./actionTypes"
import {
  getLessonPlansSuccess,
  getLessonPlansFail,
  deleteLessonPlanSuccess,
  deleteLessonPlanFail,
  addLessonPlanSuccess,
  addLessonPlanFail,
  getLessonPlanDetailSuccess,
  getLessonPlanDetailFail,
  updateLessonPlanSuccess,
  updateLessonPlanFail,
  resetLessonPlanSuccess,
  addLessonPlanScheduleSuccess,
  addLessonPlanScheduleFail,
  updateLessonPlanScheduleSuccess,
  updateLessonPlanScheduleFail,
  getLessonPlanSchedulesByLessonPlanSuccess,
  getLessonPlanSchedulesByLessonPlanFail,
  updateLessonPlanScheduleDetailSuccess,
  updateLessonPlanScheduleDetailFail,
  deleteLessonPlanScheduleSuccess,
  deleteLessonPlanScheduleFail,
  deleteLessonPlanScheduleDetailSuccess,
  deleteLessonPlanScheduleDetailFail,
  getLessonPlansViewSuccess,
  getLessonPlansViewFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLessonPlans,
  deleteLessonPlan,
  addNewLessonPlan,
  getLessonPlanDetail,
  updateLessonPlan,
  addLessonPlanSchedule,
  deleteLessonPlanSchedule,
  getLessonPlanSchedulesByLessonPlan,
  updateLessonPlanScheduleDetail,
  deleteLessonPlanScheduleDetail,
  getLessonPlansView,
  updateLessonPlanSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchLessonPlans() {
  try {
    const response = yield call(getLessonPlans)
    yield put(getLessonPlansSuccess(response))
  } catch (error) {
    yield put(getLessonPlansFail(error))
  }
}

function* onDeleteLessonPlan({ payload: LessonPlanID }) {
  try {
    const response = yield call(deleteLessonPlan, LessonPlanID)
    yield put(deleteLessonPlanSuccess(response))
    toast.success("Lesson Plan deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteLessonPlanFail(error))
    toast.error("Lesson Plan deletion failed", { autoClose: 2000 })
  }
}

function* onAddLessonPlan({ payload: lessonPlan }) {
  try {
    const lessonPlanNew = {
      ...lessonPlan,
      ClassID: lessonPlan.Classes?.ClassID
        ? lessonPlan.Classes?.ClassID
        : 0,
    }
    const response = yield call(addNewLessonPlan, lessonPlanNew)
    yield put(addLessonPlanSuccess(response[0]))
    toast.success("Lesson Plan added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addLessonPlanFail(error))
    toast.error("Lesson Plan addition failed", { autoClose: 2000 })
  }
}

function* fetchLessonPlanDetail({ RowID }) {
  try {
    const response = yield call(getLessonPlanDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getLessonPlanDetailSuccess(result))
  } catch (error) {
    yield put(getLessonPlanDetailFail(error))
  }
}

function* onUpdateLessonPlan({ payload: lessonPlan }) {
  try {
    const lessonPlanNew = {
      ...lessonPlan,
      ClassID: lessonPlan.Classes?.ClassID
      ? lessonPlan.Classes?.ClassID
      : 0,
    }
    const response = yield call(updateLessonPlan, lessonPlanNew)
    let result = response[0]

    yield put(updateLessonPlanSuccess(result))
    toast.success("Lesson Plan updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateLessonPlanFail(error))
    toast.error("Lesson Plan updation failed", { autoClose: 2000 })
  }
}

function* onResetLessonPlan() {
  try {
    yield put(resetLessonPlanSuccess({}))
  } catch (error) {}
}

function* onAddLessonPlanSchedule({
  payload: { lessonPlanschedule, lessonPlanRowId },
}) {
  try {
    const response = yield call(addLessonPlanSchedule, {
      lessonPlanschedule,
      lessonPlanRowId,
    })

    yield put(addLessonPlanScheduleSuccess(response))
  } catch (error) {
    yield put(addLessonPlanScheduleFail(error))
  }
}

function* onUpdateLessonPlanSchedule({ payload: lessonPlanschedule }) {
  try {
    const response = yield call(updateLessonPlanSchedule, lessonPlanschedule)
    yield put(addLessonPlanScheduleSuccess(response))
  } catch (error) {
    yield put(addLessonPlanScheduleFail(error))
  }
}

function* fetchLessonPlanSchedulesByLessonPlan({ RowID }) {
  try {
    const response = yield call(getLessonPlanSchedulesByLessonPlan, RowID)
    yield put(getLessonPlanSchedulesByLessonPlanSuccess(response))
  } catch (error) {
    yield put(getLessonPlanSchedulesByLessonPlanFail(error))
  }
}
function* onUpdateLessonPlanScheduleDetail({
  payload: lessonPlanScheduleDetail,
}) {
  try {
    const response = yield call(
      updateLessonPlanScheduleDetail,
      lessonPlanScheduleDetail
    )
    yield put(updateLessonPlanScheduleDetailSuccess(response))
    toast.success("Lesson Plan Schedule detail updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateLessonPlanScheduleDetailFail(error))
    toast.error("LessonPlan Schedule detail updation failed", {
      autoClose: 2000,
    })
  }
}

function* onDeleteLessonPlanSchedule({ payload: LessonPlanScheduleID }) {
  try {
    const response = yield call(deleteLessonPlanSchedule, LessonPlanScheduleID)
    yield put(deleteLessonPlanScheduleSuccess(response))
    toast.success("Lesson Plan schedule deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteLessonPlanScheduleFail(error))
    toast.error("Lesson Plan schedule deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteLessonPlanScheduleDetail({
  payload: LessonPlanScheduleDetailID,
}) {
  try {
    const response = yield call(
      deleteLessonPlanScheduleDetail,
      LessonPlanScheduleDetailID
    )
    yield put(deleteLessonPlanScheduleDetailSuccess(response))
    toast.success("Lesson Plan schedule detail deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteLessonPlanScheduleDetailFail(error))
    toast.error("Lesson Plan schedule detail deletion failed", {
      autoClose: 2000,
    })
  }
}

function* fetchLessonPlansView({ payload: lessonPlan }) {
  try {
    const response = yield call(getLessonPlansView, lessonPlan)
    yield put(getLessonPlansViewSuccess(response))
  } catch (error) {
    yield put(getLessonPlansViewFail(error))
  }
}

function* lessonPlanSaga() {
  yield takeEvery(GET_LESSON_PLANS, fetchLessonPlans)
  yield takeEvery(DELETE_LESSON_PLAN, onDeleteLessonPlan)
  yield takeEvery(ADD_LESSON_PLAN, onAddLessonPlan)
  yield takeEvery(GET_LESSON_PLAN_DETAIL, fetchLessonPlanDetail)
  yield takeEvery(UPDATE_LESSON_PLAN, onUpdateLessonPlan)
  yield takeEvery(RESET_LESSON_PLAN, onResetLessonPlan)
  yield takeEvery(ADD_LESSON_PLAN_SCHEDULE, onAddLessonPlanSchedule)
  yield takeEvery(UPDATE_LESSON_PLAN_SCHEDULE, onUpdateLessonPlanSchedule)
  yield takeEvery(
    GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN,
    fetchLessonPlanSchedulesByLessonPlan
  )
  yield takeEvery(
    UPDATE_LESSON_PLAN_SCHEDULE_DETAIL,
    onUpdateLessonPlanScheduleDetail
  )
  yield takeEvery(DELETE_LESSON_PLAN_SCHEDULE, onDeleteLessonPlanSchedule)
  yield takeEvery(
    DELETE_LESSON_PLAN_SCHEDULE_DETAIL,
    onDeleteLessonPlanScheduleDetail
  )
  yield takeEvery(GET_LESSON_PLANS_VIEW, fetchLessonPlansView)
}

export default lessonPlanSaga
