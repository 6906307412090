import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Profile Redux States
import {
  GET_CENTER_PROFILE_DETAIL,
  UPDATE_CENTER_ADDRESS,
  UPDATE_CENTER_PROFILE,
  UPDATE_CENTER_CONTACT,
  UPDATE_CENTER_OPERATING_HOUR,
  UPDATE_CENTER_CLOSING,
  DELETE_CENTER_ADDRESS,
  DELETE_CENTER_CLOSING,
  DELETE_CENTER_CONTACT,
} from "./actionTypes"
import {
  getProfileDetailSuccess,
  getProfileDetailFail,
  updateProfileSuccess,
  updateProfileFail,
  updateProfileAddressSuccess,
  updateProfileAddressFail,
  updateProfileContactSuccess,
  updateProfileContactFail,
  updateProfileOperatingHourSuccess,
  updateProfileOperatingHourFail,
  updateProfileClosingSuccess,
  updateProfileClosingFail,
  deleteProfileAddressSuccess,
  deleteProfileAddressFail,
  deleteProfileClosingSuccess,
  deleteProfileClosingFail,
  deleteProfileContactSuccess,
  deleteProfileContactFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProfileDetail,
  updateProfile,
  updateProfileAddress,
  updateProfileContact,
  updateProfileOperatingHour,
  updateProfileClosing,
  deleteProfileAddress,
  deleteProfileClosing,
  deleteProfileContact,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function ModifyResponse(response) {
  let result = response.CenterAddress.map(x => ({
    ...x,
    Countries: {
      CountryID: Number(x.CountryID) ?? 0,
      Name: x.Country ?? "Select...",
    },
    States: {
      StateID: Number(x.StateID) ?? 0,
      Name: x.State ?? "Select...",
    },
    Cities: {
      CityID: Number(x.CityID) ?? 0,
      Name: x.City ?? "Select...",
    },
  }))

  let closing = response.CenterClosing.map(x => ({
    ...x,
    ClosingDate: x.ClosingDate
      ? Moment(x.ClosingDate).format("DD MMM, YYYY")
      : "",
  }))

  response.CenterAddress = result
  response.CenterClosing = closing
  return response
}

function* fetchProfileDetail({ profileId }) {
  try {
    const response = yield call(getProfileDetail, profileId)

    yield put(getProfileDetailSuccess(ModifyResponse(response)))
  } catch (error) {
    yield put(getProfileDetailFail(error))
  }
}

function* onUpdateProfile({ payload: profile }) {
  try {
    const response = yield call(updateProfile, profile)

    yield put(updateProfileSuccess(ModifyResponse(response)))
    toast.success("Profile updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateProfileFail(error))
    toast.error("Profile updation failed", { autoClose: 2000 })
  }
}

function* onUpdateProfileAddress({ payload: address }) {
  try {
    const response = yield call(updateProfileAddress, address)

    yield put(updateProfileAddressSuccess(ModifyResponse(response)))
    toast.success("Profile Address updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateProfileAddressFail(error))
    toast.error("Profile Address updation failed", { autoClose: 2000 })
  }
}

function* onUpdateProfileContact({ payload: contact }) {
  try {
    const response = yield call(updateProfileContact, contact)

    yield put(updateProfileContactSuccess(ModifyResponse(response)))
    toast.success("Profile Contact updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateProfileContactFail(error))
    toast.error("Profile Contact updation failed", { autoClose: 2000 })
  }
}

function* onUpdateProfileClosing({ payload: closing }) {
  try {
    const response = yield call(updateProfileClosing, closing)

    yield put(updateProfileClosingSuccess(ModifyResponse(response)))
    toast.success("Profile closing updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateProfileClosingFail(error))
    toast.error("Profile closing updation failed", { autoClose: 2000 })
  }
}

function* onUpdateProfileOperatingHour({
  payload: { profileID, operatinghour },
}) {
  try {
    const request = {
      profileID: profileID,
      operatinghour: operatinghour,
    }
    const response = yield call(updateProfileOperatingHour, request)
    yield put(updateProfileOperatingHourSuccess(ModifyResponse(response)))
    toast.success("Profile opearting hour updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateProfileOperatingHourFail(error))
    toast.error("Profile opearting hour updation failed", { autoClose: 2000 })
  }
}

function* onDeleteProfileAddress({ payload: addressID }) {
  try {
    const response = yield call(deleteProfileAddress, addressID)
    yield put(deleteProfileAddressSuccess(response))
    toast.success("Profile address deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProfileAddressFail(error))
    toast.error("Profile address deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteProfileContact({ payload: contactID }) {
  try {
    const response = yield call(deleteProfileContact, contactID)
    yield put(deleteProfileContactSuccess(response))
    toast.success("Profile contact deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProfileContactFail(error))
    toast.error("Profile contact deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteProfileClosing({ payload: closingID }) {
  try {
    const response = yield call(deleteProfileClosing, closingID)
    yield put(deleteProfileClosingSuccess(response))
    toast.success("Profile closing deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteProfileClosingFail(error))
    toast.error("Profile closing deletion failed", { autoClose: 2000 })
  }
}

function* centerProfileSaga() {
  yield takeEvery(GET_CENTER_PROFILE_DETAIL, fetchProfileDetail)
  yield takeEvery(UPDATE_CENTER_PROFILE, onUpdateProfile)
  yield takeEvery(UPDATE_CENTER_ADDRESS, onUpdateProfileAddress)
  yield takeEvery(UPDATE_CENTER_CONTACT, onUpdateProfileContact)
  yield takeEvery(UPDATE_CENTER_CLOSING, onUpdateProfileClosing)
  yield takeEvery(UPDATE_CENTER_OPERATING_HOUR, onUpdateProfileOperatingHour)
  yield takeEvery(DELETE_CENTER_ADDRESS, onDeleteProfileAddress)
  yield takeEvery(DELETE_CENTER_CONTACT, onDeleteProfileContact)
  yield takeEvery(DELETE_CENTER_CLOSING, onDeleteProfileClosing)
}

export default centerProfileSaga
