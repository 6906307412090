import {
  GET_IMMUNIZATIONS,
  GET_IMMUNIZATIONS_FAIL,
  GET_IMMUNIZATIONS_SUCCESS,
  DELETE_IMMUNIZATION,
  DELETE_IMMUNIZATION_FAIL,
  DELETE_IMMUNIZATION_SUCCESS,
  ADD_NEW_IMMUNIZATION,
  ADD_IMMUNIZATION_SUCCESS,
  ADD_IMMUNIZATION_FAIL,
  GET_IMMUNIZATION_DETAIL,
  GET_IMMUNIZATION_DETAIL_SUCCESS,
  GET_IMMUNIZATION_DETAIL_FAIL,
  UPDATE_IMMUNIZATION,
  UPDATE_IMMUNIZATION_SUCCESS,
  UPDATE_IMMUNIZATION_FAIL,
  GET_CHILD_IMMUNIZATION,
  GET_CHILD_IMMUNIZATION_SUCCESS,
  GET_CHILD_IMMUNIZATION_FAIL,
  UPDATE_CHILD_IMMUNIZATION,
  UPDATE_CHILD_IMMUNIZATION_SUCCESS,
  UPDATE_CHILD_IMMUNIZATION_FAIL,
} from "./actionTypes"

export const getImmunizations = () => ({
  type: GET_IMMUNIZATIONS,
})

export const getImmunizationsSuccess = immunizations => ({
  type: GET_IMMUNIZATIONS_SUCCESS,
  payload: immunizations,
})

export const getImmunizationsFail = error => ({
  type: GET_IMMUNIZATIONS_FAIL,
  payload: error,
})

export const deleteImmunization = immunizationId => ({
  type: DELETE_IMMUNIZATION,
  payload: immunizationId,
})

export const deleteImmunizationSuccess = immunizationId => ({
  type: DELETE_IMMUNIZATION_SUCCESS,
  payload: immunizationId,
})

export const deleteImmunizationFail = error => ({
  type: DELETE_IMMUNIZATION_FAIL,
  payload: error,
})
export const addNewImmunization = immunization => ({
  type: ADD_NEW_IMMUNIZATION,
  payload: immunization,
})

export const addImmunizationSuccess = immunization => ({
  type: ADD_IMMUNIZATION_SUCCESS,
  payload: immunization,
})

export const addImmunizationFail = error => ({
  type: ADD_IMMUNIZATION_FAIL,
  payload: error,
})

export const getImmunizationDetail = rowId => ({
  type: GET_IMMUNIZATION_DETAIL,
  rowId,
})

export const getImmunizationDetailSuccess = immunization => ({
  type: GET_IMMUNIZATION_DETAIL_SUCCESS,
  payload: immunization,
})

export const getImmunizationDetailFail = error => ({
  type: GET_IMMUNIZATION_DETAIL_FAIL,
  payload: error,
})

export const updateImmunization = immunization => ({
  type: UPDATE_IMMUNIZATION,
  payload: immunization,
})

export const updateImmunizationSuccess = immunization => ({
  type: UPDATE_IMMUNIZATION_SUCCESS,
  payload: immunization,
})

export const updateImmunizationFail = error => ({
  type: UPDATE_IMMUNIZATION_FAIL,
  payload: error,
})

export const getChildImmunizations = childId => ({
  type: GET_CHILD_IMMUNIZATION,
  payload: childId,
})

export const getChildImmunizationsSuccess = childimmunizations => ({
  type: GET_CHILD_IMMUNIZATION_SUCCESS,
  payload: childimmunizations,
})

export const getChildImmunizationsFail = error => ({
  type: GET_CHILD_IMMUNIZATION_FAIL,
  payload: error,
})

export const updateChildImmunization = (childId, childimmunization) => ({
  type: UPDATE_CHILD_IMMUNIZATION,
  payload: { childId, childimmunization },
})

export const updateChildImmunizationSuccess = immunization => ({
  type: UPDATE_CHILD_IMMUNIZATION_SUCCESS,
  payload: immunization,
})

export const updateChildImmunizationFail = error => ({
  type: UPDATE_CHILD_IMMUNIZATION_FAIL,
  payload: error,
})
