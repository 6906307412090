import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getRoleTypes as onGetRoleTypes } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import PermissionModal from "./modal/PermissionModal"
import { RESET_MODULE } from "../../store/module/actionTypes"

function RoleTypePermissions() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [permissionModal, setPermissionModal] = useState(false)
  const [roleTypeID, setRoleTypeID] = useState(0)

  //////////////////ROLE STATE/////////////////////
  const { loading, roletypes } = useSelector(state => state.role)
  const [isLoading, setLoading] = useState(loading)
  ///////////////////////////////////////

  useEffect(() => {
    if (roletypes && !roletypes.length) {
      dispatch(onGetRoleTypes())
    }
  }, [dispatch, roletypes])

  const onModalClick = e => {
    setRoleTypeID(e.RoleTypeID)
    setPermissionModal(true)
  }

  const onCloseModal = () => {
    dispatch({ type: RESET_MODULE })
    setPermissionModal(false)
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const columns = useMemo(
    () => [
      {
        Header: "Role Type",
        accessor: "RoleType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Assigned",
        accessor: "Assigned",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : 0
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                onClick={() => onModalClick(cellProps.row.original)}
                className="text-success"
              >
                <i
                  className="mdi mdi-eye-outline font-size-18"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Manage Permissions
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {permissionModal && (
        <PermissionModal
          show={permissionModal}
          roleTypeID={roleTypeID}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Permissions"
            breadcrumbItem="Roles & Permission"
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={roletypes}
                      isGlobalFilter={true}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
RoleTypePermissions.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default RoleTypePermissions
