/** Get Dashboard Chart data */
export const GET_CHARTS_DATA = "GET_CHARTS_DATA"
export const GET_CHARTS_DATA_SUCCESS = "GET_CHARTS_DATA_SUCCESS"
export const GET_CHARTS_DATA_FAIL = "GET_CHARTS_DATA_FAIL"

/** Get Dashboard Scheduled data */
export const GET_SCHEDULED_DATA = "GET_SCHEDULED_DATA"
export const GET_SCHEDULED_DATA_SUCCESS = "GET_SCHEDULED_DATA_SUCCESS"
export const GET_SCHEDULED_DATA_FAIL = "GET_SCHEDULED_DATA_FAIL"

/** Get Dashboard Live data */
export const GET_LIVE_DATA = "GET_LIVE_DATA"
export const GET_LIVE_DATA_SUCCESS = "GET_LIVE_DATA_SUCCESS"
export const GET_LIVE_DATA_FAIL = "GET_LIVE_DATA_FAIL"
