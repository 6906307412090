import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Wallet Redux States
import {
  GET_FEE_TRANSACTION,
  ADD_FEE_TRANSACTION,
  GET_FEE_TRANSACTION_DETAILS,
  GET_PAID_FEE_TRANSACTION,
} from "./actionTypes"
import {
  getFeeTransactionSuccess,
  getFeeTransactionFail,
  addFeeTransactionSuccess,
  addFeeTransactionFail,
  getFeeTransactionDetailSuccess,
  getFeeTransactionDetailFail,
  getPaidFeeTransactionSuccess,
  getPaidFeeTransactionFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFeeTransaction,
  addFeeTransaction,
  getFeeTransactionDetail,
  getPaidFeeTransaction,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchFeeTransaction({ payload: childID }) {
  try {
    const response = yield call(getFeeTransaction, childID)
    yield put(getFeeTransactionSuccess(response))
  } catch (error) {
    yield put(getFeeTransactionFail(error))
  }
}

function* fetchFeeTransactionDetail({ payload: adultID }) {
  try {
    const response = yield call(getFeeTransactionDetail, adultID)
    yield put(getFeeTransactionDetailSuccess(response))
  } catch (error) {
    yield put(getFeeTransactionDetailFail(error))
  }
}

function* onAddFeetTransaction({ payload: { adultID, values } }) {
  try {
    const request = {
      AdultID: adultID,
      Values: values,
    }
    const response = yield call(addFeeTransaction, request)
    yield put(addFeeTransactionSuccess(response))
  } catch (error) {
    yield put(addFeeTransactionFail(error))
  }
}

function* fetchPaidFeeTransaction({ payload: transactionIds }) {
  try {
    const response = yield call(getPaidFeeTransaction, transactionIds)
    yield put(getPaidFeeTransactionSuccess(response))
  } catch (error) {
    yield put(getPaidFeeTransactionFail(error))
  }
}

function* feeSaga() {
  yield takeEvery(GET_FEE_TRANSACTION, fetchFeeTransaction)
  yield takeEvery(ADD_FEE_TRANSACTION, onAddFeetTransaction)
  yield takeEvery(GET_FEE_TRANSACTION_DETAILS, fetchFeeTransactionDetail)
  yield takeEvery(GET_PAID_FEE_TRANSACTION, fetchPaidFeeTransaction)
}

export default feeSaga
