import {
  GET_MEAL_SCHEDULES_FAIL,
  GET_MEAL_SCHEDULES_SUCCESS,
  RESET_MEAL_SCHEDULES_SUCCESS,
  ADD_MEAL_SCHEDULES_SUCCESS,
  ADD_MEAL_SCHEDULES_FAIL,
  DELETE_MEAL_SCHEDULE_SUCCESS,
  DELETE_MEAL_SCHEDULE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  mealSchedules: [],
  column: [],
  error: {},
  loading: true,
}

const Schedules = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEAL_SCHEDULES_SUCCESS:
      return {
        ...state,
        mealSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    case GET_MEAL_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MEAL_SCHEDULES_SUCCESS:
      return {
        ...state,
        mealSchedules: action.payload,
      }
    case ADD_MEAL_SCHEDULES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_MEAL_SCHEDULES_SUCCESS:
      return {
        ...state,
        mealSchedules: action.payload,
        column: [],
        loading: true,
      }
    case DELETE_MEAL_SCHEDULE_SUCCESS: {
      return {
        ...state,
        mealSchedules: action.payload,
        column: Object.keys(action.payload[0]),
        loading: true,
      }
    }
    case DELETE_MEAL_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Schedules
