import {
  GET_ROOMS,
  GET_ROOMS_FAIL,
  GET_ROOMS_SUCCESS,
  DELETE_ROOM,
  DELETE_ROOM_FAIL,
  DELETE_ROOM_SUCCESS,
  ADD_NEW_ROOM,
  ADD_ROOM_SUCCESS,
  ADD_ROOM_FAIL,
  GET_ROOM_DETAIL,
  GET_ROOM_DETAIL_SUCCESS,
  GET_ROOM_DETAIL_FAIL,
  UPDATE_ROOM,
  UPDATE_ROOM_SUCCESS,
  UPDATE_ROOM_FAIL,
  RESET_ROOM_SUCCESS,
  RESET_ROOM,
} from "./actionTypes"

export const getRooms = () => ({
  type: GET_ROOMS,
})

export const getRoomsSuccess = rooms => ({
  type: GET_ROOMS_SUCCESS,
  payload: rooms,
})

export const getRoomsFail = error => ({
  type: GET_ROOMS_FAIL,
  payload: error,
})

export const deleteRoom = room => ({
  type: DELETE_ROOM,
  payload: room,
})

export const deleteRoomSuccess = room => ({
  type: DELETE_ROOM_SUCCESS,
  payload: room,
})

export const deleteRoomFail = error => ({
  type: DELETE_ROOM_FAIL,
  payload: error,
})
export const addNewRoom = room => ({
  type: ADD_NEW_ROOM,
  payload: room,
})

export const addRoomSuccess = room => ({
  type: ADD_ROOM_SUCCESS,
  payload: room,
})

export const addRoomFail = error => ({
  type: ADD_ROOM_FAIL,
  payload: error,
})

export const getRoomDetail = roomId => ({
  type: GET_ROOM_DETAIL,
  roomId,
})

export const getRoomDetailSuccess = room => ({
  type: GET_ROOM_DETAIL_SUCCESS,
  payload: room,
})

export const getRoomDetailFail = error => ({
  type: GET_ROOM_DETAIL_FAIL,
  payload: error,
})

export const updateRoom = room => ({
  type: UPDATE_ROOM,
  payload: room,
})

export const updateRoomSuccess = room => ({
  type: UPDATE_ROOM_SUCCESS,
  payload: room,
})

export const updateRoomFail = error => ({
  type: UPDATE_ROOM_FAIL,
  payload: error,
})
export const resetRoom = () => ({
  type: RESET_ROOM,
})

export const resetRoomSuccess = room => ({
  type: RESET_ROOM_SUCCESS,
  payload: room,
})
