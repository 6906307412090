import React, { useEffect, useMemo, useState, Suspense } from "react"
import { useDeepCompareEffect } from "hooks"
import PropTypes from "prop-types"
import { Link, json, useNavigate } from "react-router-dom"
import "../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../../components/Common/TableContainer"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import EmptyContainer from "components/Common/EmptyContainer"

function NoteTab() {
  const selectCalendarState = state => state.calendar

  const CalendarProperties = createSelector(selectCalendarState, Calendar => ({
    programenrollmentDetails: Calendar.programenrollmentDetails,
    events: Calendar.programenrollments,
    categories: Calendar.categories,
    isLoading: Calendar.isLoading,
  }))
  const { events, categories, isLoading, programenrollmentDetails } =
    useSelector(CalendarProperties)

  const [notes, setNotes] = useState([])
  useEffect(() => {
    if (programenrollmentDetails[0]?.Notes) {
      setNotes(programenrollmentDetails[0]?.Notes)
    }
  }, [programenrollmentDetails, notes, isLoading])

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: cellProps => {
          return (
            <>
              <Link to={"/learning/notes/" + cellProps.row.original.RowID}>
                {cellProps.row.original.Title}
              </Link>
            </>
          )
        },
      },
      {
        Header: "Class",
        accessor: "ClassName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      //   {
      //     Header: "Shared",
      //     accessor: "IsShared",
      //     Cell: cellProps => {
      //       return cellProps.value == true ? "Yes" : "No"
      //     },
      //   },
    ],
    []
  )
  return (
    <React.Fragment>
      <div className="col-sm-3 d-flex align-items-center"></div>
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            {notes?.length == 0 ? (
              <EmptyContainer backURL="" message="No notes found" linkText="" />
            ) : (
              <TableContainer
                columns={columns}
                data={notes}
                customPageSize={10}
                isPagination={true}
                filterable={false}
                iscustomPageSizeOptions={true}
                tableClass="align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
NoteTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default NoteTab
