
/**
 * Add ADDONS
 */

export const GET_ADDONS = "GET_ADDONS"
export const GET_ADDONS_SUCCESS = "GET_ADDONS_SUCCESS"
export const GET_ADDONS_FAIL = "GET_ADDONS_FAIL"


export const DELETE_ADDON = "DELETE_ADDON"
export const DELETE_ADDON_FAIL = "DELETE_ADDON_FAIL"
export const DELETE_ADDON_SUCCESS = "DELETE_ADDON_SUCCESS"

export const ADD_ADDON = "ADD_ADDON"
export const ADD_ADDON_SUCCESS = "ADD_ADDON_SUCCESS"
export const ADD_ADDON_FAIL = "ADD_ADDON_FAIL"

export const GET_ADDON_DETAIL = "GET_ADDON_DETAIL"
export const GET_ADDON_DETAIL_SUCCESS = "GET_ADDON_DETAIL_SUCCESS"
export const GET_ADDON_DETAIL_FAIL = "GET_ADDON_DETAIL_FAIL"

export const UPDATE_ADDON = "UPDATE_ADDON"
export const UPDATE_ADDON_SUCCESS = "UPDATE_ADDON_SUCCESS"
export const UPDATE_ADDON_FAIL = "UPDATE_ADDON_FAIL"

  
