import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import { createSelector } from "reselect"
import Select from "react-select"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

//import { addNewDocument as onAddDocument } from "store/actions"
import {
  // getPayrates as onGetPayrates,
  addNewPayrate as onAddNewPayrate,
  //getPayrateDetail as onGetPayrateDetail,
  updatePayrate as onUpdatePayrate,
  getEmploymentTypes as onGetEmploymentTypes,
  getClassificationTypes as onGetClassificationTypes,
  resetPayrate as onResetPayrate,
} from "../../../store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { json } from "react-router-dom"

const PayrateModal = ({
  //payRateID,
  payrateTitle,
  show,
  payrate,
  staffID,
  onPayrateCloseClick,
}) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    EmploymentTypes: yup.object().required("Required"),
    ClassificationTypes: yup.object().required("Required"),
    Amount: yup.string().required("Required"),
    StartDate: yup.string().required("Required"),
    IsActive: yup.bool(),
  })
  const { staff, loading } = useSelector(state => state.staff)
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Amount: "",
      StartDate: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const onPayrateSaveClick = () => {
    trigger()
    if (isValid) {
      if (payrate == null) {
        dispatch(onAddNewPayrate({ ...getValues(), StaffID: staff.StaffID }))
        onPayrateCloseClick()
      } else {
        dispatch(
          onUpdatePayrate({
            ...getValues(),
            StaffID: staff.StaffID,
            PayRateID: payrate.PayRateID,
          })
        )
        onPayrateCloseClick()
      }
    }
  }

  const onPayrateModalClosed = () => {
    reset()
  }

  useDeepCompareEffect(() => {
    function updatePayrateState() {
      dispatch(onGetEmploymentTypes())
      dispatch(onGetClassificationTypes())
      //reset form with server data
    }

    updatePayrateState()
    if (payrate == null) {
      reset({ Amount: "", StartDate: "", IsActive: true })
    } else {
      payrate = {
        ...payrate,
        EmploymentTypes: {
          EmploymentTypeID: Number(payrate.EmploymentTypeID),
          EmploymentType: payrate.EmploymentType,
        },
        ClassificationTypes: {
          ClassificationTypeID: Number(payrate.ClassificationTypeID),
          ClassificationType: payrate.ClassificationType,
        },
        StartDate: payrate.StartDate
          ? Moment(payrate.StartDate).format("DD MMM, YYYY")
          : "",
      }
      reset(payrate)
    }
  }, [dispatch, payrate])

  //////////////////EMPLOYMENT TYPE STATE/////////////////////
  const selectEmploymentState = state => state.staff
  const EmploymentProperties = createSelector(selectEmploymentState, e => ({
    employmentTypes: e.employmentTypes,
    loading: e.loading,
  }))
  const { employmentTypes } = useSelector(EmploymentProperties)
  //console.log(employmentTypes)
  ///////////////////////////////////////
  //////////////////Classification TYPE STATE/////////////////////
  const { classificationTypes } = useSelector(state => state.staff)
  //////////////////////////////////////

  //console.log(classificationTypes)
  return (
    <Modal
      isOpen={show}
      toggle={onPayrateCloseClick}
      onClosed={() => onPayrateModalClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        {payrateTitle}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Employment</Label>
                <Controller
                  name="EmploymentTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="EmploymentTypes"
                        options={employmentTypes}
                        getOptionLabel={option => option.EmploymentType}
                        getOptionValue={option => option.EmploymentTypeID}
                        required
                        aria-invalid={!!errors.EmploymentTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.EmploymentTypes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.EmploymentTypes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>

              <div className="mb-3">
                <Label>Classification</Label>
                <Controller
                  name="ClassificationTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="ClassificationTypes"
                        options={classificationTypes}
                        getOptionLabel={option => option.ClassificationType}
                        getOptionValue={option => option.ClassificationTypeID}
                        required
                        aria-invalid={!!errors.ClassificationTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.ClassificationTypes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.ClassificationTypes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Amount</Label>
                <Controller
                  name="Amount"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="Amount"
                        type="number"
                        required
                        invalid={!!errors.Amount}
                      />
                      {errors?.Amount?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Amount?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Start Date</Label>
                <Controller
                  name="StartDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="StartDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("StartDate", dateStr)
                        }}
                        required
                      />
                      {errors?.StartDate?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.StartDate?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Active</Label>
                <div>
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsActive"
                          // defaultChecked={field.value}
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onPayrateSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onPayrateCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

PayrateModal.propTypes = {
  onPayrateCloseClick: PropTypes.func,
  onPayrateSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default PayrateModal
