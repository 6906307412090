import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import DeleteModal from "../../../components/Common/DeleteModal"
import { deleteClassNote as onDeleteClassNote } from "store/actions"
import Moment from "moment"
import { useDeepCompareEffect } from "hooks"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import NotesModal from "../modal/NotesModal"

function NotesTab() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { classnotes, loading } = useSelector(state => state.class1)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [note, setNote] = useState(null)
  const [noteTitle, setNoteTitle] = useState("Add Note")
  const [noteModal, setNoteModal] = useState(false)

  const handleAddClick = () => {
    setNote(null)
    setNoteTitle("Add Note")
    setNoteModal(true)
  }

  const onClickDelete = arg => {
    setNote(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (note && note.ClassNoteID) {
      dispatch(onDeleteClassNote(note.ClassNoteID))
      setDeleteModal(false)
    }
  }
  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }
  const onClickEdit = arg => {
    setNote(arg)
    setNoteTitle("Edit Note")
    setNoteModal(true)
  }
  const onCloseModal = () => {
    setNote(null)
    setNoteModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Notes",
        accessor: "Note",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link className="text-dark" to="#">
                  {cellProps.row.original.Note}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Last Modified",
        accessor: "ModifiedDate",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.ModifiedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.ModifiedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {noteModal && (
        <NotesModal
          title={noteTitle}
          show={noteModal}
          data={note}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            <TableContainer
              columns={columns}
              data={classnotes}
              isGlobalFilter={true}
              isAddOption={true}
              addOptionText="Add"
              handleAddClick={handleAddClick}
              customPageSize={10}
              isPagination={true}
              filterable={false}
              iscustomPageSizeOptions={true}
              tableClass="align-middle table-nowrap table-check"
              theadClass="table-light"
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
NotesTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default NotesTab
