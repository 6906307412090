import {
  GET_CERTIFICATES_FAIL,
  GET_CERTIFICATES_SUCCESS,
  DELETE_CERTIFICATE_SUCCESS,
  DELETE_CERTIFICATE_FAIL,
  ADD_CERTIFICATE_FAIL,
  ADD_CERTIFICATE_SUCCESS,
  GET_CERTIFICATE_DETAIL_SUCCESS,
  GET_CERTIFICATE_DETAIL_FAIL,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_FAIL,
  GET_CERTIFICATE_DAYS_SUCCESS,
  GET_CERTIFICATE_DAYS_FAIL,
  GET_STAFF_CERTIFICATION_SUCCESS,
  GET_STAFF_CERTIFICATION_FAIL,
  UPDATE_STAFF_CERTIFICATION_SUCCESS,
  UPDATE_STAFF_CERTIFICATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  certificates: [],
  certificate: {},
  staffcertifications: [],
  error: {},
  loading: true,
  requiredDays: [],
}

const Certificate = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        certificates: action.payload,
        loading: true,
      }
    case GET_CERTIFICATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificates: state.certificates.filter(
          c => c.CertificationID !== action.payload
        ),
      }
    case DELETE_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificates: [...state.certificates, action.payload],
      }
    case ADD_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CERTIFICATE_DETAIL_SUCCESS:
      return {
        ...state,
        certificate: action.payload,
        loading: true,
      }

    case GET_CERTIFICATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificates: state.certificates.map(certificate =>
          certificate.CertificationID.toString() ===
          action.payload.CertificationID.toString()
            ? { certificate, ...action.payload }
            : certificate
        ),
      }
    case UPDATE_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CERTIFICATE_DAYS_SUCCESS:
      return {
        ...state,
        requiredDays: action.payload,
        loading: true,
      }
    case GET_CERTIFICATE_DAYS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STAFF_CERTIFICATION_SUCCESS:
      return {
        ...state,
        staffcertifications: action.payload,
        loading: true,
      }
    case GET_STAFF_CERTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_STAFF_CERTIFICATION_SUCCESS: {
      return {
        ...state,
        staffcertifications: state.staffcertifications.map(c =>
          c.StaffCertificationID.toString() ===
          action.payload.StaffCertificationID.toString()
            ? { c, ...action.payload }
            : c
        ),
      }
    }

    case UPDATE_STAFF_CERTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Certificate
