import React, { useEffect, useState, Fragment, useMemo } from "react"
import {
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

import {
  getChildSchedules as onGetChildSchedules,
  deleteChildSchedule as onDeleteChildSchedule,
} from "store/actions"
import Spinners from "components/Common/Spinner"
import AssignModal from "./modal/AssignModal"
import { useDeepCompareEffect } from "hooks"
import AbsentModal from "./modal/AbsentModal"
import Paginations from "components/Common/Pagination"
import DeleteModal from "components/Common/DeleteModal"

function ChildScheduling() {
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [assignModal, setAssignModal] = useState(false)
  const [absentModal, setAbsentModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [childScheduleID, setChildScheduleID] = useState(0)
  const [filter, setFilter] = useState({
    date: Moment().format("yyyy-MM-DD"),
  })

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [dataList, setDataList] = useState()
  const perPageData = 5
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData

  useDeepCompareEffect(() => {
    dispatch(onGetChildSchedules(filter))
  }, [dispatch, filter])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const selectChildScheduleState = state => state.childSchedules
  const ChildScheduleProperties = createSelector(
    selectChildScheduleState,
    e => ({
      data: e.childSchedules,
      loading: e.loading,
      column: e.column,
    })
  )
  const { data, loading, column } = useSelector(ChildScheduleProperties)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  function handleDate(param) {
    setFilter({ date: param })
  }

  const onAssignModalClick = () => {
    setAssignModal(true)
  }

  const onAbsentModalClick = () => {
    setAbsentModal(true)
  }

  const onCloseModal = () => {
    setAssignModal(false)
    setAbsentModal(false)
  }

  const onClickDelete = arg => {
    setChildScheduleID(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (childScheduleID > 0) {
      dispatch(onDeleteChildSchedule(childScheduleID))
      setDeleteModal(false)
    }
  }

  const currentdata = useMemo(
    () => data ?? data.slice(indexOfFirst, indexOfLast),
    [data, indexOfFirst, indexOfLast]
  )

  useEffect(() => {
    setDataList(currentdata)
  }, [currentdata])

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <Fragment>
      {assignModal && (
        <AssignModal show={assignModal} onCloseClick={() => onCloseModal()} />
      )}
      {absentModal && (
        <AbsentModal show={absentModal} onCloseClick={() => onCloseModal()} />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <Row className="mb-2">
        <Col sm="10">
          <div className="text-sm-end">
            <div className="btn-group" role="group">
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio4"
                autoComplete="off"
                onClick={() =>
                  handleDate(
                    Moment(filter.date).subtract(7, "days").format("yyyy-MM-DD")
                  )
                }
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio4">
                <i className="fas fa-angle-left" />
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio5"
                autoComplete="off"
                defaultChecked
                onClick={() => handleDate(Moment().format("yyyy-MM-DD"))}
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio5">
                Today
              </label>

              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio6"
                autoComplete="off"
                onClick={() =>
                  handleDate(
                    Moment(filter.date).add(7, "days").format("yyyy-MM-DD")
                  )
                }
              />
              <label className="btn btn-outline-secondary" htmlFor="btnradio6">
                <i className="fas fa-angle-right" />
              </label>
            </div>
          </div>
        </Col>
        <Col sm="2">
          <div className="text-sm-end">
            <Dropdown
              isOpen={toggleMenu}
              toggle={() => {
                setToggleMenu(!toggleMenu)
              }}
              className="btn-group me-2 mb-2 mb-sm-0"
            >
              <DropdownToggle
                className="btn btn-primary  dropdown-toggle"
                tag="div"
              >
                Assign <i className="mdi mdi-chevron-down ms-1" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  to="#"
                  onClick={() => {
                    onAssignModalClick()
                  }}
                >
                  Assign
                </DropdownItem>
                <DropdownItem
                  to="#"
                  onClick={() => {
                    onAbsentModalClick()
                  }}
                >
                  Plan absent
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table className="align-middle table-nowrap table-check table-bordered">
          <thead className="table-light">
            <tr>
              {column.map((row, index) => (
                <th
                  key={"th" + index}
                  className={row === "ChildID" ? "d-none" : ""}
                >
                  <div className="m-0">{row}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataList.map((n, index) => {
              return (
                <Fragment key={"dl" + index}>
                  <tr>
                    {column.map((v, index) => {
                      return (
                        <td
                          key={"clm" + index}
                          className={v === "ChildID" ? "d-none" : ""}
                        >
                          {/* {console.log("Inner Loop", n[v])} */}
                          {v !== "Name" &&
                          v !== "ChildID" &&
                          v !== "id" &&
                          n[v] !== "" &&
                          n[v] !== undefined
                            ? JSON.parse(n[v]).map(w => (
                                <div
                                  className="border rounded p-2 w-100 cursor-pointer"
                                  key={"dv" + w.ChildSchedulingID}
                                >
                                  <div
                                    className="float-end"
                                    onClick={() =>
                                      onClickDelete(w.ChildSchedulingID)
                                    }
                                  >
                                    <i className="bx bx-trash bx-sm"></i>
                                  </div>
                                  <h6> {w.Title}</h6>
                                  <span>
                                    {Moment(w.StartTime).format("hh:mm A") +
                                      " - " +
                                      Moment(w.EndTime).format("hh:mm A")}
                                  </span>
                                </div>
                              ))
                            : n[v]}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        <Paginations
          perPageData={5}
          data={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isShowingPageLength={false}
          paginationDiv="col-12"
          paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5"
        />
      </div>
      <ToastContainer />
    </Fragment>
  )
}
export default ChildScheduling
