import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { EnumObjectTypes } from "helpers/enum_helper"
import {
  getUserImagePath,
  getAdultImagePath,
  getChildImagePath,
} from "helpers/imageService"

//controls
const ProfilePicture = props => {
  return (
    <Link to="#" onClick={props.onClick}>
      <div className="d-flex">
        <div className="flex-shrink-0 me-2">
          {props?.ObjectTypeID > 0 ? (
            <img
              className="rounded-circle avatar-xs"
              src={
                props?.ObjectTypeID === EnumObjectTypes.Staff
                  ? getUserImagePath(props?.RowID, "image.jpeg")
                  : props.ObjectTypeID === EnumObjectTypes.Adult
                  ? getAdultImagePath(props?.RowID, "image.jpeg")
                  : props.ObjectTypeID === EnumObjectTypes.Children
                  ? getChildImagePath(props?.RowID, "image.jpeg")
                  : ""
              }
              alt="Avatar"
            />
          ) : (
            ""
          )}
        </div>
        <div className="flex-grow-1">
          <h5
            className={
              props?.SubTitle
                ? "text-body font-size-13 mb-0"
                : "text-body font-size-13 mb-0 mt-2"
            }
          >
            {props?.Title}
          </h5>
          {props?.SubTitle && (
            <span className="font-size-12 text-muted mb-0">
              {props?.SubTitle}
            </span>
          )}
        </div>
      </div>
    </Link>
  )
}

ProfilePicture.propTypes = {
  RowID: PropTypes.string,
  ObjectTypeID: PropTypes.number,
  Title: PropTypes.string,
  SubTitle: PropTypes.string,
  onClick: PropTypes.func,
}

export default ProfilePicture
