import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap"
import classnames from "classnames"
import BasicInfoTab from "./tabs/BasicInfoTab"
import {
  getStaffDetail as onGetStaffDetail,
  getStaffProfile as onGetStaffProfile,
} from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import Document from "components/Common/Document"
import { EnumObjectTypes } from "helpers/enum_helper"
import { ToastContainer } from "react-toastify"

import ScheduleTab from "./tabs/ScheduleTab"
import AvailabilityModal from "./modal/AvailabilityModal"
import Availability from "./misc/Availability"

import PayrateTab from "./tabs/PayrateTab"
import HappeningTab from "./tabs/HappeningTab"
import HomeClassModal from "./modal/HomeClassModal"
import HomeClass from "./misc/HomeClass"
import EmergencyContactModal from "./modal/EmergencyContactModal"
import EmergencyContact from "./misc/EmergencyContact"
import Notes from "./misc/Notes"
import NotesModal from "./modal/NotesModal"
import Certification from "./tabs/Certification"

const StaffAddEdit = () => {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [header, setHeader] = useState("")
  const [tabValue, setTabValue] = useState("1")
  const [availabilityModal, setAvailabilityModal] = useState(false)
  const [homeClassModal, setHomeClassModal] = useState(false)
  const [emergencyContactModal, setEmergencyContactModal] = useState(false)
  const [notesModal, setNotesModal] = useState(false)
  const [revealed, setRevealed] = useState(false)

  useDeepCompareEffect(() => {
    const { id } = routeParams

    if (id === "new") {
      setHeader("Add Staff")
    } else {
      setHeader("Edit Staff")
      dispatch(onGetStaffDetail(id))
    }
  }, [dispatch, routeParams])

  //////////////////STAFF STATE/////////////////////
  const data = useSelector(state => state.staff)
  //////////////////////////////////////

  //////////////////STAFF STATE/////////////////////
  const { staffprofile } = useSelector(state => state.staff)
  //////////////////////////////////////

  useEffect(() => {
    if (isEmpty(data.staff)) {
      return
    }
    dispatch(onGetStaffProfile(data.staff.StaffID))
  }, [data.staff.StaffID])

  const toggleTab = tab => {
    if (tabValue !== tab) {
      setTabValue(tab)
    }
  }

  const onAvailabilityModalClick = () => {
    setAvailabilityModal(true)
  }

  const onHomeClassModalClick = () => {
    setHomeClassModal(true)
  }

  const onEmergencyContactModalClick = () => {
    setEmergencyContactModal(true)
  }

  const onNotesModalClick = () => {
    setNotesModal(true)
  }

  const onCloseModal = () => {
    setAvailabilityModal(false)
    setHomeClassModal(false)
    setEmergencyContactModal(false)
    setNotesModal(false)
  }

  const toggleReveal = () => {
    setRevealed(!revealed)
  }

  const MiscLeftSection = (heading, buttonText, onClick, children) => {
    return (
      <div className="p-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h5 className="m-0 pt-2">{heading}</h5>
          </div>
          <div>
            <Button
              className="btn btn-light waves-effect btn btn-light btn-sm"
              onClick={onClick}
            >
              {buttonText}
            </Button>
          </div>
        </div>
        {children}
      </div>
    )
  }

  const NavItemTab = (navTitle, navValue) => {
    return (
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className={classnames({
            active: tabValue === navValue,
          })}
          onClick={() => {
            toggleTab(navValue)
          }}
        >
          {navTitle}
        </NavLink>
      </NavItem>
    )
  }

  function RevealSecurity() {
    return (
      <div className="p-3 border-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h5 className="m-0 pt-2">Security</h5>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <h6 className="m-0 pt-4">Kiosk pin</h6>
          </div>
          <div className="m-0 pt-4">
            {!revealed && (
              <Button onClick={toggleReveal} className="btn btn-light btn-sm">
                Click to {revealed ? "hide" : "reveal"}
              </Button>
            )}
            {revealed && <div className="">{data.staff.SecurityPin}</div>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {!isEmpty(data.staff) ? (
        <>
          {availabilityModal && (
            <AvailabilityModal
              show={availabilityModal}
              onCloseClick={() => onCloseModal()}
              staffID={data.staff.StaffID}
            />
          )}
          {homeClassModal && (
            <HomeClassModal
              show={homeClassModal}
              onCloseClick={() => onCloseModal()}
              staffID={data.staff.StaffID}
              profile={staffprofile}
            />
          )}
          {emergencyContactModal && (
            <EmergencyContactModal
              show={emergencyContactModal}
              onCloseClick={() => onCloseModal()}
              staffID={data.staff.StaffID}
              profile={staffprofile}
            />
          )}
          {notesModal && (
            <NotesModal
              show={notesModal}
              onCloseClick={() => onCloseModal()}
              staffID={data.staff.StaffID}
              profile={staffprofile}
            />
          )}
        </>
      ) : (
        ""
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Staff" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(data.staff) ? (
            <EmptyContainer
              backURL="/staffs"
              message="There is no such staff!"
              linkText="Go to Staffs Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    {routeParams.id !== "new" ? (
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        {NavItemTab("Basic Info", "1")}
                        {NavItemTab("Misc", "2")}
                        {NavItemTab("Documents", "3")}
                        {NavItemTab("Pay Rates", "4")}
                        {NavItemTab("Certifications", "5")}
                        {NavItemTab("Happenings", "6")}
                      </Nav>
                    ) : (
                      ""
                    )}
                    <TabContent activeTab={tabValue} className="p-3 text-muted">
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <BasicInfoTab {...data} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col className="col-3">
                            <div className="p-3 rounded border h-100">
                              {RevealSecurity()}
                              {MiscLeftSection(
                                "General Availability",
                                "Edit",
                                () => onAvailabilityModalClick(),
                                <Availability staffID={data.staff.StaffID} />
                              )}

                              {MiscLeftSection(
                                "Home Class",
                                "Edit",
                                () => onHomeClassModalClick(),
                                <HomeClass profile={staffprofile} />
                              )}
                              {MiscLeftSection(
                                "Emergency contact",
                                "Edit",
                                () => onEmergencyContactModalClick(),
                                <EmergencyContact profile={staffprofile} />
                              )}
                              {MiscLeftSection(
                                "Notes",
                                "Edit",
                                () => onNotesModalClick(),
                                <Notes profile={staffprofile} />
                              )}
                            </div>
                          </Col>
                          <Col className="col-9  p-3 rounded border h-100">
                            <ScheduleTab staffID={data.staff.StaffID} />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <Document
                              objectID={data.staff.StaffID}
                              objectTypeID={EnumObjectTypes.Staff}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                        <Row>
                          <Col sm="12">
                            <PayrateTab />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="2"></Col>
                          <Col sm="8">
                            <Certification />
                          </Col>
                          <Col sm="2"></Col>
                        </Row>
                      </TabPane>

                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12">
                            <HappeningTab />
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default StaffAddEdit
