/*Get ROLETYPE MODULES */
export const GET_ROLETYPE_MODULES = "GET_ROLETYPE_MODULES"
export const GET_ROLETYPE_MODULES_SUCCESS = "GET_ROLETYPE_MODULES_SUCCESS"
export const GET_ROLETYPE_MODULES_FAIL = "GET_ROLETYPE_MODULES_FAIL"

/*Add ROLETYPE MODULES */
export const ADD_ROLETYPE_MODULES = "ADD_ROLETYPE_MODULES"
export const ADD_ROLETYPE_MODULES_SUCCESS = "ADD_ROLETYPE_MODULES_SUCCESS"
export const ADD_ROLETYPE_MODULES_FAIL = "ADD_ROLETYPE_MODULES_FAIL"

//Reset Module
export const RESET_MODULE = "RESET_MODULE"
