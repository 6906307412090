/**
 * Add NOTES
 */

export const GET_NOTES = "GET_NOTES"
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS"
export const GET_NOTES_FAIL = "GET_NOTES_FAIL"

export const DELETE_NOTE = "DELETE_NOTE"
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL"
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS"

export const ADD_NOTE = "ADD_NOTE"
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS"
export const ADD_NOTE_FAIL = "ADD_NOTE_FAIL"

export const GET_NOTE_DETAIL = "GET_NOTE_DETAIL"
export const GET_NOTE_DETAIL_SUCCESS = "GET_NOTE_DETAIL_SUCCESS"
export const GET_NOTE_DETAIL_FAIL = "GET_NOTE_DETAIL_FAIL"

export const UPDATE_NOTE = "UPDATE_NOTE"
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS"
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL"

/**
 * Reset NOTE
 */
export const RESET_NOTE = "RESET_NOTE"
export const RESET_NOTE_SUCCESS = "RESET_NOTE_SUCCESS"

export const GET_NOTE_CATEGORIES = "GET_NOTE_CATEGORIES"
export const GET_NOTE_CATEGORIES_SUCCESS = "GET_NOTE_CATEGORIES_SUCCESS"
export const GET_NOTE_CATEGORIES_FAIL = "GET_NOTE_CATEGORIES_FAIL"

// ADD NOTE Schedules

export const ADD_NOTE_SCHEDULE = "ADD_NOTE_SCHEDULE"
export const ADD_NOTE_SCHEDULE_SUCCESS = "ADD_NOTE_SCHEDULE_SUCCESS"
export const ADD_NOTE_SCHEDULE_FAIL = "ADD_NOTE_SCHEDULE_FAIL"

export const UPDATE_NOTE_SCHEDULE = "UPDATE_NOTE_SCHEDULE"
export const UPDATE_NOTE_SCHEDULE_SUCCESS = "UPDATE_NOTE_SCHEDULE_SUCCESS"
export const UPDATE_NOTE_SCHEDULE_FAIL = "UPDATE_NOTE_SCHEDULE_FAIL"

export const DELETE_NOTE_SCHEDULE = "DELETE_NOTE_SCHEDULE"
export const DELETE_NOTE_SCHEDULE_SUCCESS = "DELETE_NOTE_SCHEDULE_SUCCESS"
export const DELETE_NOTE_SCHEDULE_FAIL = "DELETE_NOTE_SCHEDULE_FAIL"

//getNOTESchedulesByNOTE
export const GET_NOTE_SCHEDULES_BY_NOTE = "GET_NOTE_SCHEDULES_BY_NOTE"
export const GET_NOTE_SCHEDULES_BY_NOTE_SUCCESS =
  "GET_NOTE_SCHEDULES_BY_NOTE_SUCCESS"
export const GET_NOTE_SCHEDULES_BY_NOTE_FAIL = "GET_NOTE_SCHEDULES_BY_NOTE_FAIL"

// Update NOTEScheduleDetails Details
export const UPDATE_NOTE_SCHEDULE_DETAIL = "UPDATE_NOTE_SCHEDULE_DETAIL"
export const UPDATE_NOTE_SCHEDULE_DETAIL_SUCCESS =
  "UPDATE_NOTE_SCHEDULE_DETAIL_SUCCESS"
export const UPDATE_NOTE_SCHEDULE_DETAIL_FAIL =
  "UPDATE_NOTE_SCHEDULE_DETAIL_FAIL"

export const DELETE_NOTE_SCHEDULE_DETAIL = "DELETE_NOTE_SCHEDULE_DETAIL"
export const DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS =
  "DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS"
export const DELETE_NOTE_SCHEDULE_DETAIL_FAIL =
  "DELETE_NOTE_SCHEDULE_DETAIL_FAIL"

export const GET_NOTES_VIEW = "GET_NOTES_VIEW"
export const GET_NOTES_VIEW_SUCCESS = "GET_NOTES_VIEW_SUCCESS"
export const GET_NOTES_VIEW_FAIL = "GET_NOTES_VIEW_FAIL"
