import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"
//redux
import { connect, useSelector, useDispatch } from "react-redux"
import { getNavigationSidebar as onGetNavigationSidebar } from "store/actions"
import { isEmpty } from "lodash"
import { layoutTypes } from "constants/layout"

const Navbar = props => {
  const dispatch = useDispatch()

  const [item, setItem] = useState(false)

  useEffect(() => {
    dispatch(onGetNavigationSidebar())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { navigations, loading } = useSelector(state => state.navigation)
  const { layoutType } = useSelector(state => state.Layout)
  //////////////////////////////////////

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {navigations.map((v, p) => {
                  return (
                    <React.Fragment key={"parent" + p}>
                      {!v.HasChilds ? (
                        <li className="nav-item">
                          <Link to={v.Page} className="nav-link">
                            {props.t(v.Module)}
                          </Link>
                        </li>
                      ) : (
                        <li className="nav-item dropdown">
                          <Link
                            to="/#"
                            className="nav-link dropdown-toggle arrow-none"
                            onClick={e => {
                              e.preventDefault()
                              setItem(!item)
                            }}
                          >
                            {props.t(v.Module)}
                            {props.menuOpen}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: item,
                            })}
                          >
                            {v.ChildNavigation &&
                              v.ChildNavigation.map((r, s) => {
                                return (
                                  <Link
                                    key={"child" + s}
                                    className="dropdown-item"
                                    to={r.Page}
                                  >
                                    {props.t(r.Module)}
                                  </Link>
                                )
                              })}
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
