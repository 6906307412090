import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate, useParams } from "react-router-dom"
import TableContainer from "../../components/Common/TableContainer"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Row,
  Card,
  CardBody,
} from "reactstrap"

import { ToastContainer } from "react-toastify"
import {
  getReportDetail as onGetReportDetail,
  getReportData as onGetReportData,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { useDeepCompareEffect } from "hooks"
import Flatpickr from "react-flatpickr"
import Moment from "moment"
import Spinners from "components/Common/Spinner"
import {
  ChildrenDailyColumns,
  ChildrenWeeklyColumns,
  StaffDailyColumns,
  ChildrenAttendanceColumns,
  ChildrenAllergiesColumns,
  ChildrenEmergencyContactsColumns,
  StaffEmergencyContactsColumns,
  StaffWeeklyColumns,
  AbsentChildrenColumns,
  AbsentStaffColumns,
  UnpaidServicesColumns,
  FinanceColumns,
  WalletBalanceColumns,
  WalletColumns,
  PaymentColumns,
  ProgramChargesColumns,
  DocExpColumns,
  TagExpChildrenColumns,
} from "./columns"

function ShowReport() {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [isInIt, setIsInit] = useState(true)
  const [isfilterDate, setIsFilterDate] = useState(true)
  const [filterDate, setFilterDate] = useState(Moment().format("DD-MMM-yyyy"))
  const [filterEndDate, setFilterEndDate] = useState(
    Moment().format("DD-MMM-yyyy")
  )
  const [filterColumns, setFilterColumns] = useState([])
  const [isDailyReport, setIsDailyReport] = useState(false)
  const [showDate, setShowDate] = useState(true)

  const { id } = routeParams

  useDeepCompareEffect(() => {
    dispatch(onGetReportDetail(id))
  }, [dispatch])

  const { reportdetail, data, loading } = useSelector(state => state.report)
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (reportdetail.RowID == id) {
      if (isInIt) {
        setFilterColumns(renderSwitch(reportdetail.ReportID))
        setIsInit(false)
      }
      dispatch(
        onGetReportData(reportdetail.ReportID, filterDate, filterEndDate)
      )
    }
  }, [reportdetail, filterDate, filterEndDate])

  function renderSwitch(reportID) {
    switch (reportID) {
      case 1:
        setIsDailyReport(true)
        return ChildrenDailyColumns
      case 2:
        setIsDailyReport(true)
        return StaffDailyColumns
      case 3:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(7, "days").format("DD-MMM-yyyy"))
        return ChildrenWeeklyColumns
      case 4:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(1, "month").format("DD-MMM-yyyy"))
        return ChildrenAttendanceColumns
      case 5:
        setShowDate(false)
        return ChildrenAllergiesColumns
      case 6:
        setShowDate(false)
        return ChildrenEmergencyContactsColumns
      case 7:
        setShowDate(false)
        return StaffEmergencyContactsColumns
      case 8:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(7, "days").format("DD-MMM-yyyy"))
        return StaffWeeklyColumns
      case 9:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(13, "days").format("DD-MMM-yyyy"))
        return AbsentChildrenColumns
      case 10:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(13, "days").format("DD-MMM-yyyy"))
        return AbsentStaffColumns
      case 11:
        setShowDate(false)
        return UnpaidServicesColumns
      case 12:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(1, "month").format("DD-MMM-yyyy"))
        return FinanceColumns
      case 13:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(1, "month").format("DD-MMM-yyyy"))
        return FinanceColumns
      case 14:
        setIsDailyReport(true)
        return WalletBalanceColumns
      case 15:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(1, "month").format("DD-MMM-yyyy"))
        return ProgramChargesColumns
      case 16:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(1, "month").format("DD-MMM-yyyy"))
        return WalletColumns
      case 17:
        setIsDailyReport(false)
        setFilterDate(Moment().subtract(1, "month").format("DD-MMM-yyyy"))
        return PaymentColumns
      case 18:
      case 19:
        setShowDate(false)
        return DocExpColumns
      case 20:
        setShowDate(false)
        return ""
      case 21:
        setShowDate(false)
        return TagExpChildrenColumns
      case 22:
        setShowDate(false)
        return ""
      default:
        return ""
    }
  }

  const columns = useMemo(() => filterColumns, [filterColumns])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Reports" breadcrumbItem="Show Report" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <Row className="mb-2 justify-content-between">
                      <Col sm="6">
                        <h4>{reportdetail.ReportTitle}</h4>
                        {showDate ? (
                          <h5 className="text-muted">
                            {isDailyReport
                              ? Moment(filterDate).format("DD MMMM, Y")
                              : filterDate.toString() ===
                                filterEndDate.toString()
                              ? Moment(filterDate).format("DD MMMM, Y")
                              : Moment(filterDate).format("DD MMMM, Y") +
                                " - " +
                                Moment(filterEndDate).format("DD MMMM, Y")}
                          </h5>
                        ) : (
                          ""
                        )}
                        <div className="badge rounded-1 badge-soft-success mb-3">
                          {reportdetail.ReportType}
                        </div>
                      </Col>
                      {showDate ? (
                        <Col sm="6" lg={isDailyReport ? 2 : 3}>
                          <div className="mb-3 d-flex">
                            <Flatpickr
                              hidden={!isfilterDate}
                              className="form-control me-3"
                              id="FilterDate"
                              options={{
                                dateFormat: "d M, Y",
                              }}
                              value={filterDate}
                              onChange={(selectedDates, dateStr, instance) => {
                                setFilterDate(dateStr)
                              }}
                            />
                            <Flatpickr
                              hidden={isDailyReport || !isfilterDate}
                              className="form-control me-3"
                              id="FilterDate"
                              options={{
                                dateFormat: "d M, Y",
                              }}
                              value={filterEndDate}
                              onChange={(selectedDates, dateStr, instance) => {
                                setFilterEndDate(dateStr)
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>

                    <div>
                      <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={true}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        isExportOption={true}
                        headerTitle={reportdetail.ReportTitle}
                        tableID={reportdetail.reportID}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
ShowReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default ShowReport
