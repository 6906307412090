import PropTypes, { object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewCertificate as onAddNewCertificate,
  updateCertificate as onUpdateCertificate,
} from "store/actions"
import { isEmpty } from "lodash"

const formdefault = {
  Certification: "",
  Description: "",
  IsRequired: true,
  IsActive: true,
  NoExpiry: true,
  ExpiryDays: 0,
  CertificateRequiredAfters: "",
  CertificateRequiredAfterDays: 0,
}

const CertificateModal = ({ show, onEdit, onCloseClick }) => {
  const dispatch = useDispatch()
  const { requiredDays, certificate, loading } = useSelector(
    state => state.certificate
  )
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Certification: yup.string().required("Required"),
    Description: yup.string(),
    IsRequired: yup.bool().required("Required"),
    IsActive: yup.bool().required("Required"),
    NoExpiry: yup.bool(),
    ExpiryDays: yup.number().when("Expired", {
      is: value => value === true,
      then: schema =>
        schema.min(1, "Must be grater than or equal to 1").required("Required"),
      otherwise: schema => schema,
    }),
    CertificateRequiredAfters: yup.object().required("Required"),
    CertificateRequiredAfterDays: yup
      .number()

      .when("CertificateRequiredAfters", {
        is: value => value === "8",
        then: schema =>
          schema
            .min(1, "Must be grater than or equal to 1")
            .required("Required"),
        otherwise: schema => schema,
      }),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const NoExpiry = watch("NoExpiry")
  const CertificateRequiredAfters = watch("CertificateRequiredAfters")

  useEffect(() => {
    if (onEdit) {
      if (!isEmpty(certificate)) {
        reset(certificate)
      }
    } else {
      reset(formdefault)
    }
  }, [certificate, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      if (!onEdit) {
        dispatch(onAddNewCertificate(getValues()))
      } else {
        dispatch(onUpdateCertificate(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Add Certification
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Name</Label>
                <Controller
                  name="Certification"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        type="text"
                        className="form-control mb-3"
                        id="Certification"
                        required
                        invalid={!!errors.Certification}
                      />
                      {errors?.Certification?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Certification?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Description</Label>
                <Controller
                  name="Description"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        type="text"
                        className="form-control mb-3"
                        id="Description"
                      />
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Active</Label>
                <div>
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsActive"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label>Required</Label>
                <div>
                  <Controller
                    name="IsRequired"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsRequired"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label>Doesn't Expire</Label>
                <div>
                  <Controller
                    name="NoExpiry"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="NoExpiry"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              {!NoExpiry ? (
                <div className="mb-3">
                  <Label>Validity (In Years)</Label>
                  <div>
                    <Controller
                      name="ExpiryDays"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            type="number"
                            className="form-control mb-3"
                            id="ExpiryDays"
                            required
                            invalid={!!errors.ExpiryDays}
                          />
                          {errors?.ExpiryDays?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.ExpiryDays?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="mb-3">
                <Label>Days after hire certification is required</Label>
                <Controller
                  name="CertificateRequiredAfters"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="CertificateRequiredAfters"
                        options={requiredDays}
                        getOptionLabel={option =>
                          option.CertificateRequiredAfter
                        }
                        getOptionValue={option =>
                          option.CertificateRequiredAfterID
                        }
                        required
                        aria-invalid={!!errors.CertificateRequiredAfters}
                        className="select2-selection"
                      />
                      {errors?.CertificateRequiredAfters?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.CertificateRequiredAfters?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              {CertificateRequiredAfters.CertificateRequiredAfterID == 8 ? (
                <div className="mb-3">
                  <Label>Number of days</Label>
                  <div>
                    <Controller
                      name="CertificateRequiredAfterDays"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            type="number"
                            className="form-control mb-3"
                            id="CertificateRequiredAfterDays"
                            required
                            invalid={!!errors.CertificateRequiredAfterDays}
                          />
                          {errors?.CertificateRequiredAfterDays?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.CertificateRequiredAfterDays?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

CertificateModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default CertificateModal
