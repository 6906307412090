import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  getStaffDetail as onGetStaffDetail,
  getAdultDetail as onGetAdultDetail,
  getProfile as onGetProfile,
} from "store/actions"

import { getAuthUser } from "helpers/token_helper"
import { getUserImagePath, getAdultImagePath } from "helpers/imageService"
import StaffInfo from "pages/Staff/tabs/BasicInfoTab"
import AdultInfo from "pages/Adult/tabs/BasicInfoTab"
import { isEmpty } from "lodash"
import { ToastContainer } from "react-toastify"
import { useDeepCompareEffect } from "hooks"

const MyProfile = () => {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  useEffect(() => {
    if (getAuthUser()) {
      if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        setProfile(getAuthUser())
      }
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  useEffect(() => {
    if (!isEmpty(userprofile)) {
      if (userprofile.RoleID === 1 || userprofile.RoleID === 2) {
        dispatch(onGetStaffDetail(userprofile.RowID))
      } else {
        dispatch(onGetAdultDetail(userprofile.RowID))
      }
    }
  }, [userprofile])

  //////////////////STAFF STATE/////////////////////
  const { staff, loading: staffloading } = useSelector(state => state.staff)
  //////////////////////////////////////

  //////////////////Adult STATE/////////////////////
  const { adult, loading: adultloading } = useSelector(state => state.adult)
  //////////////////////////////////////

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Profile" breadcrumbItem="Your Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={
                          !isEmpty(staff)
                            ? getUserImagePath(staff.RowID, "image.jpeg")
                            : !isEmpty(adult)
                            ? getAdultImagePath(adult.RowID, "image.jpeg")
                            : ""
                        }
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        {!isEmpty(staff) && (
                          <>
                            <h5>{staff.Name}</h5>
                            <p className="mb-1">{staff.Email}</p>
                            <p className="mb-1">{staff.RoleType}</p>
                          </>
                        )}
                        {!isEmpty(adult) && (
                          <>
                            <h5>{adult.Name}</h5>
                            <p className="mb-1">{adult.Email}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {!isEmpty(staff) && (
                    <StaffInfo staff={staff} loading={staffloading} />
                  )}
                  {!isEmpty(adult) && (
                    <AdultInfo adult={adult} loading={adultloading} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
export default MyProfile
