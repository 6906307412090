import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

//Loadash
import { isEmpty } from "lodash"

import {
  addNewChild as onAddNewChild,
  getRoles as onGetRoles,
  updateChild as onUpdateChild,
  getClasses as onGetClasses,
  resetChild as onResetChild,
  updateChildPhoto as onUpdateChildPhoto,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import LocationField from "components/Common/Location/Location"
import GenderField from "components/Common/Gender/Gender"

import PhotoField from "components/Common/Crop/PhotoField"
import { getChildImagePath } from "../../../helpers/imageService"
import Spinners from "components/Common/Spinner"

function BasicInfoTab() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()

  //////////////////Child STATE/////////////////////
  const { child, loading } = useSelector(state => state.child)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    DateOfBirth: yup.string().nullable(),
    StartDate: yup.string().nullable(),
    EndDate: yup.string().nullable(),
    Address: yup.string(),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object().required("Required"),
    ZipCode: yup.string().max(10, "Zipcode should be max 10 characters"),
    Photo: yup
      .mixed()
      .nullable(true)
      .transform((_, val) => (val ? String(val) : null)),
    IsActive: yup.bool(),
    Classes: yup.object(),
    Genders: yup.object().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      StartDate: "",
      EndDate: "",
      Address: "",
      // CountryID: "",
      // StateID: "",
      // CityID: "",
      ZipCode: "",
      Photo: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useEffect(() => {
    function updateChildState() {
      dispatch(onGetRoles())
      dispatch(onGetClasses())

      //reset form with server data
      reset(child)
    }

    updateChildState()
  }, [])

  //////////////////CLASS SCHEDULE STATE/////////////////////
  const { classes } = useSelector(state => state.class1)
  //////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetChild())
    }
  }, [])

  const onCancelClick = () => {
    navigate("/children")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewChild(getValues()))
      } else {
        dispatch(onUpdateChildPhoto(getValues()))
        dispatch(onUpdateChild(getValues()))
      }
      navigate("/children")
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="4" className="order-md-3">
          <div className="mb-3">
            <PhotoField
              url={getChildImagePath(child?.RowID, child?.Photo ?? "")}
            />
          </div>
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label>First Name</Label>
            <Controller
              name="FirstName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="FirstName"
                    type="text"
                    required
                    invalid={!!errors.FirstName}
                  />
                  {errors?.FirstName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.FirstName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Last Name</Label>
            <Controller
              name="LastName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LastName"
                    type="text"
                    required
                    invalid={!!errors.LastName}
                  />
                  {errors?.LastName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LastName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>

          <div className="mb-3">
            <GenderField />
          </div>
        </Col>

        <Col sm="4">
          <div className="mb-3">
            <Label>Date of Birth</Label>
            <Controller
              name="DateOfBirth"
              control={control}
              render={({ field }) => (
                <>
                  <InputGroup>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="DateOfBirth"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      // onChange={date => setValue("DateOfBirth", date[0])}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("DateOfBirth", dateStr)
                      }}
                    />
                    <div className="input-group-text">
                      <i
                        className="mdi mdi-trash-can-outline"
                        aria-hidden="true"
                        onClick={() => setValue("DateOfBirth", "")}
                      />
                    </div>
                  </InputGroup>
                  {errors?.DateOfBirth?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.DateOfBirth?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Start Date</Label>
            <Controller
              name="StartDate"
              control={control}
              render={({ field }) => (
                <>
                  <InputGroup>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="StartDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("StartDate", dateStr)
                      }}
                    />
                    <div className="input-group-text">
                      <i
                        className="mdi mdi-trash-can-outline"
                        aria-hidden="true"
                        onClick={() => setValue("StartDate", "")}
                      />
                    </div>
                  </InputGroup>
                  {errors?.StartDate?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.StartDate?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>End Date</Label>
            <Controller
              name="EndDate"
              control={control}
              render={({ field }) => (
                <>
                  <InputGroup>
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="EndDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("EndDate", dateStr)
                      }}
                    />
                    <div className="input-group-text">
                      <i
                        className="mdi mdi-trash-can-outline"
                        aria-hidden="true"
                        onClick={() => setValue("EndDate", "")}
                      />
                    </div>
                  </InputGroup>
                  {errors?.EndDate?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.EndDate?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Home Class</Label>
            <Controller
              name="Classes"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    placeholder="Select..."
                    id="Classes"
                    options={classes.filter(x => x.RoomID > 0)}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.ClassID}
                    classNamePrefix="select2-selection"
                  />
                </>
              )}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="8">
          <LocationField />
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="primary" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
