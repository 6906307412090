/*Get STAFF SCHEDULES */
export const GET_STAFF_SCHEDULES = "GET_STAFF_SCHEDULES"
export const GET_STAFF_SCHEDULES_SUCCESS = "GET_STAFF_SCHEDULES_SUCCESS"
export const GET_STAFF_SCHEDULES_FAIL = "GET_STAFF_SCHEDULES_FAIL"

/**
 * Reset STAFF SCHEDULES
 */
export const RESET_STAFF_SCHEDULES = "RESET_STAFF"
export const RESET_STAFF_SCHEDULES_SUCCESS = "RESET_STAFF_SCHEDULES_SUCCESS"

/*Add STAFF SCHEDULES */
export const ADD_STAFF_SCHEDULES = "ADD_STAFF_SCHEDULES"
export const ADD_STAFF_SCHEDULES_SUCCESS = "ADD_STAFF_SCHEDULES_SUCCESS"
export const ADD_STAFF_SCHEDULES_FAIL = "ADD_STAFF_SCHEDULES_FAIL"

/*Get ABSENT TYPES*/
export const GET_STAFF_ABSENT_TYPES = "GET_STAFF_ABSENT_TYPES"
export const GET_STAFF_ABSENT_TYPES_SUCCESS = "GET_STAFF_ABSENT_TYPES_SUCCESS"
export const GET_STAFF_ABSENT_TYPES_FAIL = "GET_STAFF_ABSENT_TYPES_FAIL"

/**
 * Delete STAFF SCHEDULE
 */
export const DELETE_STAFF_SCHEDULE = "DELETE_STAFF_SCHEDULE"
export const DELETE_STAFF_SCHEDULE_SUCCESS = "DELETE_STAFF_SCHEDULE_SUCCESS"
export const DELETE_STAFF_SCHEDULE_FAIL = "DELETE_STAFF_SCHEDULE_FAIL"
