import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"

function Notes({ profile }) {
  if (isEmpty(profile?.Notes)) {
    return <div>No notes added</div>
  }

  return <div>{profile.Notes}</div>
}
export default Notes
