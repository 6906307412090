import React, { useEffect, useState } from "react"
//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
//controls
import {
  Col,
  Label,
  Row,
  FormFeedback,
  Card,
  CardBody,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import "./weeklytime.css"
import Spinners from "components/Common/Spinner"
import EmptyContainer from "components/Common/EmptyContainer"
import Select from "react-select"

//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"
//custom hooks
import { useDeepCompareEffect, useUserDetail } from "hooks"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
//Date filter
import Moment from "moment"
import { ToastContainer } from "react-toastify"

import DOMPurify from "dompurify"
import {
  getNoteSchedulesByNote as onGetNoteSchedulesByNote,
  getNotesView as onGetNotesView,
} from "store/actions"
import Child from "pages/Child"
import NoteScheduleModal from "pages/Learning/LearningEdit/Notes/modal/NoteScheduleModal"

const NotesView = () => {
  const dispatch = useDispatch()
  const { userprofile, relations } = useUserDetail()

  // Initialize timetable with default values
  const { noteschedules, notesView, loading } = useSelector(state => state.note)
  const [isLoading, setLoading] = useState(loading)

  const [filter, setFilter] = useState({
    rowId: "",
    noteScheduleDetail: {},
    selectedDay: "",
    selectedTime: "",
    noteModal: false,
  })

  const updateState = data => setFilter(previous => ({ ...previous, ...data }))

  // search
  const handleChildSearch = childID => {
    dispatch(onGetNotesView({ AdultID: userprofile?.UserID, ChildID: childID }))
    updateState({ rowId: "" })
  }

  const handleNote = () => {
    dispatch(onGetNoteSchedulesByNote(filter.rowId))
  }

  const onClickView = (time, item) => {
    updateState({
      noteScheduleDetail: item,
      selectedDay: item.DayName,
      selectedTime: time,
      noteModal: true,
    })
  }

  const onCloseModal = () => {
    updateState({ selectedDay: "", selectedTime: "", noteModal: false })
  }

  return (
    <>
      <NoteScheduleModal
        show={filter.noteModal}
        onView={true}
        selectedDay={filter.selectedDay}
        selectedTime={filter.selectedTime}
        data={filter.noteScheduleDetail}
        onCloseClick={() => onCloseModal()}
      />
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Learning" breadcrumbItem="Notes" />
            <Card>
              <CardBody>
                <Row>
                  <Col sm="3">
                    <div className="mb-3">
                      <Label>Child</Label>
                      <Select
                        className="select2"
                        onChange={e => handleChildSearch(e.ChildID)}
                        options={relations}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.ChildID}
                      />
                    </div>
                  </Col>

                  <Col sm="3">
                    <div className="mb-3">
                      <Label>Note</Label>
                      <Select
                        className="select2"
                        onChange={e => {
                          updateState({ rowId: e.RowID })
                        }}
                        options={notesView}
                        getOptionLabel={option => option.Title}
                        getOptionValue={option => option.NoteID}
                      />
                    </div>
                  </Col>

                  <Col xxl={2} lg={2}>
                    <div className="mb-3">
                      <Label>&nbsp;</Label>
                      <div className="position-relative h-100 hstack gap-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleNote()}
                        >
                          <i className="bx bx-search-alt align-middle"></i>{" "}
                          Proceed
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : noteschedules?.length === 0 ? (
              <EmptyContainer />
            ) : (
              <Row>
                <Col sm="12">
                  <table className="timetable">
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}>Time</th>
                        <th style={{ width: "10%" }}>Sunday</th>
                        <th style={{ width: "10%" }}>Monday</th>
                        <th style={{ width: "10%" }}>Tuesday</th>
                        <th style={{ width: "10%" }}>Wednesday</th>
                        <th style={{ width: "10%" }}>Thursday</th>
                        <th style={{ width: "10%" }}>Friday</th>
                        <th style={{ width: "10%" }}>Saturday</th>
                      </tr>
                    </thead>
                    <tbody>
                      {noteschedules &&
                        noteschedules?.map((row, index) => (
                          <tr key={index}>
                            <td height={100}>
                              {Moment(row?.Time).format("h:mm a")}
                            </td>
                            {row.NoteScheduleDetails?.map(item => (
                              <td
                                key={"td" + item.NoteScheduleDetailID}
                                className="cell"
                              >
                                {item.NoteCategoryID === 0 ? (
                                  ""
                                ) : (
                                  <div
                                    className="border rounded p-2 cursor-pointer"
                                    key={"dv" + item.NoteScheduleDetailID}
                                  >
                                    <div className="float-end">
                                      <UncontrolledDropdown className="d-inline">
                                        <DropdownToggle
                                          href="#!"
                                          tag="a"
                                          className="dropdown-toggle"
                                        >
                                          <i
                                            style={{ fontSize: "20px" }}
                                            className="bx bx-dots-vertical-rounded"
                                          />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            onClick={() =>
                                              onClickView(row?.Time, item)
                                            }
                                            href="#"
                                          >
                                            View
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                    <h6>{item.NoteCategory}</h6>
                                    <div className="mb-3">
                                      {_.truncate(item.Description, {
                                        length: 20,
                                        separator: " ",
                                      })}
                                    </div>
                                  </div>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    </>
  )
}

export default NotesView
