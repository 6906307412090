/*Get BUS */
export const GET_ALBUMS = "GET_ALBUMS"
export const GET_ALBUMS_SUCCESS = "GET_ALBUMS_SUCCESS"
export const GET_ALBUMS_FAIL = "GET_ALBUMS_FAIL"

/**
 * Delete ALBUM
 */
export const DELETE_ALBUM = "DELETE_ALBUM"
export const DELETE_ALBUM_SUCCESS = "DELETE_ALBUM_SUCCESS"
export const DELETE_ALBUM_FAIL = "DELETE_ALBUM_FAIL"
/**
 * Add ALBUM
 */
export const ADD_NEW_ALBUM = "ADD_NEW_ALBUM"
export const ADD_ALBUM_SUCCESS = "ADD_ALBUM_SUCCESS"
export const ADD_ALBUM_FAIL = "ADD_ALBUM_FAIL"

/**
 * Get ALBUM DETAILS
 */
export const GET_ALBUM_DETAIL = "GET_ALBUM_DETAIL"
export const GET_ALBUM_DETAIL_SUCCESS = "GET_ALBUM_DETAIL_SUCCESS"
export const GET_ALBUM_DETAIL_FAIL = "GET_ALBUM_DETAIL_FAIL"

/**
 * Edit ALBUM
 */
export const UPDATE_ALBUM = "UPDATE_ALBUM"
export const UPDATE_ALBUM_SUCCESS = "UPDATE_ALBUM_SUCCESS"
export const UPDATE_ALBUM_FAIL = "UPDATE_ALBUM_FAIL"
/**
 * Reset ALBUM
 */
export const RESET_ALBUM = "RESET_ALBUM"
export const RESET_ALBUM_SUCCESS = "RESET_ALBUM_SUCCESS"

export const DELETE_ALBUMMEDIA = "DELETE_ALBUMMEDIA"
export const DELETE_ALBUMMEDIA_SUCCESS = "DELETE_ALBUMMEDIA_SUCCESS"
export const DELETE_ALBUMMEDIA_FAIL = "DELETE_ALBUMMEDIA_FAIL"

export const ADD_NEW_ALBUMMEDIA = "ADD_NEW_ALBUMMEDIA"
export const ADD_NEW_ALBUMMEDIA_SUCCESS = "ADD_NEW_ALBUMMEDIA_SUCCESS"
export const ADD_NEW_ALBUMMEDIA_FAIL = "ADD_NEW_ALBUMMEDIA_FAIL"
