import {
  GET_ALBUMS,
  GET_ALBUMS_FAIL,
  GET_ALBUMS_SUCCESS,
  DELETE_ALBUM,
  DELETE_ALBUM_FAIL,
  DELETE_ALBUM_SUCCESS,
  ADD_NEW_ALBUM,
  ADD_ALBUM_SUCCESS,
  ADD_ALBUM_FAIL,
  GET_ALBUM_DETAIL,
  GET_ALBUM_DETAIL_SUCCESS,
  GET_ALBUM_DETAIL_FAIL,
  UPDATE_ALBUM,
  UPDATE_ALBUM_SUCCESS,
  UPDATE_ALBUM_FAIL,
  RESET_ALBUM_SUCCESS,
  RESET_ALBUM,
  
DELETE_ALBUMMEDIA,
DELETE_ALBUMMEDIA_SUCCESS,
DELETE_ALBUMMEDIA_FAIL,

ADD_NEW_ALBUMMEDIA,
ADD_NEW_ALBUMMEDIA_SUCCESS,
ADD_NEW_ALBUMMEDIA_FAIL
} from "./actionTypes"

export const getAlbums = () => ({
  type: GET_ALBUMS,
})

export const getAlbumsSuccess = albums => ({
  type: GET_ALBUMS_SUCCESS,
  payload: albums,
})

export const getAlbumsFail = error => ({
  type: GET_ALBUMS_FAIL,
  payload: error,
})

export const deleteAlbum = album => ({
  type: DELETE_ALBUM,
  payload: album,
})

export const deleteAlbumSuccess = album => ({
  type: DELETE_ALBUM_SUCCESS,
  payload: album,
})

export const deleteAlbumFail = error => ({
  type: DELETE_ALBUM_FAIL,
  payload: error,
})
export const addNewAlbum = album => ({
  type: ADD_NEW_ALBUM,
  payload: album,
})

export const addAlbumSuccess = album => ({
  type: ADD_ALBUM_SUCCESS,
  payload: album,
})

export const addAlbumFail = error => ({
  type: ADD_ALBUM_FAIL,
  payload: error,
})

export const getAlbumDetail = albumId => ({
  type: GET_ALBUM_DETAIL,
  albumId,
})

export const getAlbumDetailSuccess = album => ({
  type: GET_ALBUM_DETAIL_SUCCESS,
  payload: album,
})

export const getAlbumDetailFail = error => ({
  type: GET_ALBUM_DETAIL_FAIL,
  payload: error,
})

export const updateAlbum = album => ({
  type: UPDATE_ALBUM,
  payload: album,
})

export const updateAlbumSuccess = album => ({
  type: UPDATE_ALBUM_SUCCESS,
  payload: album,
})

export const updateAlbumFail = error => ({
  type: UPDATE_ALBUM_FAIL,
  payload: error,
})
export const resetAlbum = () => ({
  type: RESET_ALBUM,
})

export const resetAlbumSuccess = album => ({
  type: RESET_ALBUM_SUCCESS,
  payload: album,
})


export const deleteAlbumMedia = albumMedia => ({
  type: DELETE_ALBUMMEDIA,
  payload: albumMedia,
})

export const deleteAlbumMediaSuccess = albumMedia => ({
  type: DELETE_ALBUMMEDIA_SUCCESS,
  payload: albumMedia,
})

export const deleteAlbumMediaFail = error => ({
  type: DELETE_ALBUMMEDIA_FAIL,
  payload: error,
})
export const addNewAlbumMedia = albumMedia => ({
  type: ADD_NEW_ALBUMMEDIA,
  payload: albumMedia,
})

export const addAlbumMediaSuccess = albumMedia => ({
  type: ADD_NEW_ALBUMMEDIA_SUCCESS,
  payload: albumMedia,
})

export const addAlbumMediaFail = albumMedia => ({
  type: ADD_NEW_ALBUMMEDIA_FAIL,
  payload: albumMedia,
})