import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  getRoleTypeModules as onGetRoleTypeModules,
  addRoleTypeModules as onAddRoleTypeModules,
} from "store/actions"
import { isEmpty } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import Spinners from "components/Common/Spinner"
import { RESET_MODULE } from "../../../store/module/actionTypes"

function PermissionModal({ show, roleTypeID, onCloseClick }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  let index = 0

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    modules: yup.array(
      yup.object().shape({
        ModuleID: yup.number(),
        Module: yup.string(),
        ParentID: yup.number(),
        Linked: yup.bool().default(false),
      })
    ),
  })

  const formdefault = {}
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger, watch } =
    methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "modules",
    control,
  })

  const { roletypemodules, loading } = useSelector(state => state.module)

  useDeepCompareEffect(() => {
    if (show) dispatch(onGetRoleTypeModules(roleTypeID))
  }, [dispatch, show])

  useEffect(() => {
    setData(roletypemodules)
    setValue("modules", roletypemodules)
  }, [roletypemodules])

  const onSaveClick = () => {
    dispatch(onAddRoleTypeModules(roleTypeID, getValues()))
    dispatch({ type: RESET_MODULE })
    onCloseClick()
  }

  /*
  function onCheckBoxClick(e) {
    const isChecked = e.target.checked
    if (isChecked) {
      debugger
      let node = data.filter(x => x.ModuleID === Number(e.target.value))
      if (node[0].ParentID > 0) {
      } else {
        let childs = data
          .filter(x => x.ParentID === node[0].ModuleID)
          .map(x => ({
            ...x,
            Linked: true,
          }))
        console.log(childs)
        setValue("modules", childs)
        console.log(getValues())
      }
    }
    //   //setActualArray(arr => [...arr, e.target.value])
    // } else {
    //   // let index = actualArray.indexOf(e.target.value)
    //   // actualArray.splice(index, 1)
    //   // setActualArray(actualArray)
    // }
  }*/

  const onClosed = () => {
    reset()
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
      size="lg"
    >
      <ModalHeader className="d-flex justify-content-center">
        Manage Permissions
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <FormProvider {...methods}>
            <table style={{ marginTop: 15 }} className="table table-bordered">
              {fields &&
                fields.map((ch, j) => {
                  return (
                    <>
                      <tr key={"node" + j}>
                        <td
                          style={
                            ch.ParentID === 0
                              ? { fontWeight: "bold" }
                              : { paddingLeft: "20px" }
                          }
                        >
                          {ch.Module}
                        </td>
                        <td>
                          <div style={{ padding: 0 }}>
                            <Controller
                              name={`modules[${index}].Linked`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="checkbox"
                                    value={ch.ModuleID}
                                    defaultChecked={field.value}
                                    id={`modules[${index}].Linked`}
                                    // onClick={onCheckBoxClick}
                                    className="me-1 mb-sm-8 "
                                  />
                                </>
                              )}
                            />
                          </div>
                        </td>
                      </tr>
                      <span hidden={true}>{(index = index + 1)}</span>
                    </>
                  )
                })}
            </table>
          </FormProvider>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default PermissionModal
