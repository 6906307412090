import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import StackedColumnChart from "./StackedColumnChart"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

const Attendance = () => {
  const { attendance } = useSelector(state => state.Dashboard)
  const [periodType, setPeriodType] = useState(1)
  const [categoryData, setCategoryData] = useState([])
  const [checkInData, setCheckInData] = useState([])
  const [checkOutData, setCheckOutData] = useState([])
  const [didNotAttendData, setDidNotAttendData] = useState([])

  useEffect(() => {
    if (!isEmpty(attendance)) {
      const data = attendance.filter(x => x.TypeID == periodType)
      setCategoryData(data[0].Categories.split(","))
      setCheckInData(data[0].CheckIn.split(",").map(Number))
      setCheckOutData(data[0].CheckOut.split(",").map(Number))
      setDidNotAttendData(data[0].DidNotAttend.split(",").map(Number))
    }
  }, [attendance, periodType])

  const periodData = [
    {
      name: "Check-In",
      data: checkInData,
    },
    {
      name: "Check-Out",
      data: checkOutData,
    },
    {
      name: "Did Not Attend",
      data: didNotAttendData,
    },
  ]

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-sm-flex flex-wrap">
            <h4 className="card-title mb-4">Attendance Chart</h4>
            <div className="ms-auto">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === 1 },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod(1)
                    }}
                    id="one_month"
                  >
                    Week
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === 2 },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod(2)
                    }}
                    id="one_month"
                  >
                    Month
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === 3 },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod(3)
                    }}
                    id="one_month"
                  >
                    Year
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {categoryData && (
            <StackedColumnChart
              periodData={periodData}
              categoryData={categoryData}
              dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Attendance
