import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
  Table,
} from "reactstrap"
import Flatpickr from "react-flatpickr"

//redux
import { useSelector, useDispatch } from "react-redux"

//Date filter
import Moment from "moment"

import { getEnrollments as onGetEnrollments } from "../../../store/actions"

import { isEmpty } from "lodash"
import ProfilePicture from "components/Common/ProfilePicture"
import { EnumObjectTypes } from "helpers/enum_helper"
import { Calculate_Age, HandleValidDate } from "helpers/method_helper"

const EnrollmentModal = ({ show, programID, dayID, onCloseClick }) => {
  const dispatch = useDispatch()
  const [objectsList, setObjectsList] = useState([])

  const { enrollments } = useSelector(state => state.program)

  useEffect(() => {
    dispatch(onGetEnrollments())
  }, [dispatch])

  useEffect(() => {
    setObjectsList(
      enrollments.filter(x => x.DayID === dayID && x.ProgramID === programID)
    )
  }, [enrollments])

  return (
    <Modal isOpen={show} toggle={onCloseClick} size="xl" centered={true}>
      <ModalHeader className="d-flex justify-content-center"></ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="table-responsive">
          <Table className="table mb-0 table">
            <thead>
              <tr>
                <th>Child</th>
                <th>Request Details</th>
                <th>Price</th>
                <th>Class</th>
                <th>Requested By</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {objectsList &&
                objectsList.map((v, p) => {
                  return (
                    <>
                      <tr key={"object" + p}>
                        <td>
                          <ProfilePicture
                            ObjectTypeID={EnumObjectTypes.Children}
                            RowID={v.RowID}
                            Title={v.Child}
                            SubTitle={Calculate_Age(v.DateOfBirth)}
                          />
                        </td>
                        <td>
                          <p className="text-muted mb-0">{v.Program}</p>
                          <div className="font-size-12 mb-1">
                            {"Starting date : " + HandleValidDate(v.StartDate)}
                          </div>
                        </td>
                        <td>{"$" + Number(v.Price).toFixed(2)}</td>
                        <td>{v.Class}</td>
                        <td>
                          <span className="font-size-14 mb-1">
                            {v.CreatedByName}
                          </span>
                          <p className="text-muted mb-0">
                            {HandleValidDate(v.CreatedDate)}
                          </p>
                        </td>
                        <td>{v.EnrollmentType}</td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

EnrollmentModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  dayID: PropTypes.number,
  programID: PropTypes.number,
}

export default EnrollmentModal
