import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const updatePassword = profile => ({
  type: UPDATE_PASSWORD,
  payload: profile,
})

export const updatePasswordSuccess = profile => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: profile,
})

export const updatePasswordFail = error => ({
  type: UPDATE_PASSWORD_FAIL,
  payload: error,
})
