import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

const DropdownButton = ({
  label,
  items,
  textField,
  valueField,
  onSelectClick,
}) => {
  const [toggleMenu, setToggleMenu] = useState(false)
  return (
    <Dropdown
      isOpen={toggleMenu}
      toggle={() => {
        setToggleMenu(!toggleMenu)
      }}
      className="flex-shrink-0 me-3"
    >
      <DropdownToggle tag="button" className="btn btn-primary">
        {label} <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu>
        {items &&
          items.map(h => (
            <DropdownItem
              key={eval(`h.${valueField}`)}
              to="#"
              onClick={() => {
                onSelectClick(h)
              }}
            >
              {eval(`h.${textField}`)}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  )
}

DropdownButton.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  textField: PropTypes.string,
  valueField: PropTypes.string,
  onSelectClick: PropTypes.func,
}

export default DropdownButton
