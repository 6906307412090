/*Get STAFF */
export const GET_STAFFS = "GET_STAFFS"
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS"
export const GET_STAFFS_FAIL = "GET_STAFFS_FAIL"

/**
 * Delete STAFF
 */
export const DELETE_STAFF = "DELETE_STAFF"
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS"
export const DELETE_STAFF_FAIL = "DELETE_STAFF_FAIL"

/**
 * Add STAFF
 */
export const ADD_NEW_STAFF = "ADD_NEW_STAFF"
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS"
export const ADD_STAFF_FAIL = "ADD_STAFF_FAIL"

/**
 * Get STAFF DETAILS
 */
export const GET_STAFF_DETAIL = "GET_STAFF_DETAIL"
export const GET_STAFF_DETAIL_SUCCESS = "GET_STAFF_DETAIL_SUCCESS"
export const GET_STAFF_DETAIL_FAIL = "GET_STAFF_DETAIL_FAIL"

/**
 * Edit STAFF
 */
export const UPDATE_STAFF = "UPDATE_STAFF"
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS"
export const UPDATE_STAFF_FAIL = "UPDATE_STAFF_FAIL"

/**
 * Add/Edit Photo
 */

export const UPDATE_STAFF_PHOTO = "UPDATE_PHOTO"
export const UPDATE_STAFF_PHOTO_SUCCESS = "UPDATE_PHOTO_SUCCESS"
export const UPDATE_STAFF_PHOTO_FAIL = "UPDATE_PHOTO_FAIL"

/**
 * Reset STAFF
 */
export const RESET_STAFF = "RESET_STAFF"
export const RESET_STAFF_SUCCESS = "RESET_STAFF_SUCCESS"

/*Get PAYRATE */
export const GET_PAYRATES = "GET_PAYRATES"
export const GET_PAYRATES_SUCCESS = "GET_PAYRATES_SUCCESS"
export const GET_PAYRATES_FAIL = "GET_PAYRATES_FAIL"

/**
 * Delete PAYRATE
 */
export const DELETE_PAYRATE = "DELETE_PAYRATE"
export const DELETE_PAYRATE_SUCCESS = "DELETE_PAYRATE_SUCCESS"
export const DELETE_PAYRATE_FAIL = "DELETE_PAYRATE_FAIL"

/**
 * Add PAYRATE
 */
export const ADD_NEW_PAYRATE = "ADD_NEW_PAYRATE"
export const ADD_PAYRATE_SUCCESS = "ADD_PAYRATE_SUCCESS"
export const ADD_PAYRATE_FAIL = "ADD_PAYRATE_FAIL"

/**
 * Get PAYRATE DETAILS
 */
export const GET_PAYRATE_DETAIL = "GET_PAYRATE_DETAIL"
export const GET_PAYRATE_DETAIL_SUCCESS = "GET_PAYRATE_DETAIL_SUCCESS"
export const GET_PAYRATE_DETAIL_FAIL = "GET_PAYRATE_DETAIL_FAIL"

/**
 * Edit PAYRATE
 */
export const UPDATE_PAYRATE = "UPDATE_PAYRATE"
export const UPDATE_PAYRATE_SUCCESS = "UPDATE_PAYRATE_SUCCESS"
export const UPDATE_PAYRATE_FAIL = "UPDATE_PAYRATE_FAIL"

/**
 * Reset PAYRATE
 */
export const RESET_PAYRATE = "RESET_PAYRATE"
export const RESET_PAYRATE_SUCCESS = "RESET_PAYRATE_SUCCESS"

export const GET_EMPLOYMENT_TYPES = "GET_EMPLOYMENT_TYPES"
export const GET_EMPLOYMENT_TYPES_SUCCESS = "GET_EMPLOYMENT_TYPES_SUCCESS"
export const GET_EMPLOYMENT_TYPES_FAIL = "GET_EMPLOYMENT_TYPES_FAIL"

export const GET_CLASSIFICATION_TYPES = "GET_CLASSIFICATION_TYPES"
export const GET_CLASSIFICATION_TYPES_SUCCESS =
  "GET_CLASSIFICATION_TYPES_SUCCESS"
export const GET_CLASSIFICATION_TYPES_FAIL = "GET_CLASSIFICATION_TYPES_FAIL"

/*Get Staff Availability */
export const GET_STAFF_AVAILABILITY = "GET_STAFF_AVAILABILITY"
export const GET_STAFF_AVAILABILITY_SUCCESS = "GET_STAFF_AVAILABILITY_SUCCESS"
export const GET_STAFF_AVAILABILITY_FAIL = "GET_STAFF_AVAILABILITY_FAIL"

/*Add Staff Availability */
export const ADD_STAFF_AVAILABILITY = "ADD_STAFF_AVAILABILITY"
export const ADD_STAFF_AVAILABILITY_SUCCESS = "ADD_STAFF_AVAILABILITY_SUCCESS"
export const ADD_STAFF_AVAILABILITY_FAIL = "ADD_STAFF_AVAILABILITY_FAIL"

/*Add Staff Profile */
export const ADD_STAFF_PROFILE = "ADD_STAFF_PROFILE"
export const ADD_STAFF_PROFILE_SUCCESS = "ADD_STAFF_PROFILE_SUCCESS"
export const ADD_STAFF_PROFILE_FAIL = "ADD_STAFF_PROFILE_FAIL"

/*Get Staff Profile */
export const GET_STAFF_PROFILE = "GET_STAFF_PROFILE"
export const GET_STAFF_PROFILE_SUCCESS = "GET_STAFF_PROFILE_SUCCESS"
export const GET_STAFF_PROFILE_FAIL = "GET_STAFF_PROFILE_FAIL"

/*Get Staff Happening */

export const GET_HAPPENING_TYPES = "GET_HAPPENING_TYPES"
export const GET_HAPPENING_TYPES_SUCCESS = "GET_HAPPENING_TYPES_SUCCESS"
export const GET_HAPPENING_TYPES_FAIL = "GET_HAPPENING_TYPES_FAIL"

export const GET_STAFF_HAPPENING = "GET_STAFF_HAPPENING"
export const GET_STAFF_HAPPENING_SUCCESS = "GET_STAFF_HAPPENING_SUCCESS"
export const GET_STAFF_HAPPENING_FAIL = "GET_STAFF_HAPPENING_FAIL"

export const ADD_STAFF_HAPPENING = "ADD_STAFF_HAPPENING"
export const ADD_STAFF_HAPPENING_SUCCESS = "ADD_STAFF_HAPPENING_SUCCESS"
export const ADD_STAFF_HAPPENING_FAIL = "ADD_STAFF_HAPPENING_FAIL"

/**
 * Get STAFF_HAPPENING DETAILS
 */
export const GET_STAFF_HAPPENING_DETAIL = "GET_STAFF_HAPPENING_DETAIL"
export const GET_STAFF_HAPPENING_DETAIL_SUCCESS =
  "GET_STAFF_HAPPENING_DETAIL_SUCCESS"
export const GET_STAFF_HAPPENING_DETAIL_FAIL = "GET_STAFF_HAPPENING_DETAIL_FAIL"

/**
 * Edit STAFF_HAPPENING
 */
export const UPDATE_STAFF_HAPPENING = "UPDATE_STAFF_HAPPENING"
export const UPDATE_STAFF_HAPPENING_SUCCESS = "UPDATE_STAFF_HAPPENING_SUCCESS"
export const UPDATE_STAFF_HAPPENING_FAIL = "UPDATE_STAFF_HAPPENING_FAIL"

/**
 * Delete STAFF_HAPPENING
 */
export const DELETE_STAFF_HAPPENING = "DELETE_STAFF_HAPPENING"
export const DELETE_STAFF_HAPPENING_SUCCESS = "DELETE_STAFF_HAPPENING_SUCCESS"
export const DELETE_STAFF_HAPPENING_FAIL = "DELETE_STAFF_HAPPENING_FAIL"

/**
 * Reset STAFF_HAPPENING
 */
export const RESET_STAFF_HAPPENING = "RESET_STAFF_HAPPENING"
export const RESET_STAFF_HAPPENING_SUCCESS = "RESET_STAFF_HAPPENING_SUCCESS"

export const GET_HOUR_TYPES = "GET_HOUR_TYPES"
export const GET_HOUR_TYPES_SUCCESS = "GET_HOUR_TYPES_SUCCESS"
export const GET_HOUR_TYPES_FAIL = "GET_HOUR_TYPES_FAIL"

export const GET_GENDERS = "GET_GENDERS"
export const GET_GENDERS_SUCCESS = "GET_GENDERS_SUCCESS"
export const GET_GENDERS_FAIL = "GET_GENDERS_FAIL"
