import React, { useState, useEffect } from "react"

//controls
import { Col, Row } from "reactstrap"
import { createSelector } from "reselect"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import { getStaffAvailability as onGetStaffAvailability } from "store/actions"
import { isEmpty } from "lodash"

function Availability({ staffID }) {
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  //////////////////ROLE STATE/////////////////////
  const { staffavailability } = useSelector(state => state.staff)
  ///////////////////////////////////////

  useDeepCompareEffect(() => {
    dispatch(onGetStaffAvailability(staffID))
  }, [dispatch])

  useEffect(() => {
    if (isEmpty(staffavailability)) {
      return
    }

    //only linked availabilities
    const filtered = staffavailability.filter(e => e.Linked == true)

    setData(filtered)
  }, [staffavailability])

  if (isEmpty(data)) {
    return <div>No available hours set</div>
  }

  return (
    <>
      {data &&
        data.map((item, index) => {
          return (
            <div
              key={`outer${index}`}
              className="d-flex justify-content-between align-items-center mb-3"
            >
              <div>{item.Availability}</div>
              <div>
                {item.Times &&
                  item.Times.map((inner, i) => {
                    return (
                      <>
                        {Moment(inner.StartTime).format("h:mm a") +
                          "-" +
                          Moment(inner.EndTime).format("h:mm a")}
                        <br />
                      </>
                    )
                  })}
              </div>
            </div>
          )
        })}
    </>
  )
}
export default Availability
