import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "./../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import DeleteModal from "../../../../components/Common/DeleteModal"
import {
  getLessonPlans as onGetLessonPlans,
  getLessonPlanDetail as onGetLessonPlanDetail,
  deleteLessonPlan as onDeleteLessonPlan,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { useDeepCompareEffect } from "hooks"

function LessonPlans() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  // const [enrollModal, setEnrollModal] = useState(false)
  const [lesson, setLesson] = useState(false)
  const [edit, setEdit] = useState(false)

  useDeepCompareEffect(() => {
    dispatch(onGetLessonPlans())
  }, [dispatch])

  //////////////////PROGRAM SCHEDULE STATE/////////////////////
  const { lessonPlans, loading } = useSelector(state => state.lessonPlan)
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  const handleAddClick = () => {
    navigate("/learning/lesson-plans/new")
  }

  const onClickDelete = arg => {
    setLessonPlan(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (lessonPlans && lessonPlans.LessonPlanID) {
      dispatch(onDeleteLessonPlan(lessonPlans.LessonPlanID))
      setDeleteModal(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: cellProps => {
          return <>{cellProps.row.original.Name}</>
        },
      },
      {
         Header: "Class",
        accessor: "Title",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Week Date",
        accessor: "WeekDate",
        Cell: cellProps => {
          return cellProps.value
            ? moment(new Date(cellProps.value)).format("DD MMM Y")
            : ""
        },
      },
      {
        Header: "Shared",
        accessor: "IsShared",
        Cell: cellProps => {
          return cellProps.value == true ? "Yes" : "No"
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/learning/lesson-plans/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Learing" breadcrumbItem="Lesson Plan List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="btn "
                        onClick={handleAddClick}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Add lesson plans
                      </Button>
                    </div>
                    {isLoading ? (
                      <Spinners setLoading={setLoading} />
                    ) : (
                      <TableContainer
                        columns={columns}
                        data={lessonPlans}
                        isGlobalFilter={true}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    )}
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
LessonPlans.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default LessonPlans
