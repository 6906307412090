import { call, put, takeEvery } from "redux-saga/effects"

// Staff Redux States
import { GET_ROLES, GET_ROLE_TYPES } from "./actionTypes"
import {
  getRolesSuccess,
  getRolesFail,
  getRoleTypesSuccess,
  getRoleTypesFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getRoles, getRoleTypes } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchRoles() {
  try {
    const response = yield call(getRoles)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* fetchRoleTypes() {
  try {
    const response = yield call(getRoleTypes)
    yield put(getRoleTypesSuccess(response))
  } catch (error) {
    yield put(getRoleTypesFail(error))
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(GET_ROLE_TYPES, fetchRoleTypes)
}

export default roleSaga
