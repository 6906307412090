import {
  GET_CERTIFICATES,
  GET_CERTIFICATES_FAIL,
  GET_CERTIFICATES_SUCCESS,
  DELETE_CERTIFICATE,
  DELETE_CERTIFICATE_FAIL,
  DELETE_CERTIFICATE_SUCCESS,
  ADD_NEW_CERTIFICATE,
  ADD_CERTIFICATE_SUCCESS,
  ADD_CERTIFICATE_FAIL,
  GET_CERTIFICATE_DETAIL,
  GET_CERTIFICATE_DETAIL_SUCCESS,
  GET_CERTIFICATE_DETAIL_FAIL,
  UPDATE_CERTIFICATE,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_FAIL,
  GET_CERTIFICATE_DAYS,
  GET_CERTIFICATE_DAYS_SUCCESS,
  GET_CERTIFICATE_DAYS_FAIL,
  GET_STAFF_CERTIFICATION,
  GET_STAFF_CERTIFICATION_SUCCESS,
  GET_STAFF_CERTIFICATION_FAIL,
  UPDATE_STAFF_CERTIFICATION,
  UPDATE_STAFF_CERTIFICATION_SUCCESS,
  UPDATE_STAFF_CERTIFICATION_FAIL,
} from "./actionTypes"

export const getCertificates = () => ({
  type: GET_CERTIFICATES,
})

export const getCertificatesSuccess = certificates => ({
  type: GET_CERTIFICATES_SUCCESS,
  payload: certificates,
})

export const getCertificatesFail = error => ({
  type: GET_CERTIFICATES_FAIL,
  payload: error,
})

export const deleteCertificate = certificateId => ({
  type: DELETE_CERTIFICATE,
  payload: certificateId,
})

export const deleteCertificateSuccess = certificateId => ({
  type: DELETE_CERTIFICATE_SUCCESS,
  payload: certificateId,
})

export const deleteCertificateFail = error => ({
  type: DELETE_CERTIFICATE_FAIL,
  payload: error,
})
export const addNewCertificate = certificate => ({
  type: ADD_NEW_CERTIFICATE,
  payload: certificate,
})

export const addCertificateSuccess = certificate => ({
  type: ADD_CERTIFICATE_SUCCESS,
  payload: certificate,
})

export const addCertificateFail = error => ({
  type: ADD_CERTIFICATE_FAIL,
  payload: error,
})

export const getCertificateDetail = rowId => ({
  type: GET_CERTIFICATE_DETAIL,
  rowId,
})

export const getCertificateDetailSuccess = certificate => ({
  type: GET_CERTIFICATE_DETAIL_SUCCESS,
  payload: certificate,
})

export const getCertificateDetailFail = error => ({
  type: GET_CERTIFICATE_DETAIL_FAIL,
  payload: error,
})

export const updateCertificate = certificate => ({
  type: UPDATE_CERTIFICATE,
  payload: certificate,
})

export const updateCertificateSuccess = certificate => ({
  type: UPDATE_CERTIFICATE_SUCCESS,
  payload: certificate,
})

export const updateCertificateFail = error => ({
  type: UPDATE_CERTIFICATE_FAIL,
  payload: error,
})

export const getCertificateDays = () => ({
  type: GET_CERTIFICATE_DAYS,
})

export const getCertificateDaysSuccess = certificateDays => ({
  type: GET_CERTIFICATE_DAYS_SUCCESS,
  payload: certificateDays,
})

export const getCertificateDaysFail = error => ({
  type: GET_CERTIFICATE_DAYS_FAIL,
  payload: error,
})

export const getStaffCertifications = staffId => ({
  type: GET_STAFF_CERTIFICATION,
  payload: staffId,
})

export const getStaffCertificationsSuccess = staffcertifications => ({
  type: GET_STAFF_CERTIFICATION_SUCCESS,
  payload: staffcertifications,
})

export const getStaffCertificationsFail = error => ({
  type: GET_STAFF_CERTIFICATION_FAIL,
  payload: error,
})

export const updateStaffCertification = (staffId, staffcertification) => ({
  type: UPDATE_STAFF_CERTIFICATION,
  payload: { staffId, staffcertification },
})

export const updateStaffCertificationSuccess = certification => ({
  type: UPDATE_STAFF_CERTIFICATION_SUCCESS,
  payload: certification,
})

export const updateStaffCertificationFail = error => ({
  type: UPDATE_STAFF_CERTIFICATION_FAIL,
  payload: error,
})
