import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getClasses as onGetClasses,
  deleteClass as onDeleteClass,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"
import { getUserImagePath } from "helpers/imageService"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"

function Class1() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { classes, loading } = useSelector(state => state.class1)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [class1, setClass] = useState(null)

  useEffect(() => {
    if (classes && !classes.length) {
      dispatch(onGetClasses())
    }
  }, [dispatch, classes])

  const handleAddClick = () => {
    navigate("/class/new")
  }

  const onClickDelete = arg => {
    setClass(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (class1 && class1.ClassID) {
      dispatch(onDeleteClass(class1.ClassID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const ClassStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Class Type",
        accessor: "ClassType",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Home Room",
        accessor: "Room",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Enrollment Limit",
        accessor: "EnrollmentLimit",
        Cell: cellProps => {
          return cellProps.value
            ? cellProps.value
            : cellProps.row.original.ClassTypeID === 1
            ? "∞"
            : ""
        },
      },
      {
        Header: "Min Age",
        accessor: "MinAge",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Max Age",
        accessor: "MaxAge",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <ClassStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                hidden={cellProps.row.original.IsSystem ? true : false}
                to={"/class/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                hidden={cellProps.row.original.IsSystem ? true : false}
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Classes" breadcrumbItem="Class List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={classes}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add class"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Class1.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Class1
