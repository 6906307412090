import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import Select from "react-select"
import { Input, Label, FormFeedback, Row, Col } from "reactstrap"

import { getHours, getMinutes } from "../../../helpers/duration_helper"

export default function DurationField(props) {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState
  let hourList = getHours()
  let minuteList = getMinutes()

  return (
    <>
      <Row>
        <Col sm="3">
          <div className="mb-3">
            <Label>Duration</Label>
            <Controller
              name="Hours"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="Hours"
                  placeholder="hh"
                  getOptionLabel={option => option.hh}
                  getOptionValue={option => option.id}
                  options={hourList}
                  classNamePrefix="select2-selection"
                />
              )}
            />
          </div>
        </Col>
        <Col sm="3">
          <div className="mb-3">
            <Label>&nbsp;</Label>
            <Controller
              name="Minutes"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="Minutes"
                  placeholder="mm"
                  getOptionLabel={option => option.mm}
                  getOptionValue={option => option.id}
                  options={minuteList}
                  classNamePrefix="select2-selection"
                />
              )}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}
