import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Class Redux States
import {
  GET_REPORTS,
  GET_REPORT_TYPES,
  GET_REPORT_DATA,
  GET_REPORT_DETAIL,
} from "./actionTypes"
import {
  getReportsSuccess,
  getReportsFail,
  getReportTypesSuccess,
  getReportTypesFail,
  getReportDataSuccess,
  getReportDataFail,
  getReportDetailSuccess,
  getReportDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getReports,
  getReportTypes,
  getReportData,
  getReportDetail,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchReports() {
  try {
    const response = yield call(getReports)
    yield put(getReportsSuccess(response))
  } catch (error) {
    yield put(getReportsFail(error))
  }
}

function* fetchReportDetail({ payload: reportID }) {
  try {
    const response = yield call(getReportDetail, reportID)
    yield put(getReportDetailSuccess(response[0]))
  } catch (error) {
    yield put(getReportDetailFail(error))
  }
}

function* fetchReportTypes() {
  try {
    const response = yield call(getReportTypes)
    yield put(getReportTypesSuccess(response))
  } catch (error) {
    yield put(getReportTypesFail(error))
  }
}

function* fetchReportData({
  payload: { reportID, filterDate, filterEndDate },
}) {
  try {
    const request = {
      reportID: reportID,
      filterDate: filterDate,
      filterEndDate: filterEndDate,
    }
    const response = yield call(getReportData, request)
    yield put(getReportDataSuccess(response))
  } catch (error) {
    yield put(getReportDataFail(error))
  }
}

function* reportSaga() {
  yield takeEvery(GET_REPORTS, fetchReports)
  yield takeEvery(GET_REPORT_DETAIL, fetchReportDetail)
  yield takeEvery(GET_REPORT_TYPES, fetchReportTypes)
  yield takeEvery(GET_REPORT_DATA, fetchReportData)
}

export default reportSaga
