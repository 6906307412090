/*Get CERTIFICATES */
export const GET_CERTIFICATES = "GET_CERTIFICATES"
export const GET_CERTIFICATES_SUCCESS = "GET_CERTIFICATES_SUCCESS"
export const GET_CERTIFICATES_FAIL = "GET_CERTIFICATES_FAIL"

/**
 * Delete CERTIFICATE
 */
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE"
export const DELETE_CERTIFICATE_SUCCESS = "DELETE_CERTIFICATE_SUCCESS"
export const DELETE_CERTIFICATE_FAIL = "DELETE_CERTIFICATE_FAIL"
/**
 * Add CERTIFICATE
 */
export const ADD_NEW_CERTIFICATE = "ADD_NEW_CERTIFICATE"
export const ADD_CERTIFICATE_SUCCESS = "ADD_CERTIFICATE_SUCCESS"
export const ADD_CERTIFICATE_FAIL = "ADD_CERTIFICATE_FAIL"

/**
 * Get CERTIFICATE DETAILS
 */
export const GET_CERTIFICATE_DETAIL = "GET_CERTIFICATE_DETAIL"
export const GET_CERTIFICATE_DETAIL_SUCCESS = "GET_CERTIFICATE_DETAIL_SUCCESS"
export const GET_CERTIFICATE_DETAIL_FAIL = "GET_CERTIFICATE_DETAIL_FAIL"

/**
 * Edit CERTIFICATE
 */
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE"
export const UPDATE_CERTIFICATE_SUCCESS = "UPDATE_CERTIFICATE_SUCCESS"
export const UPDATE_CERTIFICATE_FAIL = "UPDATE_CERTIFICATE_FAIL"
/**
 * CERTIFICATE Required After Days
 */
export const GET_CERTIFICATE_DAYS = "GET_CERTIFICATE_DAYS"
export const GET_CERTIFICATE_DAYS_SUCCESS = "GET_CERTIFICATE_DAYS_SUCCESS"
export const GET_CERTIFICATE_DAYS_FAIL = "GET_CERTIFICATE_DAYS_FAIL"

/**
 * Get STAFF CERTIFICATIONS
 */
export const GET_STAFF_CERTIFICATION = "GET_STAFF_CERTIFICATION"
export const GET_STAFF_CERTIFICATION_SUCCESS = "GET_STAFF_CERTIFICATION_SUCCESS"
export const GET_STAFF_CERTIFICATION_FAIL = "GET_STAFF_CERTIFICATION_FAIL"

/**
 * Update STAFF CERTIFICATION
 */
export const UPDATE_STAFF_CERTIFICATION = "UPDATE_STAFF_CERTIFICATION"
export const UPDATE_STAFF_CERTIFICATION_SUCCESS =
  "UPDATE_STAFF_CERTIFICATION_SUCCESS"
export const UPDATE_STAFF_CERTIFICATION_FAIL = "UPDATE_STAFF_CERTIFICATION_FAIL"
