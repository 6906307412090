import React from "react"
import { useFieldArray, Controller, useFormContext } from "react-hook-form"
import Flatpickr from "react-flatpickr"
import Moment from "moment"

//controls
import { Col, Row, Button, FormFeedback } from "reactstrap"

export default ({ nestIndex }) => {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState

  const { fields, remove, append } = useFieldArray({
    control,
    name: `availability[${nestIndex}].Times`,
  })

  return fields.map((item, k) => {
    return (
      <Row key={`times${k}`} style={{ padding: 10 }}>
        <Col sm={5}>
          <Controller
            name={`availability[${nestIndex}].Times[${k}].StartTime`}
            control={control}
            render={({ field }) => (
              <>
                <Flatpickr
                  {...field}
                  className="form-control d-block"
                  id={`availability[${nestIndex}].Times[${k}].StartTime`}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                  }}
                  value={
                    field.value ? Moment(field.value).format("h:mm a") : ""
                  }
                  onChange={(selectedDates, dateStr, instance) => {
                    setValue(
                      `availability[${nestIndex}].Times[${k}].StartTime`,
                      Moment(selectedDates[0]).format()
                    )
                  }}
                  required
                />
              </>
            )}
          />
        </Col>
        <Col sm={5}>
          <Controller
            control={control}
            name={`availability[${nestIndex}].Times[${k}].EndTime`}
            render={({ field }) => (
              <>
                <Flatpickr
                  {...field}
                  className="form-control d-block"
                  id={`availability[${nestIndex}].Times[${k}].EndTime`}
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                  }}
                  value={
                    field.value ? Moment(field.value).format("h:mm a") : ""
                  }
                  onChange={(selectedDates, dateStr, instance) => {
                    setValue(
                      `availability[${nestIndex}].Times[${k}].EndTime`,
                      Moment(selectedDates[0]).format()
                    )
                  }}
                />
              </>
            )}
          />
        </Col>
        <Col sm={2}>
          <>
            {" "}
            <Button
              hidden={k == 0}
              onClick={() => remove(k)}
              style={{ height: "40px", marginTop: 0 }}
            >
              Delete
            </Button>
            <Button
              hidden={k > 0}
              onClick={() =>
                append({
                  ChildAvailabilityDetailID: 0,
                  StartTime: "",
                  EndTime: "",
                })
              }
              style={{ marginLeft: "10px" }}
            >
              Add
            </Button>
          </>
        </Col>
      </Row>
    )
  })
}
