import PropTypes, { number, object } from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import Select from "react-select"

//Date filter
import Moment from "moment"

import { addNewTag as onAddNewTag } from "store/actions"
import { isEmpty } from "lodash"

const formdefault = {
  TagID: 0,
  Title: "",
  ChildID: 0,
  ExpiryDate: "",
  IsPrivate: false,
}

const TagModal = ({ show, data, onCloseClick }) => {
  const dispatch = useDispatch()
  const { child, tagcategory } = useSelector(state => state.child)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    TagID: yup.number(),
    ChildID: yup.number(),
    Title: yup.string().required("Required"),
    TagCategories: yup.object().required("Required"),
    ExpiryDate: yup.string(),
    IsPrivate: yup.bool(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  useEffect(() => {
    if (isEmpty(data)) {
      let response = {
        ...formdefault,
        ChildID: child.ChildID,
      }
      reset(response)
    } else {
      reset(data)
    }
  }, [data])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddNewTag(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        {isEmpty(data) ? "Add Tag" : "Edit Tag"}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Title</Label>
                <Controller
                  name="Title"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="Title"
                        type="text"
                        required
                        invalid={!!errors.Title}
                      />
                      {errors?.Title?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Title?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Category</Label>
                <Controller
                  name="TagCategories"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="TagCategories"
                        options={tagcategory}
                        getOptionLabel={option => option.TagCategory}
                        getOptionValue={option => option.TagCategoryID}
                        required
                        aria-invalid={!!errors.TagCategories}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.TagCategories?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.TagCategories?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Expiry Date</Label>
                <Controller
                  name="ExpiryDate"
                  control={control}
                  render={({ field }) => (
                    <Flatpickr
                      {...field}
                      className="form-control d-block"
                      id="ExpiryDate"
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        setValue("ExpiryDate", dateStr)
                      }}
                    />
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Is Private</Label>
                <div>
                  <Controller
                    name="IsPrivate"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsPrivate"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

TagModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
}

export default TagModal
