import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

const SignInOut = () => {
  const { title, centerstats } = useSelector(state => state.Dashboard)

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-2">Sign In-Out/Center Stats</CardTitle>
          <div className="text-center">
            <div className="mb-2">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <p>{title}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {(centerstats || []).map((item, index) => (
                  <tr key={index}>
                    <td style={{ width: "80%" }}>
                      <p className="mb-0">{item.Title}</p>
                    </td>
                    <td>
                      <h5 className="mb-0" style={{ color: item.Color }}>
                        {item.Value}
                      </h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SignInOut
