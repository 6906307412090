import { call, put, takeEvery } from "redux-saga/effects"

// Flexible Redux States
import {
  GET_FLEXIBLES,
  DELETE_FLEXIBLE,
  ADD_NEW_FLEXIBLE,
  GET_FLEXIBLE_DETAIL,
  UPDATE_FLEXIBLE,
  UPDATE_FLEXIBLE_SWITCH,
  UPDATE_FLEXIBLE_DEFAULT_RATE,
} from "./actionTypes"
import {
  getFlexiblesFail,
  getFlexiblesSuccess,
  deleteFlexibleSuccess,
  deleteFlexibleFail,
  addFlexibleSuccess,
  addFlexibleFail,
  getFlexibleDetailSuccess,
  getFlexibleDetailFail,
  updateFlexibleSuccess,
  updateFlexibleFail,
  updateFlexibleSwitchSuccess,
  updateFlexibleSwitchFail,
  updateFlexibleDefaultRateSuccess,
  updateFlexibleDefaultRateFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFlexibles,
  deleteFlexible,
  addNewFlexible,
  getFlexibleDetail,
  updateFlexible,
  updateFlexibleSwitch,
  updateFlexibleDefaultRate,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchFlexibles() {
  try {
    const response = yield call(getFlexibles)
    yield put(getFlexiblesSuccess(response))
  } catch (error) {
    yield put(getFlexiblesFail(error))
  }
}

function* onDeleteFlexible({ payload: flexibleId }) {
  try {
    const response = yield call(deleteFlexible, flexibleId)
    yield put(deleteFlexibleSuccess(response))
    toast.success("Flexible deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteFlexibleFail(error))
    toast.error("Flexible deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewFlexible({ payload: flexible }) {
  try {
    const response = yield call(addNewFlexible, flexible)
    yield put(addFlexibleSuccess(response[0]))
    toast.success("Flexible added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addFlexibleFail(error))
    toast.error("Flexible addition failed", { autoClose: 2000 })
  }
}

function* fetchFlexibleDetail({ flexibleId }) {
  try {
    const response = yield call(getFlexibleDetail, flexibleId)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getFlexibleDetailSuccess(result))
  } catch (error) {
    yield put(getFlexibleDetailFail(error))
  }
}

function* onUpdateFlexible({ payload: flexible }) {
  try {
    const response = yield call(updateFlexible, flexible)
    yield put(updateFlexibleSuccess(response))
    toast.success("Flexible updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateFlexibleFail(error))
    toast.error("Flexible updation failed", { autoClose: 2000 })
  }
}

function* onUpdateFlexibleSwitch({ payload: { operation, payratetypeId } }) {
  try {
    const request = {
      operation: operation,
      payratetypeId: payratetypeId,
    }
    const response = yield call(updateFlexibleSwitch, request)
    yield put(updateFlexibleSwitchSuccess(response))
  } catch (error) {
    yield put(updateFlexibleSwitchFail(error))
  }
}

function* onUpdateFlexibleDefaultRate({ payload: data }) {
  try {
    const request = {
      payrateId: data.ProgramPayRateID,
      price: data.Price,
    }
    const response = yield call(updateFlexibleDefaultRate, request)
    yield put(updateFlexibleDefaultRateSuccess(response))
  } catch (error) {
    yield put(updateFlexibleDefaultRateFail(error))
  }
}

function* flexibleSaga() {
  yield takeEvery(GET_FLEXIBLES, fetchFlexibles)
  yield takeEvery(DELETE_FLEXIBLE, onDeleteFlexible)
  yield takeEvery(ADD_NEW_FLEXIBLE, onAddNewFlexible)
  yield takeEvery(GET_FLEXIBLE_DETAIL, fetchFlexibleDetail)
  yield takeEvery(UPDATE_FLEXIBLE, onUpdateFlexible)
  yield takeEvery(UPDATE_FLEXIBLE_SWITCH, onUpdateFlexibleSwitch)
  yield takeEvery(UPDATE_FLEXIBLE_DEFAULT_RATE, onUpdateFlexibleDefaultRate)
}

export default flexibleSaga
