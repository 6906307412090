import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import { Col, Label, Input, Button, Row, FormFeedback } from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import { addClassNote as onAddClassNote } from "store/actions"
import { isEmpty } from "lodash"
import PageModal from "components/Common/PageModal"

const NotesModal = ({ data, title, show, onCloseClick }) => {
  const dispatch = useDispatch()

  const { class1 } = useSelector(state => state.class1)

  const formdefault = {
    ClassNoteID: 0,
    ClassID: class1.ClassID,
    Note: "",
  }

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ClassNoteID: yup.number(),
    ClassID: yup.number(),
    Note: yup.string().required("Required"),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, getValues, trigger } = methods
  const { errors, isValid } = formState

  useEffect(() => {
    if (isEmpty(data)) {
      reset(formdefault)
    } else {
      reset(data)
    }
  }, [data])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddClassNote(getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <PageModal
      show={show}
      onCloseClick={onCloseClick}
      onSaveClick={() => onSaveClick()}
      onClosed={() => onClosed()}
      header={title}
    >
      <FormProvider {...methods}>
        <div className="float-none">
          <div className="mb-3">
            <Label>Text</Label>
            <Controller
              name="Note"
              control={control}
              render={({ field }) => (
                <>
                  <textarea
                    {...field}
                    rows={3}
                    className="form-control mb-3"
                    required
                    id="Note"
                  />
                  {errors?.Note?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Note?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
        </div>
      </FormProvider>
    </PageModal>
  )
}

NotesModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
  data: PropTypes.any,
  title: PropTypes.string,
}

export default NotesModal
