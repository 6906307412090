/*Get FEE TRANSACTION*/
export const GET_FEE_TRANSACTION = "GET_FEE_TRANSACTION"
export const GET_FEE_TRANSACTION_SUCCESS = "GET_FEE_TRANSACTION_SUCCESS"
export const GET_FEE_TRANSACTION_FAIL = "GET_FEE_TRANSACTION_FAIL"

/*Get FEE TRANSACTION DETAILS*/
export const GET_FEE_TRANSACTION_DETAILS = "GET_FEE_TRANSACTION_DETAILS"
export const GET_FEE_TRANSACTION_DETAILS_SUCCESS =
  "GET_FEE_TRANSACTION_DETAILS_SUCCESS"
export const GET_FEE_TRANSACTION_DETAILS_FAIL =
  "GET_FEE_TRANSACTION_DETAILS_FAIL"

/*Add FEE TRANSACTION*/
export const ADD_FEE_TRANSACTION = "ADD_FEE_TRANSACTION"
export const ADD_FEE_TRANSACTION_SUCCESS = "ADD_FEE_TRANSACTION_SUCCESS"
export const ADD_FEE_TRANSACTION_FAIL = "ADD_FEE_TRANSACTION_FAIL"

/*Get PAID FEE TRANSACTION*/
export const GET_PAID_FEE_TRANSACTION = "GET_PAID_FEE_TRANSACTION"
export const GET_PAID_FEE_TRANSACTION_SUCCESS =
  "GET_PAID_FEE_TRANSACTION_SUCCESS"
export const GET_PAID_FEE_TRANSACTION_FAIL = "GET_PAID_FEE_TRANSACTION_FAIL"
