import {
  GET_CHILDREN,
  GET_CHILDREN_FAIL,
  GET_CHILDREN_SUCCESS,
  DELETE_CHILD,
  DELETE_CHILD_FAIL,
  DELETE_CHILD_SUCCESS,
  ADD_NEW_CHILD,
  ADD_CHILD_SUCCESS,
  ADD_CHILD_FAIL,
  GET_CHILD_DETAIL,
  GET_CHILD_DETAIL_SUCCESS,
  GET_CHILD_DETAIL_FAIL,
  UPDATE_CHILD,
  UPDATE_CHILD_SUCCESS,
  UPDATE_CHILD_FAIL,
  RESET_CHILD_SUCCESS,
  RESET_CHILD,
  UPDATE_CHILD_PHOTO,
  UPDATE_CHILD_PHOTO_SUCCESS,
  UPDATE_CHILD_PHOTO_FAIL,
  ADD_CHILD_PROVIDED_FOOD,
  ADD_CHILD_PROVIDED_FOOD_SUCCESS,
  ADD_CHILD_PROVIDED_FOOD_FAIL,
  GET_CHILD_PROVIDED_FOOD,
  GET_CHILD_PROVIDED_FOOD_SUCCESS,
  GET_CHILD_PROVIDED_FOOD_FAIL,
  ADD_CHILD_NOTES,
  ADD_CHILD_NOTES_SUCCESS,
  ADD_CHILD_NOTES_FAIL,
  GET_CHILD_NOTES,
  GET_CHILD_NOTES_FAIL,
  GET_CHILD_NOTES_SUCCESS,
  GET_CHILD_AVAILABILITY,
  GET_CHILD_AVAILABILITY_SUCCESS,
  GET_CHILD_AVAILABILITY_FAIL,
  ADD_CHILD_AVAILABILITY,
  ADD_CHILD_AVAILABILITY_SUCCESS,
  ADD_CHILD_AVAILABILITY_FAIL,
  GET_CHILD_RELATIONS,
  GET_CHILD_RELATIONS_SUCCESS,
  GET_CHILD_RELATIONS_FAIL,
  GET_RELATION_TYPES,
  GET_RELATION_TYPES_SUCCESS,
  GET_RELATION_TYPES_FAIL,
  GET_PERMISSION_TYPES,
  GET_PERMISSION_TYPES_SUCCESS,
  GET_PERMISSION_TYPES_FAIL,
  ADD_CHILD_RELATION,
  ADD_CHILD_RELATION_SUCCESS,
  ADD_CHILD_RELATION_FAIL,
  DELETE_CHILD_RELATION,
  DELETE_CHILD_RELATION_SUCCESS,
  DELETE_CHILD_RELATION_FAIL,
  GET_HAPPENING_TYPES,
  GET_HAPPENING_TYPES_SUCCESS,
  GET_HAPPENING_TYPES_FAIL,
  GET_CHILD_HAPPENING,
  GET_CHILD_HAPPENING_SUCCESS,
  GET_CHILD_HAPPENING_FAIL,
  ADD_CHILD_HAPPENING,
  ADD_CHILD_HAPPENING_SUCCESS,
  ADD_CHILD_HAPPENING_FAIL,
  DELETE_CHILD_HAPPENING,
  DELETE_CHILD_HAPPENING_SUCCESS,
  DELETE_CHILD_HAPPENING_FAIL,
  UPDATE_CHILD_HAPPENING,
  UPDATE_CHILD_HAPPENING_SUCCESS,
  UPDATE_CHILD_HAPPENING_FAIL,
  GET_CHILD_HAPPENING_DETAIL,
  GET_CHILD_HAPPENING_DETAIL_SUCCESS,
  GET_CHILD_HAPPENING_DETAIL_FAIL,
  GET_HOUR_TYPES,
  GET_HOUR_TYPES_SUCCESS,
  GET_HOUR_TYPES_FAIL,
  GET_TAG_CATEGORY,
  GET_TAG_CATEGORY_SUCCESS,
  GET_TAG_CATEGORY_FAIL,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAIL,
  ADD_NEW_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_MESSAGES,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_MESSAGE_POSTS,
  GET_MESSAGE_POSTS_SUCCESS,
  GET_MESSAGE_POSTS_FAIL,
  ADD_NEW_MESSAGE,
  ADD_NEW_MESSAGE_SUCCESS,
  ADD_NEW_MESSAGE_FAIL,
  ADD_MESSAGE_POST,
  ADD_MESSAGE_POST_SUCCESS,
  ADD_MESSAGE_POST_FAIL,
  GET_AWAITED_MESSAGES,
  GET_AWAITED_MESSAGES_SUCCESS,
  GET_AWAITED_MESSAGES_FAIL,
  UPDATE_MESSAGE_APPROVAL,
  UPDATE_MESSAGE_APPROVAL_SUCCESS,
  UPDATE_MESSAGE_APPROVAL_FAIL,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_POST,
  DELETE_MESSAGE_POST_SUCCESS,
  DELETE_MESSAGE_POST_FAIL,
} from "./actionTypes"

export const getChildren = () => ({
  type: GET_CHILDREN,
})

export const getChildrenSuccess = children => ({
  type: GET_CHILDREN_SUCCESS,
  payload: children,
})

export const getChildrenFail = error => ({
  type: GET_CHILDREN_FAIL,
  payload: error,
})

export const deleteChild = child => ({
  type: DELETE_CHILD,
  payload: child,
})

export const deleteChildSuccess = child => ({
  type: DELETE_CHILD_SUCCESS,
  payload: child,
})

export const deleteChildFail = error => ({
  type: DELETE_CHILD_FAIL,
  payload: error,
})
export const addNewChild = child => ({
  type: ADD_NEW_CHILD,
  payload: child,
})

export const addChildSuccess = child => ({
  type: ADD_CHILD_SUCCESS,
  payload: child,
})

export const addChildFail = error => ({
  type: ADD_CHILD_FAIL,
  payload: error,
})

export const getChildDetail = childId => ({
  type: GET_CHILD_DETAIL,
  childId,
})

export const getChildDetailSuccess = child => ({
  type: GET_CHILD_DETAIL_SUCCESS,
  payload: child,
})

export const getChildDetailFail = error => ({
  type: GET_CHILD_DETAIL_FAIL,
  payload: error,
})

export const updateChild = child => ({
  type: UPDATE_CHILD,
  payload: child,
})

export const updateChildSuccess = child => ({
  type: UPDATE_CHILD_SUCCESS,
  payload: child,
})

export const updateChildFail = error => ({
  type: UPDATE_CHILD_FAIL,
  payload: error,
})
export const resetChild = () => ({
  type: RESET_CHILD,
})

export const resetChildSuccess = child => ({
  type: RESET_CHILD_SUCCESS,
  payload: child,
})
export const updateChildPhoto = adult => ({
  type: UPDATE_CHILD_PHOTO,
  payload: adult,
})

export const updateChildPhotoSuccess = adult => ({
  type: UPDATE_CHILD_PHOTO_SUCCESS,
  payload: adult,
})

export const updateChildPhotoFail = error => ({
  type: UPDATE_CHILD_PHOTO_FAIL,
  payload: error,
})

export const addParentProvidedFood = (childId, providedfoods) => ({
  type: ADD_CHILD_PROVIDED_FOOD,
  payload: { childId, providedfoods },
})

export const addParentProvidedFoodSuccess = providedfoods => ({
  type: ADD_CHILD_PROVIDED_FOOD_SUCCESS,
  payload: providedfoods,
})

export const addParentProvidedFoodFail = error => ({
  type: ADD_CHILD_PROVIDED_FOOD_FAIL,
  payload: error,
})

export const getParentProvidedFood = childId => ({
  type: GET_CHILD_PROVIDED_FOOD,
  payload: childId,
})

export const getParentProvidedFoodSuccess = providedfoods => ({
  type: GET_CHILD_PROVIDED_FOOD_SUCCESS,
  payload: providedfoods,
})

export const getParentProvidedFoodFail = error => ({
  type: GET_CHILD_PROVIDED_FOOD_FAIL,
  payload: error,
})

export const addNotes = (childId, notes) => ({
  type: ADD_CHILD_NOTES,
  payload: { childId, notes },
})

export const addNotesSuccess = notes => ({
  type: ADD_CHILD_NOTES_SUCCESS,
  payload: notes,
})

export const addNotesFail = error => ({
  type: ADD_CHILD_NOTES_FAIL,
  payload: error,
})

export const getNotes = childId => ({
  type: GET_CHILD_NOTES,
  payload: childId,
})

export const getNotesSuccess = notes => ({
  type: GET_CHILD_NOTES_SUCCESS,
  payload: notes,
})

export const getNotesFail = error => ({
  type: GET_CHILD_NOTES_FAIL,
  payload: error,
})

export const getChildAvailability = childID => ({
  type: GET_CHILD_AVAILABILITY,
  childID,
})

export const getChildAvailabilitySuccess = availabilities => ({
  type: GET_CHILD_AVAILABILITY_SUCCESS,
  payload: availabilities,
})

export const getChildAvailabilityFail = error => ({
  type: GET_CHILD_AVAILABILITY_FAIL,
  payload: error,
})

export const addChildAvailability = (childID, childavailability) => ({
  type: ADD_CHILD_AVAILABILITY,
  payload: { childID, childavailability },
})

export const addChildAvailabilitySuccess = childavailability => ({
  type: ADD_CHILD_AVAILABILITY_SUCCESS,
  payload: childavailability,
})

export const addChildAvailabilityFail = error => ({
  type: ADD_CHILD_AVAILABILITY_FAIL,
  payload: error,
})

export const getChildRelations = childId => ({
  type: GET_CHILD_RELATIONS,
  payload: childId,
})

export const getChildRelationsSuccess = relations => ({
  type: GET_CHILD_RELATIONS_SUCCESS,
  payload: relations,
})

export const getChildRelationsFail = error => ({
  type: GET_CHILD_RELATIONS_FAIL,
  payload: error,
})

export const getRelationTypes = () => ({
  type: GET_RELATION_TYPES,
})

export const getRelationTypesSuccess = relationtypes => ({
  type: GET_RELATION_TYPES_SUCCESS,
  payload: relationtypes,
})

export const getRelationTypesFail = error => ({
  type: GET_RELATION_TYPES_FAIL,
  payload: error,
})

export const getPermissionTypes = () => ({
  type: GET_PERMISSION_TYPES,
})

export const getPermissionTypesSuccess = permissiontypes => ({
  type: GET_PERMISSION_TYPES_SUCCESS,
  payload: permissiontypes,
})

export const getPermissionTypesFail = error => ({
  type: GET_PERMISSION_TYPES_FAIL,
  payload: error,
})

export const addRelation = (childId, relation) => ({
  type: ADD_CHILD_RELATION,
  payload: { childId, relation },
})

export const addRelationSuccess = relations => ({
  type: ADD_CHILD_RELATION_SUCCESS,
  payload: relations,
})

export const addRelationFail = error => ({
  type: ADD_CHILD_RELATION_FAIL,
  payload: error,
})

export const deleteRelation = childRelationId => ({
  type: DELETE_CHILD_RELATION,
  payload: childRelationId,
})

export const deleteRelationSuccess = childRelationId => ({
  type: DELETE_CHILD_RELATION_SUCCESS,
  payload: childRelationId,
})

export const deleteRelationFail = error => ({
  type: DELETE_CHILD_RELATION_FAIL,
  payload: error,
})

export const getChildHappeningTypes = () => ({
  type: GET_HAPPENING_TYPES,
})

export const getChildHappeningTypesSuccess = happeningTypes => ({
  type: GET_HAPPENING_TYPES_SUCCESS,
  payload: happeningTypes,
})

export const getChildHappeningTypesFail = error => ({
  type: GET_HAPPENING_TYPES_FAIL,
  payload: error,
})

export const getChildHappenings = (childID, filterDate) => ({
  type: GET_CHILD_HAPPENING,
  payload: { childID, filterDate },
})

export const getChildHappeningsSuccess = stafHappenings => ({
  type: GET_CHILD_HAPPENING_SUCCESS,
  payload: stafHappenings,
})

export const getChildHappeningsFail = error => ({
  type: GET_CHILD_HAPPENING_FAIL,
  payload: error,
})

export const addChildHappening = childHappening => ({
  type: ADD_CHILD_HAPPENING,
  payload: childHappening,
})

export const addChildHappeningSuccess = childHappening => ({
  type: ADD_CHILD_HAPPENING_SUCCESS,
  payload: childHappening,
})

export const addChildHappeningFail = error => ({
  type: ADD_CHILD_HAPPENING_FAIL,
  payload: error,
})

export const getChildHappeningDetail = childHappeningID => ({
  type: GET_CHILD_HAPPENING_DETAIL,
  childHappeningID,
})

export const getChildHappeningDetailSuccess = childHappening => ({
  type: GET_CHILD_HAPPENING_DETAIL_SUCCESS,
  payload: childHappening,
})

export const getChildHappeningDetailFail = error => ({
  type: GET_CHILD_HAPPENING_DETAIL_FAIL,
  payload: error,
})

export const updateChildHappening = childHappening => ({
  type: UPDATE_CHILD_HAPPENING,
  payload: childHappening,
})

export const updateChildHappeningSuccess = childHappening => ({
  type: UPDATE_CHILD_HAPPENING_SUCCESS,
  payload: childHappening,
})

export const updateChildHappeningFail = error => ({
  type: UPDATE_CHILD_HAPPENING_FAIL,
  payload: error,
})

export const deleteChildHappening = childHappening => ({
  type: DELETE_CHILD_HAPPENING,
  payload: childHappening,
})

export const deleteChildHappeningSuccess = childHappeningID => ({
  type: DELETE_CHILD_HAPPENING_SUCCESS,
  payload: childHappeningID,
})

export const deleteChildHappeningFail = error => ({
  type: DELETE_CHILD_HAPPENING_FAIL,
  payload: error,
})

// export const getHourTypes = () => ({
//   type: GET_HOUR_TYPES,
// })

// export const getHourTypesSuccess = hourTypes => ({
//   type: GET_HOUR_TYPES_SUCCESS,
//   payload: hourTypes,
// })

// export const getHourTypesFail = error => ({
//   type: GET_HOUR_TYPES_FAIL,
//   payload: error,
// })

export const getTags = childId => ({
  type: GET_TAGS,
  payload: childId,
})

export const getTagsSuccess = tags => ({
  type: GET_TAGS_SUCCESS,
  payload: tags,
})

export const getTagsFail = error => ({
  type: GET_TAGS_FAIL,
  payload: error,
})

export const getTagCategory = () => ({
  type: GET_TAG_CATEGORY,
})

export const getTagCategorySuccess = category => ({
  type: GET_TAG_CATEGORY_SUCCESS,
  payload: category,
})

export const getTagCategoryFail = error => ({
  type: GET_TAG_CATEGORY_FAIL,
  payload: error,
})

export const deleteTag = tagId => ({
  type: DELETE_TAG,
  payload: tagId,
})

export const deleteTagSuccess = tagId => ({
  type: DELETE_TAG_SUCCESS,
  payload: tagId,
})

export const deleteTagFail = error => ({
  type: DELETE_TAG_FAIL,
  payload: error,
})

export const addNewTag = tag => ({
  type: ADD_NEW_TAG,
  payload: tag,
})

export const addNewTagSuccess = tag => ({
  type: ADD_TAG_SUCCESS,
  payload: tag,
})

export const addNewTagFail = error => ({
  type: ADD_TAG_FAIL,
  payload: error,
})

export const getMessages = childId => ({
  type: GET_MESSAGES,
  payload: childId,
})

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages,
})

export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

export const getMessagePosts = messageId => ({
  type: GET_MESSAGE_POSTS,
  payload: messageId,
})

export const getMessagePostsSuccess = messageposts => ({
  type: GET_MESSAGE_POSTS_SUCCESS,
  payload: messageposts,
})

export const getMessagePostsFail = error => ({
  type: GET_MESSAGE_POSTS_FAIL,
  payload: error,
})

export const addMessage = message => ({
  type: ADD_NEW_MESSAGE,
  payload: message,
})

export const addMessageSuccess = message => ({
  type: ADD_NEW_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = error => ({
  type: ADD_NEW_MESSAGE_FAIL,
  payload: error,
})

export const addMessagePost = post => ({
  type: ADD_MESSAGE_POST,
  payload: post,
})

export const addMessagePostSuccess = messages => ({
  type: ADD_MESSAGE_POST_SUCCESS,
  payload: messages,
})

export const addMessagePostFail = error => ({
  type: ADD_MESSAGE_POST_FAIL,
  payload: error,
})

export const getAwaitedMessages = () => ({
  type: GET_AWAITED_MESSAGES,
})

export const getAwaitedMessagesSuccess = messages => ({
  type: GET_AWAITED_MESSAGES_SUCCESS,
  payload: messages,
})

export const getAwaitedMessagesFail = error => ({
  type: GET_AWAITED_MESSAGES_FAIL,
  payload: error,
})

export const updateMessageApproval = message => ({
  type: UPDATE_MESSAGE_APPROVAL,
  payload: message,
})

export const updateMessageApprovalSuccess = messages => ({
  type: UPDATE_MESSAGE_APPROVAL_SUCCESS,
  payload: messages,
})

export const updateMessageApprovalFail = error => ({
  type: UPDATE_MESSAGE_APPROVAL_FAIL,
  payload: error,
})

export const deleteMessage = messageId => ({
  type: DELETE_MESSAGE,
  payload: messageId,
})

export const deleteMessageSuccess = messages => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: messages,
})

export const deleteMessageFail = error => ({
  type: DELETE_MESSAGE_FAIL,
  payload: error,
})

export const deleteMessagePost = messagePostId => ({
  type: DELETE_MESSAGE_POST,
  payload: messagePostId,
})

export const deleteMessagePostSuccess = messageposts => ({
  type: DELETE_MESSAGE_POST_SUCCESS,
  payload: messageposts,
})

export const deleteMessagePostFail = error => ({
  type: DELETE_MESSAGE_POST_FAIL,
  payload: error,
})
