
/**
 * Add CHARGES
 */

export const GET_CHARGETYPES = "GET_CHARGETYPES"
export const GET_CHARGETYPES_SUCCESS = "GET_CHARGETYPES_SUCCESS"
export const GET_CHARGETYPES_FAIL = "GET_CHARGETYPES_FAIL"


export const GET_CHARGES = "GET_CHARGES"
export const GET_CHARGES_SUCCESS = "GET_CHARGES_SUCCESS"
export const GET_CHARGES_FAIL = "GET_CHARGES_FAIL"


export const DELETE_CHARGE = "DELETE_CHARGE"
export const DELETE_CHARGE_FAIL = "DELETE_CHARGE_FAIL"
export const DELETE_CHARGE_SUCCESS = "DELETE_CHARGE_SUCCESS"

export const ADD_CHARGE = "ADD_CHARGE"
export const ADD_CHARGE_SUCCESS = "ADD_CHARGE_SUCCESS"
export const ADD_CHARGE_FAIL = "ADD_CHARGE_FAIL"

export const GET_CHARGE_DETAIL = "GET_CHARGE_DETAIL"
export const GET_CHARGE_DETAIL_SUCCESS = "GET_CHARGE_DETAIL_SUCCESS"
export const GET_CHARGE_DETAIL_FAIL = "GET_CHARGE_DETAIL_FAIL"

export const UPDATE_CHARGE = "UPDATE_CHARGE"
export const UPDATE_CHARGE_SUCCESS = "UPDATE_CHARGE_SUCCESS"
export const UPDATE_CHARGE_FAIL = "UPDATE_CHARGE_FAIL"

  
