import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Link } from "react-router-dom"
import classNames from "classnames"
import SimpleBar from "simplebar-react"

//redux
import { useSelector, useDispatch } from "react-redux"
import { isEmpty } from "lodash"

const Enrolled = () => {
  const { enrollments } = useSelector(state => state.Dashboard)
  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState(1)

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
  }

  useEffect(() => {
    if (!isEmpty(enrollments)) {
      const data = enrollments.filter(x => x.TypeID == periodType)
      setPeriodData(data)
    }
  }, [enrollments, periodType])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-sm-flex flex-wrap">
            <h4 className="card-title mb-4">Enrollment</h4>
            <div className="ms-auto">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === 1 },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod(1)
                    }}
                    id="one_month"
                  >
                    Enrolled
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  <Link
                    to="#"
                    className={classNames(
                      { active: periodType === 2 },
                      "nav-link"
                    )}
                    onClick={() => {
                      onChangeChartPeriod(2)
                    }}
                    id="one_month"
                  >
                    Registered
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <SimpleBar style={{ maxHeight: "250px" }}>
            <div className="table-responsive mt-4">
              <table className="table align-middle table-nowrap">
                <tbody>
                  {(periodData || []).map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "80%" }}>
                        <p className="mb-0">{item.Title}</p>
                      </td>
                      <td>
                        <p className="mb-0">
                          Capacity:{item.Capacity}/
                          {item.TypeID == 1 ? "Enrolled" : "Registered"}:{" "}
                          {item.Value}
                        </p>
                        <Progress
                          value={item.Value}
                          color="primary"
                          animated
                        ></Progress>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Enrolled
