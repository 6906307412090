/*Get MEAL SCHEDULES */
export const GET_MEAL_SCHEDULES = "GET_MEAL_SCHEDULES"
export const GET_MEAL_SCHEDULES_SUCCESS = "GET_MEAL_SCHEDULES_SUCCESS"
export const GET_MEAL_SCHEDULES_FAIL = "GET_MEAL_SCHEDULES_FAIL"

/**
 * Reset MEAL SCHEDULES
 */
export const RESET_MEAL_SCHEDULES = "RESET_MEAL"
export const RESET_MEAL_SCHEDULES_SUCCESS = "RESET_MEAL_SCHEDULES_SUCCESS"

/*Add MEAL SCHEDULES */
export const ADD_MEAL_SCHEDULES = "ADD_MEAL_SCHEDULES"
export const ADD_MEAL_SCHEDULES_SUCCESS = "ADD_MEAL_SCHEDULES_SUCCESS"
export const ADD_MEAL_SCHEDULES_FAIL = "ADD_MEAL_SCHEDULES_FAIL"

/**
 * Delete MEAL SCHEDULE
 */
export const DELETE_MEAL_SCHEDULE = "DELETE_MEAL_SCHEDULE"
export const DELETE_MEAL_SCHEDULE_SUCCESS = "DELETE_MEAL_SCHEDULE_SUCCESS"
export const DELETE_MEAL_SCHEDULE_FAIL = "DELETE_MEAL_SCHEDULE_FAIL"
