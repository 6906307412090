/*Get BUS SCHEDULES */
export const GET_BUS_SCHEDULES = "GET_BUS_SCHEDULES"
export const GET_BUS_SCHEDULES_SUCCESS = "GET_BUS_SCHEDULES_SUCCESS"
export const GET_BUS_SCHEDULES_FAIL = "GET_BUS_SCHEDULES_FAIL"

/**
 * Reset BUS SCHEDULES
 */
export const RESET_BUS_SCHEDULES = "RESET_BUS"
export const RESET_BUS_SCHEDULES_SUCCESS = "RESET_BUS_SCHEDULES_SUCCESS"

/*Add BUS SCHEDULES */
export const ADD_BUS_SCHEDULES = "ADD_BUS_SCHEDULES"
export const ADD_BUS_SCHEDULES_SUCCESS = "ADD_BUS_SCHEDULES_SUCCESS"
export const ADD_BUS_SCHEDULES_FAIL = "ADD_BUS_SCHEDULES_FAIL"

/**
 * Delete BUS SCHEDULE
 */
export const DELETE_BUS_SCHEDULE = "DELETE_BUS_SCHEDULE"
export const DELETE_BUS_SCHEDULE_SUCCESS = "DELETE_BUS_SCHEDULE_SUCCESS"
export const DELETE_BUS_SCHEDULE_FAIL = "DELETE_BUS_SCHEDULE_FAIL"
