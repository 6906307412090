import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb"

import { getAuthUser } from "helpers/token_helper"

import { getProfile as onGetProfile } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { isEmpty } from "lodash"
import { EnumObjectTypes } from "helpers/enum_helper"
import Document from "components/Common/Document"

function DocumentPage() {
  const dispatch = useDispatch()
  const [profile, setProfile] = useState({})

  useEffect(() => {
    if (getAuthUser()) {
      setProfile(getAuthUser())
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(profile)) {
      dispatch(onGetProfile(profile.RowID))
    }
  }, [profile])

  //////////////////USER STATE/////////////////////
  const { userprofile } = useSelector(state => state.user)
  //////////////////////////////////////

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Family" breadcrumbItem="Family Profile" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Document
                    objectID={userprofile.UserID}
                    objectTypeID={EnumObjectTypes.Adult}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DocumentPage
