import {
  GET_MEAL_SCHEDULES,
  GET_MEAL_SCHEDULES_SUCCESS,
  GET_MEAL_SCHEDULES_FAIL,
  RESET_MEAL_SCHEDULES,
  RESET_MEAL_SCHEDULES_SUCCESS,
  ADD_MEAL_SCHEDULES,
  ADD_MEAL_SCHEDULES_SUCCESS,
  ADD_MEAL_SCHEDULES_FAIL,
  DELETE_MEAL_SCHEDULE,
  DELETE_MEAL_SCHEDULE_SUCCESS,
  DELETE_MEAL_SCHEDULE_FAIL,
} from "./actionTypes"

export const getMealSchedules = filter => ({
  type: GET_MEAL_SCHEDULES,
  filter,
})

export const getMealSchedulesSuccess = mealSchedules => ({
  type: GET_MEAL_SCHEDULES_SUCCESS,
  payload: mealSchedules,
})

export const getMealSchedulesFail = error => ({
  type: GET_MEAL_SCHEDULES_FAIL,
  payload: error,
})

export const addMealSchedule = mealSchedules => ({
  type: ADD_MEAL_SCHEDULES,
  payload: mealSchedules,
})

export const addMealScheduleSuccess = mealSchedules => ({
  type: ADD_MEAL_SCHEDULES_SUCCESS,
  payload: mealSchedules,
})

export const addMealScheduleFail = error => ({
  type: ADD_MEAL_SCHEDULES_FAIL,
  payload: error,
})

export const resetMealSchedules = () => ({
  type: RESET_MEAL_SCHEDULES,
})

export const resetMealScheduleSuccess = mealSchedules => ({
  type: RESET_MEAL_SCHEDULES_SUCCESS,
  payload: mealSchedules,
})

export const deleteMealSchedule = mealSchedules => ({
  type: DELETE_MEAL_SCHEDULE,
  payload: mealSchedules,
})

export const deleteMealScheduleSuccess = mealSchedules => ({
  type: DELETE_MEAL_SCHEDULE_SUCCESS,
  payload: mealSchedules,
})

export const deleteMealScheduleFail = error => ({
  type: DELETE_MEAL_SCHEDULE_FAIL,
  payload: error,
})
