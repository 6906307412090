import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getCertificates as onGetCertificates,
  getCertificateDays as onGetCertificateDays,
  getCertificateDetail as onGetCertificateDetail,
  deleteCertificate as onDeleteCertificate,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import CertificateModal from "./modal/CertificateModal"

function Certification() {
  const dispatch = useDispatch()

  const { certificates, loading } = useSelector(state => state.certificate)

  const [isLoading, setLoading] = useState(loading)
  const [addModal, setAddModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [certificate, setCertificate] = useState(null)

  useEffect(() => {
    dispatch(onGetCertificates())
    dispatch(onGetCertificateDays())
  }, [])

  const handleAddClick = () => {
    setEdit(false)
    setAddModal(true)
  }

  const onClickEdit = arg => {
    dispatch(onGetCertificateDetail(arg.RowID))
    setEdit(true)
    setAddModal(true)
  }

  const onClickDelete = arg => {
    setCertificate(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (certificate && certificate.CertificationID) {
      dispatch(onDeleteCertificate(certificate.CertificationID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const onCloseModal = () => {
    setAddModal(false)
  }

  const CertificationStatus = ({ status }) => {
    switch (status) {
      case true:
        return <Badge className="bg-success"> Active </Badge>
      case false:
        return <Badge className="bg-danger"> Inactive </Badge>
      default:
        return ""
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Certification",
        accessor: "Certification",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.value}</h5>
            </>
          )
        },
      },
      {
        Header: "Validity",
        Cell: cellProps => {
          return (
            <>
              <p className="font-size-14 mb-1">
                {cellProps.row.original.NoExpiry
                  ? "Does't expire"
                  : cellProps.row.original.ExpiryDays + " year(s)"}
              </p>
            </>
          )
        },
      },
      {
        Header: "Provide",
        Cell: cellProps => {
          return (
            <>
              <>
                <p className="font-size-14 mb-1">
                  {cellProps.row.original.CertificateRequiredAfterID == 8
                    ? cellProps.row.original.CertificateRequiredAfterDays +
                      " Days"
                    : cellProps.row.original.CertificateRequiredAfter}
                </p>
              </>
            </>
          )
        },
      },
      {
        Header: "Type",
        Cell: cellProps => {
          return (
            <>
              <p className="font-size-14 mb-1">
                {cellProps.row.original.IsRequired ? "Required" : "Optional"}
              </p>
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "IsActive",
        Cell: cellProps => {
          return <CertificationStatus status={cellProps.value} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {addModal && (
        <CertificateModal
          show={addModal}
          onEdit={edit}
          onCloseClick={() => onCloseModal()}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Certifications"
            breadcrumbItem="Manage Certifications"
          />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={certificates}
                      isAddOption={true}
                      addOptionText="Add"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={false}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Certification.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Certification
