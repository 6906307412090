import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Certificate Redux States
import {
  GET_CERTIFICATES,
  DELETE_CERTIFICATE,
  ADD_NEW_CERTIFICATE,
  GET_CERTIFICATE_DETAIL,
  UPDATE_CERTIFICATE,
  GET_CERTIFICATE_DAYS,
  GET_STAFF_CERTIFICATION,
  UPDATE_STAFF_CERTIFICATION,
} from "./actionTypes"
import {
  getCertificatesFail,
  getCertificatesSuccess,
  deleteCertificateSuccess,
  deleteCertificateFail,
  addCertificateSuccess,
  addCertificateFail,
  getCertificateDetailSuccess,
  getCertificateDetailFail,
  updateCertificateSuccess,
  updateCertificateFail,
  getCertificateDaysSuccess,
  getCertificateDaysFail,
  getStaffCertificationsSuccess,
  getStaffCertificationsFail,
  updateStaffCertificationSuccess,
  updateStaffCertificationFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCertificates,
  deleteCertificate,
  addNewCertificate,
  getCertificateDetail,
  updateCertificate,
  getCertificateDays,
  getStaffCertifications,
  updateStaffCertification,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCertificates() {
  try {
    const response = yield call(getCertificates)
    yield put(getCertificatesSuccess(response))
  } catch (error) {
    yield put(getCertificatesFail(error))
  }
}

function* onDeleteCertificate({ payload: certificateId }) {
  try {
    const response = yield call(deleteCertificate, certificateId)
    yield put(deleteCertificateSuccess(response))
    toast.success("Certification deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteCertificateFail(error))
    toast.error("Certification deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewCertificate({ payload: certificate }) {
  try {
    const response = yield call(addNewCertificate, certificate)
    yield put(addCertificateSuccess(response[0]))
    toast.success("Certification added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addCertificateFail(error))
    toast.error("Certification addition failed", { autoClose: 2000 })
  }
}

function* fetchCertificateDetail({ rowId }) {
  try {
    const response = yield call(getCertificateDetail, rowId)
    let result = response[0]

    result = {
      ...result,
      Description: result.Description ?? "",
      CertificateRequiredAfters: {
        CertificateRequiredAfterID: result.CertificateRequiredAfterID,
        CertificateRequiredAfter: result.CertificateRequiredAfter,
      },
    }
    yield put(getCertificateDetailSuccess(result))
  } catch (error) {
    yield put(getCertificateDetailFail(error))
  }
}

function* onUpdateCertificate({ payload: certificate }) {
  try {
    const response = yield call(updateCertificate, certificate)
    let result = response[0]

    yield put(updateCertificateSuccess(result))
    toast.success("Certification updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateCertificateFail(error))
    toast.error("Certification updation failed", { autoClose: 2000 })
  }
}

function* fetchCertificateDays() {
  try {
    const response = yield call(getCertificateDays)
    yield put(getCertificateDaysSuccess(response))
  } catch (error) {
    yield put(getCertificateDaysFail(error))
  }
}

function* fetchStaffCertifications({ payload: staffId }) {
  try {
    const response = yield call(getStaffCertifications, staffId)
    yield put(getStaffCertificationsSuccess(response))
  } catch (error) {
    yield put(getStaffCertificationsFail(error))
  }
}

function* onUpdateStaffCertification({
  payload: { staffId, staffcertification },
}) {
  try {
    const request = {
      staffId: staffId,
      staffcertification: staffcertification,
    }
    const response = yield call(updateStaffCertification, request)

    yield put(updateStaffCertificationSuccess(response))
    toast.success("Certification updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateStaffCertificationFail(error))
    toast.error("Certification updation failed", { autoClose: 2000 })
  }
}

function* certificateSaga() {
  yield takeEvery(GET_CERTIFICATES, fetchCertificates)
  yield takeEvery(DELETE_CERTIFICATE, onDeleteCertificate)
  yield takeEvery(ADD_NEW_CERTIFICATE, onAddNewCertificate)
  yield takeEvery(GET_CERTIFICATE_DETAIL, fetchCertificateDetail)
  yield takeEvery(UPDATE_CERTIFICATE, onUpdateCertificate)
  yield takeEvery(GET_CERTIFICATE_DAYS, fetchCertificateDays)
  yield takeEvery(GET_STAFF_CERTIFICATION, fetchStaffCertifications)
  yield takeEvery(UPDATE_STAFF_CERTIFICATION, onUpdateStaffCertification)
}

export default certificateSaga
