/*Get REPORTS */
export const GET_REPORTS = "GET_REPORTS"
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS"
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL"

/*Get REPORT DETAIL */
export const GET_REPORT_DETAIL = "GET_REPORT_DETAIL"
export const GET_REPORT_DETAIL_SUCCESS = "GET_REPORT_DETAIL_SUCCESS"
export const GET_REPORT_DETAIL_FAIL = "GET_REPORT_DETAIL_FAIL"

/**
 * REPORT TYPES
 */
export const GET_REPORT_TYPES = "GET_REPORT_TYPES"
export const GET_REPORT_TYPES_SUCCESS = "GET_REPORT_TYPES_SUCCESS"
export const GET_REPORT_TYPES_FAIL = "GET_REPORT_TYPES_FAIL"

/**
 * REPORT DATA
 */
export const GET_REPORT_DATA = "GET_REPORT_DATA"
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS"
export const GET_REPORT_DATA_FAIL = "GET_REPORT_DATA_FAIL"
