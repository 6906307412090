import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import * as moment from "moment"

import {
  updateEnrollment as onUpdateEnrollment,
  getEnrollmentDetail as onGetEnrollmentDetail,
} from "store/actions"

const EnrollmentApprovalModal = ({
  show,
  programEnrollmentID,
  onCloseClick,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (show) {
      dispatch(onGetEnrollmentDetail(programEnrollmentID))
    }
  }, [dispatch, show])

  //////////////////PROGRAM STATE/////////////////////
  const { enrollment } = useSelector(state => state.program)
  //////////////////////////////////////

  const onSaveClick = e => {
    let request = {
      ProgramEnrollmentID: enrollment.ProgramEnrollmentID,
      EnrollmentTypeID: e,
    }

    dispatch(onUpdateEnrollment(request))
    onCloseClick()
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalHeader className="d-flex justify-content-center">
          Program Enrollment
        </ModalHeader>
        <ModalBody>
          {enrollment && (
            <>
              <p className="text-center">
                Requested by {enrollment?.CreatedByName} on{" "}
                {moment(enrollment?.CreatedDate).format("DD MMM Y, hh:mm A")}
              </p>
              <div>
                Date from :{" "}
                <b>
                  {moment(enrollment?.StartDate).format("DD MMM Y")}
                  {" - "}
                  {moment(enrollment?.EndDate).format("DD MMM Y")}
                </b>
              </div>
              <p>
                Assigned class : <b>{enrollment?.Class}</b>
              </p>
              <h4>
                {enrollment?.Program +
                  ", " +
                  enrollment?.DayID +
                  (enrollment?.DayID > 1 ? " days" : " day")}
                <Badge className="bg-success ms-1">
                  {enrollment?.BillingCycle}
                </Badge>
                <div>
                  {`Price - $` + Math.round(enrollment?.Price).toFixed(2)}
                </div>
              </h4>
              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Day</th>
                      <th scope="col">Start Time</th>
                      <th scope="col">End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enrollment &&
                      enrollment?.EnrollmentTimes?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{item.Day}</th>
                            <td>{moment(item.StartTime).format("hh:mm A")}</td>
                            <td>{moment(item.EndTime).format("hh:mm A")}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="btn btn-outline-dark"
            onClick={onCloseClick}
          >
            Close
          </Button>
          <Button
            type="button"
            color="btn btn-outline-danger"
            onClick={() => onSaveClick(0)}
          >
            Decline
          </Button>
          <Button
            type="button"
            color="btn btn-outline-warning"
            onClick={() => onSaveClick(2)}
          >
            Waitlist
          </Button>
          <Button
            type="button"
            color="btn btn-outline-primary"
            onClick={() => onSaveClick(3)}
          >
            Approve
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

EnrollmentApprovalModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EnrollmentApprovalModal
