import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Container, Card, CardBody, Col, Row, Alert } from "reactstrap"
//Import Images
import norecord from "../../assets/images/no-record.png"

const EmptyContainer = props => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row hidden={!props.message}>
            <Col lg="12">
              <div className="text-center mb-2">
                <h4 className="text-uppercase">{props.message}</h4>
                <div className="mt-5 text-center" hidden={!props.backURL}>
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to={props.backURL}
                  >
                    {props.linkText}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={norecord} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EmptyContainer.propTypes = {
  message: PropTypes.string,
  linkText: PropTypes.string,
  backURL: PropTypes.string,
}

export default EmptyContainer
