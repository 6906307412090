/*Get FORUM */
export const GET_FORUMS = "GET_FORUMS"
export const GET_FORUMS_SUCCESS = "GET_FORUMS_SUCCESS"
export const GET_FORUMS_FAIL = "GET_FORUMS_FAIL"

/**
 * Delete FORUM
 */
export const DELETE_FORUM = "DELETE_FORUM"
export const DELETE_FORUM_SUCCESS = "DELETE_FORUM_SUCCESS"
export const DELETE_FORUM_FAIL = "DELETE_FORUM_FAIL"
/**
 * Add FORUM
 */
export const ADD_NEW_FORUM = "ADD_NEW_FORUM"
export const ADD_FORUM_SUCCESS = "ADD_FORUM_SUCCESS"
export const ADD_FORUM_FAIL = "ADD_FORUM_FAIL"

/**
 * Get FORUM DETAILS
 */
export const GET_FORUM_DETAIL = "GET_FORUM_DETAIL"
export const GET_FORUM_DETAIL_SUCCESS = "GET_FORUM_DETAIL_SUCCESS"
export const GET_FORUM_DETAIL_FAIL = "GET_FORUM_DETAIL_FAIL"

/**
 * Edit FORUM
 */
export const UPDATE_FORUM = "UPDATE_FORUM"
export const UPDATE_FORUM_SUCCESS = "UPDATE_FORUM_SUCCESS"
export const UPDATE_FORUM_FAIL = "UPDATE_FORUM_FAIL"
/**
 * Reset FORUM
 */
export const RESET_FORUM = "RESET_FORUM"
export const RESET_FORUM_SUCCESS = "RESET_FORUM_SUCCESS"

/*Get VISIBILITY TYPES*/
export const GET_FORUM_VISIBILITY_TYPES = "GET_FORUM_VISIBILITY_TYPES"
export const GET_FORUM_VISIBILITY_TYPES_SUCCESS =
  "GET_FORUM_VISIBILITY_TYPES_SUCCESS"
export const GET_FORUM_VISIBILITY_TYPES_FAIL = "GET_FORUM_VISIBILITY_TYPES_FAIL"

/**
 * Delete FORUM POST
 */
export const DELETE_FORUM_POST = "DELETE_FORUM_POST"
export const DELETE_FORUM_POST_SUCCESS = "DELETE_FORUM_POST_SUCCESS"
export const DELETE_FORUM_POST_FAIL = "DELETE_FORUM_POST_FAIL"
/**
 * Add FORUM POST
 */
export const ADD_NEW_FORUM_POST = "ADD_NEW_FORUM_POST"
export const ADD_FORUM_POST_SUCCESS = "ADD_FORUM_POST_SUCCESS"
export const ADD_FORUM_POST_FAIL = "ADD_FORUM_POST_FAIL"

/**
 * Add/Edit FIle
 */

export const UPDATE_FORUM_POST_FILE = "UPDATE_FORUM_POST_FILE"
export const UPDATE_FORUM_POST_FILE_SUCCESS = "UPDATE_FORUM_POST_FILE_SUCCESS"
export const UPDATE_FORUM_POST_FILE_FAIL = "UPDATE_FORUM_POST_FILE_FAIL"
