import {
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_FAIL,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_FAIL,
  GET_PRIVACY_TYPES_SUCCESS,
  GET_PRIVACY_TYPES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  documents: [],
  privacytypes: [],
  error: {},
  loading: true,
}

const Document = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        loading: true,
      }
    case GET_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        documents: state.documents.filter(
          document => document.ObjectArtifactID !== action.payload
        ),
      }
    }
    case DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_DOCUMENT_SUCCESS: {
      let result = state.documents.filter(
        x =>
          x.ObjectArtifactID.toString() ===
          action.payload.ObjectArtifactID.toString()
      )

      if (result.length === 0) {
        return {
          ...state,
          documents: [...state.documents, action.payload],
        }
      } else {
        return {
          ...state,
          documents: state.documents.map(d =>
            d.ObjectArtifactID.toString() ===
            action.payload.ObjectArtifactID.toString()
              ? { d, ...action.payload }
              : d
          ),
        }
      }
    }
    case ADD_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PRIVACY_TYPES_SUCCESS:
      return {
        ...state,
        privacytypes: action.payload,
      }
    case GET_PRIVACY_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Document
