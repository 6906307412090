/*Get ROOM */
export const GET_ROOMS = "GET_ROOMS"
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS"
export const GET_ROOMS_FAIL = "GET_ROOMS_FAIL"

/**
 * Delete ROOM
 */
export const DELETE_ROOM = "DELETE_ROOM"
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS"
export const DELETE_ROOM_FAIL = "DELETE_ROOM_FAIL"
/**
 * Add ROOM
 */
export const ADD_NEW_ROOM = "ADD_NEW_ROOM"
export const ADD_ROOM_SUCCESS = "ADD_ROOM_SUCCESS"
export const ADD_ROOM_FAIL = "ADD_ROOM_FAIL"

/**
 * Get ROOM DETAILS
 */
export const GET_ROOM_DETAIL = "GET_ROOM_DETAIL"
export const GET_ROOM_DETAIL_SUCCESS = "GET_ROOM_DETAIL_SUCCESS"
export const GET_ROOM_DETAIL_FAIL = "GET_ROOM_DETAIL_FAIL"

/**
 * Edit ROOM
 */
export const UPDATE_ROOM = "UPDATE_ROOM"
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS"
export const UPDATE_ROOM_FAIL = "UPDATE_ROOM_FAIL"
/**
 * Reset ROOM
 */
export const RESET_ROOM = "RESET_ROOM"
export const RESET_ROOM_SUCCESS = "RESET_ROOM_SUCCESS"
