import { Controller, useFormContext } from "react-hook-form"
import { React, useEffect, useState, useRef } from "react"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop"

import _ from "lodash"
import "react-image-crop/dist/ReactCrop.css"

import { canvasPreview } from "./CanvasPreview"
import { useDebounceEffect } from "./DebounceEffect"

export default function PhotoField(props) {
  const methods = useFormContext()
  const { control, formState, setValue, getValues } = methods
  const { errors } = formState

  // For modal dialog
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }

  const x = 1
  const y = 1 // default aspect ratio 1:1

  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef("")
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(x / y)
  const [cropImage, setCropImage] = useState("")
  useEffect(() => {
    if (!cropImage) {
      setCropImage(props.url ? props.url : "")
    }
    return () => {
      if (open) {
        setOpen(false)
      }
    }
  }, [props.url, open])

  // This is to demonstate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 200,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      )
      reader.readAsDataURL(e.target.files[0])
    } 
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist")
    }

    previewCanvasRef.current.toBlob(blob => {
      if (!blob) {
        throw new Error("Failed to create blob")
      }
      setCropImage(URL.createObjectURL(blob))
      let filePhoto = new File([blob], `img${new Date().getTime()}.jpeg`, {
        type: "image/jpeg",
        lastModified: new Date().getTime(),
      })
      setCrop(blobUrlRef.current.toString() || "")
      setValue("Photo", filePhoto)
      handleClose()
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        )
      }
    },
    100,
    [completedCrop, scale, rotate]
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(x / y)
      setCrop(centerAspectCrop(width, height, x / y))
    }
  }
  return (
    <>
      {/* photo section............................... */}
      <Col sm="4">
        <Controller
          name="Photo"
          defaultValue={""}
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <div>
                <label htmlFor="button-avatar">
                  <img
                    className="img-thumbnail rounded-circle avatar-xxl"
                    src={cropImage}
                    alt="Avatar"
                    style={{ cursor: "pointer" }}
                  />
                </label>
                <input
                  accept="image/*"
                  id="button-avatar"
                  type="file"
                  onChange={async e => {
                    const file = e.target.files[0]
                    function readFileAsync() {
                      return new Promise((resolve, reject) => {
                        if (!file) {
                          return
                        }
                        const reader = new FileReader()

                        reader.onload = () => {
                          resolve(
                            `data:${file.type};base64,${btoa(reader.result)}`
                          )
                        }

                        reader.onerror = reject

                        reader.readAsBinaryString(file)
                      })
                    }
                    const newImage = await readFileAsync()

                    setCrop(undefined) // Makes crop preview update between images.
                    setImgSrc(newImage || "")
                    handleOpen()
                    onChange(newImage)
                    setCropImage(newImage)
                  }}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <Button
                  onClick={() => {
                    onChange("")
                    setCropImage("")
                  }}
                  style={{ display: "none" }}
                ></Button>
              </div>
            </>
          )}
        />
      </Col>
      <div>
        <Modal
          isOpen={open}
          toggle={() => {
            handleClose()
          }}
          className="modal-lg"
        >
          <ModalHeader>Image Crop and Upload</ModalHeader>
          <ModalBody lg={12} className="p-4 py-3 px-5">
            <div>
              <Row>
                <div className="Crop-Controls" style={{ display: "none" }}>
                  {/* <input type="file" accept="image/*" id="filePhoto" onChange={onSelectFile} /> */}
                  <div className="hide">
                    <div>
                      <label htmlFor="scale-input">Scale: </label>
                      <input
                        id="scale-input"
                        type="number"
                        step="0.1"
                        value={scale}
                        disabled={!imgSrc}
                        onChange={e => setScale(Number(e.target.value))}
                      />
                    </div>
                    <div>
                      <label htmlFor="rotate-input">Rotate: </label>
                      <input
                        id="rotate-input"
                        type="number"
                        value={rotate}
                        disabled={!imgSrc}
                        onChange={e =>
                          setRotate(
                            Math.min(
                              180,
                              Math.max(-180, Number(e.target.value))
                            )
                          )
                        }
                      />
                    </div>
                    <div>
                      <button onClick={handleToggleAspectClick}>
                        Toggle aspect {aspect ? "off" : "on"}
                      </button>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <Col lg={6}>
                  {!!imgSrc && (
                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => setCrop(percentCrop)}
                      onComplete={c => setCompletedCrop(c)}
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                        }}
                        onLoad={onImageLoad}
                      />
                    </ReactCrop>
                  )}
                </Col>
                <Col lg={6}>
                  {!!completedCrop && (
                    <>
                      <div>
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: "1px solid black",
                            objectFit: "contain",
                            width: completedCrop.width,
                            height: completedCrop.height,
                          }}
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center mt-3">
                    <Button
                      type="button"
                      className="btn-light me-1 btn-lg ms-2"
                      onClick={() => {
                        handleClose()
                      }}
                    >
                      Close
                    </Button>

                    {!!completedCrop && (
                      <>
                        <Button
                          type="button"
                          className="btn btn-success btn-lg ms-2"
                          // startIcon={<CropIcon />}
                          onClick={onDownloadCropClick}
                        >
                          Crop
                        </Button>
                        <a
                          ref={hiddenAnchorRef}
                          download
                          style={{
                            position: "absolute",
                            top: "200vh",
                            visibility: "hidden",
                          }}
                        >
                          Hidden download
                        </a>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}
