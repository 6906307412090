import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Row,
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

// simple bar
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

//import images
import wechat from "../../../../assets/images/companies/wechat.svg"
import {
  getChildImagePath,
  getMessageFileDownloadPath,
  getMessagePhotoDownloadPath,
} from "helpers/imageService"
import Moment from "moment"
import { Calculate_Age, HandleValidDate } from "helpers/method_helper"
import Spinners from "components/Common/Spinner"

import {
  getMessagePosts as onGetMessagePosts,
  addMessagePost as onAddMessagePost,
  deleteMessagePost as onDeleteMessagePost,
} from "store/actions"
import { isEmpty } from "lodash"
import { ValidExtensions } from "pages/Utility/constants"
import CustomFileInput from "components/Common/CustomFileInput"
import * as moment from "moment"

// emoji
import EmojiPicker from "emoji-picker-react"

const DetailSection = () => {
  const dispatch = useDispatch()
  const { mid } = useParams()
  const [messageID, setMessageID] = useState(0)
  const [curPost, setcurPost] = useState("")
  const [isdisable, setDisable] = useState(false)
  const [threshhold, setthreshhold] = useState(0)
  const [disDefault, setdisDefault] = useState(0)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [emoji, setEmoji] = useState(false)
  const [emojiArray, setemojiArray] = useState("")
  const [selectedReplyPost, setSelectedReplyPost] = useState(null)
  const [copyMessageAlert, setCopyMessageAlert] = useState(false)

  const { child, messages, messageposts, loading } = useSelector(
    state => state.child
  )
  const [isLoading, setLoading] = useState(loading)

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurPost(value)
      setDisable(true)
      addPost()
    }
  }

  const clearFiles = () => {
    setSelectedFiles([])
  }

  const addPost = () => {
    if (curPost !== "" || selectedFiles !== null) {
      const newPost = {
        MessageID: messageID,
        Message: curPost,
        ReplyToPostID:
          selectedReplyPost != null ? selectedReplyPost.MessagePostID : 0,
        Attachment: selectedFiles,
      }
      dispatch(onAddMessagePost(newPost))
      setcurPost("")
      setSelectedReplyPost(null)
      setdisDefault(false)
      setDisable(false)
      setEmoji(false)
      setSelectedFiles([])
    }
  }

  function threshholdDefault(event) {
    const count = event.target.value.length
    if (count > 0) {
      setdisDefault(true)
    } else {
      setdisDefault(false)
    }
    setthreshhold(event.target.value.length)
  }

  const handleRemoveFile = index => {
    const files = [...selectedFiles]
    files.splice(index, 1)
    setSelectedFiles(files)
  }

  const handleFileChange = e => {
    setSelectedFiles([...selectedFiles, e])
    // if (value) {
    //   const reader = new FileReader()
    //   reader.onload = e => {
    //     setShowImage(e.target.result)
    //     setDisable(true)
    //   }
    //   reader.readAsDataURL(value)
    //   setSelectedFiles(value)
    // }
  }

  // emoji
  const onEmojiClick = (event, emojiObject) => {
    setemojiArray([...emojiArray, emojiObject.emoji])
    setcurPost(curPost + event.emoji)
    setDisable(true)
  }

  const handleValidDate = date => {
    return moment(new Date(date)).format("DD MMM Y HH:mm")
  }

  const copyPost = e => {
    var copyText = e.closest(".conversation-list").querySelector("p").innerHTML
    console.log(copyText)
    navigator.clipboard.writeText(copyText)
    setCopyMessageAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyMessageAlert(false)
      }, 1000)
    }
  }

  useEffect(() => {
    if (!isEmpty(messages)) {
      const message = messages.filter(x => x.RowID === mid)
      setMessageID(message[0].MessageID)
      dispatch(onGetMessagePosts(message[0].MessageID))
    }
  }, [dispatch, messages, mid])

  const replyPost = e => {
    setSelectedReplyPost(e)
  }

  const deletePost = e => {
    dispatch(onDeleteMessagePost(e))
  }

  return (
    <React.Fragment>
      <div className="w-100 user-chat">
        <div className="p-4 border-bottom ">
          <Card>
            <CardBody className="border-bottom">
              <div className="d-flex">
                <img
                  src={getChildImagePath(child.RowID, "image.jpeg")}
                  alt="avatar"
                  className="rounded-circle avatar-sm"
                />
                <div className="flex-grow-1 ms-3">
                  <h5 className="fw-semibold">{child.Name}</h5>
                  <ul className="list-unstyled hstack gap-2 mb-0">
                    <li>
                      <span className="text-muted">
                        {Calculate_Age(child.DateOfBirth)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
            <CardBody>
              <div className="chat-conversation p-3">
                <SimpleBar style={{ height: "486px" }}>
                  {isLoading ? (
                    <Spinners setLoading={setLoading} />
                  ) : (
                    <ul className="list-unstyled mb-0">
                      {messageposts &&
                        (messageposts || []).map((post, index) => {
                          return (
                            <li
                              key={index}
                              style={{
                                marginLeft: 30 * (post.RowLevel - 1),
                              }}
                            >
                              {post.ReplyToPostID == 0 ? (
                                ""
                              ) : (
                                <i className="bx bx-redo fs-2 align-top"></i>
                              )}

                              <div className="conversation-list">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    href="#!"
                                    tag="a"
                                    className="dropdown-toggle"
                                  >
                                    <i className="bx bx-dots-vertical-rounded" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={e => replyPost(post)}
                                      href="#"
                                    >
                                      Reply
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={e => copyPost(e.target)}
                                      href="#"
                                    >
                                      Copy
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={e =>
                                        deletePost(post.MessagePostID)
                                      }
                                      href="#"
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <div className="ctext-wrap">
                                  <div className="conversation-name">
                                    {post.CreatedByName}
                                  </div>
                                  <p>
                                    {post.FileName ? (
                                      <a
                                        href={getForumPostImagePath(
                                          post.RowID,
                                          post.FileName
                                            ? post.FileName
                                            : "image.jpeg"
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          className=""
                                          src={getForumPostImagePath(
                                            post.RowID,
                                            post.FileName
                                              ? post.FileName
                                              : "image.jpeg"
                                          )}
                                          style={{
                                            maxWidth: "100px",
                                            height: "auto",
                                            marginRight: "2px",
                                          }}
                                          alt="File"
                                        />
                                      </a>
                                    ) : (
                                      ""
                                    )}

                                    {post.Message}
                                  </p>
                                  <p>
                                    {post.Attachments &&
                                      (post.Attachments || []).map(
                                        (attachment, e) => {
                                          return (
                                            <a
                                              key={"attachment" + e}
                                              href={getMessageFileDownloadPath(
                                                attachment.RowID,
                                                attachment.FileName
                                              )}
                                            >
                                              {attachment.FileName}
                                              {e === post.Attachments.length - 1
                                                ? ""
                                                : ", "}
                                            </a>
                                          )
                                        }
                                      )}
                                  </p>
                                  <p className="chat-time mb-0">
                                    <i className="bx bx-time-five align-middle me-1"></i>
                                    {HandleValidDate(post.CreatedDate)}
                                  </p>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                    </ul>
                  )}
                </SimpleBar>
              </div>
              {!isEmpty(selectedFiles) && (
                <div className="replymessage-block mb-0 d-flex align-items-start pe-3">
                  <div className="flex-grow-1">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Link
                          key={"file" + i}
                          to="#"
                          className="bg-light me-2 px-1 d-inline-flex align-items-center"
                          onClick={() => {
                            handleRemoveFile(i)
                          }}
                        >
                          {f.name}
                          <i
                            className="mdi mdi-delete font-size-18 text-danger ms-2"
                            id="deletetooltip"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="deletetooltip"
                          >
                            Delete
                          </UncontrolledTooltip>
                        </Link>
                      )
                    })}
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      id="close_toggle"
                      className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                      onClick={clearFiles}
                    >
                      <i className="bx bx-x align-middle fs-2"></i>
                    </button>
                  </div>
                </div>
              )}
              {selectedReplyPost && (
                <div className="replymessage-block mb-0 d-flex align-items-start p-3 bg-light">
                  <div className="flex-grow-1 ctext-wrap">
                    <div className="conversation-name">
                      <b>Replying to - </b>
                      {selectedReplyPost.CreatedByName}
                    </div>
                    <p>{selectedReplyPost.Message}</p>
                    <p className="chat-time mb-0">
                      <i className="bx bx-time-five align-middle me-1"></i>
                      {handleValidDate(selectedReplyPost.CreatedDate)}
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      id="close_toggle"
                      className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                      onClick={() => setSelectedReplyPost(null)}
                    >
                      <i className="bx bx-x align-middle fs-2"></i>
                    </button>
                  </div>
                </div>
              )}
              {copyMessageAlert && (
                <UncontrolledAlert color="warning" dismissible role="alert">
                  Message copied
                </UncontrolledAlert>
              )}
              {emoji && (
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  width={250}
                  height={382}
                />
              )}
              <div className="p-3 chat-input-section">
                <Row>
                  <Col>
                    <div className="position-relative">
                      <input
                        type="text"
                        value={curPost}
                        onKeyPress={onKeyPress}
                        onChange={e => {
                          setcurPost(e.target.value)
                          setDisable(true)
                          threshholdDefault(e)
                        }}
                        className="form-control chat-input"
                        placeholder="Enter Post..."
                        maxLength="500"
                      />
                      <div className="chat-input-links">
                        <ul className="list-inline mb-0">
                          <li
                            className="list-inline-item"
                            onClick={() => setEmoji(!emoji)}
                          >
                            <Link to="#">
                              <i
                                className="mdi mdi-emoticon-happy-outline me-1"
                                id="Emojitooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="Emojitooltip"
                              >
                                Emojis
                              </UncontrolledTooltip>
                            </Link>
                          </li>
                          <li className="list-inline-item">
                            <label
                              htmlFor="fileInput"
                              style={{ color: "#556ee6", fontSize: 16 }}
                            >
                              <i
                                className="mdi mdi-file-document-outline"
                                id="Filetooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="Filetooltip"
                              >
                                Add Files
                              </UncontrolledTooltip>
                            </label>
                            <CustomFileInput
                              id="fileInput"
                              className="d-none"
                              allowedExtension={ValidExtensions}
                              onValueChange={handleFileChange}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    {disDefault ? (
                      <span className="badgecount badge bg-success">
                        {threshhold} / 500
                      </span>
                    ) : null}
                  </Col>
                  <Col className="col-auto">
                    <Button
                      type="button"
                      color="primary"
                      disabled={!isdisable}
                      onClick={() => addPost()}
                      className="btn btn-primary btn-rounded chat-send w-md "
                    >
                      <span className="d-none d-sm-inline-block me-2">
                        Send
                      </span>
                      <i className="mdi mdi-send" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DetailSection
