/**
 * Add LESSON_PLANS
 */

export const GET_LESSON_PLANS = "GET_LESSON_PLANS"
export const GET_LESSON_PLANS_SUCCESS = "GET_LESSON_PLANS_SUCCESS"
export const GET_LESSON_PLANS_FAIL = "GET_LESSON_PLANS_FAIL"

export const DELETE_LESSON_PLAN = "DELETE_LESSON_PLAN"
export const DELETE_LESSON_PLAN_FAIL = "DELETE_LESSON_PLAN_FAIL"
export const DELETE_LESSON_PLAN_SUCCESS = "DELETE_LESSON_PLAN_SUCCESS"

export const ADD_LESSON_PLAN = "ADD_LESSON_PLAN"
export const ADD_LESSON_PLAN_SUCCESS = "ADD_LESSON_PLAN_SUCCESS"
export const ADD_LESSON_PLAN_FAIL = "ADD_LESSON_PLAN_FAIL"

export const GET_LESSON_PLAN_DETAIL = "GET_LESSON_PLAN_DETAIL"
export const GET_LESSON_PLAN_DETAIL_SUCCESS = "GET_LESSON_PLAN_DETAIL_SUCCESS"
export const GET_LESSON_PLAN_DETAIL_FAIL = "GET_LESSON_PLAN_DETAIL_FAIL"

export const UPDATE_LESSON_PLAN = "UPDATE_LESSON_PLAN"
export const UPDATE_LESSON_PLAN_SUCCESS = "UPDATE_LESSON_PLAN_SUCCESS"
export const UPDATE_LESSON_PLAN_FAIL = "UPDATE_LESSON_PLAN_FAIL"

/**
 * Reset LESSON_PLAN
 */
export const RESET_LESSON_PLAN = "RESET_LESSON_PLAN"
export const RESET_LESSON_PLAN_SUCCESS = "RESET_LESSON_PLAN_SUCCESS"

// ADD LESSON_PLAN Schedules

export const ADD_LESSON_PLAN_SCHEDULE = "ADD_LESSON_PLAN_SCHEDULE"
export const ADD_LESSON_PLAN_SCHEDULE_SUCCESS =
  "ADD_LESSON_PLAN_SCHEDULE_SUCCESS"
export const ADD_LESSON_PLAN_SCHEDULE_FAIL = "ADD_LESSON_PLAN_SCHEDULE_FAIL"

export const UPDATE_LESSON_PLAN_SCHEDULE = "UPDATE_LESSON_PLAN_SCHEDULE"
export const UPDATE_LESSON_PLAN_SCHEDULE_SUCCESS =
  "UPDATE_LESSON_PLAN_SCHEDULE_SUCCESS"
export const UPDATE_LESSON_PLAN_SCHEDULE_FAIL =
  "UPDATE_LESSON_PLAN_SCHEDULE_FAIL"

export const DELETE_LESSON_PLAN_SCHEDULE = "DELETE_LESSON_PLAN_SCHEDULE"
export const DELETE_LESSON_PLAN_SCHEDULE_SUCCESS =
  "DELETE_LESSON_PLAN_SCHEDULE_SUCCESS"
export const DELETE_LESSON_PLAN_SCHEDULE_FAIL =
  "DELETE_LESSON_PLAN_SCHEDULE_FAIL"

//getLESSON_PLANSchedulesByLESSON_PLAN
export const GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN =
  "GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN"
export const GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_SUCCESS =
  "GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_SUCCESS"
export const GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_FAIL =
  "GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_FAIL"

// Update LESSON_PLANScheduleDetails Details
export const UPDATE_LESSON_PLAN_SCHEDULE_DETAIL =
  "UPDATE_LESSON_PLAN_SCHEDULE_DETAIL"
export const UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS =
  "UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS"
export const UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL =
  "UPDATE_LESSON_PLANSCHEDULE_DETAIL_FAIL"

export const GET_LESSON_PLANSCHEDULE_DETAIL_BYNSD =
  "GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD"
export const GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_SUCCESS =
  "GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_SUCCESS"
export const GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_FAIL =
  "GET_LESSON_PLAN_SCHEDULE_DETAIL_BYNSD_FAIL"

export const DELETE_LESSON_PLAN_SCHEDULE_DETAIL =
  "DELETE_LESSON_PLAN_SCHEDULE_DETAIL"
export const DELETE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS =
  "DELETE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS"
export const DELETE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL =
  "DELETE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL"

export const GET_LESSON_PLANS_VIEW = "GET_LESSON_PLANS_VIEW"
export const GET_LESSON_PLANS_VIEW_SUCCESS = "GET_LESSON_PLANS_VIEW_SUCCESS"
export const GET_LESSON_PLANS_VIEW_FAIL = "GET_LESSON_PLANS_VIEW_FAIL"
