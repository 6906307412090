/*Get FLEXIBLE */
export const GET_FLEXIBLES = "GET_FLEXIBLES"
export const GET_FLEXIBLES_SUCCESS = "GET_FLEXIBLES_SUCCESS"
export const GET_FLEXIBLES_FAIL = "GET_FLEXIBLES_FAIL"

/**
 * Delete FLEXIBLE
 */
export const DELETE_FLEXIBLE = "DELETE_FLEXIBLE"
export const DELETE_FLEXIBLE_SUCCESS = "DELETE_FLEXIBLE_SUCCESS"
export const DELETE_FLEXIBLE_FAIL = "DELETE_FLEXIBLE_FAIL"
/**
 * Add FLEXIBLE
 */
export const ADD_NEW_FLEXIBLE = "ADD_NEW_FLEXIBLE"
export const ADD_FLEXIBLE_SUCCESS = "ADD_FLEXIBLE_SUCCESS"
export const ADD_FLEXIBLE_FAIL = "ADD_FLEXIBLE_FAIL"

/**
 * Get FLEXIBLE DETAILS
 */
export const GET_FLEXIBLE_DETAIL = "GET_FLEXIBLE_DETAIL"
export const GET_FLEXIBLE_DETAIL_SUCCESS = "GET_FLEXIBLE_DETAIL_SUCCESS"
export const GET_FLEXIBLE_DETAIL_FAIL = "GET_FLEXIBLE_DETAIL_FAIL"

/**
 * Edit FLEXIBLE
 */
export const UPDATE_FLEXIBLE = "UPDATE_FLEXIBLE"
export const UPDATE_FLEXIBLE_SUCCESS = "UPDATE_FLEXIBLE_SUCCESS"
export const UPDATE_FLEXIBLE_FAIL = "UPDATE_FLEXIBLE_FAIL"

/**
 * Update FLEXIBLE RATES SWITCH
 */
export const UPDATE_FLEXIBLE_SWITCH = "UPDATE_FLEXIBLE_SWITCH"
export const UPDATE_FLEXIBLE_SWITCH_SUCCESS = "UPDATE_FLEXIBLE_SWITCH_SUCCESS"
export const UPDATE_FLEXIBLE_SWITCH_FAIL = "UPDATE_FLEXIBLE_SWITCH_FAIL"

/**
 * Update FLEXIBLE DEFAULT RATES
 */
export const UPDATE_FLEXIBLE_DEFAULT_RATE = "UPDATE_FLEXIBLE_DEFAULT_RATE"
export const UPDATE_FLEXIBLE_DEFAULT_RATE_SUCCESS =
  "UPDATE_FLEXIBLE_DEFAULT_RATE_SUCCESS"
export const UPDATE_FLEXIBLE_DEFAULT_RATE_FAIL =
  "UPDATE_FLEXIBLE_DEFAULT_RATE_FAIL"
