import {
  GET_CURRICULUMS_FAIL,
  GET_CURRICULUMS_SUCCESS,
  DELETE_CURRICULUM_SUCCESS,
  DELETE_CURRICULUM_FAIL,
  ADD_CURRICULUM_FAIL,
  ADD_CURRICULUM_SUCCESS,
  GET_CURRICULUM_DETAIL_SUCCESS,
  GET_CURRICULUM_DETAIL_FAIL,
  UPDATE_CURRICULUM_SUCCESS,
  UPDATE_CURRICULUM_FAIL,
  RESET_CURRICULUM_SUCCESS,
  GET_CURRICULUMCATEGORIES_FAIL,
  GET_CURRICULUMCATEGORIES_SUCCESS,
  ADD_CURRICULUM_SCHEDULE_FAIL,
  ADD_CURRICULUM_SCHEDULE_SUCCESS,
  UPDATE_CURRICULUM_SCHEDULE_SUCCESS,
  UPDATE_CURRICULUM_SCHEDULE_FAIL,
  GET_CURRICULUM_SCHEDULES_BY_CURRICULUM_SUCCESS,
  GET_CURRICULUM_SCHEDULES_BY_CURRICULUM_FAIL,
  UPDATE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS,
  UPDATE_CURRICULUM_SCHEDULE_DETAIL_FAIL,
  GET_CURRICULUM_SCHEDULE_DETAIL_BYNSD_SUCCESS,
  GET_CURRICULUM_SCHEDULE_DETAIL_BYNSD_FAIL,
  DELETE_CURRICULUM_SCHEDULE_SUCCESS,
  DELETE_CURRICULUM_SCHEDULE_FAIL,
  DELETE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS,
  DELETE_CURRICULUM_SCHEDULE_DETAIL_FAIL,
  GET_CURRICULUMS_VIEW_SUCCESS,
  GET_CURRICULUMS_VIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  curriculums: [],
  curriculum: {},
  curriculumcategories: [],
  curriculumschedules: [],
  curriculumscheduleDetails: [],
  curriculumsView: [],
  error: {},
  loading: true,
}

const Curriculum = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CURRICULUMS_SUCCESS:
      return {
        ...state,
        curriculums: action.payload,
        loading: true,
      }
    case GET_CURRICULUMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CURRICULUM_SUCCESS: {
      const data = {
        ...state,
        curriculums: state.curriculums.filter(
          curriculum => curriculum.CurriculumID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        curriculums: state.curriculums.filter(
          curriculum => curriculum.CurriculumID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_CURRICULUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculums: [...state.curriculums, action.payload],
      }
    case ADD_CURRICULUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CURRICULUM_DETAIL_SUCCESS:
      return {
        ...state,
        curriculum: action.payload,
        loading: true,
      }

    case GET_CURRICULUM_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculums: state.curriculums.map(curriculum =>
          curriculum.CurriculumID.toString() ===
          action.payload.CurriculumID.toString()
            ? { curriculum, ...action.payload }
            : curriculum
        ),
      }
    case UPDATE_CURRICULUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculum: action.payload,
      }
    case GET_CURRICULUMCATEGORIES_SUCCESS:
      return {
        ...state,
        curriculumcategories: action.payload,
        loading: true,
      }
    case GET_CURRICULUMCATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CURRICULUM_SCHEDULE_SUCCESS:
      return {
        ...state,
        curriculumschedules: action.payload,
      }
    case ADD_CURRICULUM_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CURRICULUM_SCHEDULE_SUCCESS:
      return {
        ...state,
        curriculumschedules: state.curriculumschedules.map(x =>
          x.CurriculumScheduleID.toString() ===
          action.payload.CurriculumScheduleID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_CURRICULUM_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CURRICULUM_SCHEDULES_BY_CURRICULUM_SUCCESS:
      return {
        ...state,
        curriculumschedules: action.payload,
        loading: true,
      }
    case GET_CURRICULUM_SCHEDULES_BY_CURRICULUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        curriculumschedules: state.curriculumschedules.map(x =>
          x.CurriculumScheduleID.toString() ===
          action.payload.CurriculumScheduleID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_CURRICULUM_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CURRICULUM_SCHEDULE_DETAIL_BYNSD_SUCCESS:
      return {
        ...state,
        curriculumscheduleDetails: action.payload,
        loading: true,
      }
    case GET_CURRICULUM_SCHEDULE_DETAIL_BYNSD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CURRICULUM_SCHEDULE_SUCCESS: {
      return {
        ...state,
        curriculumschedules: state.curriculumschedules.filter(
          x => x.CurriculumScheduleID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_CURRICULUM_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_CURRICULUM_SCHEDULE_DETAIL_SUCCESS: {
      return {
        ...state,
        curriculumschedules: state.curriculumschedules.map(x =>
          x.CurriculumScheduleDetails.map((d, i) => {
            d.CurriculumScheduleDetailID == parseInt(action.payload)
              ? { ...d, CurriculumCategoryID: null, Description: null }
              : d
          })
        ),
      }
    }
    case DELETE_CURRICULUM_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CURRICULUMS_VIEW_SUCCESS:
      return {
        ...state,
        curriculumsView: action.payload,
        loading: true,
      }
    case GET_CURRICULUMS_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Curriculum
