import {
  GET_IMMUNIZATIONS_FAIL,
  GET_IMMUNIZATIONS_SUCCESS,
  DELETE_IMMUNIZATION_SUCCESS,
  DELETE_IMMUNIZATION_FAIL,
  ADD_IMMUNIZATION_FAIL,
  ADD_IMMUNIZATION_SUCCESS,
  GET_IMMUNIZATION_DETAIL_SUCCESS,
  GET_IMMUNIZATION_DETAIL_FAIL,
  UPDATE_IMMUNIZATION_SUCCESS,
  UPDATE_IMMUNIZATION_FAIL,
  GET_CHILD_IMMUNIZATION_SUCCESS,
  GET_CHILD_IMMUNIZATION_FAIL,
  UPDATE_CHILD_IMMUNIZATION_SUCCESS,
  UPDATE_CHILD_IMMUNIZATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  immunizations: [],
  immunization: {},
  childimmunizations: [],
  error: {},
  loading: false,
}

const Immunization = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IMMUNIZATIONS_SUCCESS:
      return {
        ...state,
        immunizations: action.payload,
        loading: true,
      }
    case GET_IMMUNIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        immunizations: state.immunizations.filter(
          c => c.ImmunizationID !== action.payload
        ),
      }
    case DELETE_IMMUNIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        immunizations: [...state.immunizations, action.payload],
      }
    case ADD_IMMUNIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_IMMUNIZATION_DETAIL_SUCCESS:
      return {
        ...state,
        immunization: action.payload,
        loading: true,
      }
    case GET_IMMUNIZATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        immunizations: state.immunizations.map(immunization =>
          immunization.ImmunizationID.toString() ===
          action.payload.ImmunizationID.toString()
            ? { immunization, ...action.payload }
            : immunization
        ),
      }
    case UPDATE_IMMUNIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CHILD_IMMUNIZATION_SUCCESS:
      return {
        ...state,
        childimmunizations: action.payload,
        loading: true,
      }
    case GET_CHILD_IMMUNIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CHILD_IMMUNIZATION_SUCCESS: {
      return {
        ...state,
        childimmunizations: state.childimmunizations.map(e => ({
          ...e,
          Doses: e.Doses.map(f =>
            f.ImmunizationID.toString() ===
              action.payload.ImmunizationID.toString() &&
            f.DoseID.toString() === action.payload.DoseID.toString()
              ? {
                  f,
                  ...action.payload,
                }
              : f
          ),
        })),
      }
    }

    case UPDATE_CHILD_IMMUNIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Immunization
