import React, { useEffect, useState, Fragment } from "react"
import { Table, Row, Col, Badge } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { isEmpty } from "lodash"

import { getStaffCertifications as onGetStaffCertifications } from "store/actions"
import CertificationModal from "../modal/CertificationModal"

function Certification() {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({})

  const { staff } = useSelector(state => state.staff)

  useEffect(() => {
    dispatch(onGetStaffCertifications(staff.StaffID))
  }, [])

  const { staffcertifications } = useSelector(state => state.certificate)

  const onModalClick = arg => {
    setData(arg)
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const ApplyStyle = (issueDate, dueDate, optOut, expiryDate) => {
    if (optOut) {
      return 3
    }

    if (expiryDate) {
      if (Moment(expiryDate).toDate() < Moment().toDate()) {
        return 2
      }
    } else {
      if (Moment(issueDate).isValid()) {
        return 1
      } else {
        if (Moment().diff(dueDate, "day") > 0) {
          return 2
        }
      }
    }
  }

  return (
    <Fragment>
      {showModal && (
        <CertificationModal
          show={showModal}
          data={data}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="table-responsive">
        <Table className="table table-bordered border-secondary mb-0">
          <thead>
            <tr>
              <th>Certification</th>
              <th>Issue Date</th>
            </tr>
          </thead>
          <tbody>
            {staffcertifications.map((v, i) => {
              return (
                <tr key={i}>
                  <td>
                    {v.Certification}
                    <p>
                      {v.IsRequired ? (
                        <Badge className="bg-success"> Required</Badge>
                      ) : (
                        <Badge className="bg-danger"> Optional </Badge>
                      )}
                    </p>
                  </td>
                  <td
                    style={
                      ApplyStyle(
                        v.IssueDate,
                        v.DueDate,
                        v.Optout,
                        v.ExpiryDate
                      ) == 1
                        ? { backgroundColor: "#D9F5C9", color: "" }
                        : ApplyStyle(
                            v.IssueDate,
                            v.DueDate,
                            v.Optout,
                            v.ExpiryDate
                          ) == 2
                        ? { backgroundColor: "#FBEBF1", color: "#FC397A" }
                        : {}
                    }
                    className={"cursor-pointer"}
                    onClick={() => onModalClick(v)}
                  >
                    {v.Optout
                      ? "Opted Out"
                      : v.IssueDate
                      ? Moment(v.IssueDate).format("DD/MM/yyyy")
                      : Moment(v.DueDate).isValid()
                      ? "by " + Moment(v.DueDate).format("DD/MM/yyyy")
                      : ""}
                    <p>
                      {v.ExpiryDate
                        ? "Expired on " +
                          Moment(v.ExpiryDate).format("DD/MM/yyyy")
                        : ""}
                    </p>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  )
}
export default Certification
