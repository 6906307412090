import { call, put, takeEvery } from "redux-saga/effects"

// Meal Redux States
import { GET_COUNTRIES, GET_STATES, GET_CITIES } from "./actionTypes"
import {
  getCountriesSuccess,
  getCountriesFail,
  getStatesSuccess,
  getStatesFail,
  getCitiesSuccess,
  getCitiesFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getCountries, getStates, getCities } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCountries() {
  try {
    const response = yield call(getCountries)
    yield put(getCountriesSuccess(response))
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}

function* fetchStates({ payload: countryID }) {
  try {
    const response = yield call(getStates, countryID)
    yield put(getStatesSuccess(response))
  } catch (error) {
    yield put(getStatesFail(error))
  }
}

function* fetchCities({ payload: stateID }) {
  try {
    const response = yield call(getCities, stateID)
    yield put(getCitiesSuccess(response))
  } catch (error) {
    yield put(getCitiesFail(error))
  }
}

function* helperSaga() {
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_CITIES, fetchCities)
}

export default helperSaga
