import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import { createSelector } from "reselect"
import Select, { components } from "react-select"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

import {
  addNewDocument as onAddDocument,
  getPrivacyTypes as onGetPrivacyTypes,
  getAdultRelations as onGetAdultRelations,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import { EnumObjectTypes } from "helpers/enum_helper"

const SingleValue = props => {
  const { PrivacyType, Description } = props.getValue()[0]

  return (
    <components.SingleValue {...props}>
      <span>{PrivacyType}</span>{" "}
      <div style={{ color: "darkgray", fontSize: 12 }}>{Description}</div>
    </components.SingleValue>
  )
}

const Option = props => {
  const { PrivacyType, Description } = props.data

  return (
    <components.Option {...props}>
      <span>{PrivacyType}</span>
      <div style={{ color: "darkgray", fontSize: 12 }}>{Description}</div>
    </components.Option>
  )
}

const DocumentModal = ({
  show,
  objectID,
  objectTypeID,
  data,
  onCloseClick,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetPrivacyTypes(objectTypeID))

    //Adult
    if (objectTypeID === EnumObjectTypes.Adult) {
      dispatch(onGetAdultRelations(objectID))
    }

    if (data == null) {
      reset(formDefault)
    } else {
      reset(data)
    }
  }, [dispatch])

  //////////////////DOCUMENT STATE/////////////////////
  const { privacytypes } = useSelector(state => state.document)
  ///////////////////////////////////////

  //////////////////ADULT STATE/////////////////////
  const { relations } = useSelector(state => state.adult)
  ///////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ObjectArtifactID: yup.number(),
    Attachment: yup
      .mixed()
      .required("Required")
      .transform((_, val) => (val ? String(val) : null)),
    ObjectID: yup.number(),
    ObjectTypeID: yup.number(),
    ExpiryDate: yup.string(),
    ShareToChildren: yup.bool(),
    ShareToStaff: yup.bool(),
    Description: yup.string(),
    Children: yup.object(),
    PrivacyTypes: yup.object().required("Required"),
    IsActive: yup.bool(),
  })

  const formDefault = {
    ObjectArtifactID: 0,
    Attachment: "",
    ExpiryDate: "",
    ObjectID: objectID,
    ObjectTypeID: objectTypeID,
    ShareToChildren: false,
    ShareToStaff: false,
    IsActive: true,
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: formDefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState
  const CurrentPrivacyType = watch("PrivacyTypes")

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddDocument(getValues()))
      onCloseClick()
    }
  }

  const ManageVisibility = () => {
    if (
      objectTypeID === EnumObjectTypes.Children ||
      objectTypeID === EnumObjectTypes.Adult ||
      objectTypeID === EnumObjectTypes.Staff
    )
      return true
    else if (
      CurrentPrivacyType &&
      CurrentPrivacyType.PrivacyType !== "Public"
    ) {
      setValue("ShareToChildren", false)
      setValue("ShareToStaff", false)
      return true
    } else return false
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Uploading Document
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Document</Label>
                <Controller
                  name="Attachment"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        className="form-control"
                        type="file"
                        id="Attachment"
                        value={field.value?.Attachment}
                        onChange={e => field.onChange(e.target.files[0])}
                        required
                        invalid={!!errors.Attachment}
                      />
                      {errors?.Attachment?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.Attachment?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
                <a>{data && "Document : " + getValues("FileName")}</a>
              </div>
              <div className="mb-3">
                <Label>Expiration Date</Label>
                <Controller
                  name="ExpiryDate"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Flatpickr
                        {...field}
                        className="form-control d-block"
                        id="ExpiryDate"
                        options={{
                          dateFormat: "d M, Y",
                        }}
                        onChange={(selectedDates, dateStr, instance) => {
                          setValue("ExpiryDate", dateStr)
                        }}
                      />
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Privacy</Label>
                <Controller
                  name="PrivacyTypes"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="PrivacyTypes"
                        options={privacytypes}
                        getOptionLabel={option => {
                          return `${option.PrivacyType} ${option.Description}`
                        }}
                        getOptionValue={option => option.PrivacyTypeID}
                        components={{
                          SingleValue,
                          Option,
                        }}
                        required
                        aria-invalid={!!errors.PrivacyTypes}
                        classNamePrefix="select2-selection"
                      />
                      {errors?.PrivacyTypes?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.PrivacyTypes?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div
                className="mb-3"
                hidden={objectTypeID !== EnumObjectTypes.Adult}
              >
                <Label>Destination</Label>
                <Controller
                  name="Children"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id="Children"
                        options={relations}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.ChildID}
                        classNamePrefix="select2-selection"
                      />
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>File Description</Label>
                <Controller
                  name="Description"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <textarea
                      {...field}
                      rows={3}
                      className="form-control mb-3"
                      id="Description"
                    />
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Active</Label>
                <div>
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsActive"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="mb-3" hidden={ManageVisibility()}>
                <Label>Share to Profile</Label>
                <div>
                  <Controller
                    name="ShareToChildren"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="ShareToChildren"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                  {objectTypeID === EnumObjectTypes.Class
                    ? "Children with this home class"
                    : "All Children"}
                </div>
                <div>
                  <Controller
                    name="ShareToStaff"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="ShareToStaff"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                  All Staff
                </div>
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

DocumentModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default DocumentModal
