import {
  GET_CHARTS_DATA,
  GET_CHARTS_DATA_SUCCESS,
  GET_CHARTS_DATA_FAIL,
  GET_SCHEDULED_DATA,
  GET_SCHEDULED_DATA_SUCCESS,
  GET_SCHEDULED_DATA_FAIL,
  GET_LIVE_DATA,
  GET_LIVE_DATA_SUCCESS,
  GET_LIVE_DATA_FAIL,
} from "./actionTypes"

// charts data
export const getChartsData = centerId => ({
  type: GET_CHARTS_DATA,
  payload: centerId,
})

export const getChartsDataSuccess = data => ({
  type: GET_CHARTS_DATA_SUCCESS,
  payload: data,
})

export const getChartsDataFail = error => ({
  type: GET_CHARTS_DATA_FAIL,
  payload: error,
})

// scheduled data
export const getScheduledData = centerId => ({
  type: GET_SCHEDULED_DATA,
  payload: centerId,
})

export const getScheduledDataSuccess = data => ({
  type: GET_SCHEDULED_DATA_SUCCESS,
  payload: data,
})

export const getScheduledDataFail = error => ({
  type: GET_SCHEDULED_DATA_FAIL,
  payload: error,
})

// live data
export const getLiveData = centerId => ({
  type: GET_LIVE_DATA,
  payload: centerId,
})

export const getLiveDataSuccess = data => ({
  type: GET_LIVE_DATA_SUCCESS,
  payload: data,
})

export const getLiveDataFail = error => ({
  type: GET_LIVE_DATA_FAIL,
  payload: error,
})
