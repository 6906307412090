import {
  GET_CLASS_SCHEDULES,
  GET_CLASS_SCHEDULES_SUCCESS,
  GET_CLASS_SCHEDULES_FAIL,
  RESET_CLASS_SCHEDULES,
  RESET_CLASS_SCHEDULES_SUCCESS,
  ADD_CLASS_SCHEDULES,
  ADD_CLASS_SCHEDULES_SUCCESS,
  ADD_CLASS_SCHEDULES_FAIL,
  //GET_STAFF_ABSENT_TYPES,
  //GET_STAFF_ABSENT_TYPES_SUCCESS,
  DELETE_CLASS_SCHEDULE,
  DELETE_CLASS_SCHEDULE_SUCCESS,
  DELETE_CLASS_SCHEDULE_FAIL,
} from "./actionTypes"

export const getClassSchedules = filter => ({
  type: GET_CLASS_SCHEDULES,
  filter,
})

export const getClassSchedulesSuccess = classSchedules => ({
  type: GET_CLASS_SCHEDULES_SUCCESS,
  payload: classSchedules,
})

export const getClassSchedulesFail = error => ({
  type: GET_CLASS_SCHEDULES_FAIL,
  payload: error,
})

export const addClassSchedule = classSchedules => ({
  type: ADD_CLASS_SCHEDULES,
  payload: classSchedules,
})

export const addClassScheduleSuccess = classSchedules => ({
  type: ADD_CLASS_SCHEDULES_SUCCESS,
  payload: classSchedules,
})

export const addClassScheduleFail = error => ({
  type: ADD_CLASS_SCHEDULES_FAIL,
  payload: error,
})

// export const getStaffAbsentTypes = () => ({
//   type: GET_STAFF_ABSENT_TYPES,
// })

// export const getStaffAbsentTypesSuccess = absenttypes => ({
//   type: GET_STAFF_ABSENT_TYPES_SUCCESS,
//   payload: absenttypes,
// })

// export const getStaffAbsentTypesFail = error => ({
//   type: GET_CLASS_SCHEDULES_FAIL,
//   payload: error,
// })

export const resetClassSchedules = () => ({
  type: RESET_CLASS_SCHEDULES,
})

export const resetClassScheduleSuccess = classSchedules => ({
  type: RESET_CLASS_SCHEDULES_SUCCESS,
  payload: classSchedules,
})

export const deleteClassSchedule = classSchedules => ({
  type: DELETE_CLASS_SCHEDULE,
  payload: classSchedules,
})

export const deleteClassScheduleSuccess = classSchedules => ({
  type: DELETE_CLASS_SCHEDULE_SUCCESS,
  payload: classSchedules,
})

export const deleteClassScheduleFail = error => ({
  type: DELETE_CLASS_SCHEDULE_FAIL,
  payload: error,
})
