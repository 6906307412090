import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Adult Redux States
import { GET_ROLETYPE_MODULES, ADD_ROLETYPE_MODULES } from "./actionTypes"
import {
  getRoleTypeModulesSuccess,
  getRoleTypeModulesFail,
  addRoleTypeModulesSuccess,
  addRoleTypeModulesFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getRoleTypeModules, addRoleTypeModules } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchRoleTypeModules({ payload: roleTypeID }) {
  try {
    debugger
    const response = yield call(getRoleTypeModules, roleTypeID)
    yield put(getRoleTypeModulesSuccess(response))
  } catch (error) {
    yield put(getRoleTypeModulesFail(error))
  }
}

function* onAddRoleTypeModules({ payload: { roleTypeID, roleTypeModules } }) {
  try {
    const request = {
      roleTypeID: roleTypeID,
      roleTypeModules: roleTypeModules.modules,
    }
    const response = yield call(addRoleTypeModules, request)
    yield put(addRoleTypeModulesSuccess(response))
  } catch (error) {
    yield put(addRoleTypeModulesFail(error))
  }
}

function* moduleSaga() {
  yield takeEvery(GET_ROLETYPE_MODULES, fetchRoleTypeModules)
  yield takeEvery(ADD_ROLETYPE_MODULES, onAddRoleTypeModules)
}

export default moduleSaga
