import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Col,
  Form,
  Button,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
import Select from "react-select"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  addNewImmunization as onAddNewImmunization,
  updateImmunization as onUpdateImmunization,
} from "store/actions"
import { isEmpty } from "lodash"

const years = [
  { value: "0", label: "0y" },
  { value: "1", label: "1y" },
  { value: "2", label: "2y" },
  { value: "3", label: "3y" },
  { value: "4", label: "4y" },
  { value: "5", label: "5y" },
  { value: "6", label: "6y" },
  { value: "7", label: "7y" },
  { value: "8", label: "8y" },
  { value: "9", label: "9y" },
  { value: "10", label: "10y" },
  { value: "11", label: "11y" },
  { value: "12", label: "12y" },
  { value: "13", label: "13y" },
  { value: "14", label: "14y" },
  { value: "15", label: "15y" },
  { value: "16", label: "16y" },
  { value: "17", label: "17y" },
  { value: "18", label: "18y" },
  { value: "19", label: "19y" },
]

const months = [
  { value: "0", label: "0m" },
  { value: "1", label: "1m" },
  { value: "2", label: "2m" },
  { value: "3", label: "3m" },
  { value: "4", label: "4m" },
  { value: "5", label: "5m" },
  { value: "6", label: "6m" },
  { value: "7", label: "7m" },
  { value: "8", label: "8m" },
  { value: "9", label: "9m" },
  { value: "10", label: "10m" },
  { value: "11", label: "11m" },
]

const formdefault = {
  ShortName: "",
  LongName: "",
  IsRequired: true,
  IsActive: true,
  Doses: [
    {
      DoseID: 1,
      Years: { value: "0", label: "0y" },
      Months: { value: "0", label: "0m" },
    },
    {
      DoseID: 2,
      Years: { value: "0", label: "0y" },
      Months: { value: "0", label: "0m" },
    },
    {
      DoseID: 3,
      Years: { value: "0", label: "0y" },
      Months: { value: "0", label: "0m" },
    },
    {
      DoseID: 4,
      Years: { value: "0", label: "0y" },
      Months: { value: "0", label: "0m" },
    },
    {
      DoseID: 5,
      Years: { value: "0", label: "0y" },
      Months: { value: "0", label: "0m" },
    },
  ],
}

const ImmunizationModal = ({ show, onEdit, onCloseClick }) => {
  const dispatch = useDispatch()
  const { immunization, loading } = useSelector(state => state.immunization)
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ShortName: yup.string().required("Required"),
    LongName: yup.string(),
    IsRequired: yup.bool(),
    IsActive: yup.bool(),
    Doses: yup.array().of(
      yup.object().shape({
        DoseID: yup.number(),
        Years: yup.object(),
        Months: yup.object(),
      })
    ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "Doses",
    control,
  })

  useEffect(() => {
    if (onEdit) {
      if (!isEmpty(immunization)) {
        reset(immunization)
      }
    } else {
      reset(formdefault)
    }
  }, [immunization, onEdit])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      if (!onEdit) {
        dispatch(onAddNewImmunization(getValues()))
      } else {
        dispatch(onUpdateImmunization(getValues()))
      }
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Add Immunization
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>Short Name</Label>
                <Controller
                  name="ShortName"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        type="text"
                        className="form-control mb-3"
                        id="ShortName"
                        required
                        invalid={!!errors.ShortName}
                      />
                      {errors?.ShortName?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.ShortName?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Long Name</Label>
                <Controller
                  name="LongName"
                  control={control}
                  value=""
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        type="text"
                        className="form-control mb-3"
                        id="LongName"
                      />
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Active</Label>
                <div>
                  <Controller
                    name="IsActive"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsActive"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="mb-3">
                <Label>Required</Label>
                <div>
                  <Controller
                    name="IsRequired"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Switch
                          {...field}
                          id="IsRequired"
                          checked={field.value}
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {fields.map((item, index) => (
            <Row key={`doses${index}`} className="mb-3">
              <Col lg={2}>{`Dose ` + item.DoseID}</Col>
              <Col lg={4}>
                <Controller
                  name={`Doses[${index}].Years`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id={`Doses[${index}].Years`}
                        options={years}
                        classNamePrefix="select2-selection"
                      />
                    </>
                  )}
                />
              </Col>
              <Col lg={4}>
                <Controller
                  name={`Doses[${index}].Months`}
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        id={`Doses[${index}].Months`}
                        options={months}
                        classNamePrefix="select2-selection"
                      />
                    </>
                  )}
                />
              </Col>
            </Row>
          ))}
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

ImmunizationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  show: PropTypes.any,
}

export default ImmunizationModal
