import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//controls
import {
  Input,
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"
import LocationField from "components/Common/Location/Location"

import {
  updateProfileAddress as onUpdateProfileAddress,
  deleteProfileAddress as onDeleteProfileAddress,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const AddressModal = ({ profileID, data, onEdit, show, onCloseClick }) => {
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [centerAddressID, setCenterAddressID] = useState(0)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    CenterAddressID: yup.number(),
    CenterProfileID: yup.number(),
    Address: yup.string(),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object().required("Required"),
    ZipCode: yup.string().max(10, "Zipcode should be max 10 characters"),
  })

  const formdefault = {
    CenterAddressID: 0,
    CenterProfileID: profileID,
    Address: "",
    ZipCode: "",
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      if (show) {
        if (onEdit) {
          reset(data)
        } else {
          reset(formdefault)
        }
      }
    }

    updateState()
  }, [show, dispatch])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onUpdateProfileAddress(getValues()))
      onCloseClick()
    }
  }

  const onDeleteClick = () => {
    setCenterAddressID(data.CenterAddressID)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (centerAddressID) {
      dispatch(onDeleteProfileAddress(centerAddressID))
      setDeleteModal(false)
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <Modal
        isOpen={show}
        toggle={onCloseClick}
        onClosed={() => onClosed()}
        centered={true}
      >
        <ModalHeader className="d-flex justify-content-center">
          {onEdit ? "Edit " : "Add "} Address
        </ModalHeader>
        <ModalBody className="py-3 px-5">
          <FormProvider {...methods}>
            <Row>
              <Col lg={12}>
                <LocationField />
              </Col>
            </Row>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex flex-wrap gap-2">
            <button
              hidden={!onEdit}
              type="button"
              className="btn btn btn-danger"
              onClick={() => onDeleteClick()}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn btn-primary"
              onClick={() => onSaveClick()}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn btn-secondary"
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

AddressModal.propTypes = {
  data: PropTypes.any,
  onEdit: PropTypes.bool,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default AddressModal
