import {
  GET_MEALS,
  GET_MEALS_FAIL,
  GET_MEALS_SUCCESS,
  DELETE_MEAL,
  DELETE_MEAL_FAIL,
  DELETE_MEAL_SUCCESS,
  ADD_NEW_MEAL,
  ADD_MEAL_SUCCESS,
  ADD_MEAL_FAIL,
  GET_MEAL_DETAIL,
  GET_MEAL_DETAIL_SUCCESS,
  GET_MEAL_DETAIL_FAIL,
  UPDATE_MEAL,
  UPDATE_MEAL_SUCCESS,
  UPDATE_MEAL_FAIL,
  RESET_MEAL_SUCCESS,
  RESET_MEAL,
} from "./actionTypes"

export const getMeals = () => ({
  type: GET_MEALS,
})

export const getMealsSuccess = meals => ({
  type: GET_MEALS_SUCCESS,
  payload: meals,
})

export const getMealsFail = error => ({
  type: GET_MEALS_FAIL,
  payload: error,
})

export const deleteMeal = meal => ({
  type: DELETE_MEAL,
  payload: meal,
})

export const deleteMealSuccess = meal => ({
  type: DELETE_MEAL_SUCCESS,
  payload: meal,
})

export const deleteMealFail = error => ({
  type: DELETE_MEAL_FAIL,
  payload: error,
})
export const addNewMeal = meal => ({
  type: ADD_NEW_MEAL,
  payload: meal,
})

export const addMealSuccess = meal => ({
  type: ADD_MEAL_SUCCESS,
  payload: meal,
})

export const addMealFail = error => ({
  type: ADD_MEAL_FAIL,
  payload: error,
})

export const getMealDetail = mealId => ({
  type: GET_MEAL_DETAIL,
  mealId,
})

export const getMealDetailSuccess = meal => ({
  type: GET_MEAL_DETAIL_SUCCESS,
  payload: meal,
})

export const getMealDetailFail = error => ({
  type: GET_MEAL_DETAIL_FAIL,
  payload: error,
})

export const updateMeal = meal => ({
  type: UPDATE_MEAL,
  payload: meal,
})

export const updateMealSuccess = meal => ({
  type: UPDATE_MEAL_SUCCESS,
  payload: meal,
})

export const updateMealFail = error => ({
  type: UPDATE_MEAL_FAIL,
  payload: error,
})
export const resetMeal = () => ({
  type: RESET_MEAL,
})

export const resetMealSuccess = meal => ({
  type: RESET_MEAL_SUCCESS,
  payload: meal,
})
