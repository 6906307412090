import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Staff Redux States
import {
  GET_STAFF_SCHEDULES,
  RESET_STAFF_SCHEDULES,
  ADD_STAFF_SCHEDULES,
  GET_STAFF_ABSENT_TYPES,
  DELETE_STAFF_SCHEDULE,
} from "./actionTypes"
import {
  getStaffSchedulesSuccess,
  getStaffSchedulesFail,
  resetStaffScheduleSuccess,
  addStaffScheduleSuccess,
  addStaffScheduleFail,
  getStaffAbsentTypesSuccess,
  getStaffAbsentTypesFail,
  deleteStaffScheduleSuccess,
  deleteStaffScheduleFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStaffSchedules,
  addStaffSchedule,
  getAbsentTypes as getStaffAbsentTypes,
  deleteStaffSchedule,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchStaffSchedules({ filter }) {
  try {
    const response = yield call(getStaffSchedules, filter)
    yield put(getStaffSchedulesSuccess(response.Table))
  } catch (error) {
    yield put(getStaffSchedulesFail(error))
  }
}

function* onAddStaffSchedule({ payload: staffschedule }) {
  try {
    const request = {
      ...staffschedule,
      StaffID: staffschedule.Staffs.StaffID,
      ClassID: staffschedule.Classes?.ClassID ?? null,
      AbsentTypeID: staffschedule.StaffAbsentTypes?.AbsentTypeID ?? null,
    }

    const response = yield call(addStaffSchedule, request)
    yield put(addStaffScheduleSuccess(response.Table))
    toast.success("Staff schedule added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addStaffScheduleFail(error))
    toast.error("Staff schedule addition failed", { autoClose: 2000 })
  }
}

function* fetchStaffAbsentTypes() {
  try {
    const response = yield call(getStaffAbsentTypes)
    yield put(getStaffAbsentTypesSuccess(response))
  } catch (error) {
    yield put(getStaffAbsentTypesFail(error))
  }
}

function* onResetStaffSchedules() {
  try {
    yield put(resetStaffScheduleSuccess({}))
  } catch (error) {}
}

function* onDeleteStaffSchedule({ payload: staffschedule }) {
  try {
    const response = yield call(deleteStaffSchedule, staffschedule)
    yield put(deleteStaffScheduleSuccess(response.Table))
    toast.success("Staff schedule deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteStaffScheduleFail(error))
    toast.error("Staff schedule deletion failed", { autoClose: 2000 })
  }
}

function* staffScheduleSaga() {
  yield takeEvery(GET_STAFF_SCHEDULES, fetchStaffSchedules)
  yield takeEvery(RESET_STAFF_SCHEDULES, onResetStaffSchedules)
  yield takeEvery(ADD_STAFF_SCHEDULES, onAddStaffSchedule)
  yield takeEvery(GET_STAFF_ABSENT_TYPES, fetchStaffAbsentTypes)
  yield takeEvery(DELETE_STAFF_SCHEDULE, onDeleteStaffSchedule)
}

export default staffScheduleSaga
