import React, { useState, useEffect } from "react"

//redux
import { useSelector, useDispatch } from "react-redux"

//Date filter
import Moment from "moment"
import Scheduler from "components/Common/Scheduler"

import { isEmpty } from "lodash"

import { getChildSchedules as onGetChildSchedules } from "store/actions"

function ScheduleTab() {
  const dispatch = useDispatch()

  const { child } = useSelector(state => state.child)

  const [filter, setFilter] = useState({
    date: Moment().format("yyyy-MM-DD"),
    childID: child.ChildID,
  })

  useEffect(() => {
    dispatch(onGetChildSchedules(filter))
  }, [filter])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const { childSchedules, column } = useSelector(state => state.childSchedules)
  //////////////////////////////////////

  const handleDate = param => {
    setFilter({ date: param, childID: child.ChildID })
  }

  if (isEmpty(childSchedules)) {
    return ""
  }

  return (
    <Scheduler
      data={childSchedules}
      column={column}
      filter={filter}
      onHandleDateClick={handleDate}
    />
  )
}
export default ScheduleTab
