import React, { useEffect, useState } from "react"
//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"
//controls
import {
  InputGroup,
  DropdownToggle,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import { Link, useNavigate, useParams } from "react-router-dom"
//import { AiOutlinePlus } from 'react-icons/ai';
//redux
import { useSelector, useDispatch } from "react-redux"
//custom hooks
import { useDeepCompareEffect } from "hooks"
//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
//Date filter
import Moment from "moment"

import LessonPlanScheduleModal from "../modal/LessonPlanScheduleModal"
import DeleteModal from "../../../../../components/Common/DeleteModal"
import { ToastContainer } from "react-toastify"
import DOMPurify from "dompurify"
import {
  getLessonPlanSchedulesByLessonPlan as onGetLessonPlanSchedulesByLessonPlan,
  deleteLessonPlanSchedule as onDeleteLessonPlanSchedule,
  deleteLessonPlanScheduleDetail as onDeleteLessonPlanScheduleDetail,
  addLessonPlanSchedule as onAddLessonPlanSchedule,
  updateLessonPlanSchedule as onUpdateLessonPlanSchedule,
} from "store/actions"
import { isEmpty } from "lodash"

const WeeklyTimetable = ({ lessonPlan }) => {
  const dispatch = useDispatch()
  const routeParams = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [lessonPlanModal, setLessonPlanModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [view, setView] = useState(false)
  const [selectedDay, setSelectedDay] = useState({})
  const [selectedTime, setSelectedTime] = useState("")
  const [lessonPlanScheduleID, setLessonPlanScheduleID] = useState(0)
  const [lessonPlanScheduleDetailID, setLessonPlanScheduleDetailID] =
    useState(0)
  const [lessonPlanScheduleDetail, setLessonPlanScheduleDetail] = useState({})

  // Initialize timetable with default values
  const { lessonPlanschedules } = useSelector(state => state.lessonPlan)

  const schema = yup.object().shape({
    LessonPlanScheduleID: yup.number(),
    Time: yup.string().required("Required"),
    LessonPlanScheduleDetails: yup.array(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      Time: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "lessonPlans",
    control,
  })

  useDeepCompareEffect(() => {
    const { id } = routeParams
    function updateLessonPlanState() {
      dispatch(onGetLessonPlanSchedulesByLessonPlan(id))
    }

    updateLessonPlanState()
    reset({ lessonPlans: lessonPlanschedules })
  }, [dispatch, lessonPlanschedules])

  const onClickView = (time, item) => {
    setLessonPlanScheduleDetail(item)
    setSelectedDay(item.DayName)
    setSelectedTime(time)
    setView(true)
    setLessonPlanModal(true)
  }

  const onClickEdit = (time, item) => {
    setLessonPlanScheduleDetail(item)
    setSelectedDay(item.DayName)
    setSelectedTime(time)
    setEdit(true)
    setLessonPlanModal(true)
  }

  const onClickLessonPlanScheduleDetailDelete = item => {
    setLessonPlanScheduleDetailID(item)
    setDeleteModal(true)
  }

  const onClickLessonPlanScheduleDelete = item => {
    setLessonPlanScheduleID(item)
    setDeleteModal(true)
  }

  // Function to handle inline time editing
  const handleTimeChange = (lessonPlanScheduleID, newTime) => {
    dispatch(
      onUpdateLessonPlanSchedule({
        LessonPlanScheduleID: lessonPlanScheduleID,
        Time: newTime,
      })
    )
  }

  const onClickAddRow = () => {
    dispatch(
      onAddLessonPlanSchedule(
        { LessonPlanID: lessonPlan.LessonPlanID },
        lessonPlan.RowID
      )
    )
  }

  const onCloseModal = () => {
    setSelectedDay("")
    setSelectedTime("")
    setLessonPlanModal(false)
    setEdit(false)
    setView(false)
    setLessonPlanScheduleID(0)
    setLessonPlanScheduleDetailID(0)
    setDeleteModal(false)
  }
  const handleDelete = () => {
    if (lessonPlanScheduleID > 0) {
      dispatch(onDeleteLessonPlanSchedule(lessonPlanScheduleID))
    }
    if (lessonPlanScheduleDetailID > 0) {
      dispatch(onDeleteLessonPlanScheduleDetail(lessonPlanScheduleDetailID))
    }
    setLessonPlanScheduleID(0)
    setLessonPlanScheduleDetailID(0)
    setDeleteModal(false)
  }
  // Render the timetable
  return (
    <>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <LessonPlanScheduleModal
        show={lessonPlanModal}
        onEdit={edit}
        onView={view}
        selectedDay={selectedDay}
        selectedTime={selectedTime}
        data={lessonPlanScheduleDetail}
        onCloseClick={() => onCloseModal()}
        header={edit == false ? "Add Lesson Plans" : "Update Lesson Plans"}
      />
      <FormProvider {...methods}>
        <table className="timetable">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>Time</th>
              <th style={{ width: "10%" }}>Sunday</th>
              <th style={{ width: "10%" }}>Monday</th>
              <th style={{ width: "10%" }}>Tuesday</th>
              <th style={{ width: "10%" }}>Wednesday</th>
              <th style={{ width: "10%" }}>Thursday</th>
              <th style={{ width: "10%" }}>Friday</th>
              <th style={{ width: "10%" }}>Saturday</th>
              <th style={{ width: "5%" }}>#</th>
            </tr>
          </thead>
          <tbody>
            {fields &&
              fields.map((row, index) => (
                <tr key={index}>
                  <td height={100}>
                    <Controller
                      name={`lessonPlans[${index}].Time`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <InputGroup>
                            <Flatpickr
                              {...field}
                              className="form-control d-block"
                              name={`lessonPlans[${index}].Time`}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "h:i K",
                              }}
                              value={
                                field.value
                                  ? Moment(field.value).format("h:mm a")
                                  : ""
                              }
                              onChange={(selectedTimes, timeStr, instance) => {
                                setValue(
                                  "Time",
                                  Moment(selectedTimes[0]).format("h:mm a")
                                )
                                setSelectedTime(timeStr)
                                handleTimeChange(
                                  row?.LessonPlanScheduleID,
                                  timeStr
                                )
                              }}
                              required
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <i className="mdi mdi-clock-outline" />
                              </span>
                            </div>
                          </InputGroup>
                        </>
                      )}
                    />
                  </td>
                  {row.LessonPlanScheduleDetails?.map(item => (
                    <td key={item.LessonPlanScheduleDetailID} className="cell">
                      {item.NoteCategoryID === 0 ? (
                        <Link
                          key={"link" + item.LessonPlanScheduleDetailID}
                          to="#"
                          className="text-success"
                          onClick={() => {
                            onClickEdit(row?.Time, item)
                          }}
                        >
                          <i
                            className="mdi mdi-plus font-size-18"
                            id="addtooltip"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="addtooltip"
                          >
                            Add
                          </UncontrolledTooltip>
                        </Link>
                      ) : (
                        <div
                          className="border rounded p-2 cursor-pointer"
                          key={"dv" + item.LessonPlanScheduleDetailID}
                        >
                          <div className="float-end">
                            <UncontrolledDropdown className="d-inline">
                              <DropdownToggle
                                href="#!"
                                tag="a"
                                className="dropdown-toggle"
                              >
                                <i
                                  style={{ fontSize: "20px" }}
                                  className="bx bx-dots-vertical-rounded"
                                />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => onClickView(row?.Time, item)}
                                  href="#"
                                >
                                  View
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => onClickEdit(row?.Time, item)}
                                  href="#"
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    onClickLessonPlanScheduleDetailDelete(
                                      item.LessonPlanScheduleDetailID
                                    )
                                  }
                                  href="#"
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <h6>{item.NoteCategory}</h6>
                          <div className="mb-3">
                            {_.truncate(item.Description, {
                              length: 20,
                              separator: " ",
                            })}
                          </div>
                        </div>
                      )}
                    </td>
                  ))}
                  <td key={`del${row.LessonPlanScheduleID}`} className="cell">
                    <Link
                      to="#"
                      className="text-danger"
                      onClick={() => {
                        onClickLessonPlanScheduleDelete(
                          row.LessonPlanScheduleID
                        )
                      }}
                    >
                      <i
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="9"
                className="add-row"
                onClick={() => {
                  onClickAddRow()
                }}
              >
                Add Row
              </td>
            </tr>
          </tfoot>
        </table>
      </FormProvider>
      <ToastContainer />
    </>
  )
}

export default WeeklyTimetable
