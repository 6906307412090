import React, { useEffect, useMemo, useState, Suspense } from "react"
import { useDeepCompareEffect } from "hooks"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"
import HappeningModal from "../modal/HappeningModal"
import JoinClassModal from "../modal/JoinClassModal"
import OtherActivityModal from "../modal/OtherActivityModal"
import {
  getStaffHappenings as onGetStaffHappenings,
  getHappeningTypes as onGetHappeningTypes,
  deleteStaffHappening as onDeleteStaffHappening,
} from "store/actions"
import * as moment from "moment"
import Flatpickr from "react-flatpickr"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  Badge,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import Spinners from "components/Common/Spinner"
import { getMinute, getHour } from "helpers/duration_helper"

function HappeningTab() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { staffHappenings, staff, happeningTypes, loading } = useSelector(
    state => state.staff
  )

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [staffHappening, setStaffHappening] = useState(null)
  const [happeningTitle, setHappeningTitle] = useState("")

  const [happeningTypeID, setHappeningTypeID] = useState(0)

  const [happeningModal, setHappeningModal] = useState(false)

  const [joinClassModal, setJoinClassModal] = useState(false)
  const [otherActivityModal, setOtherActivityModal] = useState(false)
  const [filterDate, setFilterDate] = useState(moment().format("DD-MM-yyyy"))
  useDeepCompareEffect(() => {
    dispatch(onGetHappeningTypes())
  }, [dispatch, happeningTypes])

  useEffect(() => {
    dispatch(onGetStaffHappenings(staff.StaffID, filterDate))
  }, [dispatch, filterDate])

  const handleAddClick = () => {
    setHappeningTitle("checkin")
    setHappeningModal(true)
  }
  const onHappeningClick = happeningType => {
    setStaffHappening(null)
    setHappeningTypeID(happeningType.HappeningTypeID)
    setHappeningTitle(happeningType.HappeningType)

    if (happeningType.HappeningTypeID === 5) {
      // other activity
      setHappeningTitle("Other activity")
      setOtherActivityModal(true)
    } else if (happeningType.HappeningTypeID === 2) {
      setJoinClassModal(true)
    } else {
      setHappeningModal(true)
    }
  }
  const onClickDelete = arg => {
    setStaffHappening(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (staffHappening && staffHappening.StaffHappeningID) {
      dispatch(onDeleteStaffHappening(staffHappening.StaffHappeningID))
      //  dispatch(onGetStaffHappenings(staff.StaffID))
      setDeleteModal(false)
    }
  }
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM yyyy")
    return date1
  }
  const onClickEdit = arg => {
    setHappeningTitle("Edit " + arg.HappeningType)
    if (arg.HappeningTypeID === 5) {
      // other activity
      let hourMinute = arg.Duration?.split(":")
      let hour = getHour(hourMinute.length > 0 ? hourMinute[0] : "00")
      let minute = getMinute(hourMinute.length > 1 ? hourMinute[1] : "00")
      let staffHappening = {
        ...arg,

        Hours: {
          id: hour?.id,
          hh: hour?.hh,
        },
        Minutes: {
          id: minute?.id,
          mm: minute?.mm,
        },
        HourTypes: {
          HourTypeID: arg?.HourTypeID,
          HourType: arg?.HourType,
        },
      }

      setStaffHappening(staffHappening)
      // setHappeningTitle("Other activity")
      setOtherActivityModal(true)
    } else if (arg.HappeningTypeID === 2) {
      let staffHappening = {
        ...arg,
        Classes: {
          ClassID: arg?.ClassID,
          Title: arg?.Title,
        },
      }
      setStaffHappening(staffHappening)
      setJoinClassModal(true)
    } else {
      setHappeningModal(true)
      setStaffHappening(arg)
    }
  }

  const onHappeningModalClose = () => {
    setHappeningModal(false)
    setJoinClassModal(false)
    setOtherActivityModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date & time",
        accessor: "HappeningDate",
        Cell: cellProps => {
          return <>{cellProps.row.original.HappeningDate}</>
        },
      },
      {
        Header: "Action",
        accessor: "ShortName",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Details",
        accessor: "Details",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                // to={"/staff/" + cellProps.row.original.RowID}
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      {happeningModal && (
        <HappeningModal
          happeningTitle={happeningTitle}
          show={happeningModal}
          staffHappening={staffHappening}
          onHappeningCloseClick={() => onHappeningModalClose()}
          happeningTypeID={happeningTypeID}
        />
      )}
      {joinClassModal && (
        <JoinClassModal
          happeningTitle={happeningTitle}
          show={joinClassModal}
          staffHappening={staffHappening}
          onHappeningCloseClick={() => onHappeningModalClose()}
          happeningTypeID={happeningTypeID}
        />
      )}
      {otherActivityModal && (
        <OtherActivityModal
          happeningTitle={happeningTitle}
          show={otherActivityModal}
          staffHappening={staffHappening}
          onHappeningCloseClick={() => onHappeningModalClose()}
          happeningTypeID={happeningTypeID}
        />
      )}
      <div className="text-sm-end">
        <Dropdown
          isOpen={toggleMenu}
          toggle={() => {
            setToggleMenu(!toggleMenu)
          }}
          className="btn-group me-2 mb-2 mb-sm-0"
        >
          <DropdownToggle
            className="btn btn-primary  dropdown-toggle"
            tag="div"
          >
            Add <i className="mdi mdi-chevron-down ms-1" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {happeningTypes &&
              happeningTypes
                .filter(x => x.ForStaff === true)
                .map(happeningType => (
                  <DropdownItem
                    key={happeningType.HappeningTypeID}
                    to="#"
                    onClick={() => {
                      onHappeningClick(happeningType)
                    }}
                  >
                    {happeningType.HappeningType}
                  </DropdownItem>
                ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="sm-3" style={{ width: 200, marginLeft: 20 }}>
        <Flatpickr
          className="form-control "
          id="FilterDate"
          options={{
            dateFormat: "d M, Y",
          }}
          value={moment().format("DD MMM yyyy")}
          onChange={(selectedDates, dateStr, instance) => {
            setFilterDate(dateStr)
          }}
        />
      </div>

      {isLoading ? (
        <Spinners setLoading={setLoading} />
      ) : (
        <Row>
          <Col xs="12">
            {staffHappenings?.length == 0 ? (
              "No happenings found for staff"
            ) : (
              <TableContainer
                columns={columns}
                data={staffHappenings}
                isGlobalFilter={true}
                // isAddOption={true}
                //  addOptionText="Add payrate"
                // handleAddClick={handleAddClick}
                customPageSize={10}
                isPagination={true}
                filterable={false}
                iscustomPageSizeOptions={true}
                tableClass="align-middle table-nowrap table-check"
                theadClass="table-light"
                pagination="pagination pagination-rounded justify-content-end mb-2"
              />
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}
HappeningTab.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default HappeningTab
