import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  ADD_NEW_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_DETAIL_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  RESET_NOTIFICATION_SUCCESS,
  RESET_NOTIFICATION,
  GET_NOTIFICATION_EVENTS,
  GET_NOTIFICATION_EVENTS_SUCCESS,
  GET_NOTIFICATION_EVENTS_FAIL,
  GET_NOTIFICATION_RECIPIENTS,
  GET_NOTIFICATION_RECIPIENTS_SUCCESS,
  GET_NOTIFICATION_RECIPIENTS_FAIL,
} from "./actionTypes"

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
})

export const getNotificationsSuccess = notifications => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
})

export const getNotificationsFail = error => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error,
})

export const deleteNotification = notification => ({
  type: DELETE_NOTIFICATION,
  payload: notification,
})

export const deleteNotificationSuccess = notification => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const deleteNotificationFail = error => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
})
export const addNewNotification = notification => ({
  type: ADD_NEW_NOTIFICATION,
  payload: notification,
})

export const addNotificationSuccess = notification => ({
  type: ADD_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const addNotificationFail = error => ({
  type: ADD_NOTIFICATION_FAIL,
  payload: error,
})

export const getNotificationDetail = notificationId => ({
  type: GET_NOTIFICATION_DETAIL,
  notificationId,
})

export const getNotificationDetailSuccess = notification => ({
  type: GET_NOTIFICATION_DETAIL_SUCCESS,
  payload: notification,
})

export const getNotificationDetailFail = error => ({
  type: GET_NOTIFICATION_DETAIL_FAIL,
  payload: error,
})

export const updateNotification = notification => ({
  type: UPDATE_NOTIFICATION,
  payload: notification,
})

export const updateNotificationSuccess = notification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
})
export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
})

export const resetNotificationSuccess = notification => ({
  type: RESET_NOTIFICATION_SUCCESS,
  payload: notification,
})

export const getNotificationEvents = () => ({
  type: GET_NOTIFICATION_EVENTS,
})

export const getNotificationEventsSuccess = notificationEvents => ({
  type: GET_NOTIFICATION_EVENTS_SUCCESS,
  payload: notificationEvents,
})

export const getNotificationEventsFail = error => ({
  type: GET_NOTIFICATION_EVENTS_FAIL,
  payload: error,
})

export const getNotificationRecipients = () => ({
  type: GET_NOTIFICATION_RECIPIENTS,
})

export const getNotificationRecipientsSuccess = notificationRecipients => ({
  type: GET_NOTIFICATION_RECIPIENTS_SUCCESS,
  payload: notificationRecipients,
})

export const getNotificationRecipientsFail = error => ({
  type: GET_NOTIFICATION_RECIPIENTS_FAIL,
  payload: error,
})
