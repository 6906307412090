import {
  GET_BUS_SCHEDULES,
  GET_BUS_SCHEDULES_SUCCESS,
  GET_BUS_SCHEDULES_FAIL,
  RESET_BUS_SCHEDULES,
  RESET_BUS_SCHEDULES_SUCCESS,
  ADD_BUS_SCHEDULES,
  ADD_BUS_SCHEDULES_SUCCESS,
  ADD_BUS_SCHEDULES_FAIL,
  DELETE_BUS_SCHEDULE,
  DELETE_BUS_SCHEDULE_SUCCESS,
  DELETE_BUS_SCHEDULE_FAIL,
} from "./actionTypes"

export const getBusSchedules = filter => ({
  type: GET_BUS_SCHEDULES,
  filter,
})

export const getBusSchedulesSuccess = busSchedules => ({
  type: GET_BUS_SCHEDULES_SUCCESS,
  payload: busSchedules,
})

export const getBusSchedulesFail = error => ({
  type: GET_BUS_SCHEDULES_FAIL,
  payload: error,
})

export const addBusSchedule = busSchedules => ({
  type: ADD_BUS_SCHEDULES,
  payload: busSchedules,
})

export const addBusScheduleSuccess = busSchedules => ({
  type: ADD_BUS_SCHEDULES_SUCCESS,
  payload: busSchedules,
})

export const addBusScheduleFail = error => ({
  type: ADD_BUS_SCHEDULES_FAIL,
  payload: error,
})

export const resetBusSchedules = () => ({
  type: RESET_BUS_SCHEDULES,
})

export const resetBusScheduleSuccess = busSchedules => ({
  type: RESET_BUS_SCHEDULES_SUCCESS,
  payload: busSchedules,
})

export const deleteBusSchedule = busSchedules => ({
  type: DELETE_BUS_SCHEDULE,
  payload: busSchedules,
})

export const deleteBusScheduleSuccess = busSchedules => ({
  type: DELETE_BUS_SCHEDULE_SUCCESS,
  payload: busSchedules,
})

export const deleteBusScheduleFail = error => ({
  type: DELETE_BUS_SCHEDULE_FAIL,
  payload: error,
})
