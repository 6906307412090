import {
  GET_NOTES_FAIL,
  GET_NOTES_SUCCESS,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAIL,
  ADD_NOTE_FAIL,
  ADD_NOTE_SUCCESS,
  GET_NOTE_DETAIL_SUCCESS,
  GET_NOTE_DETAIL_FAIL,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  RESET_NOTE_SUCCESS,
  GET_NOTE_CATEGORIES_FAIL,
  GET_NOTE_CATEGORIES_SUCCESS,
  ADD_NOTE_SCHEDULE_FAIL,
  ADD_NOTE_SCHEDULE_SUCCESS,
  UPDATE_NOTE_SCHEDULE_SUCCESS,
  UPDATE_NOTE_SCHEDULE_FAIL,
  GET_NOTE_SCHEDULES_BY_NOTE_SUCCESS,
  GET_NOTE_SCHEDULES_BY_NOTE_FAIL,
  UPDATE_NOTE_SCHEDULE_DETAIL_SUCCESS,
  UPDATE_NOTE_SCHEDULE_DETAIL_FAIL,
  DELETE_NOTE_SCHEDULE_SUCCESS,
  DELETE_NOTE_SCHEDULE_FAIL,
  DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS,
  DELETE_NOTE_SCHEDULE_DETAIL_FAIL,
  GET_NOTES_VIEW_SUCCESS,
  GET_NOTES_VIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  notes: [],
  note: {},
  notecategories: [],
  noteschedules: [],
  notescheduleDetails: [],
  notesView: [],
  error: {},
  loading: true,
}

const Note = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        loading: true,
      }
    case GET_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTE_SUCCESS: {
      const data = {
        ...state,
        notes: state.notes.filter(
          note => note.NoteID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        notes: state.notes.filter(
          note => note.NoteID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        notes: [...state.notes, action.payload],
      }
    case ADD_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_NOTE_DETAIL_SUCCESS:
      return {
        ...state,
        note: action.payload,
        loading: true,
      }

    case GET_NOTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        notes: state.notes.map(note =>
          note.NoteID.toString() === action.payload.NoteID.toString()
            ? { note, ...action.payload }
            : note
        ),
      }
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_NOTE_SUCCESS:
      return {
        ...state,
        note: action.payload,
      }
    case GET_NOTE_CATEGORIES_SUCCESS:
      return {
        ...state,
        notecategories: action.payload,
        loading: true,
      }
    case GET_NOTE_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_NOTE_SCHEDULE_SUCCESS:
      return {
        ...state,
        noteschedules: action.payload,
      }
    case ADD_NOTE_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTE_SCHEDULE_SUCCESS:
      return {
        ...state,
        noteschedules: state.noteschedules.map(x =>
          x.NoteScheduleID.toString() ===
          action.payload.NoteScheduleID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_NOTE_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_NOTE_SCHEDULES_BY_NOTE_SUCCESS:
      return {
        ...state,
        noteschedules: action.payload,
        loading: true,
      }
    case GET_NOTE_SCHEDULES_BY_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_NOTE_SCHEDULE_DETAIL_SUCCESS:
      return {
        ...state,
        noteschedules: state.noteschedules.map(x =>
          x.NoteScheduleID.toString() ===
          action.payload.NoteScheduleID.toString()
            ? { x, ...action.payload }
            : x
        ),
      }
    case UPDATE_NOTE_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        noteschedules: state.noteschedules.filter(
          x => x.NoteScheduleID !== parseInt(action.payload)
        ),
      }
    }
    case DELETE_NOTE_SCHEDULE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_NOTE_SCHEDULE_DETAIL_SUCCESS: {
      return {
        ...state,
        noteschedules: state.noteschedules.map(x =>
          x.NoteScheduleDetails.map((d, i) => {
            d.NoteScheduleDetailID == parseInt(action.payload)
              ? { ...d, NoteCategoryID: null, Description: null }
              : d
          })
        ),
      }
    }
    case DELETE_NOTE_SCHEDULE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_NOTES_VIEW_SUCCESS:
      return {
        ...state,
        notesView: action.payload,
        loading: true,
      }
    case GET_NOTES_VIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Note
