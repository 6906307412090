import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Input,
  Label,
  Row,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"

//Loadash
import { isEmpty } from "lodash"

import {
  addChildByAdult as onAddChildByAdult,
  getRelationTypes as onGetRelationTypes,
  getPermissionTypes as onGetPermissionTypes,
  resetChild as onResetChild,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import LocationField from "components/Common/Location/Location"
import GenderField from "components/Common/Gender/Gender"
import Spinners from "components/Common/Spinner"

function ChildModal({ show, onCloseClick }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [types, setTypes] = useState([])

  //////////////////Adult STATE/////////////////////
  const { adult } = useSelector(state => state.adult)
  //////////////////////////////////////

  //////////////////CHILD SCHEDULE STATE/////////////////////
  const { child, relationtypes, permissiontypes, loading } = useSelector(
    state => state.child
  )
  const [isLoading, setLoading] = useState(loading)
  //////////////////////////////////////

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    DateOfBirth: yup.string().nullable(),
    StartDate: yup.string().nullable(),
    EndDate: yup.string().nullable(),
    Genders: yup.object().required("Required"),
    RelationTypes: yup
      .object()
      .required("Required")
      .transform((_, val) => (val ? val : null)),
    OtherRelation: yup
      .string()
      .when("RelationTypes", {
        is: value => value?.RelationTypeID === 8,
        then: schema => schema.required("Required"),
        otherwise: schema => schema,
      })
      .nullable(),
    AdultPermissions: yup.array(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      StartDate: "",
      EndDate: "",
      OtherRelation: "",
      RelationTypes: "",
      AdultPermissions: [],
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, watch, trigger } =
    methods
  const { errors, isValid } = formState
  const RelationType = watch("RelationTypes")

  useEffect(() => {
    function updateChildState() {
      dispatch(onGetRelationTypes())
      dispatch(onGetPermissionTypes())
      //reset form with server data
      reset(child)
    }

    updateChildState()
  }, [])

  useEffect(() => {
    return () => {
      dispatch(onResetChild())
    }
  }, [])

  const onSaveClick = () => {
    trigger() //for form validation
    if (isValid) {
      dispatch(
        onAddChildByAdult({
          ...getValues(),
          AdultID: adult.AdultID,
          IsActive: true,
        })
      )
      onCloseClick()
    }
  }

  const onPermissionSelect = e => {
    const { value, checked } = e.target

    const existing = [...types]
    if (checked) {
      existing.push(value)
      setTypes(existing)
    } else {
      const updatedValue = existing.filter(x => {
        return x !== value
      })
      setTypes(updatedValue)
    }
  }

  useEffect(() => {
    setValue("AdultPermissions", types)
  }, [types])

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
      size="lg"
      className="modal-dialog-centered modal-dialog-scrollable"
    >
      <ModalHeader className="d-flex justify-content-center">
        Adding Child
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <>
              <Row>
                <Col sm="6">
                  <div className="mb-3">
                    <Label>First Name</Label>
                    <Controller
                      name="FirstName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            id="FirstName"
                            type="text"
                            required
                            invalid={!!errors.FirstName}
                          />
                          {errors?.FirstName?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.FirstName?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Last Name</Label>
                    <Controller
                      name="LastName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            id="LastName"
                            type="text"
                            required
                            invalid={!!errors.LastName}
                          />
                          {errors?.LastName?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.LastName?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <GenderField />
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-3">
                    <Label>Date of Birth</Label>
                    <Controller
                      name="DateOfBirth"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id="DateOfBirth"
                            options={{
                              dateFormat: "d M, Y",
                            }}
                            // onChange={date => setValue("DateOfBirth", date[0])}
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue("DateOfBirth", dateStr)
                            }}
                          />
                          {errors?.DateOfBirth?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.DateOfBirth?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Start Date</Label>
                    <Controller
                      name="StartDate"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id="StartDate"
                            options={{
                              dateFormat: "d M, Y",
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue("StartDate", dateStr)
                            }}
                          />
                          {errors?.StartDate?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.StartDate?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <Label>End Date</Label>
                    <Controller
                      name="EndDate"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Flatpickr
                            {...field}
                            className="form-control d-block"
                            id="EndDate"
                            options={{
                              dateFormat: "d M, Y",
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                              setValue("EndDate", dateStr)
                            }}
                          />
                          {errors?.EndDate?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.EndDate?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <div className="mb-3">
                    <Label>Relation to child</Label>
                    <Controller
                      name="RelationTypes"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id="RelationTypes"
                            options={relationtypes}
                            getOptionLabel={option => option.Relation}
                            getOptionValue={option => option.RelationTypeID}
                            required
                            aria-invalid={!!errors.RelationTypes}
                            classNamePrefix="select2-selection"
                          />
                          {errors?.RelationTypes?.message ? (
                            <FormFeedback type="invalid" className="d-block">
                              {errors?.RelationTypes?.message}
                            </FormFeedback>
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  {RelationType?.RelationTypeID === 8 ? (
                    <div className="mb-3">
                      <Label>Other Relation</Label>
                      <Controller
                        name="OtherRelation"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Input
                              {...field}
                              id="OtherRelation"
                              type="text"
                              required
                              invalid={!!errors.OtherRelation}
                            />
                            {errors?.OtherRelation?.message ? (
                              <FormFeedback type="invalid" className="d-block">
                                {errors?.OtherRelation?.message}
                              </FormFeedback>
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mb-3">
                    <Label>Permissions</Label>
                    {(permissiontypes || []).map((type, i) => (
                      <div
                        className="form-check form-check-primary d-flex align-items-center mb-3"
                        key={"d" + i}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input checkbox-medium me-2"
                          value={type.AdultPemissionTypeID}
                          id={"dvCheck" + i}
                          onChange={onPermissionSelect}
                          autoComplete="off"
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"dvCheck" + i}
                        >
                          {type.PemissionType}
                          <div className="small">
                            {type.PemissionDescription}
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
export default ChildModal
