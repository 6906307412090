import {
  GET_LESSON_PLANS,
  GET_LESSON_PLANS_FAIL,
  GET_LESSON_PLANS_SUCCESS,
  DELETE_LESSON_PLAN,
  DELETE_LESSON_PLAN_FAIL,
  DELETE_LESSON_PLAN_SUCCESS,
  ADD_LESSON_PLAN,
  ADD_LESSON_PLAN_SUCCESS,
  ADD_LESSON_PLAN_FAIL,
  GET_LESSON_PLAN_DETAIL,
  GET_LESSON_PLAN_DETAIL_SUCCESS,
  GET_LESSON_PLAN_DETAIL_FAIL,
  UPDATE_LESSON_PLAN,
  UPDATE_LESSON_PLAN_SUCCESS,
  UPDATE_LESSON_PLAN_FAIL,
  RESET_LESSON_PLAN_SUCCESS,
  RESET_LESSON_PLAN,
  ADD_LESSON_PLAN_SCHEDULE,
  ADD_LESSON_PLAN_SCHEDULE_SUCCESS,
  ADD_LESSON_PLAN_SCHEDULE_FAIL,
  UPDATE_LESSON_PLAN_SCHEDULE,
  UPDATE_LESSON_PLAN_SCHEDULE_SUCCESS,
  UPDATE_LESSON_PLAN_SCHEDULE_FAIL,
  DELETE_LESSON_PLAN_SCHEDULE,
  DELETE_LESSON_PLAN_SCHEDULE_SUCCESS,
  DELETE_LESSON_PLAN_SCHEDULE_FAIL,
  GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN,
  GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_SUCCESS,
  GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_FAIL,
  UPDATE_LESSON_PLAN_SCHEDULE_DETAIL,
  UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS,
  UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL,
  DELETE_LESSON_PLAN_SCHEDULE_DETAIL,
  DELETE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS,
  DELETE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL,
  GET_LESSON_PLANS_VIEW,
  GET_LESSON_PLANS_VIEW_SUCCESS,
  GET_LESSON_PLANS_VIEW_FAIL,
} from "./actionTypes"

//  LESSON_PLAN

export const getLessonPlans = () => ({
  type: GET_LESSON_PLANS,
})

export const getLessonPlansSuccess = lessonPlans => ({
  type: GET_LESSON_PLANS_SUCCESS,
  payload: lessonPlans,
})

export const getLessonPlansFail = error => ({
  type: GET_LESSON_PLANS_FAIL,
  payload: error,
})

export const deleteLessonPlan = lessonPlanID => ({
  type: DELETE_LESSON_PLAN,
  payload: lessonPlanID,
})

export const deleteLessonPlanSuccess = lessonPlan => ({
  type: DELETE_LESSON_PLAN_SUCCESS,
  payload: lessonPlan,
})

export const deleteLessonPlanFail = error => ({
  type: DELETE_LESSON_PLAN_FAIL,
  payload: error,
})

export const addLessonPlan = lessonPlan => ({
  type: ADD_LESSON_PLAN,
  payload: lessonPlan,
})

export const addLessonPlanSuccess = lessonPlan => ({
  type: ADD_LESSON_PLAN_SUCCESS,
  payload: lessonPlan,
})

export const addLessonPlanFail = error => ({
  type: ADD_LESSON_PLAN_FAIL,
  payload: error,
})

export const getLessonPlanDetail = RowID => ({
  type: GET_LESSON_PLAN_DETAIL,
  RowID,
})

export const getLessonPlanDetailSuccess = lessonPlan => ({
  type: GET_LESSON_PLAN_DETAIL_SUCCESS,
  payload: lessonPlan,
})

export const getLessonPlanDetailFail = error => ({
  type: GET_LESSON_PLAN_DETAIL_FAIL,
  payload: error,
})

export const updateLessonPlan = lessonPlan => ({
  type: UPDATE_LESSON_PLAN,
  payload: lessonPlan,
})

export const updateLessonPlanSuccess = lessonPlan => ({
  type: UPDATE_LESSON_PLAN_SUCCESS,
  payload: lessonPlan,
})

export const updateLessonPlanFail = error => ({
  type: UPDATE_LESSON_PLAN_FAIL,
  payload: error,
})

export const resetLessonPlan = () => ({
  type: RESET_LESSON_PLAN,
})

export const resetLessonPlanSuccess = lessonPlan => ({
  type: RESET_LESSON_PLAN_SUCCESS,
  payload: lessonPlan,
})

export const addLessonPlanSchedule = (lessonPlanschedule, lessonPlanRowId) => ({
  type: ADD_LESSON_PLAN_SCHEDULE,
  payload: { lessonPlanschedule, lessonPlanRowId },
})

export const addLessonPlanScheduleSuccess = lessonPlanschedule => ({
  type: ADD_LESSON_PLAN_SCHEDULE_SUCCESS,
  payload: lessonPlanschedule,
})

export const addLessonPlanScheduleFail = error => ({
  type: ADD_LESSON_PLAN_SCHEDULE_FAIL,
  payload: error,
})

export const updateLessonPlanSchedule = lessonPlanschedule => ({
  type: UPDATE_LESSON_PLAN_SCHEDULE,
  payload: lessonPlanschedule,
})

export const updateLessonPlanScheduleSuccess = lessonPlanschedule => ({
  type: UPDATE_LESSON_PLAN_SCHEDULE_SUCCESS,
  payload: lessonPlanschedule,
})

export const updateLessonPlanScheduleFail = error => ({
  type: UPDATE_LESSON_PLAN_SCHEDULE_FAIL,
  payload: error,
})

//  LessonPlan schedules
export const getLessonPlanSchedulesByLessonPlan = RowID => ({
  type: GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN,
  RowID,
})

export const getLessonPlanSchedulesByLessonPlanSuccess = lessonPlans => ({
  type: GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_SUCCESS,
  payload: lessonPlans,
})

export const getLessonPlanSchedulesByLessonPlanFail = error => ({
  type: GET_LESSON_PLAN_SCHEDULES_BY_LESSON_PLAN_FAIL,
  payload: error,
})

export const updateLessonPlanScheduleDetail = lessonPlanScheduleDetail => ({
  type: UPDATE_LESSON_PLAN_SCHEDULE_DETAIL,
  payload: lessonPlanScheduleDetail,
})

export const updateLessonPlanScheduleDetailSuccess =
  lessonPlanScheduleDetail => ({
    type: UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS,
    payload: lessonPlanScheduleDetail,
  })

export const updateLessonPlanScheduleDetailFail = error => ({
  type: UPDATE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL,
  payload: error,
})

export const deleteLessonPlanSchedule = lessonPlanScheduleID => ({
  type: DELETE_LESSON_PLAN_SCHEDULE,
  payload: lessonPlanScheduleID,
})

export const deleteLessonPlanScheduleSuccess = lessonPlanScheduleID => ({
  type: DELETE_LESSON_PLAN_SCHEDULE_SUCCESS,
  payload: lessonPlanScheduleID,
})

export const deleteLessonPlanScheduleFail = error => ({
  type: DELETE_LESSON_PLAN_SCHEDULE_FAIL,
  payload: error,
})

export const deleteLessonPlanScheduleDetail = lessonPlanScheduleDetailID => ({
  type: DELETE_LESSON_PLAN_SCHEDULE_DETAIL,
  payload: lessonPlanScheduleDetailID,
})

export const deleteLessonPlanScheduleDetailSuccess =
  lessonPlanScheduleDetailID => ({
    type: DELETE_LESSON_PLAN_SCHEDULE_DETAIL_SUCCESS,
    payload: lessonPlanScheduleDetailID,
  })

export const deleteLessonPlanScheduleDetailFail = error => ({
  type: DELETE_LESSON_PLAN_SCHEDULE_DETAIL_FAIL,
  payload: error,
})

export const getLessonPlansView = lessonPlan => ({
  type: GET_LESSON_PLANS_VIEW,
  payload: lessonPlan,
})

export const getLessonPlansViewSuccess = lessonPlan => ({
  type: GET_LESSON_PLANS_VIEW_SUCCESS,
  payload: lessonPlan,
})

export const getLessonPlansViewFail = error => ({
  type: GET_LESSON_PLANS_VIEW_FAIL,
  payload: error,
})
