/*Get CLASS SCHEDULES */
export const GET_CLASS_SCHEDULES = "GET_CLASS_SCHEDULES"
export const GET_CLASS_SCHEDULES_SUCCESS = "GET_CLASS_SCHEDULES_SUCCESS"
export const GET_CLASS_SCHEDULES_FAIL = "GET_CLASS_SCHEDULES_FAIL"

/**
 * Reset CLASS SCHEDULES
 */
export const RESET_CLASS_SCHEDULES = "RESET_CLASS"
export const RESET_CLASS_SCHEDULES_SUCCESS = "RESET_CLASS_SCHEDULES_SUCCESS"

/*Add CLASS SCHEDULES */
export const ADD_CLASS_SCHEDULES = "ADD_CLASS_SCHEDULES"
export const ADD_CLASS_SCHEDULES_SUCCESS = "ADD_CLASS_SCHEDULES_SUCCESS"
export const ADD_CLASS_SCHEDULES_FAIL = "ADD_CLASS_SCHEDULES_FAIL"

// /*Get ABSENT TYPES*/
// export const GET_STAFF_ABSENT_TYPES = "GET_STAFF_ABSENT_TYPES"
// export const GET_STAFF_ABSENT_TYPES_SUCCESS = "GET_STAFF_ABSENT_TYPES_SUCCESS"
// export const GET_STAFF_ABSENT_TYPES_FAIL = "GET_STAFF_ABSENT_TYPES_FAIL"

/**
 * Delete CLASS SCHEDULE
 */
export const DELETE_CLASS_SCHEDULE = "DELETE_CLASS_SCHEDULE"
export const DELETE_CLASS_SCHEDULE_SUCCESS = "DELETE_CLASS_SCHEDULE_SUCCESS"
export const DELETE_CLASS_SCHEDULE_FAIL = "DELETE_CLASS_SCHEDULE_FAIL"
