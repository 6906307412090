import React, { useState } from "react"
import { Input, Label, FormFeedback, Row, Col } from "reactstrap"

function CustomFileInput(props) {
  const handleChange = event => {
    event.preventDefault()
    let file = event.target.files[0]

    if (
      file &&
      props.allowedExtension.indexOf(file.name.split(".").pop()) > -1
    ) {
      // Call the function passed from the parent with the new value
      props.onValueChange(file)
    }
  }

  return (
    <input
      {...props}
      type="file"
      accept={props.allowedExtension}
      onChange={handleChange}
    />
  )
}

export default CustomFileInput
