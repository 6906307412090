import React, { useState, useEffect } from "react"
import { createSelector } from "reselect"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

//Date filter
import Moment from "moment"
import Scheduler from "components/Common/Scheduler"

import { isEmpty } from "lodash"

import { getStaffSchedules as onGetStaffSchedules } from "store/actions"

function ScheduleTab({ staffID }) {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    date: Moment().format("yyyy-MM-DD"),
    staffID: staffID,
  })

  useDeepCompareEffect(() => {
    dispatch(onGetStaffSchedules(filter))
  }, [dispatch, filter])

  //////////////////STAFF SCHEDULE STATE/////////////////////
  const { staffSchedules, column } = useSelector(state => state.staffSchedules)
  //////////////////////////////////////

  const handleDate = param => {
    setFilter({ date: param, staffID: staffID })
  }

  if (isEmpty(staffSchedules)) {
    return ""
  }

  return (
    <Scheduler
      data={staffSchedules}
      column={column}
      filter={filter}
      onHandleDateClick={handleDate}
    />
  )
}
export default ScheduleTab
