import React, { useState, useEffect } from "react"
import { isEmpty } from "lodash"
//redux
import { useSelector } from "react-redux"

function Notes() {
  //////////////////Child STATE/////////////////////
  const { notes } = useSelector(state => state.child)
  //////////////////////////////////////

  if (isEmpty(notes.Notes)) {
    return <div>Not set</div>
  }

  return (
    <>
      {notes.Notes &&
        notes.Notes.map((item, index) => {
          return (
            <div key={"note" + index}>
              {index + 1 + `. `}
              {item.Note}
            </div>
          )
        })}
    </>
  )
}
export default Notes
