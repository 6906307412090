import {
  GET_ADULTS_FAIL,
  GET_ADULTS_SUCCESS,
  DELETE_ADULT_SUCCESS,
  DELETE_ADULT_FAIL,
  ADD_ADULT_FAIL,
  ADD_ADULT_SUCCESS,
  GET_ADULT_DETAIL_SUCCESS,
  GET_ADULT_DETAIL_FAIL,
  UPDATE_ADULT_SUCCESS,
  UPDATE_ADULT_FAIL,
  RESET_ADULT_SUCCESS,
  UPDATE_ADULT_PHOTO_SUCCESS,
  UPDATE_ADULT_PHOTO_FAIL,
  GET_ADULT_RELATIONS_SUCCESS,
  GET_ADULT_RELATIONS_FAIL,
  UPDATE_ADULT_NOTES_SUCCESS,
  UPDATE_ADULT_NOTES_FAIL,
  ADD_CHILD_BY_ADULT_SUCCESS,
  ADD_CHILD_BY_ADULT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  adults: [],
  adult: {},
  relations: [],
  error: {},
  loading: true,
}

const Adult = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ADULTS_SUCCESS:
      return {
        ...state,
        adults: action.payload,
        loading: true,
      }
    case GET_ADULTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_ADULT_SUCCESS: {
      const data = {
        ...state,
        adults: state.adults.filter(
          adult => adult.AdultID !== parseInt(action.payload)
        ),
      }
      return {
        ...state,
        adults: state.adults.filter(adult => adult.AdultID !== action.payload),
      }
    }
    case DELETE_ADULT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_ADULT_SUCCESS:
      return {
        ...state,
        adults: [...state.adults, action.payload],
      }
    case ADD_ADULT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ADULT_DETAIL_SUCCESS:
      return {
        ...state,
        adult: action.payload,
        loading: true,
      }

    case GET_ADULT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ADULT_SUCCESS:
      return {
        ...state,
        adults: state.adults.map(adult =>
          adult.AdultID.toString() === action.payload.AdultID.toString()
            ? { adult, ...action.payload }
            : adult
        ),
      }

    case UPDATE_ADULT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case RESET_ADULT_SUCCESS:
      return {
        ...state,
        adult: action.payload,
      }

    case UPDATE_ADULT_PHOTO_SUCCESS:
      return {
        ...state,
      }
    case UPDATE_ADULT_PHOTO_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ADULT_RELATIONS_SUCCESS:
      return {
        ...state,
        relations: action.payload,
      }
    case GET_ADULT_RELATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ADULT_NOTES_SUCCESS:
      return {
        ...state,
        adult: { ...state.adult, Notes: action.payload },
      }

    case UPDATE_ADULT_NOTES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_CHILD_BY_ADULT_SUCCESS:
      return {
        ...state,
        relations: action.payload,
      }
    case ADD_CHILD_BY_ADULT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Adult
