import {
  GET_STAFF_SCHEDULES,
  GET_STAFF_SCHEDULES_SUCCESS,
  GET_STAFF_SCHEDULES_FAIL,
  RESET_STAFF_SCHEDULES,
  RESET_STAFF_SCHEDULES_SUCCESS,
  ADD_STAFF_SCHEDULES,
  ADD_STAFF_SCHEDULES_SUCCESS,
  ADD_STAFF_SCHEDULES_FAIL,
  GET_STAFF_ABSENT_TYPES,
  GET_STAFF_ABSENT_TYPES_SUCCESS,
  DELETE_STAFF_SCHEDULE,
  DELETE_STAFF_SCHEDULE_SUCCESS,
  DELETE_STAFF_SCHEDULE_FAIL,
} from "./actionTypes"

export const getStaffSchedules = filter => ({
  type: GET_STAFF_SCHEDULES,
  filter,
})

export const getStaffSchedulesSuccess = staffSchedules => ({
  type: GET_STAFF_SCHEDULES_SUCCESS,
  payload: staffSchedules,
})

export const getStaffSchedulesFail = error => ({
  type: GET_STAFF_SCHEDULES_FAIL,
  payload: error,
})

export const addStaffSchedule = staffschedule => ({
  type: ADD_STAFF_SCHEDULES,
  payload: staffschedule,
})

export const addStaffScheduleSuccess = staffschedule => ({
  type: ADD_STAFF_SCHEDULES_SUCCESS,
  payload: staffschedule,
})

export const addStaffScheduleFail = error => ({
  type: ADD_STAFF_SCHEDULES_FAIL,
  payload: error,
})

export const getStaffAbsentTypes = () => ({
  type: GET_STAFF_ABSENT_TYPES,
})

export const getStaffAbsentTypesSuccess = absenttypes => ({
  type: GET_STAFF_ABSENT_TYPES_SUCCESS,
  payload: absenttypes,
})

export const getStaffAbsentTypesFail = error => ({
  type: GET_STAFF_SCHEDULES_FAIL,
  payload: error,
})

export const resetStaffSchedules = () => ({
  type: RESET_STAFF_SCHEDULES,
})

export const resetStaffScheduleSuccess = staffSchedules => ({
  type: RESET_STAFF_SCHEDULES_SUCCESS,
  payload: staffSchedules,
})

export const deleteStaffSchedule = staffschedule => ({
  type: DELETE_STAFF_SCHEDULE,
  payload: staffschedule,
})

export const deleteStaffScheduleSuccess = staffschedule => ({
  type: DELETE_STAFF_SCHEDULE_SUCCESS,
  payload: staffschedule,
})

export const deleteStaffScheduleFail = error => ({
  type: DELETE_STAFF_SCHEDULE_FAIL,
  payload: error,
})
