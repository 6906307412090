import {
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_REPORT_TYPES_SUCCESS,
  GET_REPORT_TYPES_FAIL,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DETAIL_SUCCESS,
  GET_REPORT_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  reports: [],
  reporttypes: [],
  reportdetail: {},
  data: [],
  error: {},
  loading: true,
}

const Report = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        loading: true,
      }
    case GET_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        reportdetail: action.payload,
        loading: true,
      }
    case GET_REPORT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_REPORT_TYPES_SUCCESS:
      return {
        ...state,
        reporttypes: action.payload,
        loading: true,
      }
    case GET_REPORT_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_REPORT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: true,
      }
    case GET_REPORT_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Report
