import { call, put, takeEvery } from "redux-saga/effects"

// note Redux States
import {
  GET_NOTES,
  DELETE_NOTE,
  ADD_NOTE,
  GET_NOTE_DETAIL,
  UPDATE_NOTE,
  GET_NOTE_CATEGORIES,
  RESET_NOTE,
  ADD_NOTE_SCHEDULE,
  UPDATE_NOTE_SCHEDULE,
  GET_NOTE_SCHEDULES_BY_NOTE,
  UPDATE_NOTE_SCHEDULE_DETAIL,
  DELETE_NOTE_SCHEDULE,
  DELETE_NOTE_SCHEDULE_DETAIL,
  GET_NOTES_VIEW,
} from "./actionTypes"
import {
  getAllNotesSuccess,
  getAllNotesFail,
  deleteNoteSuccess,
  deleteNoteFail,
  addNoteSuccess,
  addNoteFail,
  getNoteDetailSuccess,
  getNoteDetailFail,
  updateNoteSuccess,
  updateNoteFail,
  resetNoteSuccess,
  getNoteCategoriesSuccess,
  getNoteCategoriesFail,
  addNoteScheduleSuccess,
  addNoteScheduleFail,
  updateNoteScheduleSuccess,
  updateNoteScheduleFail,
  getNoteSchedulesByNoteSuccess,
  getNoteSchedulesByNoteFail,
  updateNoteScheduleDetailSuccess,
  updateNoteScheduleDetailFail,
  deleteNoteScheduleSuccess,
  deleteNoteScheduleFail,
  deleteNoteScheduleDetailSuccess,
  deleteNoteScheduleDetailFail,
  getNotesViewSuccess,
  getNotesViewFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAllNotes,
  deleteNote,
  addNewNote,
  getNoteDetail,
  updateNote,
  addNoteSchedule,
  deleteNoteSchedule,
  getNoteSchedulesByNote,
  updateNoteScheduleDetail,
  deleteNoteScheduleDetail,
  getNotesView,
  updateNoteSchedule,
  getNoteCategories,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchNotes() {
  try {
    const response = yield call(getAllNotes)
    yield put(getAllNotesSuccess(response))
  } catch (error) {
    yield put(getAllNotesFail(error))
  }
}

function* onDeleteNote({ payload: NoteID }) {
  try {
    const response = yield call(deleteNote, NoteID)
    yield put(deleteNoteSuccess(response))
    toast.success("Note deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteNoteFail(error))
    toast.error("Note deletion failed", { autoClose: 2000 })
  }
}

function* onAddNote({ payload: note }) {
  try {
    const noteNew = {
      ...note,
      ClassID: note.Classes?.ClassID ? note.Classes?.ClassID : 0,
    }
    const response = yield call(addNewNote, noteNew)
    yield put(addNoteSuccess(response[0]))
    toast.success("Note added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addNoteFail(error))
    toast.error("Note addition failed", { autoClose: 2000 })
  }
}

function* fetchNoteDetail({ RowID }) {
  try {
    const response = yield call(getNoteDetail, RowID)
    let result = response[0]
    result = {
      ...result,
    }
    yield put(getNoteDetailSuccess(result))
  } catch (error) {
    yield put(getNoteDetailFail(error))
  }
}

function* onUpdateNote({ payload: note }) {
  try {
    const noteNew = {
      ...note,
      ClassID: note.Classes?.ClassID ? note.Classes?.ClassID : 0,
    }
    const response = yield call(updateNote, noteNew)
    let result = response[0]

    yield put(updateNoteSuccess(result))
    toast.success("Note updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateNoteFail(error))
    toast.error("Note updation failed", { autoClose: 2000 })
  }
}

function* onResetNote() {
  try {
    yield put(resetNoteSuccess({}))
  } catch (error) {}
}

function* onAddNoteSchedule({ payload: { noteschedule, noteRowId } }) {
  try {
    const response = yield call(addNoteSchedule, { noteschedule, noteRowId })

    yield put(addNoteScheduleSuccess(response))
  } catch (error) {
    yield put(addNoteScheduleFail(error))
  }
}

function* onUpdateNoteSchedule({ payload: noteschedule }) {
  try {
    const response = yield call(updateNoteSchedule, noteschedule)
    yield put(addNoteScheduleSuccess(response))
  } catch (error) {
    yield put(addNoteScheduleFail(error))
  }
}

function* fetchNoteSchedulesByNote({ RowID }) {
  try {
    const response = yield call(getNoteSchedulesByNote, RowID)
    yield put(getNoteSchedulesByNoteSuccess(response))
  } catch (error) {
    yield put(getNoteSchedulesByNoteFail(error))
  }
}
function* onUpdateNoteScheduleDetail({ payload: noteScheduleDetail }) {
  try {
    const response = yield call(updateNoteScheduleDetail, noteScheduleDetail)
    yield put(updateNoteScheduleDetailSuccess(response))
    toast.success("Note Schedule detail updated successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(updateNoteScheduleDetailFail(error))
    toast.error("Note Schedule detail updation failed", {
      autoClose: 2000,
    })
  }
}

function* onDeleteNoteSchedule({ payload: NoteScheduleID }) {
  try {
    const response = yield call(deleteNoteSchedule, NoteScheduleID)
    yield put(deleteNoteScheduleSuccess(response))
    toast.success("Note schedule deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteNoteScheduleFail(error))
    toast.error("Note schedule deletion failed", { autoClose: 2000 })
  }
}

function* onDeleteNoteScheduleDetail({ payload: NoteScheduleDetailID }) {
  try {
    const response = yield call(deleteNoteScheduleDetail, NoteScheduleDetailID)
    yield put(deleteNoteScheduleDetailSuccess(response))
    toast.success("Note schedule detail deleted successfully", {
      autoClose: 2000,
    })
  } catch (error) {
    yield put(deleteNoteScheduleDetailFail(error))
    toast.error("Note schedule detail deletion failed", {
      autoClose: 2000,
    })
  }
}

function* fetchNotesView({ payload: note }) {
  try {
    const response = yield call(getNotesView, note)
    yield put(getNotesViewSuccess(response))
  } catch (error) {
    yield put(getNotesViewFail(error))
  }
}

function* fetchNoteCategories() {
  try {
    const response = yield call(getNoteCategories)
    yield put(getNoteCategoriesSuccess(response))
  } catch (error) {
    yield put(getNoteCategoriesFail(error))
  }
}

function* noteSaga() {
  yield takeEvery(GET_NOTES, fetchNotes)
  yield takeEvery(DELETE_NOTE, onDeleteNote)
  yield takeEvery(ADD_NOTE, onAddNote)
  yield takeEvery(GET_NOTE_DETAIL, fetchNoteDetail)
  yield takeEvery(UPDATE_NOTE, onUpdateNote)
  yield takeEvery(RESET_NOTE, onResetNote)
  yield takeEvery(ADD_NOTE_SCHEDULE, onAddNoteSchedule)
  yield takeEvery(UPDATE_NOTE_SCHEDULE, onUpdateNoteSchedule)
  yield takeEvery(GET_NOTE_SCHEDULES_BY_NOTE, fetchNoteSchedulesByNote)
  yield takeEvery(UPDATE_NOTE_SCHEDULE_DETAIL, onUpdateNoteScheduleDetail)
  yield takeEvery(DELETE_NOTE_SCHEDULE, onDeleteNoteSchedule)
  yield takeEvery(DELETE_NOTE_SCHEDULE_DETAIL, onDeleteNoteScheduleDetail)
  yield takeEvery(GET_NOTES_VIEW, fetchNotesView)
  yield takeEvery(GET_NOTE_CATEGORIES, fetchNoteCategories)
}

export default noteSaga
