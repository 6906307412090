import React, { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty, map } from "lodash"
import * as moment from "moment"
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
// emoji
import EmojiPicker from "emoji-picker-react"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// simple bar
import SimpleBar from "simplebar-react"
import "simplebar-react/dist/simplebar.min.css"

import { ToastContainer } from "react-toastify"
import { getChatMessageImagePath } from "helpers/imageService"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getLatestChat as onGetLatestChat,
  getChatDetail as onGetChatDetail,
  deleteChatMessage as onDeleteChatMessage,
  addNewChat as onAddNewChat,
  addNewChatMessage as onAddChatMessage,
  getStaffs as onGetStaffs,
  getAdults as onGetAdults,
} from "store/actions"
import { useDeepCompareEffect } from "hooks"
import { getUserImagePath, getAdultImagePath } from "helpers/imageService"

const Chat = () => {
  const routeParams = useParams()
  //meta title
  document.title = "Chat"
  const dispatch = useDispatch()

  const [emoji, setEmoji] = useState(false)
  const [curChatMessage, setcurChatMessage] = useState("")
  const [selectedImage, setSelectedImage] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [isdisable, setDisable] = useState(false)
  const [selectedReplyChatMessage, setSelectedReplyChatMessage] = useState(null)
  const [threshhold, setthreshhold] = useState(0)
  const [disDefault, setdisDefault] = useState(0)
  const [activeTab, setactiveTab] = useState("1")
  const [receiverName, setReceiverName] = useState("")

  //Staffs
  const { staffs } = useSelector(state => state.staff)
  //adults
  const { adults } = useSelector(state => state.adult)

  const { chat, chatmessages, loading } = useSelector(state => state.chat)
  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    if (staffs && !staffs.length) {
      dispatch(onGetStaffs())
    }
  }, [dispatch, staffs])

  useEffect(() => {
    if (adults && !adults.length) {
      dispatch(onGetAdults())
    }
  }, [dispatch, adults])

  useEffect(() => {
    dispatch(onGetLatestChat()) // load data first time
    setReceiverName(chat.ReceiverName)
  }, [dispatch])

  // scroll simple bar
  const scroollRef = useRef(null)
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight
    }
  }, [chatmessages])

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y HH:mm")
    return date1
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setcurChatMessage(value)
      setDisable(true)
      addChatMessage()
    }
  }

  const addChatMessage = () => {
    if (curChatMessage !== "" || selectedImage !== null) {
      const newChatMessage = {
        ChatID: chat.ChatID,
        Title: curChatMessage,
        ChatMessageID:
          selectedReplyChatMessage != null
            ? selectedReplyChatMessage.ChatMessageID
            : 0,
        Photo: selectedFile,
      }
      dispatch(onAddChatMessage(newChatMessage))
      setcurChatMessage("")
      setSelectedReplyChatMessage(null)
      setdisDefault(false)
      setDisable(false)
      setEmoji(false)
      setSelectedImage(null)
      setSelectedFile(null)
    }
  }

  const deleteChatMessage = chatMessageID => {
    dispatch(onDeleteChatMessage(chatMessageID))
  }

  const replyChatMessage = chatMessage => {
    setSelectedReplyChatMessage(chatMessage)
  }

  const [copyChatMessageAlert, setCopyChatMessageAlert] = useState(false)
  const copyChatMessage = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
    setCopyChatMessageAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyChatMessageAlert(false)
      }, 1000)
    }
  }

  // emoji
  const [emojiArray, setemojiArray] = useState("")
  const onEmojiClick = (event, emojiObject) => {
    setemojiArray([...emojiArray, emojiObject.emoji])
    setcurChatMessage(curChatMessage + event.emoji)
    setDisable(true)
  }

  //  img upload
  const handleImageChange = event => {
    event.preventDefault()
    let allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/bmp",
    ]
    let reader = new FileReader()
    let file = event.target.files[0]
    if (allowedExtension.indexOf(file?.type) > -1) {
      reader.onloadend = () => {
        setSelectedImage(reader.result)
        setDisable(true)
      }
      reader.readAsDataURL(file)
      setSelectedFile(file)
    }
  }

  function threshholdDefault(event) {
    const count = event.target.value.length
    if (count > 0) {
      setdisDefault(true)
    } else {
      setdisDefault(false)
    }
    setthreshhold(event.target.value.length)
  }

  //Use For Chat Box
  const adultChatOpen = adult => {
    const newChat = {
      ReceiverID: adult.UserID,
    }
    setReceiverName(adult.FirstName + " " + adult.LastName)
    dispatch(onAddNewChat(newChat))
  }

  const staffChatOpen = staff => {
    const newChat = {
      ReceiverID: staff.UserID,
    }
    setReceiverName(staff.FirstName + " " + staff.LastName)
    dispatch(onAddNewChat(newChat))
  }

  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById("search-user")
    filter = input.value.toUpperCase()
    ul = document.getElementById("recent-list")
    li = ul.getElementsByTagName("li")
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0]
      txtValue = a.textContent || a.innerText
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = ""
      } else {
        li[i].style.display = "none"
      }
    }
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chats" breadcrumbItem="Chat" />
          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <img
                            src={getAdultImagePath("", "image.jpeg")}
                            className="avatar-xs rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">
                            {chat.SenderName}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav position-relative">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Adults</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Staffs</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            {/* <h5 className="font-size-14 mb-3">Recent</h5> */}
                            <ul className="list-unstyled chat-list">
                              {isLoading ? (
                                <Spinners setLoading={setLoading} />
                              ) : (
                                <SimpleBar style={{ maxHeight: "410px" }}>
                                  {map(
                                    adults.filter(
                                      a => a.UserID !== chat.SenderID
                                    ),
                                    (adult, index) => (
                                      <li
                                        key={`adult_${adult.id}_${index}`}
                                        className={
                                          adult.UserID === chat.ReceiverID
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            adultChatOpen(adult)
                                          }}
                                        >
                                          <div className="d-flex">
                                            <div className="align-self-center me-3">
                                              <i
                                                className={
                                                  adult.status === "online"
                                                    ? "mdi mdi-circle text-success font-size-10"
                                                    : adult.status ===
                                                      "intermediate"
                                                    ? "mdi mdi-circle text-warning font-size-10"
                                                    : "mdi mdi-circle font-size-10"
                                                }
                                              />
                                            </div>

                                            <div className="align-self-center me-3">
                                              <img
                                                className="rounded-circle avatar-xs"
                                                src={getAdultImagePath(
                                                  adult.RowID,
                                                  "image.jpeg"
                                                )}
                                                alt="Header Avatar"
                                              />
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                              <h5 className="text-truncate font-size-14 mb-1">
                                                {adult.Name}
                                              </h5>
                                            </div>
                                            <div className="font-size-11">
                                              {/* {staff.time} */}
                                            </div>
                                          </div>
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </SimpleBar>
                              )}
                            </ul>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div>
                            <ul className="list-unstyled chat-list">
                              {isLoading ? (
                                <Spinners setLoading={setLoading} />
                              ) : (
                                <SimpleBar style={{ maxHeight: "410px" }}>
                                  {map(
                                    staffs.filter(
                                      s => s.UserID !== chat.SenderID
                                    ),
                                    (staff, index) => (
                                      <li
                                        key={`staff${staff.id}_${index}`}
                                        className={
                                          staff.UserID === chat.ReceiverID
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            staffChatOpen(staff)
                                          }}
                                        >
                                          <div className="d-flex">
                                            <div className="align-self-center me-3">
                                              <i
                                                className={
                                                  staff.status === "online"
                                                    ? "mdi mdi-circle text-success font-size-10"
                                                    : staff.status ===
                                                      "intermediate"
                                                    ? "mdi mdi-circle text-warning font-size-10"
                                                    : "mdi mdi-circle font-size-10"
                                                }
                                              />
                                            </div>

                                            <div className="align-self-center me-3">
                                              <img
                                                className="rounded-circle avatar-xs"
                                                src={getUserImagePath(
                                                  staff.RowID,
                                                  "image.jpeg"
                                                )}
                                                alt="Header Avatar"
                                              />
                                            </div>

                                            <div className="flex-grow-1 overflow-hidden">
                                              <h5 className="text-truncate font-size-14 mb-1">
                                                {staff.Name}
                                              </h5>
                                            </div>
                                            <div className="font-size-11">
                                              {/* {staff.time} */}
                                            </div>
                                          </div>
                                        </Link>
                                      </li>
                                    )
                                  )}
                                </SimpleBar>
                              )}
                            </ul>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <h5 className="font-size-15 mb-1">{receiverName}</h5>
                        </Col>
                        <Col md="8" xs="3">
                          <div className="text-end d-none">
                            <i className="bx bx-bell bx-tada"></i>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <div className="chat-conversation p-3">
                        <SimpleBar ref={scroollRef} style={{ height: "486px" }}>
                          {isLoading ? (
                            <Spinners setLoading={setLoading} />
                          ) : (
                            <ul className="list-unstyled mb-0">
                              {chatmessages &&
                                (chatmessages || []).map(
                                  (chatMessage, index) => {
                                    return (
                                      <>
                                        <li
                                          key={"chatmessages" + index}
                                          className={
                                            "" +
                                            (chatMessage.IsSelfCreated &&
                                            chatMessage.ReplyToChatMessageID !=
                                              0
                                              ? "right"
                                              : "d-none")
                                          }
                                        >
                                          <div className="conversation-list mb-0">
                                            <div className="ctext-wrap bg-secondary-subtle">
                                              <div className="conversation-name">
                                                <b>Replying to-</b>
                                                {chatMessage.ReplyToUserName}
                                              </div>
                                              <p>
                                                {
                                                  chatMessage.ReplyToChatMessageTitle
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                        <li
                                          key={"messages" + index}
                                          className={
                                            "" +
                                            (chatMessage.IsSelfCreated
                                              ? "right"
                                              : "")
                                          }
                                        >
                                          <div className="conversation-list">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href="#!"
                                                tag="a"
                                                className="dropdown-toggle"
                                              >
                                                <i className="bx bx-dots-vertical-rounded" />
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  onClick={e =>
                                                    replyChatMessage(
                                                      chatMessage
                                                    )
                                                  }
                                                  href="#"
                                                >
                                                  Reply
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={e =>
                                                    copyChatMessage(e.target)
                                                  }
                                                  href="#"
                                                >
                                                  Copy
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={e =>
                                                    deleteChatMessage(
                                                      chatMessage.ChatMessageID
                                                    )
                                                  }
                                                  href="#"
                                                >
                                                  Delete
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <div className="ctext-wrap">
                                              <div className="conversation-name">
                                                {chatMessage.ModifiedByName}
                                              </div>
                                              <p>
                                                {chatMessage.FileName ? (
                                                  <a
                                                    href={getChatMessageImagePath(
                                                      chatMessage.RowID,
                                                      chatMessage.FileName
                                                        ? chatMessage.FileName
                                                        : "image.jpeg"
                                                    )}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    <img
                                                      className=""
                                                      src={getChatMessageImagePath(
                                                        chatMessage.RowID,
                                                        chatMessage.FileName
                                                          ? chatMessage.FileName
                                                          : "image.jpeg"
                                                      )}
                                                      style={{
                                                        maxWidth: "100px",
                                                        height: "auto",
                                                        marginRight: "2px",
                                                      }}
                                                      alt="File"
                                                    />
                                                  </a>
                                                ) : (
                                                  ""
                                                )}
                                                {chatMessage.Title}
                                              </p>

                                              <p className="chat-time mb-0">
                                                <i className="bx bx-time-five align-middle me-1"></i>
                                                {handleValidDate(
                                                  chatMessage.ModifiedDate
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    )
                                  }
                                )}
                            </ul>
                          )}
                        </SimpleBar>
                      </div>
                      {selectedImage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start px-3 bg-light">
                          <div className="flex-grow-1">
                            <img
                              src={selectedImage}
                              alt="select img"
                              style={{ maxWidth: "150px", height: "auto" }}
                            />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedImage(null)}
                            >
                              <i className="bx bx-x align-middle fs-2"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {selectedReplyChatMessage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start p-3 bg-light">
                          <div className="flex-grow-1 ctext-wrap">
                            <div className="conversation-name">
                              <b>Replying to-</b>
                              {selectedReplyChatMessage.ModifiedByName}
                            </div>
                            <p>{selectedReplyChatMessage.Title}</p>
                            <p className="chat-time mb-0">
                              <i className="bx bx-time-five align-middle me-1"></i>
                              {handleValidDate(
                                selectedReplyChatMessage.ModifiedDate
                              )}
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedReplyChatMessage(null)}
                            >
                              <i className="bx bx-x align-middle fs-2"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {copyChatMessageAlert && (
                        <UncontrolledAlert
                          color="warning"
                          dismissible
                          role="alert"
                        >
                          ChatMessage copied
                        </UncontrolledAlert>
                      )}
                      {emoji && (
                        <EmojiPicker
                          onEmojiClick={onEmojiClick}
                          width={250}
                          height={382}
                        />
                      )}
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={curChatMessage}
                                onKeyPress={onKeyPress}
                                onChange={e => {
                                  setcurChatMessage(e.target.value)
                                  setDisable(true)
                                  threshholdDefault(e)
                                }}
                                className="form-control chat-input"
                                placeholder="Enter Message..."
                                maxLength="500"
                              />
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li
                                    className="list-inline-item"
                                    onClick={() => setEmoji(!emoji)}
                                  >
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-emoticon-happy-outline me-1"
                                        id="Emojitooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Emojitooltip"
                                      >
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <label
                                      htmlFor="imageInput"
                                      style={{ color: "#556ee6", fontSize: 16 }}
                                    >
                                      <i
                                        className="mdi mdi-file-image-outline me-1"
                                        id="Imagetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Imagetooltip"
                                      >
                                        Images
                                      </UncontrolledTooltip>
                                    </label>
                                    <input
                                      type="file"
                                      id="imageInput"
                                      className="d-none"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                    />
                                  </li>
                                  {/* <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-document-outline"
                                        id="Filetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Filetooltip"
                                      >
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                            {disDefault ? (
                              <span className="badgecount badge bg-success">
                                {threshhold} / 500
                              </span>
                            ) : null}
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              disabled={!isdisable}
                              onClick={() => addChatMessage()}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Chat
