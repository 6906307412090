import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import classnames from "classnames"

import {
  getLiveData as onGetLiveData,
  getScheduledData as onGetScheduledData,
} from "store/actions"
import { isEmpty } from "lodash"
import { getChildImagePath, getUserImagePath } from "helpers/imageService"

const LiveStatusTab = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(onGetScheduledData(1))
    dispatch(onGetLiveData(1))
  }, [dispatch])

  const {
    liveclass,
    liveteacher,
    livechildren,
    scheduledteacher,
    scheduledchildren,
  } = useSelector(state => state.Dashboard)
  let idCounter = 0
  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table table-bordered mb-0 table">
          <thead>
            <tr>
              <th>Class</th>
              <th>Teachers</th>
              <th>Children</th>
              <th>Events</th>
              <th>Room</th>
              <th>Teachers</th>
              <th>Children</th>
            </tr>
          </thead>
          <tbody>
            {liveclass &&
              liveclass.map((row, i) => (
                <tr key={"th" + i}>
                  <th scope="row">{row.Title}</th>
                  <td>
                    {row.IsSystem
                      ? scheduledteacher.filter(x => x.ClassID === row.ClassID)
                          .length
                      : liveteacher.filter(x => x.ClassID === row.ClassID)
                          .length}
                  </td>
                  <td>
                    {row.IsSystem
                      ? scheduledchildren.filter(x => x.ClassID === row.ClassID)
                          .length
                      : livechildren.filter(x => x.ClassID === row.ClassID)
                          .length}
                  </td>
                  <td></td>
                  <td>{row.RoomID == 0 ? "-" : row.Room}</td>
                  <td>
                    <div className="avatar-group">
                      {row.IsSystem
                        ? scheduledteacher
                            .filter(x => x.ClassID === row.ClassID)
                            .map((tch, key) => {
                              idCounter++ // Increment the counter for each iteration
                              const linkId = `teacher_${idCounter}` // Generate unique ID for Link
                              const tooltipId = `teacher_${idCounter}` // Generate unique ID for UncontrolledTooltip
                              return (
                                <div key={key} className="avatar-group-item">
                                  <Link
                                    to="#"
                                    id={linkId}
                                    className="team-member d-inline-block"
                                  >
                                    <img
                                      className="rounded-circle avatar-xs"
                                      src={getUserImagePath(
                                        tch.RowID,
                                        "image.jpeg"
                                      )}
                                      alt="Avatar"
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={tooltipId}
                                    >
                                      {tch.Name}
                                    </UncontrolledTooltip>
                                  </Link>
                                </div>
                              )
                            })
                        : liveteacher
                            .filter(x => x.ClassID === row.ClassID)
                            .map((tch, key) => {
                              idCounter++ // Increment the counter for each iteration
                              const linkId = `teacher_${idCounter}` // Generate unique ID for Link
                              const tooltipId = `teacher_${idCounter}` // Generate unique ID for UncontrolledTooltip
                              return (
                                <div key={key} className="avatar-group-item">
                                  <Link
                                    to="#"
                                    id={linkId}
                                    className="team-member d-inline-block"
                                  >
                                    <img
                                      className="rounded-circle avatar-xs"
                                      src={getUserImagePath(
                                        tch.RowID,
                                        "image.jpeg"
                                      )}
                                      alt="Avatar"
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={tooltipId}
                                    >
                                      {tch.Name}
                                    </UncontrolledTooltip>
                                  </Link>
                                </div>
                              )
                            })}
                    </div>
                  </td>
                  <td>
                    <div className="avatar-group">
                      <div className="avatar-group">
                        {row.IsSystem
                          ? scheduledchildren
                              .filter(x => x.ClassID === row.ClassID)
                              .map((ch, key) => {
                                idCounter++ // Increment the counter for each iteration
                                const linkId = `children_${idCounter}` // Generate unique ID for Link
                                const tooltipId = `children_${idCounter}` // Generate unique ID for UncontrolledTooltip
                                return (
                                  <div key={key} className="avatar-group-item">
                                    <Link
                                      to="#"
                                      id={linkId}
                                      className="team-member d-inline-block"
                                    >
                                      <img
                                        className="rounded-circle avatar-xs"
                                        src={getChildImagePath(
                                          ch.RowID,
                                          "image.jpeg"
                                        )}
                                        alt="Avatar"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={tooltipId}
                                      >
                                        {ch.Name}
                                      </UncontrolledTooltip>
                                    </Link>
                                  </div>
                                )
                              })
                          : livechildren
                              .filter(x => x.ClassID === row.ClassID)
                              .map((ch, key) => {
                                idCounter++ // Increment the counter for each iteration
                                const linkId = `children_${idCounter}` // Generate unique ID for Link
                                const tooltipId = `children_${idCounter}` // Generate unique ID for UncontrolledTooltip
                                return (
                                  <div key={key} className="avatar-group-item">
                                    <Link
                                      to="#"
                                      id={linkId}
                                      className="team-member d-inline-block"
                                    >
                                      <img
                                        className="rounded-circle avatar-xs"
                                        src={getChildImagePath(
                                          ch.RowID,
                                          "image.jpeg"
                                        )}
                                        alt="Avatar"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={tooltipId}
                                      >
                                        {ch.Name}
                                      </UncontrolledTooltip>
                                    </Link>
                                  </div>
                                )
                              })}
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

LiveStatusTab.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LiveStatusTab)
