import PropTypes from "prop-types"
import React, { useEffect } from "react"
//controls
import {
  Col,
  Label,
  Row,
  Modal,
  ModalBody,
  Input,
  InputGroup,
  FormFeedback,
  ModalHeader,
  ModalFooter,
} from "reactstrap"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import { addStaffProfile as onAddStaffProfile } from "store/actions"
import LocationField from "components/Common/Location/Location"

const EmergencyContactModal = ({ staffID, profile, show, onCloseClick }) => {
  const dispatch = useDispatch()

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    FirstName: yup.string().required("Required"),
    LastName: yup.string().required("Required"),
    PhoneNo: yup.string().required("Required"),
    Address: yup.string(),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object().required("Required"),
    ZipCode: yup.string(),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      FirstName: "",
      LastName: "",
      PhoneNo: "",
      Address: "",
      Countries: "",
      States: "",
      Cities: "",
      ZipCode: "",
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    function updateState() {
      if (show) {
        reset(profile)
      }
    }

    updateState()
  }, [show, dispatch])

  const onSaveClick = () => {
    trigger()
    if (isValid) {
      dispatch(onAddStaffProfile(staffID, getValues()))
      onCloseClick()
    }
  }

  const onClosed = () => {
    reset()
  }

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      onClosed={() => onClosed()}
      centered={true}
    >
      <ModalHeader className="d-flex justify-content-center">
        Edit Emergency Contact
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <FormProvider {...methods}>
          <Row>
            <Col lg={12}>
              <div className="mb-3">
                <Label>First Name</Label>
                <Controller
                  name="FirstName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="FirstName"
                        type="text"
                        required
                        invalid={!!errors.FirstName}
                      />
                      {errors?.FirstName?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.FirstName?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Last Name</Label>
                <Controller
                  name="LastName"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="LastName"
                        type="text"
                        required
                        invalid={!!errors.LastName}
                      />
                      {errors?.LastName?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.LastName?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <Label>Phone Number</Label>
                <Controller
                  name="PhoneNo"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        id="PhoneNo"
                        type="number"
                        invalid={!!errors.PhoneNo}
                      />
                      {errors?.PhoneNo?.message ? (
                        <FormFeedback type="invalid" className="d-block">
                          {errors?.PhoneNo?.message}
                        </FormFeedback>
                      ) : null}
                    </>
                  )}
                />
              </div>
              <div className="mb-3">
                <LocationField />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-wrap gap-2">
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={() => onSaveClick()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn btn-secondary"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

EmergencyContactModal.propTypes = {
  staffID: PropTypes.number,
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default EmergencyContactModal
