import React, { useEffect, useState, Fragment } from "react"
import { Table, Row, Col, Badge } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

import { getChildImmunizations as onGetChildImmunizations } from "store/actions"
import DoseModal from "../modal/DoseModal"

function Immunization() {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState({})

  const { child } = useSelector(state => state.child)

  useEffect(() => {
    dispatch(onGetChildImmunizations(child.ChildID))
  }, [])

  const { childimmunizations } = useSelector(state => state.immunization)

  const onDoseModalClick = arg => {
    setData(arg)
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const ApplyStyle = (doseDate, dueDate, optOut) => {
    if (optOut) {
      return 3
    }

    if (Moment(doseDate).isValid()) {
      return 1
    } else {
      if (Moment().diff(dueDate, "day") > 0) {
        return 2
      }
    }
  }

  return (
    <Fragment>
      {showModal && (
        <DoseModal
          show={showModal}
          data={data}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <div className="table-responsive">
        <Table className="table table-bordered border-secondary mb-0">
          <thead>
            <tr>
              <th>Vaccine</th>
              <th>Dose 1</th>
              <th>Dose 2</th>
              <th>Dose 3</th>
              <th>Dose 4</th>
              <th>Dose 5</th>
            </tr>
          </thead>
          <tbody>
            {childimmunizations.map((n, i) => {
              return (
                <tr key={i}>
                  <td>
                    {n.ShortName}
                    <p>
                      {n.IsRequired ? (
                        <Badge className="bg-success"> Required</Badge>
                      ) : (
                        <Badge className="bg-danger"> Optional </Badge>
                      )}
                    </p>
                  </td>
                  {n.Doses.map((v, j) => {
                    return (
                      <td
                        key={i + j}
                        style={
                          ApplyStyle(v.DoseDate, v.DueDate, v.Optout) == 1
                            ? { backgroundColor: "#D9F5C9", color: "" }
                            : ApplyStyle(v.DoseDate, v.DueDate, v.Optout) == 2
                            ? { backgroundColor: "#FBEBF1", color: "#FC397A" }
                            : {}
                        }
                        className={v.IsApplicable ? "cursor-pointer" : ""}
                        onClick={() =>
                          v.IsApplicable ? onDoseModalClick(v) : {}
                        }
                      >
                        {v.Optout
                          ? "Opted Out"
                          : v.DoseDate
                          ? Moment(v.DoseDate).format("DD/MM/yyyy")
                          : Moment(v.DueDate).isValid()
                          ? "by " + Moment(v.DueDate).format("DD/MM/yyyy")
                          : ""}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </Fragment>
  )
}
export default Immunization
