import React, { useEffect, useState, useMemo, Fragment } from "react"
import { Table, Row, Col, Badge, UncontrolledTooltip } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Moment from "moment"
import { ToastContainer } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { isEmpty } from "lodash"

import {
  deleteTag as onDeleteTag,
  getTags as onGetTags,
  getTagCategory as onGetTagCategory,
} from "store/actions"
import TagModal from "../modal/TagModal"
import DeleteModal from "../../../components/Common/DeleteModal"
import EmptyContainer from "components/Common/EmptyContainer"
import TableContainer from "components/Common/TableContainer"
import { useDeepCompareEffect } from "hooks"

function TagTab() {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [data, setData] = useState()

  const { child, tags } = useSelector(state => state.child)

  useDeepCompareEffect(() => {
    dispatch(onGetTagCategory())
  }, [dispatch])

  useEffect(() => {
    dispatch(onGetTags(child.ChildID))
  }, [dispatch])

  const onClickDelete = arg => {
    setData(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (data && data.TagID) {
      dispatch(onDeleteTag(data.TagID))
      setDeleteModal(false)
    }
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  const handleAddClick = () => {
    setData({})
    setShowModal(true)
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM yyyy")
  }

  const onClickEdit = arg => {
    let tag = {
      ...arg,
      TagCategories: {
        TagCategoryID: arg?.TagCategoryID,
        TagCategory: arg?.TagCategory,
      },
      ExpiryDate: Moment(arg.ExpiryDate).format("DD MMM yyyy"),
    }
    setData(tag)
    setShowModal(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "Title",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Category",
        accessor: "TagCategory",
        Cell: cellProps => {
          return cellProps.value ? cellProps.value : ""
        },
      },
      {
        Header: "Expiry Date",
        accessor: "ExpiryDate",
        Cell: cellProps => {
          return cellProps.value ? handleValidDate(cellProps.value) : "n/a"
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  onClickEdit(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}

      {showModal && (
        <TagModal
          show={showModal}
          data={data}
          onCloseClick={() => onCloseModal()}
        />
      )}
      <Row>
        <Col xs="12">
          {data?.length == 0 ? (
            <EmptyContainer
              backURL=""
              message="No tags found for child"
              linkText=""
            />
          ) : (
            <TableContainer
              columns={columns}
              data={tags}
              isGlobalFilter={true}
              isAddOption={true}
              addOptionText="Add Tag"
              handleAddClick={handleAddClick}
              customPageSize={10}
              isPagination={true}
              filterable={false}
              iscustomPageSizeOptions={true}
              tableClass="align-middle table-nowrap table-check"
              theadClass="table-light"
              pagination="pagination pagination-rounded justify-content-end mb-2"
            />
          )}
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  )
}
export default TagTab
