import {
  GET_FEE_TRANSACTION_SUCCESS,
  GET_FEE_TRANSACTION_FAIL,
  ADD_FEE_TRANSACTION_SUCCESS,
  ADD_FEE_TRANSACTION_FAIL,
  GET_FEE_TRANSACTION_DETAILS_SUCCESS,
  GET_FEE_TRANSACTION_DETAILS_FAIL,
  GET_PAID_FEE_TRANSACTION_SUCCESS,
  GET_PAID_FEE_TRANSACTION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  feetransactions: [],
  feetransactiondetails: [],
  paidfeetransactions: [],
  error: {},
  loading: true,
}

const Fee = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEE_TRANSACTION_SUCCESS:
      return {
        ...state,
        feetransactions: action.payload,
        loading: true,
      }
    case GET_FEE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_FEE_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        feetransactiondetails: action.payload,
        loading: true,
      }
    case GET_FEE_TRANSACTION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_FEE_TRANSACTION_SUCCESS:
      return {
        ...state,
        feetransactions: action.payload,
        loading: true,
      }
    case ADD_FEE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PAID_FEE_TRANSACTION_SUCCESS:
      return {
        ...state,
        paidfeetransactions: action.payload,
        loading: true,
      }
    case GET_PAID_FEE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Fee
